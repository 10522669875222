import React, { useState, useEffect } from 'react'
import Header from '../Header/header'
import Footer from '../Footer/Footer'
import Container from '@mui/material/Container';
import { Box, Button, Grid, createTheme, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { Country, State, City } from 'country-state-city'
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { Bottomnavigatevalue, Handlemyaddress, Handlemyplace } from '../Redux/action';
import Bottomnavigation from '../Home/BottomNavigation';
import { useNavigate } from 'react-router-dom';
import Addressskeleton from './Addressskeleton';
import UpdateIcon from '@mui/icons-material/Update';


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:'20px',
    marginBottom:'20px'
    
   }));

const Myaddress = () => {


    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });

     const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
     const businessname=localStorage.getItem('businessname');
     const Baseurl = process.env.REACT_APP_BASE_URL;
     const access = localStorage.getItem('accessToken');
     const businessId=localStorage.getItem('storeId')

    

     const Myaddress=useSelector(state=>state.address)

     const Myplace=useSelector(state=>state.place)

     const dispatch=useDispatch()
     const navigate = useNavigate()


     const customBooking=
     {
   
     backgroundColor:'#4D1BDB',
     color:'var(--white)',
   fontSize:'16px',
   fontWeight: 500,
   textTransform:'none',
   marginTop:'20px',
   letterSpacing: '0.8px',  
     '&:hover': {
       backgroundColor: '#4D1BDB', 
     },
     }

     const customReschedule=
     {
         backgroundColor:'#ffffff',
     color: '#4D1BDB',
    fontSize:'16px',
    fontWeight: 500,
   border:'1px solid #4D1BDB',
   letterSpacing: '0.8px',  
   textTransform:'none',
   marginTop:'20px',
     '&:hover': {
       backgroundColor: '#ffffff', 
     },
 
     }

     const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));
  
    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

     const[userData, setuserData] = useState({
  
       address1:"",
       address2:"",
       pincode:"",
       city:"",
       state:"",
      

     })

     const deletebuttons=
     {
         color:'var(--primary)',
         textTransform:'none',
       }
   
     const [openmodal, setopenmodal] = useState();

     const CountryData = Country.getAllCountries()

     const [stateData, setStateData] = useState()
     const [state, setState] = useState('')

     const timerStarted = false;

     const [showAddress, setShowAddress] =useState(Myaddress)
     const [address, setaddress]=useState()
     const [customer, setcustomer]=useState()
     const [isLoading, setIsLoading]=useState(true)
     const [update,setupdate]=useState();
     const [updateId, setupdateId]=useState()
     
     const [loading, setloading]=useState(false)

     const [country, setCountry] = useState(CountryData[100])

     useEffect(() => {
      setStateData(State.getStatesOfCountry(country?.isoCode))
    }, [country, state])

  


    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
  
    
  
     const [openSnackbar1, setopenSnackbar1]=useState({
  
      openSnackbar: false,
      vertical: 'top',
      horizontal: 'center',
     });
  
  
     const { vertical, horizontal, openSnackbar } = openSnackbar1;
  
     const handleClose = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setopenSnackbar1({...openSnackbar1, openSnackbar:false});
  
    };

    const [openSnackbar2, setopenSnackbar2]=useState({

      opentoast: false,
      verticaltoast: 'top',
      horizontaltoast: 'center',
     });
  
  
     const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;
  
     const handleCloseToast = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setopenSnackbar2({...openSnackbar2, opentoast:false});
  
    };

    const [openSnackbar3, setopenSnackbar3]=useState({

      opentoast1: false,
      verticaltoast1: 'top',
      horizontaltoast1: 'center',
     });

    const { verticaltoast1, horizontaltoast1, opentoast1 } = openSnackbar3;
  
    const handleCloseToast1 = (reason) => {
     if (reason === 'clickaway') {
       return;
     }
     setopenSnackbar3({...openSnackbar3, opentoast1:false});
 
   };
  

    useEffect(()=>
    {
      
      const apiUrl8=`${Baseurl}/consumer/customer-address/`;
 
      const fetchData = async()=>
      {
          try 
          {
            setloading(true)
           const response2=await fetch(apiUrl8,
            {
              headers: {
                'Authorization' : `Bearer ${access}`
               }
            });

            const Update=await response2.json();;
            const address=Update.address_list;
            const profile=Update.customer_data;

            setaddress(address);
            setcustomer(profile);
            setloading(false)
            setIsLoading(false);

          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
      }
      if (isLoading) {
        fetchData();
      }
  
  
    },[isLoading,access])
    

     const [errors, setErrors] = useState()


     const handleChange = e => {
      e.preventDefault()
  
      const name = e.target.name
      const value = e.target.value
  
      const newErrors = {}
  
      if (name === 'address1' && value.length === 0) {
        newErrors.address1 = 'Address1 is required'
      } else {
        newErrors.address1 = ''
      }
     
      if (name === 'address2' && value.length === 0) {
        newErrors.address2 = 'Address2 is required'
      } else {
        newErrors.address2 = ''
      }
  
      if (name === 'pincode' && value.length === 0) {
        newErrors.pincode = 'Pincode is required'
      } else {
        newErrors.pincode = ''
      }

      if (name === 'city' && value.length === 0) {
        newErrors.city = 'City is required'
      } else {
        newErrors.city = ''
      }

      if (name === 'state' && value.length===0) {
        newErrors.state = 'State is required'
      } else {
        newErrors.state = ''
      }
  
  
      if (
        newErrors.address1 === '' &&
        newErrors.address2 === '' &&
        newErrors.pincode === '' &&
        newErrors.city === '' &&
        newErrors.state === ''
      ) {
        setErrors('')
      } else {
       
        setErrors(newErrors)
      }
  
      setuserData({ ...userData, [name]: value })
    }
  

    const handleSubmit=(newState)=>
    {
      const newErrors = {}
  
      if (userData.address1 === "") {
        newErrors.address1 = 'Address1 is required'
      } else {
        newErrors.address1 = ''
      }
  
      if (userData.address2 === "") {
        newErrors.address2 = 'Address2 is required'
      } else {
        newErrors.address2 = ''
      }
  
      if (userData.pincode === "") {
        newErrors.pincode = 'Pincode is required'
      } else {
        newErrors.pincode = ''
      }
  
      if (userData.city=== "") {
        newErrors.city = 'City is required'
      } else {
        newErrors.city = ''
      }
  
      if (userData.state === "") {
        newErrors.state = 'State is required'
      } else {
        newErrors.state = ''
      }
      
      if (
        newErrors.address1 === '' &&
        newErrors.address2 === '' &&
        newErrors.pincode === '' &&
        newErrors.state === '' &&
        newErrors.city === '' 
      ) {
           setErrors({})
           setuserData({address1:"", address2:"", pincode:"",state:"",city:""})
         
           if(update==='Update')
           {
          
             let myHeaders = new Headers();
             myHeaders.append("Authorization", `Bearer ${access}`);

             let formdata = new FormData();
             formdata.append("business_owner_id", businessId);
             formdata.append("_id", updateId);
             formdata.append("ca_address1", userData.address1);
             formdata.append("ca_address2", userData.address2);
             formdata.append("ca_city", userData.city);
             formdata.append("ca_state", userData.state);
             formdata.append("ca_pin_code", userData.pincode);
             formdata.append("ca_country", "india");
             
             let requestOptions = {
               method: 'PATCH',
               headers: myHeaders,
               body: formdata,
               redirect: 'follow'
             };
             
             fetch(`${Baseurl}/consumer/customer-address/`, requestOptions)
               .then(response => response.text())
               .then(result=>{
                 const data= JSON.parse(result);
                 console.log(data.status);
                 if(data.status==='success')
                 {
                  setopenSnackbar1({ ...newState, openSnackbar: true })
                    setIsLoading(true)
                    dispatch(Handlemyaddress(true));
                    setShowAddress(true)
                 } 
                 
           })
               .catch(error => console.log('error', error));
               
           }
          
          else 
          {
               
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);

            let formdata = new FormData();
            formdata.append("business_owner_id",  businessId);
            formdata.append("ca_address1", userData.address1);
            formdata.append("ca_address2", userData.address2);
            formdata.append("ca_city", userData.city);
            formdata.append("ca_state", userData.state);
            formdata.append("ca_pin_code", userData.pincode);
            formdata.append("ca_country", "india");
            
            let requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
            };
            
            fetch(`${Baseurl}/consumer/customer-address/`, requestOptions)
              .then(response => response.text())
              .then(result=>{
                const data= JSON.parse(result);
                console.log(data.status); 
                if(data.status==='success')
                {
                  setopenSnackbar2({ ...newState, opentoast: true })
                   
                  dispatch(Handlemyaddress(true));
                   if(!Myplace)
                   {
                     setIsLoading(true)
                     setShowAddress(true)
                   }
                   

                   if (!timerStarted && Myplace) {

                    const timer2 = setTimeout(() => {
                      dispatch(Handlemyplace(false))
                      navigate(`/${businessname}/address`)
                      
                    }, 1200);
                    return () => clearTimeout(timer2);
                         
                }
                }
               
          })
              .catch(error => console.log('error', error));
              
          } 
         
      }

      else 
      {
        setErrors(newErrors)
      }

    }
    
    
    const Cancel=()=>
    {
       setErrors({})
       dispatch(Handlemyaddress(true));
       setShowAddress(true)
       setuserData({address1:"", address2:"", pincode:"",state:"",city:""})
    }

    const newaddress=()=>
    {
      setShowAddress(false)
      dispatch(Handlemyaddress(false));
        setupdate('')
       
    }

    const Updateaddress=(value,Id)=>
    {

       const selectedArray = address?.filter((item) => item.pk=== Id);

       setuserData({address1:selectedArray[0].ca_address1, address2:selectedArray[0].ca_address2, pincode:selectedArray[0].ca_pin_code, state:selectedArray[0].ca_state,city:selectedArray[0].ca_city})
         setupdateId(Id)
         setupdate(value)
         dispatch(Handlemyaddress(false));
        setShowAddress(false)
    }

    const Delete=(Id)=>
    {
       
        setupdateId(Id)
       setopenmodal(true);
        
    }

    const Cancelmobile=()=>
    {
        setopenmodal(false);
        
    }



    const Deleteaddress=(newState)=>
    {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
      
      let formdata = new FormData();
      formdata.append("_id", updateId);
      formdata.append("business_owner_id", businessId);
      
      let requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/customer-address/`, requestOptions)
        .then(response => response.text())
        .then(result=>{
          const data= JSON.parse(result);
          console.log(data.status); 
          if(data.status==='success')
          {
             setIsLoading(true)
            setopenSnackbar3({ ...newState, opentoast1: true })
            setopenmodal(false);
          }
          
    })
        .catch(error => console.log('error', error));
    }

    console.log(customer)

  return (
     <>
           <div className='contain'>
            <Header/>
            <div className='content'>

            <section className='section'>
              <Container maxWidth = 'lg'>

              <div className="breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
            <StyledNavLink to={`/${businessname}/`}>
             Home
           </StyledNavLink>
           <StyledNavLink to={`/${businessname}/viewcart`}>
             Cart
           </StyledNavLink>
           <StyledNavLink to={`/${businessname}/address`}>
             Checkout
           </StyledNavLink>
        <StyleNavLink to={`/${businessname}/my-address`}>
         My Addresses
        </StyleNavLink>
      </Breadcrumbs>
      </div>
       
            {loading ?
            <>
              <Addressskeleton/>
            </>
             :
           

              <div className='explore_container'>
            <Box sx={{width:'100%'}}>
            <Grid container display="flex" justifyContent="center">
            <Grid  item xs={12} sm={isSmScreen ? 12 : 9} md={9}>

          {showAddress ?  
           
           <div>
          <div className="personalinfo" style={{paddingBottom:'20px'}}>My Addresses</div>

         {address && customer && address.length!==0 && <div>
          {address.map(cur=>
            {
               return (
                <>
                  <Item className='global_box_shadow'>
         
                  <div style={{display:'grid', gridRowGap:'6px'}}>
        
        <div className='priceonline'>{customer[0].c_name}</div>
        <div className='priceonline'>Mobile: +91-{customer[0].c_mobile_no}</div>
        <div className='cash'>{cur.ca_address1} {cur.ca_address2}</div>

        <div className='cash'>{cur.ca_city}, {cur.ca_state} - {cur.ca_pin_code}</div>
       
        </div>

          <div style={{display:'flex', justifyContent:'flex-end', marginRight:'10px', gridColumnGap:'12px'}}>

           <div style={{cursor:'pointer'}} onClick={()=>Updateaddress('Update', cur.id)}><UpdateIcon  style={{color:'gray'}}/></div>
           <div style={{cursor:'pointer'}} onClick={()=>Delete(cur.pk)}><DeleteIcon style={{color:'gray'}}/></div>
           </div>

         </Item>
                </>
               )
            })}
          </div>}
         

          <div style={{display:'flex', justifyContent:'flex-end', marginRight:'10px'}}>
          <Button style={customBooking} className='secondary_button' onClick={newaddress}>Add new address</Button>
           </div>
          </div>
          
          :  <Item className='global_box_shadow'>
            <div className="personalinfo">Add Address</div>

            <Grid container  columnSpacing={4} rowGap={2} display="flex" justifyContent="space-between">
              <Grid  item xs={12} sm={isSmScreen ? 12 : 6} md={6}>
            

              <TextField
               fullWidth
          id="standard-search"
          label={
            <span>
               Address1 <span style={{ color: 'red' }}>*</span>
            </span>
          }
          name='address1'
          value={userData.address1}
          error={Boolean(errors?.address1)}
          helperText={errors?.address1}
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
      
        <Grid  item xs={12} sm={isSmScreen ? 12 : 6} md={6}>

          
        <TextField
               fullWidth
          id="standard-search"
          label={
            <span>
               Address2 <span style={{ color: 'red' }}>*</span>
            </span>
          }
          name='address2'
          value={userData.address2}
          error={Boolean(errors?.address2)}
          helperText={errors?.address2}
          onChange={handleChange}
          variant="standard"
        />

        
        </Grid>
          <Grid  item xs={12} sm={isSmScreen ? 12 : 6} md={6}>
             
          <TextField
               fullWidth
          id="standard-search"
          label={
            <span>
               City <span style={{ color: 'red' }}>*</span>
            </span>
          }
          name='city'
          value={userData.city}
          error={Boolean(errors?.city)}
          helperText={errors?.city}
          onChange={handleChange}
          variant="standard"
        />
 
              </Grid>


              <Grid  item xs={12} sm={isSmScreen ? 12 : 6} md={6} >

              <TextField
                fullWidth
          id="standard-select-currency"
          select
          label={
            <span>
               State <span style={{ color: 'red' }}>*</span>
            </span>
          }
           name="state"
          value={userData.state}
          onChange={handleChange}
          error={Boolean(errors?.state)}
          helperText={errors?.state}
          variant="standard"
        >
          {stateData?.map((option) => (
            <MenuItem key={option.isoCode} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
             

         </Grid>

       <Grid  item xs={12} sm={isSmScreen ? 12 : 6} md={6} >
       <TextField
               fullWidth
          id="standard-search"
          label={
            <span>
               Pincode <span style={{ color: 'red' }}>*</span>
            </span>
          }
          name='pincode'
          value={userData.pincode}
          error={Boolean(errors?.pincode)}
          helperText={errors?.pincode}
          onChange={handleChange}
          variant="standard"
        />
              
        </Grid>

        <Grid  item xs={12} sm={isSmScreen ? 12 : 6} md={6} >

             <TextField
                fullWidth
          id="standard-select-currency"
          select
          label={
            <span>
               Country <span style={{ color: 'red' }}>*</span>
            </span>
          }
          defaultValue='India'
          variant="standard"
        >
           <MenuItem value='India'>India</MenuItem>
        </TextField>
       
              </Grid>


          </Grid>

            <div style={{display:'flex', justifyContent:'flex-end', marginRight:'10px', gridColumnGap:'20px'}}>

            <Button style={customReschedule} className='secondary_button' onClick={Cancel}>Cancel</Button>
            <Button style={customBooking} className='secondary_button' onClick={()=>handleSubmit({ vertical: 'top', horizontal: 'center' })}>Add address</Button>
            </div>

            </Item>
}
             </Grid>
            </Grid>
            </Box>

              </div>
      }

              <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                          Updated address successfully!
                           </Alert>
                      </Snackbar>

                    </Stack>

                       <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                        Created new address successfully!
                           </Alert>
                      </Snackbar>

                    </Stack>

                      <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast1} autoHideDuration={2000}  key={verticaltoast1 + horizontaltoast1}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast1}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                         Deleted address successfully!
                           </Alert>
                      </Snackbar>

                    </Stack>

                    <Dialog
       
       open={openmodal}
       aria-labelledby="responsive-dialog-title"
     >
       <DialogTitle id="responsive-dialog-title">
         {"Delete Address"}
       </DialogTitle>
       <DialogContent>
         <DialogContentText>
         Are you sure you want to delete the address ?
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button autoFocus style={deletebuttons} onClick={Cancelmobile}>Cancel</Button>
         <Button style={deletebuttons} onClick={()=>Deleteaddress({ vertical: 'top', horizontal: 'center' })}>Yes, Delete</Button>
       </DialogActions>
     </Dialog>


              </Container>
            </section>

              
            </div>
            <Bottomnavigation/>
            <Footer/>
           </div>
     </>
  )
}

export default Myaddress

/*    <TextField
          error
          id="standard-error-helper-text"
          label="Error"
          defaultValue="Hello World"
          helperText="Incorrect entry."
          variant="standard"
        />
        */
