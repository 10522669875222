import React from "react";
import { Box, Grid} from "@mui/material";
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import 'react-loading-skeleton/dist/skeleton.css'
import Exploreskeleton from "./Exploreskeleton";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {createTheme,  useMediaQuery } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: '6px',

  }));


const ExploreCategory=({value, loading})=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });


  const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: 'inherit',
    fontSize:'12px',
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const StyleNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    ariaCurrent:"page",
    fontSize:'12px',
    color:"var(--primary)",
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));


  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

 
  const businessname=localStorage.getItem('businessname');
  

  const navigate= useNavigate();

  function handleCategory(values, names)
  {
    if(window.location.pathname!==`${businessname}/search`)
    {
      
      navigate(`/${businessname}/search`);
      localStorage.setItem("CategoryId" ,values);
      localStorage.setItem("Categoryname",names);
    }
  }

 

    return(
        <>
          <section className="section explore-padding">
      
             <Container maxWidth="lg" className="container-padding" >
           
         
         
              {loading ? 

                <>

             <Exploreskeleton/> 
        </>

                  :
                  
            
              <div className="explore_container">
             
             <h1 className="head_title">Explore Category</h1>

             <Box sx={{ width: '100%' }}>
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 2, sm: 3, md: 4 }}
        display="flex"
        justifyContent="start"
        minWidth="18rem"
        height='100%'
      >
        {value.map((cur, index) => (
          <Grid item key={index} xs={3} sm={isSmScreen ? 3 : 1.5} md={1.5}>
            <div className="categoriesdata">
              <div className="global_box_explore" onClick={()=>handleCategory(cur._id, cur.bo_category_name)}>
              <div className="category_name"><LazyLoadImage  src={cur.bo_category_image_url!=="" ? cur.bo_category_image_url : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                   alt={cur.bo_category_image_url!=="" ? 'category' : 'No Image'} onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} /></div> 
                <div className="explore_categoryname" >
                  {cur.bo_category_name}
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
           
             </div>
              }
     </Container>
     </section>

        </>
    )
};

export default ExploreCategory;

/* 

    <div className="breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>

        <StyleNavLink to={`/${businessname}/all-category`}>
          Categories
        </StyleNavLink>
      </Breadcrumbs>
      </div>

onClick={()=>handleCategory(cur.category_id, cur.category_name)}
                      <div className="category_name"><LazyLoadImage src={cur.category_image_light} alt="category"/></div> 
                      <div className="explore_categoryname" >{cur.category_name}</div>


 <div className="global_box_explore"  onClick={()=>handleCategory(cur.category_id, cur.category_name)}>
                      <div className="category_name"><LazyLoadImage src={cur.category_image_light} alt="category"/></div> 
                      <div className="explore_categoryname" >{cur.category_name}</div>
                         </div>

                    <div className="category_name"><LazyLoadImage src={cur.category_image_light} alt="category"/></div> 
                         <div className="explore_categoryname" >{cur.category_name}</div>


 <div className="categoriesdata" onClick={()=>handleCategory(cur.category_id, cur.category_name)}>
                      
                        
                         <div>

                        <img className="category_name" src={cur.category_image_light} alt="category"/>
                         <div className="explore_namecategory" >{cur.category_name}</div>
                         </div>
                         </div>








  line no. 60   className="category_image_light"
          +1       className="explore_namecategory"

         58        className="explore_categorydata"








                 
   const [item, setItem] = useState([]);
    const access= JSON.parse(localStorage.getItem("access_token"));
 
    useEffect(()=>
    {
      
      if(access!==null)
      {  
    
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}` );
    
         var requestOptions = {
          method: 'GET',
          headers: myHeaders,
           redirect: 'follow'
      };
      
       fetch("https://prod-lavandel.azurewebsites.net/api/service-by-category-without-token/1/", requestOptions)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
          const  Categorydata = data.categories;
    
          setItem( Categorydata);
         

        })
        .catch(error => console.log('error', error));
      
      }

      else
      {
        var requestOptions = {
          method: 'GET',
        
           redirect: 'follow'
      };
      
       fetch("https://prod-lavandel.azurewebsites.net/api/service-by-category-without-token/1/", requestOptions)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
          const  Categorydata = data.categories;
    
          setItem( Categorydata );
      
    
        })
        .catch(error => console.log('error', error));
      }
    
    },[])


*/