import React, { useState, useEffect } from "react";
import Auth from "./Authentication/Login";
import { Route, Routes, Navigate  } from "react-router-dom";
import SignUp from "./Authentication/SignUp";
import Home from "./Home/Home";
import Verifycode from "./Authentication/Verifycode";
import Verification from "./Authentication/Verification";
import Searchshow from "./Home/Searchshow";
import Checkout from "./Product/Checkout";
import Connect from "./Authentication/Connect";
import SearchSuggest from "./Header/searchsuggestion";
import Review from "./Product/Review";
import ProfileDetail from "./Header/ProfileDetail";
import Checkoutdetails from "./Checkout/Checkoutdetails";
import Applycoupon from "./Checkout/Applycoupon";
import Schedule from "./Schedule/Schedule";
import Booking from "./Booking/Booking";
import Paymentgatway from "./Schedule/Paymentgetway";
import BookingSuccessfull from "./Schedule/BookingSuccessfull";
import Accepted from "./Booking/Accepted";
import Bookingreview from "./Booking/Bookingreview";
import Cancelled from "./Booking/Cancelled";
import AcceptedItem from "./Booking/AcceptedItem";
import Confirmdelete from "./Booking/Confirmdelete";
import Error from "./Schedule/Errorpage";
import Thanking from "./Schedule/Thanking";
import Privacypolicy from "./Footer/Privacypolicy";
import {useNavigate} from 'react-router-dom';
import Terms from "./Footer/Terms";
import Shipping from "./Footer/Shipping";
import Emptycart from "./Checkout/Emptycart";
import { useLocation } from 'react-router-dom';
import EmptyStore from "./Home/EmptyStore";
import Page404 from "./Home/Page404";
import { useDispatch, useSelector } from "react-redux";
import Verifymobile from "./Authentication/Verifymobile";
import Aboutpage from "./Footer/Aboutpage";
import Allcategory from "./Allcategory/category";
import Payment from "./Payment/Payment";
import Myaddress from "./Myaddress/Address";
import Myaccount from "./Home/Myaccount";
import Bookingcancel from "./Booking/Bookingcancel";
import Emptybookmark from "./Bookmark/Emptybookmark";
import Bookmark from "./Bookmark/Bookmark";
import { HandleLogoitem, Handlemyhomeload, Showdatavalue, UpdateName } from "./Redux/action";
import  jwtDecode  from 'jwt-decode';
import Refund from "./Footer/Refund";
import Return from "./Footer/Return";






function App() {

  
   const businessname=localStorage.getItem('businessname');

   const accessToken=localStorage.getItem("accessToken")
   
   const [loading, setloading]=useState(false)

  const Showdata=useSelector(state=>state.Showdata)

  const Homeload=useSelector(state=>state.homeload)

  const Baseurl = process.env.REACT_APP_BASE_URL;

  const navigate=useNavigate();
  const dispatch=useDispatch()

   const [showhome,setshowhome]=useState(true)

  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/');
  const encodedPart = pathSegments[1]; 
  const decodedPart = decodeURIComponent(encodedPart);

  const [prevDecodedPart, setPrevDecodedPart] = useState('null');


  const parseDateToUnixTimestamp = (dateString) => {
    return new Date(dateString).getTime() / 1000; 
  };

  useEffect(()=>
  {
    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken); 
        const currentTime = Date.now() / 1000; 


      const expiringOn = parseDateToUnixTimestamp(decodedToken.expiring_on);
  
        if (expiringOn < currentTime) {
          navigate(`/${businessname}/`)
          localStorage.clear();
          dispatch(UpdateName(""));
          return null;
        }
      } catch (error) {
        console.error('Error decoding token:', error); 
        return null;
      }
    } 
  },[accessToken])


useEffect(() => {
  if (decodedPart!=="") {
    document.title = `${decodedPart} - Online Shopping Site`;
  } else
  {
    localStorage.removeItem('businessname');
    localStorage.removeItem('storeId');
    document.title='Store - Online Shopping Site';
  }
}, [decodedPart]);

console.log(location.pathname.split("/").pop().replace(/-/g, " "))

useEffect(() => {

  const apiUrl23 = `${Baseurl}/consumer/customer-store-info/`;

  const fetchData = async () => {
    try {
      let formdata = new FormData();
      formdata.append("store_name", decodedPart);

      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      const response2 = await fetch(apiUrl23, requestOptions);

      if (!response2.ok) {
        throw new Error("Failed to fetch data");
      }

      const business = await response2.json();
      const store = business.store_info;

      if (business.status === 'success') {
       
        const storeId = store[0].bo_id;
        const businessName = store[0].bo_business_name;
      
          dispatch(Showdatavalue(true))
          dispatch(HandleLogoitem(store[0].bo_store_logo))
          
        localStorage.setItem('storeId', storeId);
        localStorage.setItem('businessname', businessName);

        const apiUrl3 = `${Baseurl}/consumer/customer-homepage/`;

        let formdata = new FormData();
        formdata.append("business_owner_id", storeId);
        
        let requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
    
        if (location.pathname.split("/").pop() !== businessName) {
          try {

            const response4 = await fetch(apiUrl3, requestOptions);
            
            if (!response4.ok) {
              throw new Error('Failed to fetch product data');
            }
        
            const recommendeddata = await response4.json();
            const recommended = recommendeddata.product_list;
        
            const productFromUrl = location.pathname.split("/").pop().replace(/-/g, " ");
        
            const filteredProductsList = recommended.filter(
              (product) =>
                product.bo_product_name === productFromUrl || 
                product.bo_product_name === location.pathname.split("/").pop()
            );
        
            if (filteredProductsList.length > 0) {
              const matchedProduct = filteredProductsList[0]; 
        
              localStorage.setItem('DataId', matchedProduct._id);
              localStorage.setItem('CategoryId', matchedProduct.bo_product_category_id);
              localStorage.setItem('Categoryname', matchedProduct.bo_product_category_name);
              localStorage.setItem('Discountprice', matchedProduct.bo_product_discounted_price);
              localStorage.setItem('Show', false);
              localStorage.setItem('productname', matchedProduct.bo_product_name);
              localStorage.setItem('productId', matchedProduct._id);
        
              localStorage.removeItem('totalPrice1');
              localStorage.removeItem('totalAmount');
              localStorage.removeItem('tempQuantities');
            } else {
              console.error("No matching product found");
            }
          } catch (error) {
            console.error("Error fetching and processing data:", error);
          }
        }

        dispatch(Handlemyhomeload(false))
        setloading(true)
      }
    } catch (error) {
      console.error("Error fetching business data:", error);
    }
  };

  if(Homeload)
  {
    fetchData();
  }
  else{
    setloading(true)
  }
  
}, []);


useEffect(() => {
  const timer = setTimeout(() => {
    if (Showdata) {

      setshowhome(Showdata);
    } else {

      setshowhome(false);
    }
  }, 2000);

  return () => clearTimeout(timer); 

}, [Showdata]);




  return (
    <>
           {loading &&
             <Routes>
           
              <Route path="/signIn"  element={<Auth/>}/>
              <Route path="/verify" element={<Verification/>}/>
              <Route path={`/${businessname}/verify-code`} element={<Verifycode/>}/> 
              <Route path={`/${businessname}/searchsuggestion`} element={<SearchSuggest/>}/>     
             {showhome===true ? <Route  path={`/:business/`} element= {<Home/>}/>  :
              <Route  path={`/:business/`} element= {<EmptyStore/>}/> }
              <Route path={`/${businessname}/connect`} element={<SignUp/>}/>
              <Route path={`/${businessname}/verify-mobile`} element={<Verifymobile/>}/>
              <Route path={`/${businessname}/search`} element = {<Searchshow/>}/>
              <Route path={`/${businessname}/all-category`} element = {<Allcategory/>}/>
              <Route path={`/${businessname}/product/:categoryname/:servicename`} element={<Checkout/>}/>
              <Route path={`/${businessname}/viewcart`} element={<Checkoutdetails/>}/>
              <Route path={`/${businessname}/empty-cart`} element={<Emptycart/>}/>
              <Route path={`/${businessname}/bookmark`} element={<Bookmark/>}/>
              <Route path={`/${businessname}/empty-bookmark`} element={<Emptybookmark/>}/>
              <Route path="/welcome" element={<Connect/>}/>
              <Route path={`/${businessname}/review`} element={<Review/>}/>
              <Route path={`/${businessname}/applycoupons`} element={<Applycoupon/>}/>
              <Route path={`/${businessname}/address`} element={<Schedule/>}/>
              <Route path={`/${businessname}/payment`} element={<Payment/>}/>
              <Route path={`/${businessname}/profile`} element={<ProfileDetail/>}/>
              <Route path={`/${businessname}/my-address`} element={<Myaddress/>}/>
              <Route path={`/${businessname}/account`} element={<Myaccount/>}/>
              <Route path='/paymentgetway' element={<Paymentgatway/>}/>
              <Route path={`/${businessname}/order-history`} element={<Booking/>}/>
              <Route path={`/${businessname}/bookingId/:id`} element={<Accepted/>}/>
              <Route path={`/${businessname}/bookingsuccessfull`} element={<BookingSuccessfull/>}/>
              <Route path={`/${businessname}/customer-review`} element={<Bookingreview/>}/>
              <Route path={`/${businessname}/return`} element={<Bookingcancel/>}/>
              <Route path={`/${businessname}/closed`} element={<Cancelled/>}/>
              <Route path={`/${businessname}/completed`} element={<AcceptedItem/>}/>
              <Route path={`/${businessname}/delete-service`} element={<Confirmdelete/>}/>
              <Route path={`/${businessname}/thank-you`} element={<Thanking/>}/>
              <Route path={`/${businessname}/payment-error`} element={<Error/>}/>
               <Route path={`/${businessname}/privacy-policy`} element={<Privacypolicy/>}/>
              <Route path={`/${businessname}/terms-and-conditons`} element={<Terms/>}/>
              <Route path={`/${businessname}/shipping`} element={<Aboutpage/>}/>
              <Route path={`/${businessname}/refund-and-cancellation-policy`} element={<Refund/>}/>
              <Route path={`/${businessname}/return-policy`} element={<Return/>}/>
              <Route path="*" element={<Page404/>}/>

             </Routes>
}
    </>
   
  );

  

  
}


export default App;


/*     
                    <Route path={`/shipping-policy`} element={<Shipping/>}/>     

                     <Route path="/" element={<Navigate to={`/${businessname}/`} />} />
           
*/
