import React, {useState, useEffect} from "react";
import Header from "../Header/header";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import { createTheme, useMediaQuery } from '@mui/material';
import ExploreCategory from "../Home/ExploreCategory.js";
import Headercategory from "../Home/Headercategory.js";
import Bottomnavigation from "../Home/BottomNavigation.js";


const Allcategory=()=>
{

  const themes = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(themes.breakpoints.down('sm'));

    const item=useSelector(state=>state.search);
    const [loadingdata, setloadingdata]=useState(true);
    const [value, setValue] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const businessId=localStorage.getItem('storeId');
    const Baseurl = process.env.REACT_APP_BASE_URL;

  

useEffect(() => {


    const fetchData = async () => {
      try {
      
        setIsLoading(true);
       
      
        const apiUrl11 = `${Baseurl}/consumer/customer-homepage/`;

        let formdata = new FormData();
        formdata.append("business_owner_id", businessId);
        
        let requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

         let response;
        
          setloadingdata(true);
          
          response = await fetch(apiUrl11, requestOptions);
          const recommendeddata = await response.json();

          const  categorydata = recommendeddata.categories;

           setValue( categorydata );           
          setloadingdata(false);
        
     
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      
        setIsLoading(false);
      }
    };
 
    if(isLoading)
    {
        fetchData();
    }
  
  }, [isLoading]);
  
 

    return (
        <>
             <div className="contain">
                {isSmScreen ?<Headercategory/>  :<><Header/></>}
             
              <div className="content">
             
              <ExploreCategory value = {value} loading={loadingdata}  />
          
             </div>
             <Footer/>
            
             </div>
        </>
    )
    
}
export default Allcategory;