import React, {useState, useEffect} from "react";
import OtpInput from "otp-input-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Edit from "../assests/iconpencil.png"
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { connect, useSelector,useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

import { Handlemylocal, UpdateName, resetItemAdded } from "../Redux/action";

const Verification=({handleVerifyClose, handleLoggedInOpen, handleLoggedInClose,handleOpen,resetItemAdded })=>
{

   const login_submit={
    
    backgroundColor:'var(--primary)',
    color:'var(--white)',
    fontSize:'16px',
    textTransform:'none',
    fontWeight:500,
    letterSpacing: '0.8px',  
    '&:hover': {
      backgroundColor: '#57339F', 
    },
   }

  const Baseurl = process.env.REACT_APP_BASE_URL;
  const access = localStorage.getItem('accessToken')

  const businessId=localStorage.getItem('storeId');
  const businessname=localStorage.getItem('businessname');

    const [OTP, setOTP] = useState("");
    const [error, setError]=useState(false);

    const [profile, setprofile]=useState(false)

    const dispatch=useDispatch();
      
    const timerStarted = false;
 
   const verifyMobile= useSelector(state=>state.phone)
   const selectedName = useSelector(state=>state.Name);

  
  
    const [show, setShow] = useState(true);

    const [time, setTime] = useState(30);
    const navigate =useNavigate();

    useEffect(() => {
      const intervalId = setInterval(() => {
        setTime(prevTime => {
          if (prevTime === 0) {
            setShow(false);
            clearInterval(intervalId); 
            return  0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000); 

      return () => clearInterval(intervalId);
    }, []); 
  
    const minutes = Math.floor(time / 60); 
    const seconds = time % 60;

   const Editable=()=>
   {
      localStorage.removeItem('accessToken')
      handleOpen();
      handleVerifyClose();
   }


    const Home=()=>
    {

      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}`);

      let formdata = new FormData();
     
      formdata.append("otp_code", OTP);
      formdata.append("business_owner_id", businessId);
      
        let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/verify-customer-otp/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          
          const data= JSON.parse(result);
          
          if(data.status==="success")
          {
            console.log(data);
               setprofile(true)
                 
          }

          else
          {
             setError(true);
             setOTP("");
          }
          
          })
        .catch(error => console.log('error', error)); 

    }

    const Resend=()=>
    {
      
        setOTP("");
        setTime(30) ;
        setShow(true);
        const intervalId = setInterval(() => {
          setTime(prevTime => {
            if (prevTime === 0) {
              setShow(false);
              clearInterval(intervalId); 
              return  0;
            } else {
              return prevTime - 1;
            }
          });
        }, 1000); 

        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
  
       let formdata = new FormData();
       formdata.append("business_owner_id", businessId);
  
       let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${Baseurl}/consumer/customer-otp-send/`, requestOptions)
             .then(response => response.text())
            .then(result=>{
             const data= JSON.parse(result);
             console.log(data.otp);
              
       })
           .catch(error => console.log('error', error));
      
    }


    useEffect(()=>
    {
     
      const access= localStorage.getItem("accessToken");

   
      if(profile===true)
      {
      if(access!==null)
      {
  
      (async () => {
  
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
  
        let formdata = new FormData();
        formdata.append("business_owner_id", businessId);
      
        let requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
          redirect: 'follow'
     };
     
      fetch(`${Baseurl}/consumer/customer-profile/`, requestOptions)
       .then(response => response.text())
       .then(result => {
         const data=JSON.parse(result);
         const Updateprofile=data.data;
         dispatch(UpdateName(Updateprofile.c_name.split(' ')[0]));
         if(data.status==="success")
         {
            setError(false);
            handleVerifyClose();
            handleLoggedInOpen();

            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);
      
        
      
           let requestOptions = {
            method: 'GET',
            headers: myHeaders
          };
          fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const checkout=JSON.parse(result);
      
            const  Categorydata = checkout.cart_data;
            if(Categorydata.length===0&& checkout.status==="success")
            {
               console.log('checkout')
               const updatedCartItems =JSON.parse(localStorage.getItem('updatedCartItems'))
                if(updatedCartItems && updatedCartItems.length!==0)
                {
                      dispatch(Handlemylocal(true))
                }
                else
                {
                  resetItemAdded();
                }
             
            }
           
          })
          .catch(error => console.log('error', error)); 
            
            if (!timerStarted) {

            const timer2 = setTimeout(() => {
              
                handleLoggedInClose();
              
              
            }, 2000);
            return () => clearTimeout(timer2);
         }
         }
     
       })
       .catch(error => console.log('error', error));
     
   })();
      }
    }
  
    },[access,profile])

  

    const Back=()=>
    {
       
          handleVerifyClose();
          
    }

    return(
        <>
   <div className="logincontainer">
           
           <div className="otpheader">
         

        <div className="login_title">OTP</div>
        </div>
                     
               <div className="loginbody">     
                     <span className="secondary">
                     We have sent an verification code to your mobile number
                     </span>

         
 

         <div className="number_login">
          <span className="number">{verifyMobile}</span>
                     <span><img className="verify_icon" src={Edit} alt="icon"  onClick={Editable}/></span>
               
                     </div>
                     <div className="verifyread "><OtpInput  className="verifyinput" value={OTP} onChange={setOTP} autoFocus OTPLength={5}  otpType="number" style={{  transform: "scale(1.04)",backgroundColor:"#fff",border:"none", }} disabled={false} secure  /></div>
  
                        <div className="timer">
                       {error && <div className="verifyalready_otp">Enter a  valid OTP.</div>}
                       
                         
                       <div className="verify_restart">
                      {show &&  <div className="verify_otp">0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>}
                        <button className="verify_otpcode" onClick={Resend} disabled={seconds>0} >Resend OTP</button>
                        </div> 

                        </div>
                        </div> 
                    
                      <div className="loginfooter">
                        
                      <Button style={login_submit} className="secondary_button" onClick={Home}>Submit</Button>
                    
                      </div>
       
                      </div>


        
        </>
    )
}

const mapDispatchToProps = {
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Verification);

/*  
 if(data.status==='success')
          {
            const access= JSON.parse(localStorage.getItem("access_token"));
          if(access!==null)
          {   
            fetch(`${Baseurl}/profile/?store_id=${businessId}`, {
              headers: {
                'Authorization' : `Bearer ${access}`
               }
            })
            .then(response => response.text())
            .then(result => {
              const Update=JSON.parse(result);
              console.log(Update);
              if(Update.status==="success")
              {
                const Updateprofile=Update.data;
                dispatch(UpdateName(Updateprofile?.first_name));
              }
            
              });
        
    
            }
          }  


 <div style={{position:"static"}}>
       
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 1 ,mb:1}}
            style={{color:"black"}}
          >
            <ArrowBackIcon  onClick={Back}/>
          </IconButton>
        
   
        </div>

<div className="container section_container">
               <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} display="flex" justifyContent="center">
          <Grid item xs={11} sm={6} md={4} >
         
        
      </div>
         <div style={{position:"static" ,marginTop:"-22px"}}>
        <div style={{backgroundColor:'var(--background-color)'}}>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            style={{color:"black"}}
          >
            <ArrowBackIcon  onClick={Back}/>
          </IconButton>
        
        </div>
        </div>
       
          <div>
         

                     <div className="edit">
                     <span className="number">{verifyMobile}</span>
                     <span><img className="icon" src={Edit} alt="icon"  onClick={Editable}/></span>
                     </div>
                     </div>
                     <div className="otpread "><OtpInput  className="otpinput" value={OTP} onChange={setOTP} autoFocus OTPLength={5}  otpType="number" style={{backgroundColor:"#F8F9FB",border:"none"}} disabled={false} secure  /></div>
  
                        <div className="timer">
                       {error && <div className="already_otp">Enter a  valid OTP.</div>}
                       
                         
                       <div className="timer_restart">
                      {show &&  <div className="resend_otp">0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>}
                        <button className="resend_otpcode" onClick={Resend} disabled={seconds>0} >Resend OTP</button>
                        </div> 

                        </div>
                    
                      <div className="enterotp">
                        
                      <button className="primary_button submission" onClick={Home}>Submit</button>
                    
                      </div>
                     
              
                      
                      </Grid>
                       
                       

                  </Grid>
                  </Box>
                
                 
                 </div> 
                 */