import React, {useState, useEffect} from "react";
import { Box, Grid} from "@mui/material";
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import {createTheme,  useMediaQuery } from '@mui/material';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
  }));


const Searchservice=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const navigate=useNavigate();

    const [item, setItem] = useState([]);
    const access= JSON.parse(localStorage.getItem("access_token"));
    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');
    const Baseurl = process.env.REACT_APP_BASE_URL;

    useEffect(()=>
    {
      
     
    
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}` );
    
         var requestOptions = {
          method: 'GET',
          headers: myHeaders,
           redirect: 'follow'
      };
      
       fetch(`${Baseurl}/service-by-category/1/?store_id=${businessId}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
          const  Categorydata = data.categories;
    
          setItem( Categorydata );
         // console.log(item);
         // console.log(result);
    
        })
        .catch(error => console.log('error', error));  
    
    },[])
  

    function handleCategory(values, names)
    {
      if(window.location.pathname!==`${businessname}/search`)
      {
        
        navigate(`/${businessname}/search`);
        localStorage.setItem("CategoryId" ,JSON.stringify(values));
        localStorage.setItem("Categoryname",JSON.stringify(names));
      }
    }



    return(
        <>
         
           <section className="section">

               <Container maxWidth="lg">
         
              <div className="explore">Search by services</div>
              <div className="explore_container">
              <Box sx={{width:"100%"}}>
             <Grid container rowSpacing={2} columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start"  >
           

             {item.map((cur,index)=>
             {
                return(
                    <>
                 
                 <Grid item key={index} xs={3} sm={isSmScreen ? 3 : 1.5} md={1.5}>
            <div className="categoriesdata">
              <div className="global_box_explore"  onClick={()=>handleCategory(cur.category_id, cur.category_name)}>
              <div className="category_name"><LazyLoadImage src={cur.category_image_light} alt="category"/></div> 
                <div className="explore_categoryname" >
                  {cur.category_name}
                </div>
              </div>
            </div>
          </Grid>
                        

                      
                    </>
                )
             })}


           

             </Grid>             
             </Box>
             </div>
            </Container>
            </section>

        </>
    )
}

export default Searchservice;