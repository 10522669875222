import React, {useState, useEffect} from "react";
import CircularProgress from '@mui/joy/CircularProgress';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Approvepayment=()=>
{

    const [time, setTime] = useState(300);

    const navigate= useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
          setTime(prevTime => {
            if (prevTime === 0) {
              clearInterval(intervalId); 
              return  0;
            } else {
              return prevTime - 1;
            }
          });
        }, 1000); 
  
        return () => clearInterval(intervalId);
      }, []); 

      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
      };

     const Cancel=()=>
     {
          navigate(-1);
     }


   return (
    <>
      

      
      <section className="section">
        <div className="containerpayment">
            <div className="circularprogressdata">
                <div>
        <h1 className="explore_title explore serviceaddress">Open your UPI app to approve the</h1>
        <h1 className="explore_title explore serviceaddress">Request for from the Lavendel App</h1>
        </div>
        <div className="circulardata">
        <CircularProgress  sx={{ '--CircularProgress-size': '160px', animation:'none', }}  value={100 - (time / 300) * 100} color="info">
            <div>
        <div className="formattiming">{formatTime(time)} mins</div>
        <div className="leftpayment">Left to approve your payment</div>
        </div>
            </CircularProgress>
            </div>
           
          <div className="approvepayment"> Please approve the payment <br></br> request before it times out</div>
          
          <div className="canceldata">
           <NavLink className='cancelpayment' onClick={Cancel}>Cancel payment</NavLink>
           </div>

            </div>
        </div>
        <div className="back-button">Note : Do not hit back button until the transaction is complete</div>
      </section>

    </>
   )

}

export default Approvepayment;