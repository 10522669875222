import React, {useState, useEffect} from "react";
import Header from "../Header/header";
import Footer from "../Footer/Footer";
import SearchFilter from "../Header/SearchFilter";
import CheckoutPage from "./CheckoutPage";
import Viewsimilar from "./Viewsimilar";
import Ratings from "./Rating";
import { useSelector } from "react-redux";
import Bottomnavigation from "../Home/BottomNavigation";
import Additional from "./Additional";
import Servicenote from "./Servicenote";
import Addtocart from "./Addtocart";
import {createTheme,  useMediaQuery } from '@mui/material';
import Headercheckout from "./Headercheckout";




const Checkout=()=>
{

  const themes = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  
    const Baseurl = process.env.REACT_APP_BASE_URL;
    const variantId= localStorage.getItem('variantId');
   const showAdd=JSON.parse(localStorage.getItem('Show'));
   const datas=localStorage.getItem('DataId')

   const isSmScreen = useMediaQuery(themes.breakpoints.down('sm'));
  
   const businessId=localStorage.getItem('storeId');
  


    const [loading1, setloading1] = useState(true);
    const [loading3, setloading3] = useState(true);
    const [loading4, setloading4] = useState(true);


    const [itemed, setItem] = useState();
    const [productdata, setproductdata]=useState();
    const [rating, setRating] = useState();
    const [coupons, setcoupons] = useState();
    const [Add, setAdd]=useState([]);
    const [selectedVariant, setSelectedVariant] = useState(variantId);
    const [variant, setVariant]= useState();
    const [isLoading, setIsLoading] = useState(true);
    const [showaddtocart, setShowaddtocart] = useState(showAdd);
    const [similar,setSimilar] = useState([]);
    const [reviewed, setReviewed]=useState('');
    const [datarating, setDatarating]=useState([]);
   

   
   const [loadingnumber, setloadingnumber]=useState(false)

    if (!localStorage.getItem('Show')) {
    
      localStorage.setItem('Show', false);
      setShowaddtocart(true);
      
    }
    


  const handleLoading=()=>
  {
     
  }



  useEffect(()=>
{
     
    const apiUrl5 =`${Baseurl}/consumer/product-detail-web/${datas}/?business_owner_id=${businessId}`;
    const apiUrl6=`${Baseurl}/consumer/customer-coupon/`;
    const apiUrl7=`${Baseurl}/consumer/customer-homepage/`;
    const apiUrl25 =`${Baseurl}/consumer/customer-get-reviews-web/${datas}/?business_owner_id=${businessId}`;

   const fetchData = async()=>
   {
     try 
     {
        
         setloading1(true);
         const response3 = await fetch(apiUrl5);
         
         const data = await response3.json();
       
           setSelectedVariant(data.data[0]._id.$oid)
           setproductdata(data.data)
               
       

         let formdata = new FormData();
         formdata.append("business_owner_id", businessId);
         
          let requestOptions = {
           method: 'POST',
           body: formdata,
           redirect: 'follow'
         };
         
         const response4 = await fetch(apiUrl6,requestOptions);
         
         const coupondata = await response4.json();
         const latestcoupons = coupondata.coupons;
         console.log(latestcoupons)
         setcoupons(latestcoupons)
       
                         
         setloading1(false)
         
         setloading3(true)

          
         const response5 = await fetch(apiUrl7,requestOptions);
         
         const productdata = await response5.json();
         const similar = productdata.product_list;
         setSimilar(similar)

         setloading3(false)
         
         setloading4(false) // change from false to true

         const response6 = await fetch(apiUrl25);
         
         const reviewdata = await response6.json();
         const rating= reviewdata.review_counter;
         const review=reviewdata.reviews

          setReviewed(rating)
          setDatarating(review)
        
         setloading4(false);

         setIsLoading(false);
      

     }
      catch (error) {
      console.log(error);
      setIsLoading(false);
    }
   }

   if (isLoading) {
    fetchData();
  }

},[isLoading, datas])


const handleloading = (loading) => {

  if(loading===true)
  {
    
    setloadingnumber(loading);
    console.log(loading)
  }
 
}




     return (
        <>

             <div className="contain">
            {isSmScreen ? <Headercheckout/> : <Header />}
           
            <div className="content" style={{backgroundColor:"#f3f3f3",marginBottom:"30px"}}>

              <CheckoutPage data={datas} productdata={productdata} coupons={coupons}  selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} loading={loading1} handleloading={handleloading}/> 
            
             <Servicenote productdata={productdata} loading={loading1}/>
              <Viewsimilar similar={similar} data={datas} loading={loading3}/> 
              <Ratings reviewed={reviewed} datarating={datarating} loading={loading4}/> 
             <Addtocart data={datas} productdata={productdata} coupons={coupons}  selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant}  loadingnumber={loadingnumber} setloadingnumber={setloadingnumber}/> 
             
             </div>
             <Footer/>
             </div>

      
        </>
     ) 

}

export default Checkout;

