import React, { useEffect, useState } from "react";
import Icons from "../assests/icons.png";
import Logo from "../assests/Logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import {connect, useDispatch, useSelector } from 'react-redux';
import { Handlemyaccount, Handlemylocal, mobileNumber, UpdateName, resetItemAdded } from "../Redux/action";
import Button from '@mui/material/Button';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import OtpInput from "otp-input-react";
import Edit from "../assests/iconpencil.png"
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';


const Auth=({handle,resetItemAdded})=>
{

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const Loginitem=
  {
    backgroundColor:'var(--primary)',
    color:'var(--white)',
    fontSize:'16px',
    textTransform:'none',
    fontWeight:500,
    letterSpacing: '0.8px',  
    '&:hover': {
      backgroundColor: '#57339F', 
    },
  }


  const login_submit={
    
    backgroundColor:'var(--primary)',
    color:'var(--white)',
    fontSize:'16px',
    textTransform:'none',
    fontWeight:500,
    letterSpacing: '0.8px',  
    '&:hover': {
      backgroundColor: '#57339F', 
    },
   }
  
  const Baseurl=process.env.REACT_APP_BASE_URL;
  const access = localStorage.getItem('accessToken')

  const [user, setuser]=useState('');
  const [phone, setphone]= useState("primary_login")
  const [invalid, setInvalid] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);

    const [OTP, setOTP] = useState("");
    const [error, setError]=useState(false);

    const [profile, setprofile]=useState(false)

  const [showlogin, setshowlogin]=useState(true)


  const [google, setgoogle]=useState('');
  const [isloading, setisLoading]=useState(false);

  const navigate= useNavigate();
  const dispatch=useDispatch();


  const verifyMobile= useSelector(state=>state.phone);

  const businessname=localStorage.getItem('businessname');

  const clientId="651863082976-om5d8r132rksclhu4uhcs90vdqepaqte.apps.googleusercontent.com";

  const businessId=localStorage.getItem('storeId');

  const [show, setShow] = useState(true);

  const [time, setTime] = useState(30);
 


  const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };


  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;

   const handleClose1 = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(prevTime => {
        if (prevTime === 0) {
          setShow(false);
          clearInterval(intervalId); 
          return  0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000); 

    return () => clearInterval(intervalId);
  }, []); 

  const minutes = Math.floor(time / 60); 
  const seconds = time % 60;

 const Editable=()=>
 {
    localStorage.removeItem('accessToken')
    setshowlogin(true)
 }

    
  const Home=()=>
    {

      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}`);

      let formdata = new FormData();
     
      formdata.append("otp_code", OTP);
      formdata.append("business_owner_id", businessId);
      
        let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/verify-customer-otp/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          
          const data= JSON.parse(result);
          
          if(data.status==="success")
          {
               console.log(data);
               dispatch(Handlemyaccount(false))
               setprofile(true)
               setopenSnackbar2({...openSnackbar2, opentoast:true});
          }

          else
          {
             setError(true);
             setOTP("");
          }
          
          })
        .catch(error => console.log('error', error)); 

    }

    const Resend=()=>
    {
      
        setOTP("");
        setTime(30) ;
        setShow(true);
        const intervalId = setInterval(() => {
          setTime(prevTime => {
            if (prevTime === 0) {
              setShow(false);
              clearInterval(intervalId); 
              return  0;
            } else {
              return prevTime - 1;
            }
          });
        }, 1000); 

        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
  
       let formdata = new FormData();
       formdata.append("business_owner_id", businessId);
  
       let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${Baseurl}/consumer/customer-otp-send/`, requestOptions)
             .then(response => response.text())
            .then(result=>{
             const data= JSON.parse(result);
             console.log(data.otp);
              
       })
           .catch(error => console.log('error', error));
      
    }


    useEffect(()=>
    {
     
      const access= localStorage.getItem("accessToken");

   
      if(profile===true)
      {
      if(access!==null)
      {
  
      (async () => {
  
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
  
        let formdata = new FormData();
        formdata.append("business_owner_id", businessId);
      
        let requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
          redirect: 'follow'
     };
     
      fetch(`${Baseurl}/consumer/customer-profile/`, requestOptions)
       .then(response => response.text())
       .then(result => {
         const data=JSON.parse(result);
         const Updateprofile=data.data;
         dispatch(UpdateName(Updateprofile.c_name.split(' ')[0]));
         if(data.status==="success")
         {
            setError(false);
            handle()
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);
      
           let requestOptions = {
            method: 'GET',
            headers: myHeaders
          };
          fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const checkout=JSON.parse(result);
      
            const  Categorydata = checkout.cart_data;
            if(Categorydata.length===0&& checkout.status==="success")
            {
               console.log('checkout')
               const updatedCartItems =JSON.parse(localStorage.getItem('updatedCartItems'))
                if(updatedCartItems && updatedCartItems.length!==0)
                {
                      dispatch(Handlemylocal(true))

                }
                else
                {
                  resetItemAdded();
                }
             
            }
           
          })
          .catch(error => console.log('error', error)); 
         }
     
       })
       .catch(error => console.log('error', error));
     
   })();
      }
    }
  
    },[access,profile])

  const handleinput=(e)=>
  {
   
    
       let inputValue = e.target.value;
       
       if (!inputValue.startsWith('+91 ')) {
         setuser('+91 ' + inputValue);
       } else {
         setuser(inputValue);
       }
  }
  
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && user === '+91 ') {
      setuser('');
    }
  };

  const submit=()=>
  {
    
    

     const user1 = /^[+ 0-9]+$/g.test(user);

     const user2 = user.replace(/^0+/, '');

    if(user.length!==14 ||  user1===false)
   {
     setInvalid(true);
     setphone("secondary_login");
   }
   else{
     setInvalid(false);
     setphone("primary_login")

 
     if(user)
     {
      
  
         let formdata = new FormData();
         formdata.append("mobile_number", user2.slice(user2.length - 10));
         formdata.append("business_owner_id", businessId);
         formdata.append("fcm_token", "abc");

             let requestOptions = {
             method: 'POST',
             body: formdata,
             redirect: 'follow',
               
        };
        
           fetch(`${Baseurl}/consumer/customer-connect/`, requestOptions)
          .then(response => response.text())
          .then(result=>{
              const data= JSON.parse(result);
              if(data.status==='success')
              { 
                
                
                localStorage.setItem('accessToken', data.access_token)
                
                setopenSnackbar1({...openSnackbar1, openSnackbar:true});
                dispatch(mobileNumber(user2.slice(user2.length - 10)));
                dispatch(Handlemylocal(false))
               
                let myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${data.access_token}`);
          
               let formdata = new FormData();
               formdata.append("business_owner_id", businessId);
          
               let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              };
          
              fetch(`${Baseurl}/consumer/customer-otp-send/`, requestOptions)
              .then(response => response.text())
              .then(result => {
                const data=JSON.parse(result);
          
                if(data.status==='success')
                {
                  setshowlogin(false)
            
                }
               
              })
              .catch(error => console.log('error', error)); 
                

              }
              else{
                navigate(`/${businessname}/connect`);
               
              } 
              
           })
            .catch(error => console.log('error', error));
          
       }  
  }
             
  }


const handleLogin=()=>
{
   dispatch(Handlemyaccount(false))
}

  const handleClick=()=>
  {
      submit();

  }

  const handleClickdata = () => {
    handle();
    handleLogin();
  };

 

    return (
      <>
      
      {showlogin ?  <div className="logincontainer">
          
          <div className="loginheader">
           <div className="login_title">Login/Sign up</div>
          <div className="closeicon" onClick={handleClickdata}><CloseIcon style={{transform:"scale(1.1)"}}/></div> 
          </div>

               <div className="loginbody">        
           <div>
           
           {!invalid &&  <TextField className="login_data" id="outlined-required"   label="Phone Number"  type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1" },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }}  value={user} name="user"  minLength={10} maxLength={10}  onKeyDown={handleKeyDown}  />}
           {invalid &&   <TextField
          error
          id="outlined-error"
          label="Enter Phone"
          className="login_data"   type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1" },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }}  value={user} name="user"  onKeyDown={handleKeyDown}
          
        />}
                          <div>{invalid  && <label className="login_exist">Enter a valid mobile number.</label>}</div>
                         </div>
                         
       
                    
                     
                   
             <div className="logging_title">By logging in, I agree to <NavLink to ="/terms-and-conditons">terms</NavLink> and  <NavLink to="/privacy-policy">privacy policy</NavLink>.</div>

          </div>

          <div className="loginfooter">
                      <Button style={Loginitem} className="secondary_button" onClick={handleClick}>Send OTP</Button>
                      
                      </div>

          </div>



          :

          <div className="logincontainer">
           
          <div className="otpheader">
        

       <div className="login_title">OTP</div>
       </div>
                    
              <div className="loginbody">     
                    <span className="secondary">
                    We have sent an verification code to your mobile number
                    </span>

        


        <div className="number_login">
         <span className="number">{verifyMobile}</span>
                    <span><img className="verify_icon" src={Edit} alt="icon"  onClick={Editable}/></span>
              
                    </div>
                    <div className="verifyread "><OtpInput  className="verifyinput" value={OTP} onChange={setOTP} autoFocus OTPLength={5}  otpType="number" style={{  transform: "scale(1.04)",backgroundColor:"#fff",border:"none", }} disabled={false} secure  /></div>
 
                       <div className="timer">
                      {error && <div className="verifyalready_otp">Enter a  valid OTP.</div>}
                      
                        
                      <div className="verify_restart">
                     {show &&  <div className="verify_otp">0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>}
                       <button className="verify_otpcode" onClick={Resend} disabled={seconds>0} >Resend OTP</button>
                       </div> 

                       </div>
                       </div> 
                   
                     <div className="loginfooter">
                       
                     <Button style={login_submit} className="secondary_button" onClick={Home}>Submit</Button>
                   
                     </div>
      
                     </div>
}
        
            <Stack spacing={2} sx={{ width: '100%' }}>
                  
                  <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                <Alert  severity="success" sx={{ width: '100%' }}>
                   OTP send successfully!
                </Alert>
                </Snackbar>

                </Stack> 

                <Stack spacing={2} sx={{ width: '100%' }}>
                  
                  <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose1}>
                <Alert  severity="success" sx={{ width: '100%' }}>
                Successfully logged in !
                </Alert>
                </Snackbar>

                </Stack> 
              

      </>
    )
}
const mapDispatchToProps = {
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Auth);


/*  
                  <div className="signupwith"><hr style={{width:"82px", height:'0px'}}></hr><span className="signupwithgoogle">or sign up with</span><hr style={{width:"82px", height:'0px'}}></hr></div>
 <div className="google">
                    <GoogleOAuthProvider
                    clientId={clientId}>

                   <GoogleLogin
                     onSuccess={credentialResponse => {
                      let decoded = jwt_decode(credentialResponse.credential);
                    console.log(decoded);
                     setgoogle(decoded);
                    // setIsLoading(true);
                    
                    }}
               
                     onError={() => {
                  console.log('Login Failed');
                     }}
                    />

                 </GoogleOAuthProvider>
                 </div>

 if(data.status==='success')
      {

        const access= JSON.parse(localStorage.getItem("access_token"));
  
      if(access!==null)
      {   
        fetch(`${Baseurl}/profile/?store_id=${businessId}`,  {
          headers: {
            'Authorization' : `Bearer ${access}`
           }
        })
        .then(response => response.text())
        .then(result => {
          const Update=JSON.parse(result);
          console.log(Update);
          if(Update.status==="success")
          {
            const Updateprofile=Update.data;
            dispatch(UpdateName(Updateprofile?.first_name));
          }
        
          });
    

        }
      }

    image-----   <div className="lavendel_image"><img src={Logo} alt="logo"/></div>
    input phone----     <PhoneInput  country={'in'} className="login_data" id="standard-required"   type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1"     },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }} placeholder="Phone Number" variant="standard" value={user} name="user"  minLength={10} maxLength={10}   />

        handleVerify();
               
             localStorage.removeItem("access_token")   



if(data.status==='success')
          {
               navigate("/home")
          }
          else{
              navigate("/connect");
              localStorage.removeItem("access_token")
          }


          */

          