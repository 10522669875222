import React from 'react'
import Headeraccount from './Headeraccount'
import {ReactComponent as SignoutLogo} from "../assests/Signout.svg"
import {ReactComponent as AvatarLogo} from "../assests/Avatar.svg";
import {ReactComponent as MyaddressLogo} from "../assests/Myaddress.svg";
import { useNavigate } from 'react-router-dom';
import Bottomnavigation from './BottomNavigation';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux';
import { Bottomnavigatevalue, Handlemyaddress } from '../Redux/action';

const Myaccount = () => {


    const businessname=localStorage.getItem('businessname');
    const navigate = useNavigate();
    const dispatch=useDispatch();

    
    dispatch(Bottomnavigatevalue(2));

    const Profile=()=>
    {
        navigate(`/${businessname}/profile`);
    }

    const Address=()=>
    {
        dispatch(Handlemyaddress(true));
        navigate(`/${businessname}/my-address`);
    }

    const Sign=()=>
    {
         localStorage.removeItem('accessToken');
         navigate(`/${businessname}/connect`);
    }


  return (
     <>

         <div className='contain'>
            <Headeraccount/>
          <div className='content'>
          <section className="section explore-padding mt-20 ">
      
   
      <Container maxWidth="lg" className="container-padding" >

          <div className='myaccount'>
             
              <AvatarLogo/> 
              <span className=" secondary_title text-wrapping" onClick={Profile}>My profile</span>
            </div>

           
          <div className='myaccount'>
             <MyaddressLogo/>
              <span  className=" secondary_title text-wrapping" onClick={Address}>My addresses</span>
            </div>

           
          <div className='myaccount'>
              <SignoutLogo/>
              <span className=" secondary_title text-wrapping" onClick={Sign}>Sign Out</span>
            </div>
            </Container>
            </section>
          
            </div> 
            <Bottomnavigation/> 
         </div>
     </>
  )
}

export default Myaccount