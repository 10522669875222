import React, {useState, useEffect, useReducer, useRef} from "react";
import { Box, Grid, Typography, createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector, connect, useDispatch } from "react-redux";

import { useNavigate, NavLink } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import {ReactComponent as Delete } from "../assests/Delete.svg";
import useRazorpay from "react-razorpay";
import { resetItemAdded, Bottomnavigatevalue, couponsCode, mobileNumber, Handlemylocal, Handlemycheckout, Handlemyaccount } from "../Redux/action";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Container from '@mui/material/Container';
import { ConstructionOutlined } from "@mui/icons-material";
import Cartskeleton from "./Cartskeleton";




const Items = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  padding: '20px',
  background: '#FFF',
  boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
  marginTop:'1px'

}));





const CheckoutCart=({resetItemAdded})=>
{

  

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:"20px",
    margin:isSmScreen?"2px 0px 10px":"12px 0px 20px",
    background: '#FFF',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
   
  }));

  const [showbutton, setshowbutton]=useState(true)

  const checkoutflex={

    display:'grid',
    gridTemplateColumns: isSmScreen ? '96px  auto' : '110px  auto',
    gridTemplateRows:'1fr',
    gridColumnGap:'18px'

  }

  const checkoutgrid=
  {
    display:'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows:'1fr',
    gridColumnGap:'18px',
    paddingBottom:'10px',
    marginBottom: '10px',
  }


  const deletebuttons=
  {
      color:'var(--primary)',
      textTransform:'none',
    }

    const ProceedItem={

      backgroundColor:'var(--primary)',
      fontSize:isSmScreen?'14px':'16px',
      color:'var(--white)',
      border:'2px solid var(--primary)',
      outline:'none',
      textTransform: 'none',
      opacity:1,
      flex:1,
    }
    const Cancelitem=
    {
       backgroundColor:'#ffffff',
       color:'#4D1BDB',
       fontSize:isSmScreen?'14px':'16px',
       textTransform: 'none',
       border:'1px solid #4D1BDB',
       outline:'none',
       flex:1,
    }

 

 
    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

  
    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');

    const servicename = localStorage.getItem('productname');
    const Categoryname= localStorage.getItem('Categoryname');

    const sanitizedServiceName = servicename?.replace(/ /g, '-');

    const Baseurl = process.env.REACT_APP_BASE_URL;
    
    const increased=useSelector(state=>state.forceData)

    const  countnumber1= useSelector(state=>state.countno1);
   


    const [count1, setcount1] = useState(countnumber1);



    const access= localStorage.getItem("accessToken");

   const [item, setitem] = useState([]);

   const [value, setvalue] = useState([]);

   const [open,setopen] = useState(false);


   const [buttonClicked, setButtonClicked] = useState(true);


   const navigate= useNavigate();
  
   const [loading1, setloading1] = useState(true);
  const [totalprice1, settotalprice1] = useState(0);
  
  const [showcart, setshowcart]=useState(false)
 
  const quantityData = localStorage.getItem('quantity');
  const Totalcount = quantityData ? JSON.parse(quantityData) : 0;

 const Discountprice = localStorage.getItem('Discountprice')

 

  const [showpayment, setshowpayment] = useState(localStorage.getItem('showbutton'));

  const couponValue= useSelector(state=>state.code);

 const [variantSize, setvariantSize]=useState()
 const [variantColor, setvariantColor]=useState()

 const [productImage, setproductImage]=useState()

   console.log(couponValue);
 
 const [products, setProducts] = useState();

 let variantdiscount=[];
 let variantproduct=[];
 let variantdelete=[];


 const clickCountRef = useRef({});


 

  const variantdata=useSelector(state=>state.variantData);

  const local=useSelector(state=>state.local)

  
  const Mycheckout=useSelector(state=>state.checkoutdata)

  const [selectedVariant, setSelectedVariant] = useState(variantdata);

  const [ignored, forcedata] = useReducer(x=>x+1,0);
 
  const [tempQuantities, setTempQuantities] = useState(Totalcount);


  const [isLoading, setIsLoading] = useState(true);
  const [alldelete,setalldelete] = useState(false);
 
  const [value2,setValue2]=useState([]);

  const [loadingdata, setloadingdata]=useState(true)
 
 const [deletecreate, setdeletecreate] = useState(false);

 const [showlocal, setshowlocal]=useState(false)

 const totalquantity = localStorage.getItem('totalquantity')

 const dispatch = useDispatch();
 dispatch(Bottomnavigatevalue(2));


   const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

      const [input, setinput]=useState(
        {
            payment:""
        }
      )

      if(!localStorage.getItem('checked'))
      {
            input.payment="";
      }

    const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };

  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;

   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

  const [openSnackbar3, setopenSnackbar3]=useState({

    opentoast1: false,
    verticaltoast1: 'top',
    horizontaltoast1: 'center',
   });


   const { verticaltoast1, horizontaltoast1, opentoast1 } = openSnackbar3;

   const handleCloseToast1 = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar3({...openSnackbar3, opentoast1:false});

  };

   const Showbutton = localStorage.getItem('showbutton');
   console.log(Showbutton)

   const couponType = localStorage.getItem('couponType')

useEffect(()=>
{


  if(Showbutton==='true')
  {
   
      setshowpayment(true);
  }
  else
  {
 
      setshowpayment(false);
  }
    
},[Showbutton])

useEffect(() => {
  const productDataFromStorage = localStorage.getItem('productdata');
  const variantsize = localStorage.getItem('variantSize');
  const variantcolor = localStorage.getItem('variantColor');
  const  productimage = localStorage.getItem('productImage');
  console.log(productDataFromStorage)
  if (productDataFromStorage && loadingdata) {
    const productdata = JSON.parse(productDataFromStorage);
    setshowlocal(true)
    setProducts(productdata);
    setValue2(productdata);
    setitem(productdata);
    setvalue(productdata); 
    
    
  }

 if(variantsize && loadingdata)
 {
   const variantsizedata = JSON.parse(variantsize);
   setvariantSize(variantsizedata)
 }

 if(variantcolor && loadingdata)
 {
   const variantcolordata = JSON.parse(variantcolor);
   setvariantColor(variantcolordata)
 }
 if(productimage && loadingdata)
 {
   const variantproductimagedata = JSON.parse(productimage);
   setproductImage(variantproductimagedata)
 }
 
 setshowcart(true)
 setIsLoading(true)

}, [loadingdata,showcart]);


useEffect(()=>
{
  if(showlocal)
  {
     setloading1(false)
  }

},[showlocal])



 
const remove=(cartId, productId)=>
{
  setSelectedVariant(cartId);


  if(showlocal)
  {
    setProducts((prevProducts) =>
    prevProducts.map((item,index) => {
      if (item._id.$oid === cartId) {
       
    
        const clickCount = (clickCountRef.current[cartId] || 0) - 1;
          clickCountRef.current[cartId] = clickCount;

        

         if((item.quantity + (tempQuantities[cartId] || 0))<2)
          {
              setopen(true);
              localStorage.setItem('cartId', cartId);
              localStorage.setItem('productId', productId)
          }
          else
          {
            setTempQuantities((prevTempQuantities) => ({
            ...prevTempQuantities,
            [cartId]: (prevTempQuantities[cartId] || 0) - 1,
      }));

          }
    }
      return item;
    })
  );
  }
  else {
    setProducts((prevProducts) =>
    prevProducts.map((item) => {
      if (item._id === cartId) {
       
      
        const clickCount = (clickCountRef.current[cartId] || 0) - 1;
          clickCountRef.current[cartId] = clickCount;


          if((parseInt(item.catc_quantity) + (tempQuantities[cartId] || 0))<2)
          {
              setopen(true);
              localStorage.setItem('cartId', cartId);
              localStorage.setItem('productId', productId)
          }
          else
          {
              setTempQuantities((prevTempQuantities) => ({
      ...prevTempQuantities,
      [cartId]: (prevTempQuantities[cartId] || 0) - 1,
    }));

          }
      }
      return item;
    })
  );
  }
  

}



const add=(productId)=>
{
  
   
  setSelectedVariant(productId);
    if(showlocal)
    {
      setProducts((prevProducts) =>
      prevProducts.map((item) => {
        if (item._id.$oid === productId) {
        
          if((item.quantity + (tempQuantities[productId] || 0))< item.bo_product_quantity)
            {
          const clickCount = (clickCountRef.current[productId] || 0) + 1;
          clickCountRef.current[productId] = clickCount;
      

        setTempQuantities((prevTempQuantities) => ({
        ...prevTempQuantities,
        [productId]: (prevTempQuantities[productId] || 0) + 1,
      }));

      console.log(productId)
    }
      else 
      {
        setopenSnackbar3({...openSnackbar3, opentoast1:true});
      }
    
        }
        return item;
      
      })
      
    );

    }
    else 
    {
      setProducts((prevProducts) =>
      prevProducts.map((item) => {
        if (item._id === productId) {
        

          console.log("itemquantity",item,tempQuantities[productId],item.bo_product_quantity)

          if((parseInt(item.catc_quantity) + (tempQuantities[productId] || 0))< item.product_details[0].bo_product_quantity)
            {
        
          const clickCount = (clickCountRef.current[productId] || 0) + 1;
          clickCountRef.current[productId] = clickCount;
      

        setTempQuantities((prevTempQuantities) => ({
        ...prevTempQuantities,
        [productId]: (prevTempQuantities[productId] || 0) + 1,
      }));


        }
        else
        {
          setopenSnackbar3({...openSnackbar3, opentoast1:true});
        }

      }
        return item;
      
      })
      
    );

    }
}

useEffect(()=>
{ 
  if(showpayment===true)
  {
     localStorage.setItem('quantity', JSON.stringify(tempQuantities));
  }
  
},[showpayment, tempQuantities])



const deleted=(cartId,productId)=>
{
   setopen(true);
 localStorage.setItem('cartId', cartId);
 localStorage.setItem('productId', productId)
}

const cancel=()=>
{
   setopen(false);
}

useEffect(() => {

  dispatch(Handlemycheckout(true));
}, []);


const productId=localStorage.getItem('productId')
  
 const selectedArray = products?.filter((item) => item.bo_product_id === productId);
 variantdelete.push(selectedArray);

console.log("selectedArray", selectedArray)

const deleteditem=(newState)=>
{
 
   const cartId= localStorage.getItem('cartId');

   if(access!==null)
   {
     
  
     let myHeaders = new Headers();

     myHeaders.append("Authorization", `Bearer ${access}` );  

     const formdata = new FormData();
     formdata.append("business_owner_id", businessId);
     for(let i=0;i<variantdelete[0].length;i++)
     {
     formdata.append("cart_id", variantdelete[0][i]._id);
     }
 
     let requestOptions = {
       method: 'DELETE',
       headers: myHeaders,
       body: formdata,
       redirect: 'follow'
     };
     
     fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
       .then(response => response.text())
       .then(result => {
         const data=JSON.parse(result);
         console.log(data);
       
           if (data.status === 'success') {
         
           
              dispatch(Handlemycheckout(true))
              localStorage.removeItem('cartId');  
              setalldelete(true);
              localStorage.removeItem('variant');            
   
              localStorage.removeItem('productImage')
              localStorage.removeItem('productdata')
              localStorage.removeItem('variantSize')
              localStorage.removeItem('variantColor')
              localStorage.removeItem('updatedCartItems')
               setopenSnackbar1({ ...newState, openSnackbar: true });
               setIsLoading(true);
          }  

          setopen(false);
          setButtonClicked(true);
  
       })
       .catch(error => console.log('error', error)); 
      
  } 

  else 
  {
    isSmScreen? navigate(`/${businessname}/connect`):dispatch(Handlemyaccount(true));
  }
 
}


  
  const calculateTotalPrice = () => {

   console.log("productdata",products)
   
    let totalquantity=0;
    let total = 0;
    let save=0;
    products?.forEach((cur,index) => {
      const quantity = showlocal? showpayment ?cur.quantity + (tempQuantities[cur._id.$oid] || 0) : cur.quantity : showpayment ? parseInt(cur.catc_quantity) + (tempQuantities[cur._id] || 0) : parseInt(cur.catc_quantity);
 
      if(showlocal)
      {
        if((cur.variant_details && cur.variant_details.length!==0) && (variantSize||variantColor))
        {
           if(variantColor && variantColor.length!==0&& variantColor.some(color => color.bo_product_id.$oid === cur._id.$oid))
           {
            variantColor?.forEach(curs => {
  
              if (curs.bo_product_id.$oid === cur._id.$oid) {
                  total += parseInt(quantity) * curs.bo_product_discount;
                  totalquantity+= parseInt(quantity);
                  localStorage.setItem('totalquantity', parseInt(totalquantity))
              }
          });
            
           }
           else 
           {
            variantSize?.forEach(curs => {
              if (curs.bo_product_id.$oid === cur._id.$oid) {
                  total += quantity * curs.bo_product_discount;
                  totalquantity+= parseInt(quantity);
                  localStorage.setItem('totalquantity', parseInt(totalquantity))
                 
              }
          });
           }
        }
        else 
        {
          total+=quantity*cur.bo_product_discounted_price
          totalquantity+= parseInt(quantity);
          localStorage.setItem('totalquantity', parseInt(totalquantity))
         
        }
      }
     
      else 
      {
        if((cur.product_details[0].varient_info && cur.product_details[0].varient_info.length!==0))
        {
           if(cur.product_details[0].varient_info.length>1)
           {
             
                  total +=parseInt(cur.product_details[0].varient_info[1].bo_product_discount*quantity)
                  totalquantity+= parseInt(quantity);
                  localStorage.setItem('totalquantity', parseInt(totalquantity))
           } 
           else 
           {
              total += parseInt(cur.product_details[0].varient_info[0].bo_product_discount*quantity)
              totalquantity+= parseInt(quantity);
              localStorage.setItem('totalquantity', parseInt(totalquantity))
           }
        }
        else 
        {
          total+=parseInt(quantity*cur.product_details[0].bo_product_discounted_price)
          totalquantity+= parseInt(quantity);
          localStorage.setItem('totalquantity', parseInt(totalquantity))
        }
      }
    });

     
    return total;
  };

  useEffect(() => {

    settotalprice1(calculateTotalPrice());
   localStorage.setItem('Discountprice', parseInt(totalprice1));
  
   
  
},[tempQuantities,totalprice1,products]);

const handleisLoading=(loading)=>
{
  
   setIsLoading(loading);
}

console.log("buttonClicked", buttonClicked)
console.log("showcart", showcart)
console.log("Mycheckout", Mycheckout)

 useEffect(()=>
{
  const access= localStorage.getItem('accessToken')
 
  const apiUrl8 =`${Baseurl}/consumer/customer-checkout/`;
   
 

  const fetchData = async()=>
  {
     try 
     {
      if((buttonClicked===true && access!==null)&& (showcart===true && Mycheckout===true))
      { 
          

           setloading1(true)
          const response2 = await fetch(apiUrl8, 
            {
              headers: {
                'Authorization' : `Bearer ${access}`
               }
            });
           
            const checkout = await response2.json();

            console.log(checkout)
            const  Categorydata = checkout.cart;

             setValue2(Categorydata);
              setitem(Categorydata);
              setProducts(Categorydata);
              setvalue(Categorydata); 
              setButtonClicked(false);
              setshowlocal(false)
              setloading1(false)
              dispatch(Handlemycheckout(false))
            
        }
        
     
      
            setIsLoading(false);


     } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    fetchData();
  }
 
            
},[isLoading, businessId, increased, showcart])


  const handleBookingCompletion = () => {

    localStorage.setItem('arr',JSON.stringify(arr))
    navigate(`/${businessname}/address`, { state: { arr} });

  };

  function formatIndianNumber(number) {
    const parts = number.toString().split(".");
    let integerPart = parts[0];
    let formattedInteger = "";
  
    while (integerPart.length > 3 && number>=10000) {
      formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
      integerPart = integerPart.slice(0, -3);
    }
    formattedInteger = integerPart + formattedInteger;
  
    return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
  }

/*
 useEffect(()=>
 {

    
     const fetchData =async()=>
     {
      

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}`);
  
    
        const formdata = new FormData();
         formdata.append("business_owner_id", businessId);
         for (let i = 0; i < products.length; i++) {

         formdata.append("cart_id", products[i].cart_id);
         }
  
        const requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow',
        };
  
        const response = await fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions);
        const result = await response.text();
        const data = JSON.parse(result);
  
        console.log(data);
  
        if (data.status === 'success') {
          
            localStorage.removeItem('productImage')
            localStorage.removeItem('productdata')
            localStorage.removeItem('variantSize')
            localStorage.removeItem('variantColor')
            localStorage.removeItem('updatedCartItems')
            
        } 
      
    } catch (error) {
      console.log('error', error);
      setdeletecreate(false)
    }

  }

  if (deletecreate) {
    fetchData();
  }
})
*/


const Canceldata=async ()=>
{
     if(access!==null)
     { 
        try {
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${access}`);
      
         
            const formdata = new FormData();
            formdata.append("business_owner_id", businessId);
            for (let i = 0; i < products.length; i++) {
            formdata.append("cart_id", products[i]._id);
            }
            const requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow',
            };
      
            const response = await fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions);
            const result = await response.text();
            const data = JSON.parse(result);
      
            console.log(data);
      
            if (data.status === 'success') {
              
             
                  localStorage.removeItem('checked')
                  localStorage.removeItem('productImage')
                  localStorage.removeItem('productdata')
                  localStorage.removeItem('variantSize')
                  localStorage.removeItem('variantColor')
                  localStorage.removeItem('updatedCartItems')
                  localStorage.removeItem('arr')
                  dispatch(Handlemycheckout(true))
                  setalldelete(true);
                  setIsLoading(true);
                  setButtonClicked(true)
                
            } 
            
          

        } catch (error) {
          console.log('error', error);
        }
      }

      else 
      {
        isSmScreen? navigate(`/${businessname}/connect`):dispatch(Handlemyaccount(true));
      }
}


    useEffect(()=>
    {
      
        if((products===undefined || products?.length===0) && alldelete===true)
        {
              navigate(`/${businessname}/`)
              setshowpayment(true);
              resetItemAdded();
        }
        
    },[products, alldelete])



    const coupon=()=>
    {
        localStorage.setItem('cart','cart')
        navigate(`/${businessname}/applycoupons`);
    }

  


    const removecoupon=()=>
    {
      
      dispatch(couponsCode(''));
      localStorage.removeItem('coupon_id')
    }


    const coupon_id=JSON.parse(localStorage.getItem('coupon_id'));

    const selectedThumbanil = localStorage.getItem('selectedThumbanil') 

    const discount = couponValue === "" ? 0 : ((totalprice1 * 10) / 100).toFixed(2);


       console.log("alldelete", alldelete)
        console.log("products", products);


    const arr =products && products?.map((cur) => {

      const quantity = showlocal? showpayment ? cur.quantity + (tempQuantities[cur._id.$oid] || 0) : Totalcount : showpayment ? cur.catc_quantity + (tempQuantities[cur._id] || 0) : cur.catc_quantity;

        const product_id = cur.bo_product_id? cur.bo_product_id:cur._id.$oid;
        const variant_id =  cur.bo_variant_id?cur.bo_variant_id:"";
        const itemprice =  (showlocal===true) ? parseInt(Discountprice): (cur.variant_details&& cur.variant_details.length!==0  ? cur.variant_details.length>1 ? cur.variant_details[1].bo_product_discount:cur.variant_details[0].bo_product_discount :cur.product_details[0].bo_product_discounted_price);
        const product_name= cur.bo_product_name?cur.bo_product_name:cur.product_details[0].bo_product_name 

        return { 
          product_name:product_name,
          product_id:product_id ,
          variant_id: variant_id,
          quantity: parseInt(quantity),
          item_price:parseInt(itemprice)
        };
      });

      console.log("arr", arr);

 const Proceed=()=>
 {

  const access= localStorage.getItem('accessToken')
  console.log(access)
    
  if(access!==null)
      
   {

     let myHeaders = new Headers();

     myHeaders.append("Authorization", `Bearer ${access}` ); 
      
     var formdata = new FormData();
     formdata.append("business_owner_id", businessId);
    for(let i=0;i<arr.length;i++)
    {
      formdata.append("product_name", arr[i].product_name);
      formdata.append("product_id", arr[i].product_id);
      formdata.append("variant_id", arr[i].variant_id);
      formdata.append("quantity", arr[i].quantity);
    }
     

   let requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
};
             
      fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
     .then(response => response.text())
     .then(result=>{
         const data= JSON.parse(result);
         
         if(data.status==='success')
         {
          localStorage.removeItem('productdata')
          localStorage.removeItem('variantSize')
          localStorage.removeItem('variantColor')
          localStorage.removeItem('updatedCartItems')
          setIsLoading(true);
          localStorage.removeItem('timeslot');
          localStorage.removeItem('daySlot');
          localStorage.removeItem('checked');
          localStorage.removeItem('quantity');
            handleBookingCompletion(); 
         }
        
     })
     .catch(error => console.log('error', error));

   }
   else 
   {
    isSmScreen? navigate(`/${businessname}/connect`):dispatch(Handlemyaccount(true));
   }
   
}


 useEffect(()=> {

  

     const updatedCartItems = JSON.parse(localStorage.getItem('updatedCartItems'))
     const storedAccessToken = localStorage.getItem("accessToken");

     if(showlocal && (storedAccessToken!==null) && local===true)
     {
      for(let i=0;i<updatedCartItems.length;i++)
    {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${storedAccessToken}` );   
      
      let raw = JSON.stringify({
        "business_owner_id": businessId,
        "cart": [updatedCartItems[i]]
      });

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
        console.log(data);

         if(data.status==="success")
         {
              dispatch(Handlemycheckout(true))
              dispatch(Handlemylocal(false))
              setshowcart(true)
              setIsLoading(true)
          
         }
      

        })
        .catch(error => console.log('error', error)); 
      }
    }   

 },[access,showlocal,local])


 console.log(arr);
 console.log(products)
 console.log(showlocal)

     return (
        <>
                  <section className= {isSmScreen? "slider-header service-margin": "explore-padding"}>
             <Container maxWidth="lg" className="p-16">
              <div>

             <div className="breadcrumb mobile-bodypadding ">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>

        <StyledNavLink to={`/${businessname}/product/${Categoryname}/${sanitizedServiceName}`}>
        {servicename}
        </StyledNavLink>

        <StyleNavLink
          to={`/${businessname}/viewcart`}  
        >
          Cart
        </StyleNavLink>
        
      </Breadcrumbs>
      </div>

           {loading1 ?
             <>
              <Cartskeleton/>
             </> 
             :
          

              <Box sx={{width:"100%", boxSizing:"border-box", marginBottom:'60px'}}>
          <Grid container rowSpacing={2}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start">
           

          <Grid item  xs={12} sm={isSmScreen? 12:8} md={8}>
       
          <Items className="global_box_shadow mobile-bodymargin">
          
           {products?.map((cur,index)=>
            {      
                return (
                  <>
                    {showlocal ? <>
                      
                      <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >
                    {showpayment &&  <div className="image_lazyload">

                      <LazyLoadImage  src={productImage.length!==0 ? productImage[index] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                        alt={productImage.length!==0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                    </div>
                }
                <div>
                    
                    {((cur.variant_details && cur.variant_details.length!==0) &&(variantSize || variantColor)) ? 
                <h2 className="label_titles" style={isSmScreen?{marginTop:'0px', marginBottom:'6px'} :{marginTop:'0px'}}>
                                        {cur.bo_product_name} <div className="variantDetails"> [
                                        {variantSize?.map(curs=>
                                          {
                                             return(<>
                                                 {curs.bo_product_id.$oid===cur._id.$oid && curs.bo_product_variant_value}
                                             </>)
                                          })}{(variantSize?.some(size => size.bo_product_id.$oid === cur._id.$oid) &&
                                            variantColor?.some(color => color.bo_product_id.$oid === cur._id.$oid)) && " + "}
                                              {variantColor?.map(curs=>
                                          {
                                             return(<>
                                                 {curs.bo_product_id.$oid===cur._id.$oid && curs.bo_product_variant_value}
                                             </>)
                                          })}]  </div>
                                        </h2>
                         :
                         <h2 className="label_titles" style={isSmScreen?{marginTop:'0px', marginBottom:'6px'} :{marginTop:'0px'}}>
                         {cur.bo_product_name}              
                         </h2>

                    }
          
          {showpayment &&   <div>
       
            {((cur.variant_details && cur.variant_details.length!==0) &&(variantSize|| variantColor)) ? 
                    <div className="priceIcon">
                  
                  {(variantColor && variantColor.length!==0 &&  variantColor.some(color => color.bo_product_id.$oid === cur._id.$oid)) ? <div className="expected_price">
                    {variantColor?.map(curs=>
                      {
                         return(
                          <>
                              {curs.bo_product_id.$oid===cur._id.$oid && 
                               <span>₹{formatIndianNumber(curs.bo_product_discount)}</span>
                              }
                              
                          </>
                         )
                      })}

              </div> : <div className="expected_price">
              {variantSize?.map(curs=>
                      {
                         return(
                          <>
                              {curs.bo_product_id.$oid===cur._id.$oid && 
                               <span>₹{formatIndianNumber(curs.bo_product_discount)}</span>
                              }
                              
                          </>
                         )
                      })}
                    
                    </div>}
                  
                  {(variantColor && variantColor.length!==0&& variantColor.some(color => color.bo_product_id.$oid === cur._id.$oid)) ? <div className="real_price">
                  {variantColor?.map(curs=>
                      {
                         return(
                          <>
                              {curs.bo_product_id.$oid===cur._id.$oid && 
                            <span><del>₹{formatIndianNumber(curs.bo_product_price)}</del></span>
                              }
                              
                          </>
                         )
                      })}
                    
                    
                    </div> : <div className="real_price">
                    {variantSize?.map(curs=>
                      {
                         return(
                          <>
                              {curs.bo_product_id.$oid===cur._id.$oid && 
                            <span><del>₹{formatIndianNumber(curs.bo_product_price)}</del></span>
                              }
                              
                          </>
                         )
                      })}
                    
                      
                      </div>}


                  {(variantColor && variantColor.length!==0&& variantColor.some(color => color.bo_product_id.$oid === cur._id.$oid)) ? 
                    <> 
                  {variantColor?.map(curs=>
                      {
                         return(
                          <>
                              {curs.bo_product_id.$oid===cur._id.$oid && 
                                <div className="discount_price">{parseInt(((curs.bo_product_price-curs.bo_product_discount)*100)/curs.bo_product_price)} % OFF</div>
                              }
                              
                          </>
                         )
                      })}
                         </>
                    
                   
                  : 
                  <> 
                  {variantSize?.map(curs=>
                      {
                         return(
                          <>
                              {curs.bo_product_id.$oid===cur._id.$oid && 
                                <div className="discount_price">{parseInt(((curs.bo_product_price-curs.bo_product_discount)*100)/curs.bo_product_price)} % OFF</div>
                              }
                              
                          </>
                         )
                      })}
                         </>
                  
                  }
                 
               </div>
               :
               <div className="priceIcon">
                  
               {cur.bo_product_discounted_price!==null &&  <div className="expected_price"><span>₹{formatIndianNumber(cur.bo_product_discounted_price)}</span></div>}
               
               {cur.bo_product_price!==null &&  <div className="real_price"><span><del>₹{formatIndianNumber(cur.bo_product_price)}</del></span></div>}
               <div className="discount_price">{parseInt(((cur.bo_product_price-cur.bo_product_discounted_price)*100)/cur.bo_product_price)}% OFF</div>
              
            </div>
            }
                     
           
                    <div className="checkoutdetails">
              <table>
              <tr>
              <td>
             {showlocal ? <button onClick={()=>remove(cur._id.$oid, cur._id.$oid)}  className="removeIcon"><RemoveIcon/></button>
             :<button onClick={()=>remove(cur._id, cur.bo_product_id)}  className="removeIcon"><RemoveIcon/></button>}</td>

              {showlocal ? localStorage.setItem('count', cur.quantity + (tempQuantities[cur._id.$oid]|| 0))  :localStorage.setItem('count', parseInt(cur.catc_quantity) + (tempQuantities[cur._id]|| 0))}
              
              {showlocal ?    <td> <div className="datacount">{cur.quantity + (tempQuantities[cur._id.$oid]||0)}</div></td> : 
              <td> <div className="datacount">{parseInt(cur.catc_quantity) + (tempQuantities[cur._id]||0)}</div></td>
              }
              <td>
               {showlocal ? <button onClick={()=>add(cur._id.$oid)} className="removeIcon"><AddIcon/></button>
               : <button onClick={()=>add(cur._id)} className="removeIcon"><AddIcon/></button>}
                </td>
              </tr>
              </table>
               <div className="deleteoutlined" onClick={()=>deleted(cur._id, cur.bo_product_id)}><Delete/></div>
              </div>
              </div>
          }

            

                                <Dialog
                      
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle id="responsive-dialog-title">
                          {"Delete Service"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                          Are you sure you want to delete service  item ?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus  style={deletebuttons} onClick={cancel}>
                            Cancel
                          </Button>
                          <Button style={deletebuttons} onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })} autoFocus>
                            Yes, Delete
                          </Button>
                        </DialogActions>
                      </Dialog>

                 
              </div> 
              </div>  
                    

                    </> : 
                    <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >
                    {showpayment &&  <div className="image_lazyload">

                      <LazyLoadImage  src={cur.product_details[0].bo_product_images.img_urls.length>0 ? cur.product_details[0].bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                        alt={cur.product_details[0].bo_product_images.img_urls.length > 0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                    </div>
                }
                <div>
                    
                    {(cur.product_details[0].varient_info && cur.product_details[0].varient_info.length!==0) ? 
                <h2 className="label_titles" style={isSmScreen?{marginTop:'0px', marginBottom:'6px'} :{marginTop:'0px'}}>
                                        {cur.product_details[0].bo_product_name}
                                        <div className="variantDetails">[{cur.product_details[0].varient_info && cur.product_details[0].varient_info[0].bo_product_variant_value}{cur.product_details[0].varient_info.length>1 && " + " }{cur.product_details[0].varient_info.length>1 && cur.product_details[0].varient_info[1].bo_product_variant_value}]</div>         
                                        </h2>
                         :
                         <h2 className="label_titles" style={isSmScreen?{marginTop:'0px', marginBottom:'6px'} :{marginTop:'0px'}}>
                         {cur.product_details[0].bo_product_name}              
                         </h2>

                    }
          
          {showpayment &&   <div>
       
            {(cur.product_details[0].varient_info && cur.product_details[0].varient_info.length!==0) ? 
                    <div className="priceIcon">
                  
                  {cur.product_details[0].varient_info.length>1 ? <div className="expected_price"><span>₹{formatIndianNumber(cur.product_details[0].varient_info[1].bo_product_discount)}</span></div> : <div className="expected_price"><span>₹{formatIndianNumber(cur.product_details[0].varient_info[0].bo_product_discount)}</span></div>}
                  
                  {cur.product_details[0].varient_info.length>1 ? <div className="real_price"><span><del>₹{formatIndianNumber(cur.product_details[0].varient_info[1].bo_product_price)}</del></span></div> : <div className="real_price"><span><del>₹{formatIndianNumber(cur.product_details[0].varient_info[0].bo_product_price)}</del></span></div>}
                  {cur.product_details[0].varient_info.length>1 ? <div className="discount_price">{parseInt(((cur.product_details[0].varient_info[1].bo_product_price-cur.product_details[0].varient_info[1].bo_product_discount)*100)/cur.product_details[0].varient_info[1].bo_product_price)}% OFF</div>
                  : 
                  <div className="discount_price">{parseInt(((cur.product_details[0].varient_info[0].bo_product_price-cur.product_details[0].varient_info[0].bo_product_discount)*100)/cur.product_details[0].varient_info[0].bo_product_price)}% OFF</div>
                  }
                 
               </div>
               :
               <div className="priceIcon">
                  
               {cur.product_details[0].bo_product_discounted_price!==null &&  <div className="expected_price"><span>₹{formatIndianNumber(cur.product_details[0].bo_product_discounted_price)}</span></div>}
               
               {cur.product_details[0].bo_product_price!==null &&  <div className="real_price"><span><del>₹{formatIndianNumber(cur.product_details[0].bo_product_price)}</del></span></div>}
               <div className="discount_price">{parseInt(((cur.product_details[0].bo_product_price-cur.product_details[0].bo_product_discounted_price)*100)/cur.product_details[0].bo_product_price)}% OFF</div>
              
            </div>
            }
                     
           
                    <div className="checkoutdetails">
              <table>
              <tr>
              <td>
              <button onClick={()=>remove(cur._id, cur.bo_product_id)}  className="removeIcon"><RemoveIcon/></button></td>

              {localStorage.setItem('count', parseInt(cur.catc_quantity) + (tempQuantities[cur._id]|| 0))}

              <td> <div className="datacount">{parseInt(cur.catc_quantity) + (tempQuantities[cur._id]||0)}</div></td>
            
              <td><button onClick={()=>add(cur._id)} className="removeIcon"><AddIcon/></button></td>
              </tr>
              </table>
               <div className="deleteoutlined" onClick={()=>deleted(cur._id, cur.bo_product_id)}><Delete/></div>
              </div>
              </div>
          }

            

                                <Dialog
                      
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle id="responsive-dialog-title">
                          {"Delete Service"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                          Are you sure you want to delete service  item ?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus  style={deletebuttons} onClick={cancel}>
                            Cancel
                          </Button>
                          <Button style={deletebuttons} onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })} autoFocus>
                            Yes, Delete
                          </Button>
                        </DialogActions>
                      </Dialog>

                 
              </div> 
              </div>  
                
        }  
                  
                  </>
                )
            })}

          </Items>
           
    
        </Grid>

        <Grid item xs={12} sm={isSmScreen? 12:4} md={4} style={{ paddingTop: isSmScreen ? "8px" : "6px"}}  >

       <div>
         {couponValue==="" ?

         <Item className="global_box_shadow">
         <div className="coupon" onClick={coupon}>
         <div className="offerIcon">
         <div className="localoffericon"><LocalOfferIcon/></div>
          <div className="apply">Apply coupon</div>
          </div>
          
          <div className="keyboard"><KeyboardArrowRightIcon/></div>
          </div>
         </Item>
            :
         <Item className="global_box_shadow">
         <div className="coupon">
         <div className="offerIcons">
         <div className="localoffericon"><LocalOfferIcon/></div>
       
         <div>
         <div className="apply">1 Coupon Applied</div>
         {(()=>
               {
                const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);

                  return <div className="total_discount additional_save"> You saved additional ₹{formatIndianNumber(parseInt(totalservicecharge1))}</div>
               })()}
      
         </div>
         </div>
          <button className="remove-coupon" onClick={removecoupon} >Remove coupon</button>
          </div>
         
         </Item>
         }
         </div>

        

          <Item className={ showpayment ?"item_booking global_box_shadow " : "item_booking global_box_shadow mobile-bodymargin"}>
           <div className="bill_details">Billing Details</div>
          
            <div className="totalcost">
            <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Price {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>
             {(()=>
               {

                const totalservicecharge1= (totalprice1);
                return <div className="totalprices">₹{formatIndianNumber(totalservicecharge1)}</div>                     
               })()}

            </div>
{/*  
            <div className="totalcost">
            <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Delivery fee</div>
           
             {(()=>
               {

                const totalservicecharge1= (totalprice1/10);             

                return <div className="total_discount">FREE</div>
                   
               })()}
                
            </div> */}

            <div className="totalcost">
             <div className="total_discount">Discount</div>
             {couponValue==="" ?  
            <div className="total_discount"><RemoveIcon className="remove_discount"/>₹0</div>
              :
                <div>

                 {(()=>
               {

                const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);
           

                return <div className="total_discount"> <RemoveIcon className="remove_discount"/>₹{formatIndianNumber(parseInt(totalservicecharge1))}</div>
                 

               })()}
               </div>
             }
            </div>


         <div className="border"></div>              
  
            <div className="totalcost">
             
            <div className="total_money"> Grand Total {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>

             {(()=>
               {
                  if(couponValue==="")
                  {
                     

                      
                      const totalservicecharge= (parseInt(totalprice1));
    
                     localStorage.setItem("totalAmount",(totalservicecharge));
    
                      return <div className="totalprices">₹{formatIndianNumber(totalservicecharge)}</div>
                  }

                  else
                  {
                   
                    const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);
                    const totalservicecharge= (parseInt(totalprice1)  - totalservicecharge1) ;
  
                   
                    localStorage.setItem("totalAmount",(totalservicecharge));
  
                    return <div className="totalprices">₹{formatIndianNumber(totalservicecharge)}</div>
                  }
               })()}
           
            </div>

             
                   <div className="booking_button">

                     <Button style={Cancelitem} className="secondary_button" onClick={Canceldata}>Cancel</Button>
                     <Button style={ProceedItem} className="secondary_button" onClick={Proceed}>Continue</Button>    

                   </div>
                     

          </Item>
       
         </Grid> 

  
         </Grid>
         </Box>
}
         </div>
         
             <Stack spacing={2} sx={{ width: '100%' }}>

                  <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                   <Alert  severity="success" sx={{ width: '100%' }}>
                         Cart deleted successfully !
                         </Alert>
                    </Snackbar>

                  </Stack> 

                  <Stack spacing={2} sx={{ width: '100%' }}>
                
                <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                <Alert  severity="error" sx={{ width: '100%' }}>
                    Click here to Login/Sign Up
                      </Alert>
                  </Snackbar>

                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>

                <Snackbar open={opentoast1} autoHideDuration={2000}  key={verticaltoast1 + horizontaltoast1}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast1}>
                <Alert  severity="error" sx={{ width: '100%' }}>

                  Sorry! We don't have any more quantity.

                      </Alert>
                  </Snackbar>

                </Stack>

         </Container>

         


         </section>
       
        </>
     )
}

const mapDispatchToProps = {
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(CheckoutCart);


/*



  const selectedArray = cur.product_data[0].variant_details?.filter((item) => item._id.$oid === cur.bo_variant_id);
                          variantproduct.push(selectedArray);
                         
                       const emptyIndex =  variantproduct.map((arr, index) => {
                        return Array.isArray(arr) && arr.length === 0 ? index : -1;
                      });
                      const nonEmptyArrays = variantproduct.filter((arr) => Array.isArray(arr) && arr.length > 0);
                   
                      

                      console.log(nonEmptyArrays)
                      console.log(emptyIndex)
                      console.log(variantproduct)
          
                       if(nonEmptyArrays.length>1&& index===0)
                       {

                         console.log('double')
                         return (
                          <>
                               <div style={showpayment? checkoutflex : checkoutgrid} className={nonEmptyArrays.length===1? "" :"check_out_data"} >
                               { (showpayment && nonEmptyArrays.length>1) && 
                           <div className="image_lazyload">

                            <LazyLoadImage  src={cur.product_data.length > 0 ? cur.product_data[0].bo_product_images.img_urls[selectedThumbanil] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                             alt={cur.product_data.length > 0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                      </div>
                    
                      }      <div>
                                 {nonEmptyArrays.length>1 && <>
                                <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                                  {cur.product_data[0]?.bo_product_name}
                                  [{nonEmptyArrays.map(cur=>(
                                    cur.filter(item => item.bo_product_variant_name === 'Size')[0]?.bo_product_variant_value
                                  ))}
                                  + {nonEmptyArrays.map(cur=>(
                                    cur.filter(item => item.bo_product_variant_name === 'Color')[0]?.bo_product_variant_value
                                  ))}
                                ]
                                </h2>
                                </>}

                                {showpayment && <div>
                                  <div className="priceIcon">
                                  {nonEmptyArrays.length>1 && <>
                              <div className="expected_price">
                                <span>₹{nonEmptyArrays.map(cur=>(
                                    cur.filter(item => item.bo_product_variant_name === 'Color')[0]?.bo_product_discount
                                  ))}</span>
                              </div>
                              <div className="real_price">
                                <span><del>₹{nonEmptyArrays.map(cur=>(
                                    cur.filter(item => item.bo_product_variant_name === 'Color')[0]?.bo_product_price
                                  ))}</del></span>
                              </div>
                              <div className="discount_price">
                                {parseInt((nonEmptyArrays.reduce((totalPrice, cur) => {
                                  const colorVariant = cur.filter(item => item.bo_product_variant_name === 'Color')[0];
                                  return totalPrice + (colorVariant ? colorVariant.bo_product_price : 0);
                                }, 0) -
                                nonEmptyArrays.reduce((totalDiscount, cur) => {
                                  const colorVariant = cur.filter(item => item.bo_product_variant_name === 'Color')[0];
                                  return totalDiscount + (colorVariant ? colorVariant.bo_product_discount : 0);
                                }, 0))* 100 /
                                nonEmptyArrays.reduce((totalPrice, cur) => {
                                  const colorVariant = cur.filter(item => item.bo_product_variant_name === 'Color')[0];
                                  return totalPrice + (colorVariant ? colorVariant.bo_product_price : 0);
                                }, 0))
                                }% OFF
                              </div>

                              </>}
                                        
                                  </div>

                                  {nonEmptyArrays.length>1 &&
                              <div className="checkoutdetails">
                              <table>
                              <tr>
                              <td>
                              <button onClick={()=>remove(cur._id,cur.bo_product_id)}  className="removeIcon"><RemoveIcon/></button></td>

                              {localStorage.setItem('count', cur.catc_quantity + (tempQuantities[cur._id]|| 0))}

                              <td> <div className="datacount">{cur.catc_quantity + (tempQuantities[cur._id]||0)}</div></td>
                            
                              <td><button onClick={()=>add(cur._id)} className="removeIcon"><AddIcon/></button></td>
                              </tr>
                              </table>
                              <div className="deleteoutlined" onClick={()=>deleted(cur._id, cur.bo_product_id)}><Delete/></div>
                              </div>
                      }
                                  
                                  </div>} 

                                  <Dialog
       
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                  >
                                    <DialogTitle id="responsive-dialog-title">
                                      {"Delete Service"}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                      Are you sure you want to delete service  item ?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button autoFocus  style={deletebuttons} onClick={cancel}>
                                        Cancel
                                      </Button>
                                      <Button style={deletebuttons} onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })} autoFocus>
                                        Yes, Delete
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                </div>

                               </div>
                          </>
                         )
                       }
                        

                       else if(nonEmptyArrays.length===1 && emptyIndex!==index)
                       {
                           console.log(variantproduct.length)
                          return (
                            <>
                                  <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >
                               { (showpayment && nonEmptyArrays.length===1) && 
                           <div className="image_lazyload">

                            <LazyLoadImage  src={cur.product_data.length > 0 ? cur.product_data[0].bo_product_images.img_urls[selectedThumbanil] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                             alt={cur.product_data.length > 0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                      </div>
                    
                      }      <div>
                                 {nonEmptyArrays.length===1 && <>
                                <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                                  {cur.product_data[0]?.bo_product_name}
                                  [{nonEmptyArrays[0][0]?.bo_product_variant_value}
                                  ]
                                </h2>
                                </>}

                                {showpayment && <div>
                                  <div className="priceIcon">
                                  {nonEmptyArrays.length===1 && <>
                              <div className="expected_price">
                                <span>₹{nonEmptyArrays[0][0]?.bo_product_discount}</span>
                              </div>
                              <div className="real_price">
                                <span><del>₹{nonEmptyArrays[0][0]?.bo_product_price}</del></span>
                              </div>
                              <div className="discount_price">
                                {parseInt(((nonEmptyArrays[0][0]?.bo_product_price)-(nonEmptyArrays[0][0]?.bo_product_discount))*100 
                                 /  (nonEmptyArrays[0][0]?.bo_product_price))}
                                % OFF
                              </div>

                              </>}
                                        
                                  </div>

                                  {nonEmptyArrays.length===1 &&
                              <div className="checkoutdetails">
                              <table>
                              <tr>
                              <td>
                              <button onClick={()=>remove(cur._id,cur.bo_product_id)}  className="removeIcon"><RemoveIcon/></button></td>

                              {localStorage.setItem('count', cur.catc_quantity + (tempQuantities[cur._id]|| 0))}

                              <td> <div className="datacount">{cur.catc_quantity + (tempQuantities[cur._id]||0)}</div></td>
                            
                              <td><button onClick={()=>add(cur._id)} className="removeIcon"><AddIcon/></button></td>
                              </tr>
                              </table>
                              <div className="deleteoutlined" onClick={()=>deleted(cur._id, cur.bo_product_id)}><Delete/></div>
                              </div>
                      }
                                  
                                  </div>} 

                                  <Dialog
       
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                  >
                                    <DialogTitle id="responsive-dialog-title">
                                      {"Delete Service"}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText>
                                      Are you sure you want to delete service  item ?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button autoFocus  style={deletebuttons} onClick={cancel}>
                                        Cancel
                                      </Button>
                                      <Button style={deletebuttons} onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })} autoFocus>
                                        Yes, Delete
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                </div>

                               </div>
                            </>
                          )
                       }

             {variantproduct.length>1 && parseInt((((variantproduct[1][0]?.bo_product_price - variantproduct[1][0]?.bo_product_discount) * 100) / variantproduct[1][0]?.bo_product_price))}% OFF

  <Items className="global_box_shadow mobile-bodymargin">
          {products?.map(cur=>
          {
             
               return (
                  <>
          

           <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >

           {(()=>
           {
            const selectedArray = cur.product_data[0].variant_details?.filter((item) => item._id.$oid === cur.bo_variant_id);
            variantproduct.push(selectedArray);

             if(selectedArray && selectedArray.length > 0)
             {
                return (<>
                  { (showpayment && variantproduct.length>1) && 
                      <div className="image_lazyload">

                     <LazyLoadImage  src={cur.product_data.length > 0 ? cur.product_data[0].bo_product_images.img_urls[selectedThumbanil] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                             alt={cur.product_data.length > 0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                     </div>
                    
                  }
                 
                </>)
             }
             else 
             {
               return (
                <>
                    {showpayment  &&  <div className="image_lazyload">

                      <LazyLoadImage  src={cur.product_data.length > 0 ? cur.product_data[0].bo_product_images.img_urls[selectedThumbanil] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                              alt={cur.product_data.length > 0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                      </div>
                      }
                </>
               )
             }


           })()}

       
           <div>
                    {(() => {
           
            const selectedArray = cur.product_data[0].variant_details?.filter((item) => item._id.$oid === cur.bo_variant_id);
            variantarray.push(selectedArray);

            console.log(variantarray);

            if (selectedArray && selectedArray.length > 0) {

              return (
                <>
                   {variantarray.length>1 && <>
                  <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                    {cur.product_data[0]?.bo_product_name}
                    [{variantarray[0][0].bo_product_variant_value}
                    + {variantarray.length>1 &&  variantarray[1][0].bo_product_variant_value}
                   ]
                  </h2>
                  </>}
                </>
              );
            } 
            else
             {
              return (
                <>
                  <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                    {cur.product_data[0]?.bo_product_name}
                  </h2>
                </>
              );
            }
          })()}


       

          
          {showpayment &&   <div>
       
                   
                      {(()=>
                      {
                        const selectedArray = cur.product_data[0].variant_details?.filter((item) => item._id.$oid === cur.bo_variant_id)
                        varaintdiscount.push(selectedArray);
                        console.log(varaintdiscount)

                        if (selectedArray &&  selectedArray.length > 0) {
                          return (
                            <>
                              {varaintdiscount.length>1 &&  <div className="priceIcon">
                              <div className="expected_price">
                                <span>₹{varaintdiscount.length>1 && varaintdiscount[1][0]?.bo_product_discount}</span>
                              </div>
                              <div className="real_price">
                                <span><del>₹{varaintdiscount.length>1 && varaintdiscount[1][0]?.bo_product_price}</del></span>
                              </div>
                              <div className="discount_price">
                                {varaintdiscount.length>1 && parseInt((((varaintdiscount[1][0]?.bo_product_price - varaintdiscount[1][0]?.bo_product_discount) * 100) / varaintdiscount[1][0]?.bo_product_price))}% OFF
                              </div>
                              </div>}
                            </>
                          );
                        } else {
                          return (
                            <>
                            <div className="priceIcon">
                              <div className="expected_price">
                                <span>₹{cur.product_data[0].bo_product_discounted_price}</span>
                              </div>
                              <div className="real_price">
                                <span><del>₹{cur.product_data[0].bo_product_price}</del></span>
                              </div>
                              <div className="discount_price">
                                {parseInt(((cur.product_data[0].bo_product_price - cur.product_data[0].bo_product_discounted_price) * 100) / cur.product_data[0].bo_product_price)}% OFF
                              </div>
                              </div>
                            </>
                          );
                        }

                      })()}

                 
             

                    {(()=>
                    {
                      const selectedArray = cur.product_data[0].variant_details?.filter((item) => item._id.$oid === cur.bo_variant_id)
                      variantprice.push(selectedArray);
                      
                      if(selectedArray && selectedArray.length>0)
                      {
                         return (
                          <> 
                            {variantprice.length>1 &&
                              <div className="checkoutdetails">
                              <table>
                              <tr>
                              <td>
                              <button onClick={()=>remove(cur.bo_product_id,cur._id)}  className="removeIcon"><RemoveIcon/></button></td>

                              {localStorage.setItem('count', cur.catc_quantity + (tempQuantities[cur.bo_product_id]|| 0))}

                              <td> <div className="datacount">{cur.catc_quantity + (tempQuantities[cur.bo_product_id]||0)}</div></td>
                            
                              <td><button onClick={()=>add(cur.bo_product_id)} className="removeIcon"><AddIcon/></button></td>
                              </tr>
                              </table>
                              <div className="deleteoutlined" onClick={()=>deleted(cur._id, cur.bo_product_id)}><Delete/></div>
                              </div>
                      }
                          </>
                         )
                      }
                      else 
                      {
                         return (
                         <>
                           <div className="checkoutdetails">
                            <table>
                            <tr>
                            <td>
                            <button onClick={()=>remove(cur.bo_product_id,cur._id)}  className="removeIcon"><RemoveIcon/></button></td>

                            {localStorage.setItem('count', cur.catc_quantity + (tempQuantities[cur.bo_product_id]|| 0))}

                            <td> <div className="datacount">{cur.catc_quantity + (tempQuantities[cur.bo_product_id]||0)}</div></td>
                          
                            <td><button onClick={()=>add(cur.bo_product_id)} className="removeIcon"><AddIcon/></button></td>
                            </tr>
                            </table>
                            <div className="deleteoutlined" onClick={()=>deleted(cur._id, cur.bo_product_id)}><Delete/></div>
                            </div>
                         
                         </>
                         )
                      }

                    })()} 
           
                   



              </div>
          }

            

                <Dialog
       
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Service"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete service  item ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus  style={deletebuttons} onClick={cancel}>
            Cancel
          </Button>
          <Button style={deletebuttons} onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })} autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

                 
              </div> 
              </div>  
   
            
             
     
          
             </>
             )
        })

       
        }  
         </Items> 
 






  {cur.product_data[0].variant_details?.filter((item) => item._id.$oid=== cur.bo_variant_id)  ?  <div className="real_price"><span><del>₹{selectedArray[0]?.bo_product_price}</del></span></div> :<div className="real_price"><span><del>₹{cur.product_data[0].bo_product_price}</del></span></div>}
                   {cur.product_data[0].variant_details?.filter((item) => item._id.$oid=== cur.bo_variant_id) ?  <div className="discount_price">{parseInt(((selectedArray[0]?.bo_product_price-selectedArray[0]?.bo_product_discount)*100)/selectedArray[0]?.bo_product_price)}% OFF</div>    :<div className="discount_price">{parseInt(((cur.product_data[0].bo_product_price-cur.product_data[0].bo_product_discounted_price)*100)/cur.product_data[0].bo_product_price)}% OFF</div>}


const handle1=()=>
{
  setOpen1(false)
}





   const handleinput=(e)=>
  {
   
    
       let inputValue = e.target.value;
       
       if (!inputValue.startsWith('+91 ')) {
         setuser('+91 ' + inputValue);
       } else {
         setuser(inputValue);
       }
  }
  
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && user === '+91 ') {
      setuser('');
    }
  };

  const handleClick=()=>
  {
    
    

     const user1 = /^[+ 0-9]+$/g.test(user);

     const user2 = user.replace(/^0+/, '');

    if(user.length!==14 ||  user1===false)
   {
     setInvalid(true);
     setphone("secondary_login");
   }
   else{
     setInvalid(false);
     setphone("primary_login")

 
     if(user)
     {
      
      
         let formdata = new FormData();
         formdata.append("mobile_number", user2.slice(user2.length - 10));
         formdata.append("store_id", businessId);
        
             let requestOptions = {
             method: 'POST',
             body: formdata,
             redirect: 'follow',
               
        };
        
           fetch(`${Baseurl}/otp-sends/`, requestOptions)
          .then(response => response.text())
          .then(result=>{
              const data= JSON.parse(result);
              
              console.log(data.otp);
              if(data.status==='success')
              { 
               
                console.log(data);
              
                 handleOpen2();
                
                 dispatch(mobileNumber(user2.slice(user2.length - 10)));
                
            
                 /*
                 if (!timerStarted) {
 
                  const timer2 = setTimeout(() => {
                     handleVerify();
                     handleSuccessfullyClose();

                   
                  }, 2000);
                  return () => clearTimeout(timer2);
                }

}
else{
    navigate(`/${businessname}/verify-mobile`);
 
} 

})
.catch(error => console.log('error', error));



}


}

}

  <Modal open ={open1}>
           
           <div className="logincontainer">
        
        <div className="loginheader">
         <div className="head_title">Verify Mobile</div>
        <div className="closeicon" onClick={handle1}><CloseIcon style={{transform:"scale(1.3)"}}/></div> 
        </div>
      
      
                        
         <div className="loginbody">
         
         {!invalid &&  <TextField className="login_data" id="outlined-required"   label="Phone Number"  type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1" },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }}  value={user} name="user"  minLength={10} maxLength={10}  onKeyDown={handleKeyDown}  />}
         {invalid &&   <TextField
        error
        id="outlined-error"
        label="Error"
        className="login_data"   type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1" },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }}  value={user} name="user"  onKeyDown={handleKeyDown}
        
      />}
                       {invalid  && <label className="login_exist">Enter a valid mobile number.</label>}
                      
                       
                       <div className="logging_title agree">By logging in, I agree to <NavLink  to={`/${businessname}/terms-and-conditons`}>terms</NavLink> and  <NavLink  to={`/${businessname}/privacy-policy`}>privacy policy</NavLink>.</div>

                       </div>

                       <div className="loginfooter">
                    <Button style={Loginitem} className="primary_button" onClick={handleClick}>Send OTP</Button>
                    
                    </div>
                   
                 


    
        </div>

           </Modal>

           <Modal open ={open2}>

            <Mobileupdate handleOpen2={handleOpen2} handleClose2={handleClose2} handleOpen1={handleOpen1}/>
           </Modal>

                    <div className=" body_title real_pricevariant"><del className="price">₹{cur.variant_details.variant_price}</del></div>
                    <div className=" body_title discountvariant">{cur.variant_details.variant_percentage}% Off</div>

 
                   {localStorage.setItem('count', cur.quantity + (tempQuantities[cur.variant_id]||0))}

 <div>
               <div className="total_money">Total</div>
               {(()=>
                 {
                    if(couponValue==="")
                    {
                       

                        
                        const totalservicecharge= ((parseFloat(totalprice1*0.18) +parseFloat(totalprice1) + parseFloat(totalprice1/10)).toFixed(2));
      
                       localStorage.setItem("totalAmount",(totalservicecharge));
      
                        return(
                          <>
                              <div className="total_save">
                              <div className="totalprices">₹{parseInt(totalservicecharge)}</div>
                               <div className="total_discount">Save {save}%</div>
                               </div>
                          </>
                        )
                     
                        
                       
                    }

                    else
                    {
                     

                      const totalservicecharge1= ((totalprice1*couponValue)/100).toFixed(2);
                      const totalservicecharge= ((parseFloat(totalprice1*0.18) +parseFloat(totalprice1) + parseFloat(totalprice1/10)) - totalservicecharge1).toFixed(2) ;
    
                     
                      localStorage.setItem("totalAmount",(totalservicecharge));
    
                      return (
                      <>
                              <div className="total_save">
                             <div className="totalprices">₹{parseInt(totalservicecharge)}</div>
                             <div className="total_discount">Save {save}%</div>
                             </div>
                      </>
                      )
                    }
             
                     

                 })()}
             </div>










  if(input.payment==='online')
            {
              handlePayment();
             setIsLoading(true);
            localStorage.removeItem('timeslot');
            localStorage.removeItem('daySlot');
            localStorage.removeItem('checked');
            localStorage.removeItem('addressId');
             setdeletecreate(false);
             resetItemAdded(); 
            
            }

            else
            {
             navigate("/bookingsuccessfull");
             setIsLoading(true);
             localStorage.removeItem('timeslot');
             localStorage.removeItem('daySlot');
             localStorage.removeItem('checked');
             localStorage.removeItem('addressId');
             setdeletecreate(false);
             resetItemAdded();
            }






   <div className="totalprices">₹{parseInt(totalAmount*0.98)}</div>
                <div className="Off-online">(2% off)</div>
                <div  className="total_discount">Save ₹{parseInt(totalAmount*0.02)}</div>
  
                <Modal open={open}>
               
                   <div className="confirmdelete">
                      <div className="removeItem">Remove Item</div>
                      <div className="deleteservice">Are you sure you want to delete Service  item ?</div>
                      <div>
                        <div className="deleted_item">
                           <button className="cancelitem" onClick={cancel}>Cancel</button>
                           <button className="deleteitem" onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })}>Yes, Delete</button>
                        </div>
                      </div>

                   </div>


                </Modal>



 
      */      