import React,{useEffect, useState} from 'react'
import CheckoutCart from './CheckoutCart'
import Footer from '../Footer/Footer'
import Bottomnavigation from '../Home/BottomNavigation'
import Header from '../Header/header';
import { useSelector } from 'react-redux'
import SearchFilter from '../Header/SearchFilter'
import {createTheme,  useMediaQuery } from '@mui/material';
import Headercart from './Headercart';

import { useNavigate } from 'react-router-dom';
import { connect} from "react-redux";

const Checkoutdetails = ({itemAdded}) => {


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const access = localStorage.getItem("accessToken");

  const businessname=localStorage.getItem('businessname');


 
const navigate=useNavigate()
  

useEffect(() => {
  if (!itemAdded) {
     navigate(`/${businessname}/empty-cart`);
  }
}, []);


useEffect(() => {


  if (access === null) {

    navigate(`/${businessname}/viewcart`);
  }
}, [access]);

  return (
    <>
         <div className="contain">

                {isSmScreen ? <Headercart /> : <Header/>}
                <div className="content" style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}} >
                <CheckoutCart  />
             
                </div>
                <Footer />
                </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    itemAdded: state.itemAdded,
  };
};

export default connect(mapStateToProps)(Checkoutdetails);



/*     

line number 72
 {(()=>
        {
            if(access!==null)
            { return(
              <>
                      {itemAdded &&   <div className="contain">

                {isSmScreen ? <Headercart /> : <Header updateLocation={updateLocation} Message={Message} handleLoading={handleLoading} />}
                <div className="content" style={{ backgroundColor: "#f3f3f3"}}>
                <CheckoutCart />
                </div>
                <Footer />
                </div>
              } 
              </>
            )
               
            }
        })()}



*/