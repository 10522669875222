
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {createTheme, useMediaQuery } from "@mui/material";

const Couponskeleton=()=>
{
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const Item = styled(Paper)(({ theme }) => ({
    
        ...theme.typography.body2,
        padding: '20px',
        background: '#FFF',
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
        marginBottom:isSmScreen?'4px':'14px',
      
      }));
      const arrayLength = 3;



      const item = Array.from({ length: arrayLength }, (_, index) => `Item ${index + 1}`);
      
    return (
        <>
             {item.map(cur=>{
                return(
                    <>

                   <Item className="global_box_shadow">
                         <div  className="coupon_data">
             <div className="coupon_applied" >
             
              <div className="coupon_image"><Skeleton height={"15.5vh"}/></div>
              <div className="coupon_applydata">
                <div className="coupon_title"><Skeleton width={"24vh"}/></div>
                <div className="coupon_description"><Skeleton width={"24vh"}/></div>
                <div className="apply_coupon">
                <div><Skeleton width={"22vh"}/></div>
                <div><Skeleton width={"18vh"}/></div>
                </div>
              </div>
             </div>
            
             </div>
             </Item>
              
                    </>
                )
             })}
              
                 
        </>
    )
}
export default Couponskeleton 