import React, {useState, useEffect} from "react";
import { Box, Grid } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import StarIcon from '@mui/icons-material/Star';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect, useDispatch } from "react-redux";
import { Handledecreaseitem, Handleincreaseitem, Handlemyaccount, Handlemycheckout, addToCarts,resetItemAdded,variantdataId,} from "../Redux/action";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { NavLink, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CheckoutPageskeleton from "./CheckoutPageskeleton";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container';
import { FacebookIcon, FacebookShareButton,  WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon} from "react-share";
import {createTheme,  useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Helmet } from "react-helmet";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const Item = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  
}));

const  custombuyButton= {
    backgroundColor:'var(--white)',
    color:'black',
    fontSize:'16px',
    border:"1px solid black",
    textTransform:'none',
    whiteSpace: 'nowrap',
    fontWeight:600,
    '&:hover': {
      backgroundColor: 'black', 
    },

};
const  customaddButton= {
  backgroundColor:'#4D1BDB',
  color:'var(--white)',
  fontSize:'16px',
  textTransform:'none',
  whiteSpace: 'nowrap',
  fontWeight:600,
  '&:hover': {
    backgroundColor: '#4D1BDB', 
  },

};
const  customselect= {
  border:'1px solid #d2d2d2',
  backgroundColor:'#fff',
  color:'black',
  opacity:'0.6',
  padding:'10px 12px',
  fontSize:'14px',
  fontFamily:'var(--fontfamily)',
  width:'fit-content',
  borderRadius:'4px',
  cursor:'pointer',
  fontWeight:500,
  '&:hover': {
    backgroundColor: '#fff', 
  },

};

const  customproduct_select= {
  border:'1px solid #4D1BDB',
  backgroundColor:'#fff',
  color:'#4D1BDB',
  padding:'6px 8px',
  fontSize:'14px',
  fontFamily:'var(--fontfamily)',
  width:'fit-content',
  borderRadius:'4px',
  cursor:'pointer',
  fontWeight:500,
  '&:hover': {
    backgroundColor: '#fff', 
  },

};

const CheckoutPage=({data, productdata,coupons, loading, variant , selectedVariant, setSelectedVariant, setShowaddtocart, addToCarts,resetItemAdded, handleloading })=>
{


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Node_baseurl = process.env.REACT_APP_NODEJS_BASE_URL;

  const Item1 = styled(Paper)(({ theme }) => ({
      
    ...theme.typography.body2,
    padding:"20px",
    borderRadius:'6px',
    marginBottom:isSmScreen?"10px":"20px",
    boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',
  
  }));

    const Baseurl = process.env.REACT_APP_BASE_URL;

    const navigate=useNavigate();

    const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {};


  //  const itemAdded=useSelector(state=>state.itemAdded);

   const access= localStorage.getItem("accessToken");



    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));
    
    const bookmarkaddstyle={
      color:"#ff4343",
 
    }

    const bookmarkremovestyle={
      color:"#000000",
      opacity:0.7
    }
   
 
   const Price = localStorage.getItem('Price');
   const TotalMoney = localStorage.getItem('Money');
   const Money=parseFloat(TotalMoney).toFixed(2);
   const servicename = localStorage.getItem('productname');
   const categoryname= localStorage.getItem('Categoryname');
   const  Totalcount = localStorage.getItem('quantity');

   const productId =localStorage.getItem('DataId')

   const businessId=localStorage.getItem('storeId');
   const businessname=localStorage.getItem('businessname');

   const [isloading, setisloading]=useState(false);
    
   const [share, setshare]=useState(false);

   const Show=localStorage.getItem('Show');

   const [openoffers, setopenoffers]= useState(false)

  const [totalprice, settotalprice] = useState();

 
  const [selectedPrice, setSelectedPrice] = useState();

  const [selectedColor, setselectedColor] = useState();
  const [selectedSize, setselectedSize] =useState();
 
   const [variantdata, setvariantdata] =useState([]);

   const [isOpen, setisOpen] = useState(false);

   const [selectedThumbnail, setSelectedThumbnail] = useState(0);

   const [defaultSelect, setdefaultSelect] = useState(true)

   const[actualprice, setactualprice] = useState()

   const [showFullDescription, setShowFullDescription] = useState(false);

   const toggleDescription = () => {
     setShowFullDescription(!showFullDescription);
   };

   const handleCarouselChange = (index) => {

     setSelectedThumbnail(index);
   };

   localStorage.setItem('selectedThumbanil',selectedThumbnail)

   const [tempQuantities, setTempQuantities] = useState(
    JSON.parse(localStorage.getItem('tempQuantities')) || {}
  );


  useEffect(() => {
    localStorage.setItem('tempQuantities', JSON.stringify(tempQuantities));
  }, [tempQuantities]);



  const visibleCoupons = coupons && (coupons?.length<3 ? coupons : coupons.slice(0, 2));
   
  const RemainingCoupons= coupons && (coupons?.slice(2, coupons.length));

   const dispatch=useDispatch();

  


   const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  

   const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

  

  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;

   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

  const [openSnackbar3, setopenSnackbar3]=useState({

    opentoast1: false,
    verticaltoast1: 'top',
    horizontaltoast1: 'center',
   });


   const { verticaltoast1, horizontaltoast1, opentoast1 } = openSnackbar3;

   const handleCloseToast1 = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar3({...openSnackbar3, opentoast1:false});

  };

  const [openSnackbar4, setopenSnackbar4]=useState({

    opentoast2: false,
    verticaltoast2: 'top',
    horizontaltoast2: 'center',
   });


   const { verticaltoast2, horizontaltoast2, opentoast2 } = openSnackbar4;

   const handleCloseToast2 = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar4({...openSnackbar4, opentoast2:false});

  };



   const handle=()=>
   {
     setisOpen(false);
   }

 
  const [updatedCartItems, setUpdatedCartItems] = useState([]);

 const shareIcon=()=>
 {
    setshare(!share);
 }


  useEffect(() => {
    
    let updatedItems = [updatedCartItems];

  
    if (updatedItems.length > 0 && updatedItems[0].product_id !== data) {
      updatedItems = [];
    }

    const itemprice=localStorage.getItem('Discountprice')

    if((selectedColor===""||selectedColor===undefined) && (selectedSize==="" || selectedSize===undefined))
    {
      updatedItems.push({
        product_name:servicename,
        product_id: data,
        variant_id: "",
        quantity:1,
        item_price:parseInt(itemprice)
      })
    }

    else 
    {
      updatedItems.push({
        product_name:servicename,
        product_id: data,
        variant_id: `${selectedSize ? selectedSize :''}${(selectedSize&&selectedColor) ? ",":''}${selectedColor ?selectedColor : ''}`,
        quantity:1,
        item_price:parseInt(variantdata[0].bo_product_discount)
      })
    }
    
  
  
    console.log(updatedItems);
       
    setUpdatedCartItems(updatedItems);
  
  }, [data,variantdata, selectedColor, selectedSize,actualprice]);
  
useEffect(()=>
{
   console.log("updatedCartItems",updatedCartItems)

  const existingDataString = localStorage.getItem('updatedCartItems');

  const existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];

  const foundIndex = existingDataArray.findIndex(cur => cur.product_id === productId);


            if (foundIndex === -1) {
              existingDataArray.push(...updatedCartItems);
            } else {
            
              existingDataArray.splice(foundIndex, 1, ...updatedCartItems);
            }

            const updatedDataString = JSON.stringify(existingDataArray);
            localStorage.setItem('updatedCartItems', updatedDataString);
  

},[updatedCartItems])
 

  const handleAddToCart=(newState)=>
  {
     const storedAccessToken = localStorage.getItem("accessToken");
     

    if(storedAccessToken!==null)
    {

      
   console.log("updatedCartItems",updatedCartItems)
      localStorage.setItem('Show', true);
  
      for(let i=0;i<updatedCartItems.length;i++)
     {
      let myHeaders = new Headers();
       myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${storedAccessToken}` );   
      
      let raw = JSON.stringify({
        "business_owner_id": businessId,
        "cart": [updatedCartItems[i]]
      });
  
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
         console.log(data);

       
          if (data.status === 'success') {
          
            if(updatedCartItems.length===i+1)
            {
              
              addToCarts();
              localStorage.setItem('showbutton', true)
              localStorage.setItem('quantity', (tempQuantities[selectedVariant]||1));
              localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);
              setopenSnackbar2({ ...newState, opentoast: true });
                
            }
           
          }

        })
        .catch(error => console.log('error', error)); 
      }
   }

   else
   {
      localStorage.setItem('Show', true);
      addToCarts();
      localStorage.setItem('showbutton', true)
      localStorage.setItem('quantity', (tempQuantities[selectedVariant]||1));
      localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);
      setopenSnackbar2({ ...newState, opentoast: true });

      const productdataWithQuantity = productdata.map(item => ({ ...item, quantity: 1 }));

      
      const existingproductDataString = localStorage.getItem('productdata');

      const existingproductDataArray = existingproductDataString ? JSON.parse(existingproductDataString) : [];

      
      const foundIndex = existingproductDataArray.findIndex(cur => cur._id.$oid===productId);


      if (foundIndex === -1) {
        existingproductDataArray.push(...productdataWithQuantity);
      } else {
      
        existingproductDataArray.splice(foundIndex, 1, ...productdataWithQuantity);
      }

      const updatedDataString = JSON.stringify(existingproductDataArray);
              
      localStorage.setItem('productdata', updatedDataString);
    
    
   }
  }


   const handleGoToCart=()=>
   {

     dispatch(Handlemycheckout(true))
     setTimeout(()=>
    {
      navigate(`/${businessname}/viewcart`);
    },1000)
    
         
   }

  

 const handleVariantsize=(Id)=>
 {
  const selectedArray = productdata[0].variant_details?.filter((item) => item._id=== Id);
   console.log(selectedArray)
   setdefaultSelect(false)

   if(selectedColor===undefined && selectedSize!==undefined)
   {
      setvariantdata(selectedArray)
      const existingDataString = localStorage.getItem('variantSize');

    const existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];
   
      const foundIndex = existingDataArray.findIndex(cur => cur.bo_product_id.$oid === productId);


            if (foundIndex === -1) {
              existingDataArray.push(...selectedArray);
            } else {
            
              existingDataArray.splice(foundIndex, 1, ...selectedArray);
            }


            const updatedDataString = JSON.stringify(existingDataArray);
            localStorage.setItem('variantSize', updatedDataString);
   }
 
   setselectedSize(Id.$oid)
   
 }

 const handleVariantcolor=(Id)=>
 {
   const selectedArray = productdata[0].variant_details?.filter((item) => item._id=== Id);
   console.log(selectedArray)
   setdefaultSelect(false)
    setvariantdata(selectedArray)
    setselectedColor(Id.$oid)
   
    const existingDataString = localStorage.getItem('variantColor');

    const existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];
    
    const foundIndex = existingDataArray.findIndex(cur => cur.bo_product_id.$oid === productId);


            if (foundIndex === -1) {
              existingDataArray.push(...selectedArray);
            } else {
            
              existingDataArray.splice(foundIndex, 1, ...selectedArray);
            }

            const updatedDataString = JSON.stringify(existingDataArray);
            localStorage.setItem('variantColor', updatedDataString);
          
 }


  useEffect(()=>
  {

    if(loading===false && defaultSelect===true)

     {
       
      const defaultSize = productdata[0]?.variant_details.find((variant) => variant.bo_product_variant_name === 'Size');
      const defaultColor = productdata[0]?.variant_details.find((variant) => variant.bo_product_variant_name === 'Color');
    
    
      if (defaultSize) {

        setselectedSize(defaultSize._id.$oid);
        setvariantdata([defaultSize])

   

        const existingDataString = localStorage.getItem('variantSize');

        const existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];

        if(existingDataArray.length===0)
        {
          localStorage.setItem('variantSize', JSON.stringify([defaultSize]));
        }

        else if(existingDataArray)
        {

            const foundIndex = existingDataArray.findIndex(cur => cur.bo_product_id.$oid===productId);

            console.log(foundIndex)

            if (foundIndex === -1) {
              existingDataArray.push(defaultSize);
            } else {
            
              existingDataArray.splice(foundIndex, 1, defaultSize);
            }

            const updatedDataString = JSON.stringify(existingDataArray);
            localStorage.setItem('variantSize', updatedDataString);
          } 
      }
    
      if (defaultColor) {
        setselectedColor(defaultColor._id.$oid);
        setvariantdata([defaultColor])
       
        const existingDataString = localStorage.getItem('variantColor');

        const existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];

        if(existingDataArray.length===0)
        {
          localStorage.setItem('variantColor', JSON.stringify([defaultColor]));
        }

        else if(existingDataArray)
        {
    
          const foundIndex = existingDataArray.findIndex(cur => cur.bo_product_id.$oid===productId);


          if (foundIndex === -1) {
            existingDataArray.push(defaultColor);
          } else {
          
            existingDataArray.splice(foundIndex, 1, defaultColor);
          }

          const updatedDataString = JSON.stringify(existingDataArray);
          localStorage.setItem('variantColor', updatedDataString);
        }
       
      }

      }
  },[defaultSelect,loading])

  function formatIndianNumber(number) {
    const parts = number.toString().split(".");
    let integerPart = parts[0];
    let formattedInteger = "";
  
    while (integerPart.length > 3 && number>=10000) {
      formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
      integerPart = integerPart.slice(0, -3);
    }
    formattedInteger = integerPart + formattedInteger;
  
    return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
  }

  

 const Readmore=()=>
 {
    setopenoffers(true)
 }

   

   const sanitizedServiceName = servicename?.replace(/ /g, '-');
   const productimage= productdata && productdata[0].bo_product_images.img_urls[0]

   const Categoryname=categoryname?.replace(/ /g, '-');
   

   const renderVariantData=()=>
   {
       return(
        <>
              
           {coupons && coupons.length!==0 && <div className="selection" style={(productdata[0].variant_details && productdata[0].variant_details.length!==0) ? {paddingBottom:'16px'}:{paddingBottom:'0px'}}>
            <div className="select">Available Offers</div>
          {visibleCoupons.filter(cur => cur.bo_coupen_status === "1")?.map((cur, index)=>
            {
                return (
                  <>
                    <div style={{display:'flex'}}>
        <span style={{color: 'var(--primary)'}}>
          <LocalOfferOutlinedIcon style={{transform:'scale(0.8)'}}/>
        </span>&nbsp;
          <span>
        <span className="selectoffer">{cur.bo_coupen_code}</span>&nbsp;
         <span style={{ fontFamily: 'var(--fontfamily)', fontWeight: '500'}}>
            {cur.bo_discount_title}
         </span>&nbsp;
         <span
        style={{
          fontFamily: 'var(--fontfamily)',
          fontWeight: '500',
          maxWidth: '80%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {showFullDescription ? cur.bo_discount_description : cur.bo_discount_description.length > 40 ? cur.bo_discount_description.slice(0, 40) + '...' : cur.bo_discount_description}
      </span>&nbsp;
        
       {cur.bo_discount_description.length > 40 && (
        <span
          style={{
            color: 'var(--primary)',
            fontFamily: 'var(--fontfamily)',
            fontWeight: '550',
            cursor: 'pointer',
            fontSize:'12px'
          }}
          onClick={toggleDescription}
        >
          {showFullDescription ? 'Read less' : 'Read more'}
        </span>
      )}

        </span>
      </div>

      {(coupons.length > 2 && index===1) && (
        <div style={{ cursor: 'pointer', color: 'var(--primary)', fontFamily: 'var(--fontfamily)', fontWeight: '550', fontSize:'14px' }} onClick={Readmore}>
           View {coupons.length-2} more offers
        </div>
      )}
                  </>
                )
            })}
            
            </div>
   }
              
              {parseInt(productdata[0].bo_product_quantity)>0 ?
                
              <>
           
           {productdata[0].variant_details.length!==0 && 
                     
            <div className="variantdata">

       {productdata?.some((cur) =>
  cur.variant_details.some((variant) => variant.bo_product_variant_name === 'Size')
    )? 
           <div>
         {productdata?.map((cur) => {
          const variantDetails = cur.variant_details.filter(
            (variant) => variant.bo_product_variant_name === 'Size'
            ) 
              if(variantDetails.length>0)
              {
                 return (
                   <>
                   <div className="selection" >
                      <div className="select">{variantDetails[0].bo_product_variant_name}</div>
                      <div style={{display:'flex',gridColumnGap:'10px'}}>
                    {variantDetails.map(cur=>
                    {
                       return (
                        <>
                        <div style={selectedSize===cur._id.$oid ? customproduct_select:customselect} onClick={()=>handleVariantsize(cur._id)}>{cur.bo_product_variant_value}</div>
                        </>
                       )
                    })}
                    </div>
                    </div>
                   </>
                 )
              }
            })         
         }
         </div>:
         null    
              }

       {productdata?.some((cur) =>
           cur.variant_details.some((variant) => variant.bo_product_variant_name === 'Color')
        )? 
           <div>
         {productdata?.map((cur) => {
          const variantDetails = cur.variant_details.filter(
            (variant) => variant.bo_product_variant_name === 'Color'
            ) 
              if(variantDetails.length>0)
              {
                 return (
                   <>
                      <div className="selection" >
                      <div className="select">{variantDetails[0].bo_product_variant_name}</div>
                      <div style={{display:'flex',gridColumnGap:'10px'}}>
                    {variantDetails.map(cur=>
                    {
                       
                       return (
                        <>
                       <div style={selectedColor===cur._id.$oid ? customproduct_select:customselect} onClick={()=>handleVariantcolor(cur._id)}>{cur.bo_product_variant_value}</div>
                        </>
                       )
                    })}
                    </div>
                    </div>
                   </>
                 )
              }
            })         
         }
         </div>:
         null    
              }
            </div>
   
   }
   </>
   :
   <div className="sold primary_title">Sold Out</div>
   }
        </>
       )
      
   
   }


 const Gallery=()=>
 {
     setisOpen(true); 
 }

 const BuyNow =()=>
 {

  const access= localStorage.getItem("accessToken");
    if(access!==null)
    {
      
        localStorage.setItem('updatedCartItems', JSON.stringify(updatedCartItems))
        navigate(`/${businessname}/address`, { state: { updatedCartItems } });
      
    }
    else 
    {
     isSmScreen? navigate(`/${businessname}/connect`):dispatch(Handlemyaccount(true));
    }

 }

 
console.log(updatedCartItems)
console.log("productdata",productdata)

  const Bookmarked=(productId,newState)=>
  {
    

    const storedAccessToken = localStorage.getItem("accessToken");
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {};
     

    if(storedAccessToken!==null)
    {
       if(!bookmarks.hasOwnProperty(productId))
       {

    
   
      let formdata = new FormData();
      formdata.append("boId", businessId);
      formdata.append("productId", productId);
  

   let requestOptions = {
     method: 'POST',
     body: formdata,
     redirect: 'follow'
   };
   
   fetch(`${Node_baseurl}/bookmark/`, requestOptions)
     .then(response => response.text())
     .then(result => {
       const data=JSON.parse(result);

   
       if (data.status === 'success') {
 
          bookmarks[productId] = data.bookmark_id;
        
        localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
       
        setopenSnackbar3({ ...newState, opentoast1: true });
       }
      
     })
     .catch(error => console.log('error', error)); 
    }

     else 
     {
      

        if (bookmarks.hasOwnProperty(productId)) {
        
          let value = bookmarks[productId];
       
        
    
        let requestOptions = {
          method: 'DELETE',
          redirect: 'follow'
        };
   
   fetch(`${Node_baseurl}/bookmark/${value}/${businessId}`, requestOptions)
     .then(response => response.text())
     .then(result => {
       const data=JSON.parse(result);

   
       if (data.status === 'success') {

        const isBookmarked = !!bookmarks[productId];
    
        if (isBookmarked) {

          delete bookmarks[productId];
        } 

        localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
       
        setopenSnackbar4({ ...newState, opentoast2: true });
       }
      
     })
     .catch(error => console.log('error', error)); 
     }
    }
    }
  
    else {
        setopenSnackbar1({ ...newState, openSnackbar: true });
    }
        
  }

const closeCoupon=()=>
{
   setopenoffers(false)
}

console.log("productdata",productdata)

     return (
        <>
             <Helmet>
            <meta charset="utf-8" />
            <meta name="description" content={sanitizedServiceName} />
            <meta property="og:type" content="website"/>
            <meta property="og:site_name" content={sanitizedServiceName}/>
            <meta property="og:title" content={sanitizedServiceName}/>
        <meta property="og:description" content={sanitizedServiceName} />
        <meta property="og:image" itemProp="image" content={productimage} />
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="300"/>
        <meta property="og:image:height" content="300"/>
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={window.location.href}/>
        <meta property="twitter:title" content={sanitizedServiceName}/>
        <meta property="twitter:site" content={sanitizedServiceName}/>
        <meta property="twitter:description" content={sanitizedServiceName}/>
        <meta property="twitter:image" itemProp="image" content=''/>
        <meta property="twitter:image:type" content="image/png"/>
        <meta property="twitter:image:width" content="300"/>
        <meta property="twitter:image:height" content="300"/>

         <link rel='canonical' href={window.location.href}/>
     
      </Helmet>
   

           <section className= {isSmScreen? "m-10": "section1 p-10"}>
           <Container maxWidth='lg' className="p-16">
          
           {
              loading ? 
              <>
              <CheckoutPageskeleton/>
              </>

              :

             <div>
              <div>
              <div className="breadcrumb  mobile-bodypadding">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyledNavLink
        
          to={`/${businessname}/search`}
        >
           {categoryname}

        </StyledNavLink>
        <StyleNavLink
     
          to={`/${businessname}/product/${Categoryname}/${sanitizedServiceName}`}
          
        >
           {servicename}
        </StyleNavLink>
      </Breadcrumbs>
      </div>

    
         
      <Box sx={{width:"100%"}}>

            <Item className="global_box_shadow">
          
            <Grid container rowSpacing={0}  columnSpacing={{xs:0, sm:2, md:2}} display="flex" justifyContent="center">

           <Grid item  xs={12} sm={isSmScreen?12:5.5} md={5.5} display='flex' flexDirection='column' justifyContent='center'>

           <div>
 
           <Carousel  interval={3000} showThumbs={false} showStatus={false}  infiniteLoop={true}   onChange={handleCarouselChange} className="carousel-rooted">
                   
                   {productdata[0].bo_product_images.img_urls.map((cur,index)=>
                    {
                       return (
                        <>
                                      <div className="checkout_image" onClick={Gallery}  > <LazyLoadImage  src={cur}
                               alt={sanitizedServiceName} visibleByDefault={true} style={{ textAlign:"start"}} 
                               onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} /> 
                                {(()=>
                                {   
                                  const existingDataString = localStorage.getItem('productImage');

                                  const existingDataArray = existingDataString ? JSON.parse(existingDataString) : [];
                                  
                                 
                                  if (!existingDataArray.includes(cur)) {
                                    existingDataArray.push(cur);
                                  }

                                  const updatedDataString = JSON.stringify(existingDataArray);
                                  {selectedThumbnail === index &&  localStorage.setItem('productImage', [updatedDataString])};

                                })()}
       

                                  </div>  
                        </>
                       )
                    })}

 
                   </Carousel> 

                  

                  <div className="thumbnail">

                   {productdata[0].bo_product_images.img_urls.map((cur,index)=>
                    {
                       return (
                        <> 
                         <Grid item xs={2.4} sm={2.4} md={2.4}>
                        <div className={`thumbnailimage_show ${selectedThumbnail === index ? 'highlighted' : ''}`}>
                 <LazyLoadImage
                src={cur}
            alt={sanitizedServiceName}
              visibleByDefault={true}
             style={{ textAlign: "start" }}
             onError={(e) => {
             e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png";
            e.target.alt = 'No Image';
        }}
                
        />
                
     </div> 
     </Grid>
                        </>
                       )
                    })}
     
     </div>  
              
                   </div>
                
             
                   </Grid>
                  
                   <Grid item  xs={12} sm={isSmScreen?12:6.5} md={6.5}>
           <div className="checkout_rating">
           <div className="checkout_data">
          
           <div><h1 className="primary_title">{servicename}</h1></div>

          <div>
          <div className="priceIcon" style={{alignItems:'center', gridColumnGap:'10px'}}>
                  
                  {variantdata.length===0 ?
                   <>
                       {localStorage.setItem('Discountprice',productdata[0].bo_product_discounted_price)}
                             <div className="product_expected_price"><span>₹{formatIndianNumber(productdata[0].bo_product_discounted_price)}</span></div>
                   </>
        
                  :
                    <>
                     { localStorage.setItem('Discountprice',variantdata[0].bo_product_discount)}
                    <div className="product_expected_price"><span>₹{formatIndianNumber(variantdata[0].bo_product_discount)}</span></div>
                    </>
                  
                }
                  
                  {variantdata.length===0 ? 
                    <>
                         { localStorage.setItem('Realprice',productdata[0].bo_product_price)}
                         <div className="product_real_price"><span><del>₹{formatIndianNumber(productdata[0].bo_product_price)}</del></span></div>
                    </>

                  :
                  <>
                         { localStorage.setItem('Realprice',variantdata[0].bo_product_price)}
                        <div className="product_real_price"><span><del>₹{formatIndianNumber(variantdata[0].bo_product_price)}</del></span></div>
                  </>
                
                  }

                {variantdata.length===0 ?  <div className="product_discount_price">(-{parseInt(((productdata[0].bo_product_price-productdata[0].bo_product_discounted_price)*100)/productdata[0].bo_product_price)}% Off)</div>
                :
                <div className="product_discount_price">(-{parseInt(((variantdata[0].bo_product_price-variantdata[0].bo_product_discount)*100)/variantdata[0].bo_product_price)}% Off)</div>
                } 
               </div>
             <div className="all_taxes">Inclusive of all taxes</div>
             </div>

            <div>
             {renderVariantData()}
            </div>
           
      

        <div>


   </div>

    
   {parseInt(productdata[0].bo_product_quantity)>0 ?
                
                <>

    {!isSmScreen &&
        <div>

        <div className="addtocheckout">

           <div>
           <Button style= {custombuyButton} className="secondary_button"  onClick={BuyNow}>Buy Now</Button> 
           </div>

          <div>
       {Show==="true" ?   <Button style={customaddButton}  className="secondary_button" onClick={handleGoToCart}>Go to Cart</Button>  
       :
         <Button style={customaddButton}  className="secondary_button" onClick={()=>handleAddToCart({ vertical: 'top', horizontal: 'center' })}>Add to Cart</Button>  
    }
          </div>

           </div>

          </div>
           }
        </>
        :
        <div className="sold select ">Currently  Out of Stock </div>
}   

           </div>
          <div className="shareItem">

            <div style={{display:'flex',gridColumnGap:'8px'}}>
           <div className="shareIcon" onClick={shareIcon} ><ShareOutlinedIcon/></div>
           <div className="bookmarked" onClick={() => {Bookmarked(productdata[0]._id.$oid, { vertical: 'top', horizontal: 'center' })}}><BookmarkIcon style={bookmarks.hasOwnProperty(productdata[0]._id.$oid)? bookmarkaddstyle:bookmarkremovestyle} /></div> 
           </div>

          
        {share &&  <div className="sharelink">

      <FacebookShareButton url={window.location.href} onClick={()=>setshare(false)} >
      <FacebookIcon size={isSmScreen ?28:34} round  />
      </FacebookShareButton>
      <WhatsappShareButton url={window.location.href} onClick={()=>setshare(false)}>
      <WhatsappIcon size={isSmScreen ?28:34} round  />
      </WhatsappShareButton>
      <TelegramShareButton url={window.location.href} onClick={()=>setshare(false)} >
      <TelegramIcon size={isSmScreen ?28:34} round />
      </TelegramShareButton>

      </div>
      }  

</div>
      </div>

           </Grid>           
    
           </Grid>
           </Item>

               
           <Modal open={isOpen}>

           <div className="gallery_image">

            <div className="right_image">
           
           <div className="closeicon_image" onClick={handle}><CloseIcon style={{transform:"scale(1.3)"}}/></div> 

          </div>
        
             <div className="image_showgallery">
                 
             <Carousel  interval={3000} showThumbs={false} showStatus={false} infiniteLoop={true} >


             {productdata[0].bo_product_images.img_urls.map(cur=>
                    {
                       return (
                        <>
                              <div className="image_show" > <LazyLoadImage src={cur} alt="image" />  </div> 
                        </>
                       )
                    })}

                   </Carousel>  
                   </div> 
                   </div>
                   </Modal>
          
                <Modal open={openoffers}> 
                       <Item1 className="completeprofile">
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingBottom:'8px'}}>
                         <div style={{fontSize:'14px',fontFamily:'var(--fontfamily)',fontWeight:'600'}}>Remaining Offers</div>
                        <div onClick={closeCoupon}><CloseIcon style={{cursor:'pointer'}}/></div>
                        </div>

                 {RemainingCoupons?.filter(cur => cur.bo_coupen_status === 1)?.map((cur, index)=>
                      {
                          return (
                            <>
                              <div style={{display:'flex'}}>
                  <span style={{color: 'var(--primary)'}}>
                    <LocalOfferOutlinedIcon style={{transform:'scale(0.8)'}}/>
                  </span>&nbsp;
                    <span>
                  <span className="selectoffer">{cur.bo_coupen_code}</span>&nbsp;
                  <span style={{ fontFamily: 'var(--fontfamily)', fontWeight: '500'}}>
                      {cur.bo_discount_title}
                  </span>&nbsp;
                  <span
                  style={{
                    fontFamily: 'var(--fontfamily)',
                    fontWeight: '500',
                    maxWidth: '80%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {showFullDescription ? cur.bo_discount_description : cur.bo_discount_description.length > 40 ? cur.bo_discount_description.slice(0, 40) + '...' : cur.bo_discount_description}
                </span>&nbsp;
                  
                {cur.bo_discount_description.length > 40 && (
                  <span
                    style={{
                      color: 'var(--primary)',
                      fontFamily: 'var(--fontfamily)',
                      fontWeight: '550',
                      cursor: 'pointer',
                      fontSize:'12px'
                    }}
                    onClick={toggleDescription}
                  >
                    {showFullDescription ? 'Read less' : 'Read more'}
                  </span>
                )}

                  </span>
                </div>

                            </>
                          )
                      })}
                     

                            </Item1>
                       </Modal>     
       
           </Box>
           </div>
                   

                    <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                     <Alert  severity="success" sx={{ width: '100%' }}>
                        Cart added successfully !
                           </Alert>
                      </Snackbar>

                    </Stack>

                      <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast1} autoHideDuration={2000}  key={verticaltoast1 + horizontaltoast1}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast1}>
                     <Alert  severity="success" sx={{ width: '100%' }}>
                        Bookmark added successfully !
                           </Alert>
                      </Snackbar>

                    </Stack>

                      <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast2} autoHideDuration={2000}  key={verticaltoast2 + horizontaltoast2}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast2}>
                     <Alert  severity="success" sx={{ width: '100%' }}>
                        Bookmark deleted successfully !
                           </Alert>
                      </Snackbar>

                    </Stack>

                   

           </div>
           }
            </Container>
           </section>
        </>
     )
     
}

const mapDispatchToProps = {
  addToCarts,
  resetItemAdded,
};


export default connect(null, mapDispatchToProps)(CheckoutPage);

/*
 
    {existingDataArray.map(cur=>
      {
          if(cur.bo_product_id.$oid!==productId)
          {
            existingDataArray.push(...selectedArray);
    
            const updatedDataString = JSON.stringify(existingDataArray);
          
            localStorage.setItem('variantSize', updatedDataString);

          }
          else
            {
              localStorage.setItem('variantSize', JSON.stringify(selectedArray));
            }
      })}
  if(selectedSize==="" ||selectedSize===undefined)
   {
      if(selectedColor==="" || selectedColor===undefined)
      {
        updatedItems.push({
          product_id: data,
          variant_id: "",
          quantity:1,
          item_price:parseInt(itemprice)
        })
      }

      else 
      {
       updatedItems.push({
         product_id: data,
         variant_id: selectedColor,
         quantity:1,
         item_price:parseInt(actualprice)
       })
      }
  
   }
  else if((selectedSize!=="" && selectedSize!==undefined) && (selectedColor===""||selectedColor===undefined))
   {
    updatedItems.push({
      product_id: data,
      variant_id: selectedSize,
      quantity:1,
      item_price:parseInt(actualprice)
    })
   }
   
   if((selectedColor!==""  && selectedColor!==undefined) && (selectedSize!==undefined))
   {
    updatedItems.push({
      product_id: data,
      variant_id: selectedColor,
      quantity:1,
      item_price:parseInt(actualprice)
      
    })
   }
     
   if((selectedSize!==""  && selectedSize!==undefined) && (selectedColor!==undefined))
   {
    updatedItems.push({
      product_id: data,
      variant_id: selectedSize,
      quantity:1,
      item_price:parseInt(actualprice)
    })
   }

  const handleGoToCart=(newState)=>
   {
    
    
    const storedAccessToken = localStorage.getItem("accessToken");
 

    if(storedAccessToken!==null && Show==="true")
    {
      

      let myHeaders = new Headers();
       myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${storedAccessToken}` );   
      
      let raw = JSON.stringify({
        "business_owner_id": businessId,
        "cart": updatedCartItems
      });
  
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
         console.log(data);

          if(data.status==="success")
          {
              addToCarts();
             localStorage.setItem('showbutton', true)
             localStorage.setItem('quantity', (tempQuantities[selectedVariant]||1));

            localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);
            localStorage.setItem('Show', false);
             navigate(`/${businessname}/viewcart`);
          }

        })
        .catch(error => console.log('error', error)); 
    
   }

   else
   {
      setopenSnackbar1({ ...newState, openSnackbar: true });
   }
         
   }

    const handleClick = (variantId, variantSalePrice) => {

      setSelectedVariant(variantId);
      setSelectedPrice(variantSalePrice);
      localStorage.setItem('Price', variantSalePrice);
      localStorage.setItem('variantId',variantId);
      localStorage.setItem('Money',variantSalePrice*(tempQuantities[variantId]|| 0))
      localStorage.setItem('quantity', parseInt(tempQuantities[variantId]||0));
      localStorage.setItem('Totalcounts', parseInt(tempQuantities[variantId]||0));
     
      if(parseInt((tempQuantities[variantId]|| 0))<1)
      {
          setShowaddtocart(true);
      }

      else 
      {
        setShowaddtocart(false);

      };
     
      setvariantdata(variantId);
      dispatch(variantdataId(variantId));

      
    }; 
   


   const handleAddToCart=(variantId)=>
   {
             addToCarts();
         
      
           setShowaddtocart(false);
           setSelectedVariant(variantId);
           setvariantdata(variantId);
           dispatch(variantdataId(variantId));
           setTempQuantities((prevTempQuantities) => ({
            ...prevTempQuantities,
            [variantId]:  1+ (tempQuantities[selectedVariant]||0),
          }));

          localStorage.setItem('quantity', parseInt(tempQuantities[selectedVariant]||0)+1);
          localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);
          localStorage.setItem('Show', JSON.stringify(false));
   }



const remove=(variantId)=>
   {
       handleloading(true)      

    if(parseInt((tempQuantities[variantId]|| parseInt(Totalcount)))>1)
    {
      setTempQuantities((prevTempQuantities) => ({
        ...prevTempQuantities,
        [variantId]: (prevTempQuantities[variantId] || parseInt(Totalcount)) - 1,
      }));
      dispatch(Handledecreaseitem(parseInt(tempQuantities[variantId]|| parseInt(Totalcount) )-1))
      localStorage.setItem('quantity', parseInt(tempQuantities[variantId]|| parseInt(Totalcount) )-1);
      localStorage.setItem('Totalcounts', parseInt(tempQuantities[variantId]||parseInt(Totalcount) )-1);
    }

    else
    {
      setTempQuantities((prevTempQuantities) => ({
        ...prevTempQuantities,
        [variantId]: (prevTempQuantities[variantId] || parseInt(Totalcount)) - 1,
      }));
      dispatch(Handledecreaseitem(parseInt(tempQuantities[variantId]|| parseInt(Totalcount) )-1))
      localStorage.setItem('quantity', parseInt(tempQuantities[variantId]|| parseInt(Totalcount) )-1);
      localStorage.setItem('Totalcounts', parseInt(tempQuantities[variantId]||parseInt(Totalcount) )-1);
      setShowaddtocart(true);
      resetItemAdded();
      localStorage.setItem('Show', JSON.stringify(true));
    }
     
   }
   
   const add=(variantId)=>
   {
      handleloading(true)

    if(parseInt((tempQuantities[variantId]|| parseInt(Totalcount)))>0)
    {
      setTempQuantities((prevTempQuantities) => ({
        
        ...prevTempQuantities,
        [variantId]: (parseInt(Totalcount)||prevTempQuantities[variantId] ) + 1,
      }));
          
    

      dispatch(Handleincreaseitem( parseInt(parseInt(Totalcount) || tempQuantities[variantId])+1));

      localStorage.setItem('quantity', parseInt(parseInt(Totalcount) || tempQuantities[variantId] )+1);
      localStorage.setItem('Totalcounts', parseInt(parseInt(Totalcount) ||tempQuantities[variantId])+1);
    }
   
   }










         <div className="thumbnail">

   {[...Array()].map((_, index) => (
                   <div key={index} className="thumbnailimage_show">
                 <LazyLoadImage
                src={productdata[0].bo_product_images?.img_urls[index % productdata[0].bo_product_images.img_urls.length]}
            alt={sanitizedServiceName}
              visibleByDefault={true}
             style={{ textAlign: "start" }}
             onError={(e) => {
             e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png";
            e.target.alt = 'No Image';
        }}
        />
     </div>
  ))}

   <div className="selection" >
            <div className="select">Available Offers</div>
            <div className="product_select">10% discount</div>
            </div>

  line number 664 mein hoga
 {images?.map((cur,index)=>
                     {
                           return (
                            <>
                               
                                <div  className={images.length===1 ? "checkout_images" : "checkout_image"} onClick={Gallery}  > <LazyLoadImage  src={cur.image_url} alt={sanitizedServiceName} visibleByDefault={true} style={{ textAlign:"start"}} onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />    </div>   
                               
                            </>
                           )
                     })         
                   }  


 onClick={()=>handleGoToCart({ vertical: 'top', horizontal: 'center' })}  line number 685

 onClick={()=>handleAddToCart(selectedVariant)} line number 687

 if (selectedVariant !== null) {
      const variants = variant.find((v) => v.variant_id === selectedVariant);
      if (variants) {
        return (
          <>

           {variants.variant_staff &&  <div className="checkout_star">
             <div className="checkout_starIcon"><AccountCircleOutlinedIcon/></div>
             <div className="checkout_rating_rate">
             <div className="checkout_rating_name">{variants?.variant_staff}</div>
              <div className="rating_name">Staff</div>
             </div>
             </div>
      }
      {variants.variant_duration &&  <div className="checkout_star">
             <div className="checkout_starIcon"><AccessTimeOutlinedIcon/></div>
             <div className="checkout_rating_rate">
             <div className="checkout_rating_name">{variants?.variant_duration}</div>
              <div className="rating_name">Duration</div>
             </div>
             </div>
      }  
          </>

        )
        }
   }    

             {variantprice()} line number 669

             line number 738
               <Carousel  interval={3000} showThumbs={false} showStatus={false} infiniteLoop={true} >
                    {images.map((cur,index)=>
                     {
                           return (
                            <>
                                <div className={images.length===1?"image_shows":"image_show"} > <LazyLoadImage src={cur.image_url} alt={index} />  </div> 
                            </>
                           )
                     })         
                   }   
                   </Carousel>  
                  

             <div>
    <h2 className="secondary_title booking_name" style={{margin:"0px"}}>Select Type</h2>
      <div className="select_bhk">
         {
            variant.map((cur)=>
       {
    return (
<>
<div className={selectedVariant === cur.variant_id?"bhk1":"bhk"} key={cur.variant_id} onClick={()=>handleClick(cur.variant_id,cur.variant_sale_price)}>{cur.variant_name}</div>
</>
)

})
}
</div>
</div>

<div className="total_money_service">
<div className="title">Total Price:</div>

<div className="total_price1">{Money}</div>
     
</div>


     <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: '20px' }}
            style={{color:"black"}}
          >
            <ArrowBackIcon  onClick={Back}/>
          </IconButton>



 if(parseInt((tempQuantities[variantId]|| parseInt(Totalcount)))>0)
    {
      setTempQuantities((prevTempQuantities) => ({
        ...prevTempQuantities,
        [variantId]: (prevTempQuantities[variantId] || parseInt(Totalcount)) + 1,
      }));
          
      dispatch(Handleincreaseitem( parseInt(tempQuantities[variantId]|| parseInt(Totalcount) )+1));

      localStorage.setItem('quantity', parseInt(tempQuantities[variantId]|| parseInt(Totalcount) )+1);
      localStorage.setItem('Totalcounts', parseInt(tempQuantities[variantId]||parseInt(Totalcount) )+1);
    }




         localStorage.setItem('quantity', parseInt(tempQuantities[selectedVariant]||0)+1);
          localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);



  <div className="shareItem">
           <div className="shareIcon" onClick={shareIcon} ><ShareOutlinedIcon/></div>

     
</div>
  {share &&  <div className="sharelink">

           <FacebookShareButton url={window.location.href} onClick={()=>setshare(false)} >
      <FacebookIcon size={isSmScreen ?28:34} round  />
    </FacebookShareButton>
    <WhatsappShareButton url={window.location.href} onClick={()=>setshare(false)}>
      <WhatsappIcon size={isSmScreen ?28:34} round  />
    </WhatsappShareButton>
    <TelegramShareButton url={window.location.href} onClick={()=>setshare(false)} >
      <TelegramIcon size={isSmScreen ?28:34} round />
    </TelegramShareButton>
     
     </div>
}






  <div  className="checkout_star">
                <div className="checkout_starIcon"><AccountCircleOutlinedIcon/> </div>
                <div className="checkout_rating_rate">
                 <span className="checkout_rating_name">{variants.variant_staff}</span>
                 <div className="rating_name">Staff</div>
                </div>
              
                  </div>
                  
                <div className="checkout_star">
                <div className="checkout_starIcon"><AccessTimeOutlinedIcon/> </div>
                <div className="checkout_rating_rate">
                 <div className="checkout_rating_name">{variants.variant_duration}</div>
                 <div className="rating_name">Duration</div>
                </div>
              
                 </div>






      <div className="checkout_data">
      </div>
         <div className="shareIcon"><ShareOutlinedIcon/></div>

<Carousel  interval={3000} showThumbs={false} showStatus={false}  >
                    {images.map((cur,index)=>
                     {
                           return (
                            <>
                                <div  className="checkout_image" onClick={Gallery}  > <LazyLoadImage src={cur.image_url} alt={index} visibleByDefault={true} style={{ textAlign:"start"}} onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />    </div> 
                            </>
                           )
                     })         
                   }   
                   </Carousel> 


 <div className="checkout_data">
           <h1 className="primary_title text-wrapped">{itemed}</h1>


             <div className="select_checkout">

           <div className="checkout_star">
           <div className="checkout_starIcon"><StarIcon/> </div>
           <div className="checkout_rating_rate">
           <div className="checkout_rating_name">{rating}</div>
           </div>
           <div className="rating_name">Rating</div>

           </div>
      
               {renderVariantData()}
              
            </div>

             <h2 className="secondary_title">Select Type</h2>
            <div className="select_bhk">
            {
              variant.map((cur)=>
              {
                   
                return (
                  <>
                  <div className={selectedVariant === cur.variant_id?"bhk1":"bhk"} key={cur.variant_id} onClick={()=>handleClick(cur.variant_id,cur.variant_sale_price)}>{cur.variant_name}</div>
                  </>
                )
              
              })
            }
            </div>
           
             <div className="pricedetail">
              
               {variantprice()}
                     
               

                    </div>

              {showaddtocart ? 


            
               <Button style={customButton}  className="primary_button"  onClick={()=>handleAddToCart(selectedVariant)} >Add to Cart</Button> :  
              <div className="money_cart">
               <div className="total_money_service">
                <div className="title">Total Price:</div>
               
                 <div className="total_price1">{Money}</div>
                           
               </div>

              <div className="addtocheckout">
              
              <div>
              <Button style= {customButton} className="primary_button"  onClick={()=>handleGoToCart({ vertical: 'top', horizontal: 'center' })} >View Cart</Button> 
              </div>
              </div>

              </div>}
              </div>
           












<Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                     <Alert  severity="error" sx={{ width: '100%' }}>
                        Please Update your profile !
                           </Alert>
                      </Snackbar>

                    </Stack>


   cart add 
   line number 213

    useEffect(() => {
    
    let updatedItems = [updatedCartItems];

    if (updatedItems.length > 0 && updatedItems[0].service_id !== data) {
      updatedItems = [];
    }
   
    {variant.forEach((cur)=>
    {
       

      if((parseInt(tempQuantities[cur.variant_id]|| 0))!==0)      
      {
      updatedItems.push({
        service_id: data,
        variant_id: cur.variant_id,
        quantity:(tempQuantities[cur.variant_id]|| 0),
      })
    }
    })}
  
    console.log(updatedItems);
   
  
    setUpdatedCartItems(updatedItems);
  }, [data,variant,tempQuantities]);
  





  line number 409

 const calculateTotalPrice=()=>
 {
      let MoneyList=0;

      variant.forEach((cur)=>
      {
          
           MoneyList += cur.variant_sale_price*(tempQuantities[cur.variant_id]|| 0);
           localStorage.setItem('Price',MoneyList);
      })
    return MoneyList;
 }
 */

