import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, createTheme, useMediaQuery } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ClearIcon from '@mui/icons-material/Clear';
import Searchskeleton from "./Searchskeleton";
import { useDispatch, useSelector } from "react-redux";
import { Handlemyload, Handlemysort, Handlemyvariant, variantdataId } from "../Redux/action";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { Menu, MenuItem, Button} from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  textAlign: 'center',
  borderRadius:'12px'
}));

const Items = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  padding: theme.spacing(1),
  border: "1px solid #e8e8e8",

  
}));

const Searchresult=({value, value2})=>
{
    
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });




  

  const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: 'inherit',
    textDecoration: 'none',
    fontSize:'12px',
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const StyleNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    ariaCurrent:"page",
    fontSize:'12px',
    color:"var(--primary)",
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

    let filter  = localStorage.getItem("CategoryId");

    const servicename= localStorage.getItem("Categoryname");

    const [anchorEl, setAnchorEl] = useState(null);

    const variantStatus = {};

    const variantsubcategory=useSelector(state=>state.variant);

    const allsortdata =useSelector(state=>state.sort)

    const allloadingdata =useSelector(state=>state.loading)

    const [selectedOption, setSelectedOption] = useState(allsortdata);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-menu' : undefined;
    
     const [selectedItems, setSelectedItems] = useState();

     const [selectedItemsize, setSelectedItemsize] = useState()
     const [selectedItemcolor, setSelectedItemcolor] = useState()
     
     const [updatedVariantDetails, setupdatedVariantDetails]=useState()

     const [selectedSubcategory, setSelectedSubcategory] = useState();
     const [subcategoryname, setSubcategoryname] =useState()
     
    const [selectedData, setSelectedData] = useState();
    const [loading, setloading]=useState(allloadingdata)
    
    const itemsPerPage = 20;

    const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };


  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

useEffect(()=>
{
  if(allloadingdata)
  {
     setloading(true)
  }
  
},[allloadingdata])

 useEffect(()=>
{

  const searchParams= new URLSearchParams(window.location.search);
  searchParams.set("sort", selectedOption.replace(/\s+/g, '-'))
  const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
   window.history.replaceState(null, '', newUrl);

},[selectedOption])
  

  const [variantData, setvariantData] =useState()

  
  const items=useSelector(state=>state.search);


   const [displayData, setDisplayData] = useState([]);

   const [valuedata, setvaluedata]=useState()


   const [sortedData, setSortedData] = useState([]);

   const [filterData, setfilterData] = useState([]);

   useEffect(()=>
   {
       setvaluedata(value)
     

   },[value2])



   useEffect(() => {
    const checkData = setTimeout(() => {
      if (value2.length !== 0) {

        if (selectedOption) {
          let sorted;
        if (selectedOption === 'High to Low') {
          sorted = [...value2].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price)
        } else if (selectedOption === 'Low to High') {
          sorted = [...value2].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price)
        } else if (selectedOption === 'Recently added') {
          sorted = [...value2].sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
        }

        setSortedData(sorted);
        const updatedDetails = sorted.map((item) => ({
          ...item,
          variant_details: item.variant_details.map((detail) => ({
            ...detail,
            checked: variantsubcategory[detail._id.$oid] || false,
          })),
        }));
      

        setupdatedVariantDetails(updatedDetails)
        setfilterData(updatedDetails)
        setDisplayData(updatedDetails)
      }
      dispatch(Handlemyload(false))
      setloading(false)
    }
   else {
    dispatch(Handlemyload(false))
     setloading(false)
   }

    }, 1000); 

    return () => clearTimeout(checkData);
  }, [value2]);


  const handleClose = (option) => {
    if (option) {
      let sorted;
    if (option === 'High to Low') {
      sorted = [...filterData].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
    } else if (option === 'Low to High') {
      sorted = [...filterData].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
    } else if (option === 'Recently added') {
      sorted = [...filterData].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
    }

    setSortedData(sorted);
     setfilterData(sorted)
    dispatch(Handlemysort(option))

      setSelectedOption(option);
    }
    setAnchorEl(null);
  };

   const navigate= useNavigate();

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedName, setSelectedName] = useState(servicename);

    const dispatch=useDispatch();

    const [count, setCount]=useState(0)
   
    const businessname=localStorage.getItem('businessname');

    const handleChange = (event, variantname) => {

  
      const selectedValue = event.target ? event.target.value:event;



      if(valuedata.some(item=>item.bo_category_name===selectedSubcategory && item.selected===true))
      {
        const selectArray = displayData.filter((item) => item.bo_product_name === selectedSubcategory);

        const updatedDisplayData = selectArray.map((cur) => {
      
          const updatedVariantDetails = cur.variant_details.map((variant) => {
           
              if (variant.bo_product_variant_name === variantname) {
  
                if(variant.bo_product_variant_value===selectedValue)
                {
                   console.log(variant.checked)
                return {
                  ...variant,
                  checked: !variant.checked,
                } }
  
                else 
                {
                  
                  return {
                    ...variant,
                    checked:variant.checked,
                  };
                }
              }
  
              else
             
                return {
              
                  ...variant,
                  checked: variant.checked,
                };
            }
           
          )
          
          const filteredArray = updatedVariantDetails?.filter((item) => item.bo_product_variant_value=== selectedValue);
        
       const newSelectedValue = filteredArray.checked === false ? '' : selectedValue
        setSelectedItems(newSelectedValue)
  
        setSelectedItemsize(selectedValue)
        setSelectedItemcolor(selectedValue)

     
  
          return {
            ...cur,
            variant_details: updatedVariantDetails,
          };
        });

        let checkedCombinations = new Set();

        updatedDisplayData.forEach(product => {
            product.variant_details.forEach(variant => {
                if (variant.checked === true && variant.bo_product_variant_value) {
                    const combinationKey = `${variant.bo_product_id}_${variant.bo_product_variant_name}_${variant.bo_product_variant_value}`
                    checkedCombinations.add(combinationKey)
                }
            });
        });
        
        const uniqueCount = checkedCombinations.size
        setCount(uniqueCount);
        
        const selectedArray = updatedDisplayData.filter(product => {
          return product.variant_details.some(variant =>variant.checked===true);
        });  
          
        setSelectedItemsize(selectedValue)
        setSelectedItemcolor(selectedValue)
  
    
     setDisplayData(updatedDisplayData)
     setvariantData(selectedArray)
    


     selectedArray.forEach(product => {
      
       product.variant_details.forEach(variant => {
       
         variantStatus[variant._id.$oid] = variant.checked;
       });
     });
   
     if(selectedOption)
      {
      let sorted;
      if (selectedOption === 'High to Low') {
        sorted = [...selectedArray].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
      } else if (selectedOption === 'Low to High') {
        sorted = [...selectedArray].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
      } else if (selectedOption === 'Recently added') {
        sorted = [...selectedArray].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      }  

      setfilterData(sorted)
    }

      dispatch(Handlemyvariant(variantStatus))
     
       if(selectedArray.length!==0)
       {
        if(selectedOption)
          {
          let sorted;
          if (selectedOption === 'High to Low') {
            sorted = [...selectedArray].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
          } else if (selectedOption === 'Low to High') {
            sorted = [...selectedArray].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
          } else if (selectedOption === 'Recently added') {
            sorted = [...selectedArray].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
          }  

          setfilterData(sorted)
        }
       }
      else 
      {
        if(selectedOption)
          {
          let sorted;
          if (selectedOption === 'High to Low') {
            sorted = [...updatedVariantDetails].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
          } else if (selectedOption === 'Low to High') {
            sorted = [...updatedVariantDetails].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
          } else if (selectedOption === 'Recently added') {
            sorted = [...updatedVariantDetails].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
          }  

          setfilterData(sorted)
        }
      }
      
      }
     
      else 
      {
     

          const updatedDisplayData = displayData.map((cur) => {
      
            const updatedVariantDetails = cur.variant_details.map((variant) => {
             
                if (variant.bo_product_variant_name === variantname) {
    
                  if(variant.bo_product_variant_value===selectedValue)
                  {
                  
                  return {
                    ...variant,
                    checked: !variant.checked,
                  } }
    
                  else 
                  {
                    
                    return {
                      ...variant,
                      checked:variant.checked,
                    };
                  }
                }
    
                else
               
                  return {
                
                    ...variant,
                    checked: variant.checked,
                  };
              }
             
            )
            
            const filteredArray = updatedVariantDetails?.filter((item) => item.bo_product_variant_value=== selectedValue);
          
         const newSelectedValue = filteredArray.checked === false ? '' : selectedValue
          setSelectedItems(newSelectedValue)
    
          setSelectedItemsize(selectedValue)
          setSelectedItemcolor(selectedValue)

       
            return {
              ...cur,
              variant_details: updatedVariantDetails,
            };
          });
    
          const selectedArray = updatedDisplayData.filter(product => {
            return product.variant_details.some(variant =>variant.checked===true);
          });     
          let checkedCombinations = new Set();

        updatedDisplayData.forEach(product => {
            const uniqueVariantIds = new Set();
            const uniqueVariantColors = new Set(); 
            product.variant_details.forEach(variant => {
                if (variant.checked === true && variant.bo_product_variant_value && variant.bo_product_id) {

                    const combinationKey = `${variant.bo_product_variant_value}`;

                    if (!uniqueVariantIds.has(variant.bo_product_id) && !uniqueVariantColors.has(variant.bo_product_variant_name)) {
                        checkedCombinations.add(combinationKey);
                        uniqueVariantIds.add(variant.bo_product_id);
                        uniqueVariantColors.add(variant.bo_product_variant_name);
                    }
                }
            });
        });

        const uniqueCount = checkedCombinations.size;
        setCount(uniqueCount);

          
        selectedArray.forEach(product => {
      
          product.variant_details.forEach(variant => {
          
            variantStatus[variant._id.$oid] = variant.checked;
          });
        });
      
         dispatch(Handlemyvariant(variantStatus))
    
          setSelectedItemsize(selectedValue)
          setSelectedItemcolor(selectedValue)
    
      
       setDisplayData(updatedDisplayData)
       setvariantData(selectedArray)


       if(selectedOption)
        {
        let sorted;
        if (selectedOption === 'High to Low') {
          sorted = [...selectedArray].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
        } else if (selectedOption === 'Low to High') {
          sorted = [...selectedArray].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
        } else if (selectedOption === 'Recently added') {
          sorted = [...selectedArray].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
        }  
  
        setfilterData(sorted)
      }

       if(selectedArray.length!==0)
        {
         if(selectedOption)
           {
           let sorted;
           if (selectedOption === 'High to Low') {
             sorted = [...selectedArray].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
           } else if (selectedOption === 'Low to High') {
             sorted = [...selectedArray].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
           } else if (selectedOption === 'Recently added') {
             sorted = [...selectedArray].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
           }  
 
           setfilterData(sorted)
           console.log("updatedVariantDetails",sorted)
           console.log("selectedArray",selectedArray)
         }
        }
       
        else 
        {
          if(selectedOption)
          {
          let sorted;
          if (selectedOption === 'High to Low') {
            sorted = [...updatedVariantDetails].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
          } else if (selectedOption === 'Low to High') {
            sorted = [...updatedVariantDetails].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
          } else if (selectedOption === 'Recently added') {
            sorted = [...updatedVariantDetails].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
          }  

          setfilterData(sorted)
        }
        console.log("updatedVariantDetails",updatedVariantDetails)
        console.log("selectedArray",selectedArray)
        }

      }
        
    }
   
    
    const handleSubcategory=(event)=>
    {
      const selectedValue = event.target.value;
      
      const selectedArray = updatedVariantDetails.filter((item) => item.bo_product_name === selectedValue);
    

     setSubcategoryname(selectedValue)
     if (selectedArray) {
     
      const updatedArray = valuedata.map(item => {
        if (item.bo_category_name===selectedValue) {

          return {
            ...item,
            selected: !item.selected
          };
        }
        else 
        {
          return item
        }
       
      });

       if(updatedArray.some(item=>item.bo_category_name===selectedValue && item.selected===true))
        {
          console.log("updatedArray",updatedArray)
            setvaluedata(updatedArray)
            setSelectedSubcategory(selectedValue);

            if(selectedOption)
              {
              let sorted;
              if (selectedOption === 'High to Low') {
                sorted = [...selectedArray].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
              } else if (selectedOption === 'Low to High') {
                sorted = [...selectedArray].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
              } else if (selectedOption === 'Recently added') {
                sorted = [...selectedArray].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
              }  
              
              
              setfilterData(sorted)
            }

          

            if(displayData.some(curr => curr.bo_product_name === selectedValue))
           {
              const selectedArray = updatedVariantDetails.filter((item) => item.bo_product_name === selectedValue);

              setDisplayData(selectedArray)
          }
          else 
          {
             setDisplayData([])
             
          }

           }
           else 
           {
             
              setvaluedata(updatedArray)
              setSelectedSubcategory();
              if(selectedOption)
                {
                let sorted;
                if (selectedOption === 'High to Low') {
                  sorted = [...updatedVariantDetails].sort((a, b) => b.bo_product_discounted_price - a.bo_product_discounted_price);
                } else if (selectedOption === 'Low to High') {
                  sorted = [...updatedVariantDetails].sort((a, b) => a.bo_product_discounted_price - b.bo_product_discounted_price);
                } else if (selectedOption === 'Recently added') {
                  sorted = [...updatedVariantDetails].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
                }  
      
                setfilterData(sorted)
              }
              setDisplayData(updatedVariantDetails)
           }
    }

    }

    const Categorydata=()=>
    {
      setSubcategoryname()
      setSelectedSubcategory()
      setDisplayData(updatedVariantDetails)

    }
     
   

  useEffect(()=>
  {
     if(selectedSubcategory==="" || selectedSubcategory===undefined)
     {
        
          const searchParams= new URLSearchParams(window.location.search);
          searchParams.delete("subcategory");
          searchParams.set("category", selectedName)
           const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
          window.history.replaceState(null, '', newUrl);  
     } 

     else
     {
      const searchParams= new URLSearchParams(window.location.search);
      searchParams.set("category", selectedName)
      searchParams.set("subcategory", subcategoryname)
       const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl); 
     }


  },[selectedSubcategory])


  useEffect(()=>
  {
  
      setSelectedItems(filter)
      setSelectedName(servicename)
     

  },[filter,servicename])
  
  const handleProduct=(Id,categoryname,productname, dataId)=>
  {
     localStorage.setItem('DataId',dataId.$oid)
     localStorage.setItem('CategoryId', Id)
     localStorage.setItem('Categoryname', categoryname);

    const Categoryname=categoryname?.replace(/ /g, '-');
    const servicename=productname?.replace(/ /g, '-');

     navigate(`/${businessname}/product/${Categoryname}/${servicename}`);

       localStorage.setItem('productname', productname);
       localStorage.removeItem('totalPrice1');
       localStorage.removeItem('totalAmount');
       localStorage.removeItem('tempQuantities');
  }

  let variantproduct = [];

  


    return (
        <> 
            <section className="section explore-padding">
      
        <Container maxWidth="lg" className="container-padding" >
          
            {(loading) ?
            <>
                <Searchskeleton/>
            </>
            
             :
             <>
            

             <div>

                             <Box sx={{width:"100%"}}>

           {(selectedSubcategory==="" || selectedSubcategory===undefined) ?         
                <div className="breadcrumb1" style={{paddingTop:'10px'}}>
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/search`}  
        >
          {selectedName}
        </StyleNavLink>
      </Breadcrumbs>
      </div>
      :
      <div className="breadcrumb1" style={{paddingTop:'10px'}}>
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyledNavLink
          to={`/${businessname}/search?category=${selectedName}`} 
          onClick={Categorydata} 
        >
          {selectedName}
        </StyledNavLink>

        <StyleNavLink
          to={`/${businessname}/search?category=${selectedName}&subcategory=${subcategoryname}`}  
        >
          {subcategoryname}
        </StyleNavLink>

      </Breadcrumbs>
      </div>

      } 

          <Grid container rowSpacing={0} rowGap={3}  columnSpacing={{xs:2, sm:3, md:3}} display="flex" justifyContent="space-between" columns={12} marginTop="18px"  >

          <Grid item xs={12} sm={isSmScreen ? 12 : 3.6} md={2.8} className="filter_search" >
 
          {value2.some(item => item.variant_details.length > 0) ? <div className="filteringdata">FILTERS</div> : <div className="filteringdata">NO FILTERS</div> }
  
         {value.length!==0 &&
                
                 <>
            <Items className="global_box_shadows">
            <div className="filtering filter">Sub Category</div> 
            </Items>
           <Items className="global_box_shadows border_top">
           <div className="filter">
         

           <RadioGroup aria-label="dataOptions" name="dataOptions" value={selectedSubcategory} >  

    {valuedata?.map((cur) => {
    
    if (valuedata.length > 0) {
      return (
        <div key={cur._id}>
          {valuedata.map((variantDetail) =>
            variantDetail.bo_category_name !== '' ? (
              <div key={variantDetail._id} className="check_filter">
                <div>
                  <FormControlLabel
                    value={variantDetail.bo_category_name}
                    control={
                      <Checkbox
                        style={{
                          color:
                          selectedSubcategory === variantDetail.bo_category_name && variantDetail.selected
                              ? 'var(--primary)'
                              : '',
                        }}
                        checked={
                          variantDetail.selected
                        }
                        onClick={(e) => handleSubcategory(e, variantDetail.bo_category_name)}

                      />
                    }
                    label={
                      <Typography style={{ fontSize: '12px' }}>
                        {variantDetail.bo_category_name}
                      </Typography>
                    }
                  />
                </div>
              </div>
            ) : null
          )}
        </div>
      )
    }
    
    return null; 
  })}
</RadioGroup>

               </div>
           </Items>
           </>
}

         

    {displayData?.some((cur) =>
      cur.variant_details?.some((variant) => variant.bo_product_variant_name === 'Size')
    ) ? (
      (() => {
        const allVariantDetails = displayData.flatMap(cur => cur.variant_details);
        const uniqueVariantValuesSet = new Set();
        const uniqueVariantValues = [];
      
        allVariantDetails.forEach(variant => {
          const variantKey = `${variant.bo_product_variant_name}_${variant.bo_product_variant_value}`;
          if (!uniqueVariantValuesSet.has(variantKey)) {
            uniqueVariantValuesSet.add(variantKey);
            uniqueVariantValues.push({
              variantName: variant.bo_product_variant_name,
              variantValue: variant.bo_product_variant_value,
              checked: variant.checked
            });
          }
        });
      
      
    return (
      <>
            <Items className="global_box_shadows">
              <div className="filtering filter">Size</div> 
            </Items>

            <Items className="global_box_shadows border_top ">
              <div className="filter">
                <RadioGroup
                  aria-label="dataOptions"
                  name="dataOptions"
                  value={selectedItemsize}
                  
                >
                  {uniqueVariantValues?.map((cur, index) => {
    
      if (cur.variantName === 'Size') {
        return (
          <div key={index} className="check_filter">
            <FormControlLabel
              value={cur.variantValue}
              control={
                <Checkbox
                  style={{
                    color: cur.checked ? 'var(--primary)' : '',
                  }}
                  checked={cur.checked}
                  onClick={(e) => handleChange(e, cur.variantName)}
                />
              }
              label={
                <Typography style={{ fontSize: '12px' }}>
                  {cur.variantValue}
                </Typography>
              }
            />
          </div>
        )
      }
      return null; 
    })}

                </RadioGroup>
              </div>
            </Items>
          </>
        );
      })()
    ) : null}


{displayData?.some((cur) =>
      cur.variant_details?.some((variant) => variant.bo_product_variant_name === 'Color')
    ) ? (
      (() => {
        const allVariantDetails = displayData.flatMap(cur => cur.variant_details);
        const uniqueVariantValuesSet = new Set();
        const uniqueVariantValues = [];
      
        allVariantDetails.forEach(variant => {
          const variantKey = `${variant.bo_product_variant_name}_${variant.bo_product_variant_value}`;
          if (!uniqueVariantValuesSet.has(variantKey)) {
            uniqueVariantValuesSet.add(variantKey);
            uniqueVariantValues.push({
              variantName: variant.bo_product_variant_name,
              variantValue: variant.bo_product_variant_value,
              checked: variant.checked
            });
          }
        });
      
    return (
      <>
            <Items className="global_box_shadows">
              <div className="filtering filter">Color</div> 
            </Items>

            <Items className="global_box_shadows border_top">
              <div className="filter">
                <RadioGroup
                  aria-label="dataOptions"
                  name="dataOptions"
                  value={selectedItemcolor}
                 
                >
                  {uniqueVariantValues?.map((cur, index) => {
    
      if (cur.variantName === 'Color') {
        return (
          <div key={index} className="check_filter">
            <FormControlLabel
              value={cur.variantValue}
              control={
                <Checkbox
                  style={{
                    color: cur.checked ? 'var(--primary)' : '',
                  }}
                  checked={cur.checked}
                  onClick={(e) => handleChange(e, cur.variantName)}
                />
              }
              label={
                <Typography style={{ fontSize: '12px' }}>
                  {cur.variantValue}
                </Typography>
              }
            />
          </div>
        );
      }
      return null; 
    })}

                </RadioGroup>
              </div>
            </Items>
          </>
        )
      })()
    ) : null}



          </Grid> 

              
      
     <Grid  item  xs={12} md={9.2} sm={isSmScreen ? 12 : 8.4} columnSpacing= {2}  >

  <div className="showingdata">

  <span>
  {displayData && (
  count === 0 ? (
    filterData.filter(cur => cur.bo_product_status === 1).length > 1 ? (
      <>
        <span className="displayDatalength">
          {`Showing Results for "${(selectedSubcategory === "" || selectedSubcategory === undefined )? selectedName : selectedSubcategory}"`}
        </span>
        <span className="displayDataspace"> - </span>
        <span className="displayDataitems">{`${filterData.filter(cur => cur.bo_product_status === 1).length} items`}</span>
      </>
    ) : (
      <>
        <span className="displayDatalength">
          {`Showing Results for "${(selectedSubcategory === "" || selectedSubcategory === undefined) ? selectedName : selectedSubcategory}"`}
        </span>
        <span className="displayDataspace"> - </span>
        {displayData && <span className="displayDataitems">{`${filterData.filter(cur => cur.bo_product_status === 1).length} item`}</span>}
      </>
    )
  ) : (
    count > 1 ? (
      <>
        <span className="displayDatalength">
          {`Showing Results for "${(selectedSubcategory === "" || selectedSubcategory === undefined )? selectedName : selectedSubcategory}"`}
        </span>
        <span className="displayDataspace"> - </span>
        <span className="displayDataitems">{`${filterData.filter(cur => cur.bo_product_status === 1).length} items`}</span>
      </>
    ) : (
      <>
        <span className="displayDatalength">
          {`Showing Results for "${(selectedSubcategory === "" || selectedSubcategory === undefined) ? selectedName : selectedSubcategory}"`}
        </span>
        <span className="displayDataspace"> - </span>
        {displayData && <span className="displayDataitems">{`${filterData.filter(cur => cur.bo_product_status === 1).length} item`}</span>}
      </>
    )
  )
)}

</span>

 {value2.length!==0 && 

  <>
  
   <Button
            aria-controls={id}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{textTransform:'none'}}
          >
             <div className="sorted">
             <span className="Sort">Sort by: {selectedOption}</span>
             </div>
          </Button>
          
        
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(null)}
            style={{marginLeft:"10px"}}
          >
            <MenuItem  style={{fontSize:"14px"}} onClick={() => handleClose('High to Low')}>High to Low</MenuItem>
            <MenuItem  style={{fontSize:"14px"}} onClick={() => handleClose('Low to High')}>Low to High</MenuItem>
            <MenuItem  style={{fontSize:"14px"}} onClick={() => handleClose('Recently added')}>Recently added</MenuItem>
          </Menu>
        
          </>
 }

  </div>
         
     <div style={{display:'flex', gridColumnGap:'6px',position:'relative', top:'-8px'}}>
      
            {(() => {
          const allVariantDetails = displayData && displayData.flatMap(cur => cur.variant_details);
          const uniqueVariantValuesSet = new Set();
          const uniqueVariantValues = [];

          allVariantDetails?.forEach(variant => {
            const variantKey = `${variant.bo_product_variant_name}_${variant.bo_product_variant_value}`;
            if (!uniqueVariantValuesSet.has(variantKey)) {
              uniqueVariantValuesSet.add(variantKey);
              uniqueVariantValues.push({
                variantName: variant.bo_product_variant_name,
                variantValue: variant.bo_product_variant_value,
                checked: variant.checked
              });
            }
          });
            
          return uniqueVariantValues.map((cur, index) => (
            (cur.checked===true &&
            <div key={index} className="chips">
              <div className="chips-value">{cur.checked === true ? cur.variantValue : ''}</div>
              <div style={{display:'flex', alignItems:'center'}} onClick={() => handleChange(cur.variantValue, cur.variantName)} ><CloseIcon className="chips-closeicon"/></div>
            </div>
            )
          ) 
          );
        })()}

      </div>   

              

             <Grid container columnSpacing={{xs:2,sm:3,md:3}}>
  
             {(()=>
             {
                  if(displayData.length===0 || filterData.length===0)
                  {
                    
                    return (<>
                     <Grid item  xs={6} sm={6} md={4} >
                     <h1 className="head_title explore">No data available</h1>
                     </Grid>
                    </>)
                  }
             })()}


            
      
      {filterData?.filter(cur => cur.bo_product_status === 1)?.map((cur) => {
        if (!cur) {
          return null
        }
         variantproduct = [];

         filterData
         .filter(cur => cur.bo_product_status === 1)
         .forEach(data => {
           data.variant_details.forEach(variant => {
             if (variant.checked === true) {
               const existingProduct = variantproduct.find(item => item._id === data._id);
               if (existingProduct) {

                 existingProduct.variant_details.push(variant);
               } else {
                 variantproduct.push({
                   ...data,
                   variant_details: [variant]
                 });
               }
             }
           });
         });
     

        filterData.filter(cur => cur.bo_product_status === 1).forEach(item => {
          const sizeVariants = item.variant_details.filter(variant => variant.bo_product_variant_name === "Size" && variant.checked === true);
          const colorVariants = item.variant_details.filter(variant => variant.bo_product_variant_name === "Color" && variant.checked === true);
      
          if (sizeVariants.length > 0 && colorVariants.length > 0) {
              const filteredVariantProduct = variantproduct.filter(items => items._id.$oid !== item._id.$oid);
              variantproduct = filteredVariantProduct;
      
              sizeVariants.forEach(sizeVariant => {
                  colorVariants.forEach(colorVariant => {
                      filteredVariantProduct.push({
                          ...item,
                          variant_details: [sizeVariant, colorVariant]
                      });
                  });
              });
          }
      });
    

        if(variantproduct.length===0)
        {
          return (
            <Grid item xs={6} sm={4} md={3}>
              <div style={{ marginBottom: "20px" }}>
                <Item style={{ cursor: "pointer" }} className="global_box_shadow transform_data" onClick={() => handleProduct(cur.bo_product_category_id, cur.bo_product_category_name, cur.bo_product_name, cur._id)}>
                  <div className="explore_image_recommend" style={{ position: 'relative' }}>
                    <LazyLoadImage
                      src={cur.bo_product_images.img_urls !== null ? cur.bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                      alt={cur.bo_product_images.img_urls !== null > 0 ? 'category' : 'No Image'}
                      onError={(e) => { e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image' }}
                    />
                    {(cur.variant_details.length !== 0 && cur.variant_details.some(variant => variant.checked === true)) ? (cur.variant_details.some(variant => variant.checked === true && variant.bo_product_variant_name === "Color")) ? <div className="discounted_price" style={{ padding: isSmScreen ? '2px' : '4px' }}>{parseInt(((cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_price - cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_discount) * 100) / cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_price)}% OFF</div> :
                      <div className="discounted_price" style={{ padding: isSmScreen ? '2px' : '4px' }}>{parseInt(((cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_price - cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_discount) * 100) / cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_price)}% OFF</div>
                      :
                      <div className="discounted_price" style={{ padding: isSmScreen ? '2px' : '4px' }}>{parseInt(((cur.bo_product_price - cur.bo_product_discounted_price) * 100) / cur.bo_product_price)}% OFF</div>
                    }
                  </div>
                  <div>
                    <div className="bottom_container">
                      <div className="secondary_title text-wrapping">{cur.bo_product_name}</div>
                      <div className="priceIcon" style={{ paddingBottom: '0px' }}>
                        {(cur.variant_details.length !== 0 && cur.variant_details.some(variant => variant.checked === true)) ? (cur.variant_details.some(variant => variant.checked === true && variant.bo_product_variant_name === "Color")) ? <div className="expected_price"><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_discount}</span></div> :
                          <div className="expected_price"><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_discount}</span></div>
                          :
                          <div className="expected_price"><span>₹{cur.bo_product_discounted_price}</span></div>
                        }
                        {(cur.variant_details.length !== 0 && cur.variant_details.some(variant => variant.checked === true)) ? (cur.variant_details.some(variant => variant.checked === true && variant.bo_product_variant_name === "Color")) ? <div className="real_price"><del><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_price}</span></del></div> :
                          <div className="real_price"><del><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_price}</span></del></div>
                          :
                          <div className="real_price"><del><span>₹{cur.bo_product_price}</span></del></div>
                            }
                          </div>
                        </div>
                      </div>
                    </Item>
                  </div>
                </Grid>
              )
        }
        
      })}
       

        {variantproduct.map((cur) => {
         
       
          return (
            <Grid item xs={6} sm={4} md={3}>
              <div style={{ marginBottom: "20px" }}>
                <Item style={{ cursor: "pointer" }} className="global_box_shadow transform_data" onClick={() => handleProduct(cur.bo_product_category_id, cur.bo_product_category_name, cur.bo_product_name, cur._id)}>
                  <div className="explore_image_recommend" style={{ position: 'relative' }}>
                    <LazyLoadImage
                      src={cur.bo_product_images.img_urls !== null ? cur.bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                      alt={cur.bo_product_images.img_urls !== null > 0 ? 'category' : 'No Image'}
                      onError={(e) => { e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image' }}
                    />
                    {(cur.variant_details.length !== 0 && cur.variant_details.some(variant => variant.checked === true)) ? (cur.variant_details.some(variant => variant.checked === true && variant.bo_product_variant_name === "Color")) ? <div className="discounted_price" style={{ padding: isSmScreen ? '2px' : '4px' }}>{parseInt(((cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_price - cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_discount) * 100) / cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_price)}% OFF</div> :
                      <div className="discounted_price" style={{ padding: isSmScreen ? '2px' : '4px' }}>{parseInt(((cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_price - cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_discount) * 100) / cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_price)}% OFF</div>
                      :
                      <div className="discounted_price" style={{ padding: isSmScreen ? '2px' : '4px' }}>{parseInt(((cur.bo_product_price - cur.bo_product_discounted_price) * 100) / cur.bo_product_price)}% OFF</div>
                    }
                  </div>
                  <div>
                    <div className="bottom_container">
                      <div className="secondary_title text-wrapping">{cur.bo_product_name}</div>
                      <div className="priceIcon" style={{ paddingBottom: '0px' }}>
                        {(cur.variant_details.length !== 0 && cur.variant_details.some(variant => variant.checked === true)) ? (cur.variant_details.some(variant => variant.checked === true && variant.bo_product_variant_name === "Color")) ? <div className="expected_price"><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_discount}</span></div> :
                          <div className="expected_price"><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_discount}</span></div>
                          :
                          <div className="expected_price"><span>₹{cur.bo_product_discounted_price}</span></div>
                        }
                        {(cur.variant_details.length !== 0 && cur.variant_details.some(variant => variant.checked === true)) ? (cur.variant_details.some(variant => variant.checked === true && variant.bo_product_variant_name === "Color")) ? <div className="real_price"><del><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Color')?.bo_product_price}</span></del></div> :
                          <div className="real_price"><del><span>₹{cur.variant_details.find(variant => variant.checked === true && variant.bo_product_variant_name === 'Size')?.bo_product_price}</span></del></div>
                          :
                          <div className="real_price"><del><span>₹{cur.bo_product_price}</span></del></div>
                            }
                          </div>
                        </div>
                      </div>
                    </Item>
                  </div>
                </Grid>
              );
            })}
            
          

               {value2?.length>20 &&     <div className="pagination">
                    <Stack spacing={2}>
                        <Pagination
          count={Math.ceil(value2.length / itemsPerPage)} 
          page={page + 1} 
          onChange={handleChangePage}
          variant="outlined"
          sx={{'& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: 'var(--primary)',
            color: 'var(--white)',
          },}}
        />
        </Stack>
        </div>
}
       </Grid>
         
            </Grid>

          </Grid>
          </Box>

          
            
            </div>

    </>

}


         </Container>    
            </section>        
         
      
        </>
    )
    
}

export default Searchresult;


/* 
 <span className="recently">Recently added</span>

  <Grid item xs={12} sm={11.3} md={0} className="check_bunchfilter">
             
             {
                   value?.map(cur =>
                   {
                      return (
                        <>
  
                          <div className="check_bunch">
                            <div className="select_bunchcagtegory"   onClick={()=>handleLabelClick(cur.category_id)} >{cur.bo_category_name}</div>
                            </div>
                           
                        </>
                      )
                   } )
                 }
  
            </Grid>



   // setSelectedData(value.find(item => item.category_id === values)?.category_id);
       // setSelectedName(value.find(item => item.category_id === values)?.category_name);
       // handleCategory(true); 
name="dataOptions" value={selectedItems} onChange={handleChange}

  <FormControlLabel   key={cur.category_id} value={cur.category_id.toString()} control={<Radio  style={{ color: selectedItems === cur.category_id ? 'var(--primary)' : '' }}  />} label=<Typography style={{fontSize:"12px"}}   > {cur.category_name}</Typography> />

 {selectedItems &&
              <div className="item-value">
                <div >{selectedName}</div>
                <div><ClearIcon className="clearicon"   onClick={handleChanged}/></div>
              </div>
             
           }  

 onClick={()=>Description(cur.service_id,cur.category_id, cur.service_name,cur.variant[0]?.variant_id, cur.variant[0]?.variant_sale_price)}


         <div><Checkbox checked={selectedItems.includes(cur.category_id)}  onChange={() => handleChange(cur.category_id)}  inputProps={{ 'aria-label': 'controlled' }}/></div>
     const handleChange = (itemId) => {
     setValue((prevItems) =>
      prevItems.map((item) =>
        (item.category_id === itemId) ? { ...item, selected: !item.selected } : item
      )
      );

    if (selectedItems.includes(itemId)) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => (selectedItem !== itemId))
        
      );
        setSelectedItems("")

    } else {

      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);

       selectedfilter(itemId);
     
      console.log(itemId);
    }



  };


    {selectedItems.length > 0 && (
        <div className="selecteditem">
            {selectedItems.map((item) => (
              <>
                 
                 <div className="item-value">
              <div key={item}>{selectedItems}</div>
              <div><ClearIcon className="clearicon"   onClick={() => handleChange(item)}/></div>
            
              </div>
              </>
            ))}
            
            
        </div>
      )}




className="search_category"  jisme margin bottom hai 

className="search_data"  item ke niche wala class

       <Grid container xs={9} sm={6} md={6} columns={9} rowSpacing={0}  columnSpacing={{xs:2, sm:1, md:4}} display="flex" justifyContent="space-between" >
    </Grid>

  <div className="search_result">
                       <div className="search_booking">
                       <div className="search_name">{cur.service_name}</div>
                       <button className="book_button">Book Now</button>
                       </div>
                   
                     <div className="search_showdata">

                       <div className="searchpriceIcon ">
                      
                       <div className="searchexpected_price"><CurrencyRupeeOutlinedIcon className="rupee" /><span className="currency">{cur.variant[0].variant_sale_price}</span></div>
                    <div className="searchreal_price"><del className="price"><CurrencyRupeeOutlinedIcon  className="rupee"/> <span className="currency">{cur.variant[0].variant_price}</span></del></div>
                    <div className="searchdiscount">{(cur.variant[0].variant_percentage).toFixed(1)} %</div>
                    </div>
                    
                    <div className="searchpriceIcon  search_rating" style={{paddingTop:"10px"}}>
                <div className="searchpickrating"><StarIcon className="rupees" /><span className="searchcurrency">{cur.rating}</span></div>
                <div className="searchpickrating"><AccountCircleOutlinedIcon className="rupees" /><span className="searchcurrency">{cur.variant[0].variant_staff} staff</span></div>
                <div className="searchpickrating"><AccessTimeOutlinedIcon className="rupees" /> <span className="searchcurrency">{cur.variant[0].variant_duration}</span></div>
                </div> 

                

                </div>
                </div>

import 'react-lazy-load-image-component/src/effects/blur.css';
import Searchskeleton from "./Searchskeleton";
     <Searchskeleton/>            
   */             