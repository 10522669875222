
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";
import Dealskeleton from "./Dealskeleton";
import Container from '@mui/material/Container';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Deals=({value,loading})=>
{

  
  
    return (
        <>  
            <section className="mt-20 mb-20 explore-padding">
      
      <Container maxWidth="lg" className="container-padding" >
      
        {loading ? 
              <>
               <Dealskeleton/>
              </>  
          :

          <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} columnSpacing={{xs:2, sm:2, md:4}} display="flex" justifyContent="start" >

            <Grid item  xs={12} sm={12} md={12}>

            <Carousel  interval={3000} showThumbs={false} showStatus={false}  infiniteLoop={true} >
               
             {value && value?.reverse().map((cur)=>
             {
                return (
                    <>
                    
              <div className="bath_deal">  <LazyLoadImage className="bathroom" src={cur.bo_banner_url} alt="bathroom"/></div> 
             
                    </>
                )
             })}
          
           
             </Carousel>
         
             </Grid>
             </Grid>
             </Box>
}

           </Container>       
            </section>
          
        </>
    )
}

export default Deals;

/*   


<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>

 
         <div className="thumbor">   
  



     
     

<div className="deal_off">
                    <div className="title_deal_day">{cur.deal}</div>
                    <div className="title_off">{cur.off}</div>
                    <div className="title_price">{cur.price}</div>
                    </div>



                    */