export const addToCarts = () => {
    return {
      type: "ADD_TO_CART",
    };
  };

  export const resetItemAdded = () => ({
    type: 'RESET_ITEM_ADDED',
  });

 export const SET_DATA = 'SET_DATA';

 export const setData = (data) =>
 (
  {
         type: SET_DATA,
         payload: data,
  }
 ); 
 

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';

export const uploadImage = (imageData) => {
  return {
    type: UPLOAD_IMAGE,
    payload: imageData,
  };
};

export const deleteImage = () => {
  return {
    type: DELETE_IMAGE,
  };
};


export const PHONE="PHONE";
export const mobileNumber=(phoneNumber)=>
{
    return {
      type : PHONE,
      payload:phoneNumber,
    };
}

export const LOCATION= 'LOCATION';

export const locationUpdate = (cityname)=>
{

  return {
    type:LOCATION,
    payload:cityname,
  };

};

export const SEARCH_FILTER='SEARCH_FILTER';

export const searchFilter=(search)=>
{
  return {
    type:SEARCH_FILTER,
    payload:search,
  }
}

export const FILTER_DATA='FILTER_DATA';

export const filterData=(servicevalue)=>
{
  return {
    type: FILTER_DATA,
    payload:servicevalue,
  }
}

export const FORCE_DATA='FORCE_DATA';

export const ignoredData=(forceData)=>
{
   return {
    type:FORCE_DATA,
    payload:forceData,
   }
}

export const VARIANT='VARIANT';

export const variantdataId=(variantData)=>
{
  return {
    type:VARIANT,
    payload:variantData,
   }
}

export const CHECKOUT='CHECKOUT';

export const checkoutDetails=(checkout)=>
{
    return {
      type:CHECKOUT,
      payload:checkout
    }
}

export const COUNT1='COUNT1';

export const countnumber1=(countno1)=>
{
   return {
    type:COUNT1,
    payload:countno1
   }
}

export const COUNT2='COUNT2';

export const countnumber2=(countno2)=>
{
   return {
    type:COUNT2,
    payload:countno2
   }
}

export const  TOTALPRICE='TOTALPRICE';

export const totalPrice=(totalprice)=>
{
   return {
    type:TOTALPRICE,
    payload:totalprice
   }
}

export const COUPONS='COUPONS';

export const couponsCode=(code)=>
{
  return {
     type:COUPONS,
     payload:code
  }
}

export const Bottomnav='Bottomnav';

export const Bottomnavigatevalue=(bottomvalue)=>
{
    return {
      type:Bottomnav,
      payload:bottomvalue
    }
}

export const Name='Name';

export const UpdateName=(FirstName)=>
{
     return  {
        type:Name,
        payload:FirstName
     }
}

export const Showdata='Showdata';

export const Showdatavalue=(datavalue)=>
{
  return  {
    type:Showdata,
    payload:datavalue
 }
}


export const Openlogin='Openlogin';

export const Handleopenlogin=(openLogin)=>
{
  return  {
    type:Openlogin,
    payload:openLogin
 }
}

export const Increaseitem='Increaseitem';

export const Handleincreaseitem=(increaseitem)=>
{
   return {
    type:Increaseitem,
    payload:increaseitem
   }
}

export const Decreaseitem='Decreaseitem';

export const Handledecreaseitem=(decreaseitem)=>
{
   return {
    type:Decreaseitem,
    payload:decreaseitem
   }
}

export const LogoItem='LogoItem';

export const HandleLogoitem=(logoitem)=>
{
  return {
    type:LogoItem,
    payload:logoitem
   }
}

export const Myaddress='Myaddress';

export const Handlemyaddress=(myaddress)=>
{
  return {
    type:Myaddress,
    payload:myaddress
   }
}

export const Mylocal='Mylocal';

export const Handlemylocal=(mylocal)=>
{
  return {
    type:Mylocal,
    payload:mylocal
   }
}

export const Myplace='Myplace';

export const Handlemyplace=(myplace)=>
{
  return {
    type:Myplace,
    payload:myplace
   }
}

export const Myprofile='Myprofile';

export const Handlemyprofile=(myprofile)=>
{
  return {
    type:Myprofile,
    payload:myprofile
   }
}

export const Mycheckout='Mycheckout';

export const Handlemycheckout=(mycheckout)=>
{
  return {
    type:Mycheckout,
    payload:mycheckout
   }
}


export const Myallaccountdata='Myallaccountdata';

export const Handlemyaccount=(myaccount)=>
{
  return {
    type:Myallaccountdata,
    payload:myaccount
   }
}

export const mysortdata='mysortdata';

export const Handlemysort=(sortdata)=>
{
  return {
    type:mysortdata,
    payload:sortdata
   }
}

export const myvariantdata='myvariantdata';

export const Handlemyvariant=(variantdata)=>
{
  return {
    type:myvariantdata,
    payload:variantdata
   }
}

export const Myloaddata='Myloaddata';

export const Handlemyload=(myloaddata)=>
{
  return {
    type:Myloaddata,
    payload:myloaddata
   }
}

export const Myhomeloading='Myhomeloading';

export const Handlemyhomeload=(myhomeloaddata)=>
{
  return {
    type:Myhomeloading,
    payload:myhomeloaddata
   }
}







