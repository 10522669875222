import React, {useState} from "react";
import HeaderbookingId from "./HeaderbookingId";
import CompletedItem from "./CompletedItem";
import Header from "../Header/header";
import SearchFilter from "../Header/SearchFilter";
import { useSelector } from 'react-redux'
import {createTheme,  useMediaQuery } from '@mui/material';
import HeaderorderId from "./HeaderoderId";

const AcceptedItem=()=>
{

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
    
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

      


 

 

  const  updateLocation=()=>
  {
     
  } 

  const Message=()=>
  {
    
  }
 

  const handleLoading=()=>
{

}


    return (
        <>
            <div className="contain">
           { isSmScreen ?  <HeaderbookingId/>: <Header  updateLocation={updateLocation} Message={Message}  handleLoading={handleLoading}/> }
           
            <div className="content">  

          {isSmScreen ? "" :  <HeaderorderId/>}
             <CompletedItem/>
             
             </div>
             </div>
        </>
    )
}

export default AcceptedItem;