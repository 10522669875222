import React from "react";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { Box, Grid, createTheme, useMediaQuery} from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   textAlign: 'center',
   borderRadius:'12px',
  }));

const Cardskeleton=({handle})=>
{

   const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });
  
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const arrayLength = 5;



  const item = Array.from({ length: arrayLength }, (_, index) => `Item ${index + 1}`);

      return (
        <>
             
              <div className="explore_container">
             <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start" >

          {item.map((cur,index)=>
                   
                  {
                     return (
                      <>
                             
                      <Grid item  xs={6}  sm={isSmScreen ? 4 : 3} md={2.4} key={index}>
                       <div className="categoriesdata">
                       <Item className="global_box_shadow"  style={{cursor:"pointer"}}>
                       <div className="explore_image_recommend"><Skeleton className="skeleton" style={{borderRadius:"4px 4px 0px 0px"}}/></div>
            
                       <div className="bottom_container">
                       <span className=" secondary_title text-wrapping"><Skeleton width={"10vw"}/></span>
                  
           
                       <div className="priceIcon">
                   
                       <div className="real_price"><del className="price"><Skeleton  style={{width:"3vw"}} /></del></div>
                     <div className="expected_price"><Skeleton  style={{width:"3vw"}}/></div>
                    <div className="discount"><Skeleton style={{width:"3vw"}}/></div>
                    </div>

                    <div className="buttons">
                     <Skeleton width={"100px"} height={'30px'}/>

                     </div>
                 </div>
                       </Item>
                       </div>
                       </Grid>
                      </>
                     )
                  }
          )}
            
             </Grid>             
             </Box>
            
             </div>
        </>
      )

}

export default Cardskeleton;

/*
            {item.map(cur=>
            {
               return (
                <>
                      </>
               )
              
            })

            }
            */