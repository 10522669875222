import React  from "react";
import Booked from "./Booked";
import HeaderbookingId from "./HeaderbookingId";
import {createTheme,  useMediaQuery } from '@mui/material';
import Header from "../Header/header";
import HeaderorderId from "./HeaderoderId";



const Accepted=()=>

{

    
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
    
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

      


    
    return (
        <>
             <div className="contain">
           
             {isSmScreen ?   <HeaderbookingId/> : <Header/>}  
           
           
            <div className="content" style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}} >  
         
            {isSmScreen ? "" : <HeaderorderId/>}
              <Booked/>
             
             </div>
             </div>
         
        </>
    )
}

export default Accepted;