import React, {useState, useEffect } from "react";
import Header from "../Header/header.js";
import Cleaning from "./Cleaning";
import Bottomnavigation from "./BottomNavigation";
import Footer from "../Footer/Footer";
import { connect,useDispatch } from "react-redux";
import { Bottomnavigatevalue, HandleLogoitem, Showdatavalue, resetItemAdded} from "../Redux/action";
import  ExploreCategory from './ExploreCategory.js';
import Deals from "./Deals.js";
import { useLocation, useNavigate } from 'react-router-dom';
import { couponsCode } from "../Redux/action";
import Categoryheader from "../Header/Categoryheader.js";
import {createTheme,  useMediaQuery } from '@mui/material';
import Headermobile from "../Header/headermobile.js";


const Home=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Baseurl=process.env.REACT_APP_BASE_URL;

   
  useEffect(() => {
  
    if (localStorage.length === 0) {
   
      resetItemAdded();
    }
  }, []);


  const [Update, setUpdate] =useState();
  const [isLoading, setIsLoading] = useState(true);
 


  const dispatch = useDispatch();


  localStorage.setItem('screen', 1);


  dispatch(couponsCode(""));

  dispatch(Bottomnavigatevalue(0));
 

  const [value, setvalue] = useState([]);
  const [loading1, setloading1] = useState(true);
  const [value2, setvalue2]= useState()
  const [value3, setvalue3] = useState()


  const [showup, setshowup]=useState(false)

  const [logo, setlogo]=useState()


  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/');
  const encodedPart = pathSegments[1]; 
  const decodedPart = decodeURIComponent(encodedPart);
  const businessname= localStorage.getItem('businessname');


 
useEffect(()=> 
 {
   
    const apiUrl23=`${Baseurl}/consumer/customer-store-info/`;
  

    let formdata = new FormData();
    formdata.append("store_name", decodedPart);
    
    let requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

     const fetchData = async()=>
     { 
         try 
         {
        
          const response2 = await fetch(apiUrl23, requestOptions);
        const business = await response2.json();
         const store=business.store_info;
        
        
         if(business.status==='success')
         {
         
           dispatch(Showdatavalue(true))
          
          setlogo(store[0].bo_store_logo)
          dispatch(HandleLogoitem(store[0].bo_store_logo))
          localStorage.setItem('storeId', store[0].bo_id)
          localStorage.setItem('businessname', store[0].bo_business_name);
         }
        

         const businessId=localStorage.getItem('storeId');
         
     
   if(business.status==='success')
   {

      setloading1(true)
      const apiUrl11 = `${Baseurl}/consumer/customer-homepage-banner-web/?business_owner_id=${businessId}`;
    
      let requestOption = {
      method: 'GET',
    redirect: 'follow'
    };

    let response,data
   
     response = await fetch(apiUrl11, requestOption);
     data = await response.json();

     setvalue3(data.banner_data)
     setloading1(false)
      
        const apiUrl3 = `${Baseurl}/consumer/customer-homepage/`;

  
        let formdata = new FormData();
        formdata.append("business_owner_id", businessId);
        
        let requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

              if(Update!==''&&businessId!==null)
              {
               
                setshowup(false)
                 setloading1(true);

             const response4 = await fetch(apiUrl3, requestOptions);
              
             const recommendeddata = await response4.json();

             const  Categorydata = recommendeddata.categories;
             const  recommended = recommendeddata.product_list;

             const groupedProducts = {};

             recommended.forEach(product => {
                 const categoryName = product.bo_product_category_name;
             
                 const categoryExists = Categorydata.some(category => category.bo_category_name === categoryName);
             
                 if (categoryExists) {
                
                     if (!groupedProducts[categoryName]) {
                         groupedProducts[categoryName] = [];
                     }
                     groupedProducts[categoryName].push(product);
                 } else {

                     if (!groupedProducts[categoryName]) {
                         groupedProducts[categoryName] = [];
                     }
                     groupedProducts[categoryName].push(product);
                 }
             });
             
             const groupedProductsArray = Object.values(groupedProducts);
              

          //   setvalue1(deals);
             setvalue( Categorydata );
             localStorage.setItem("Allcategorydata",JSON.stringify(Categorydata))
             setvalue2(groupedProductsArray);
             setshowup(true)
             setloading1(false);
              setIsLoading(false);
              
            }

            } 

            else 
            {
                dispatch(Showdatavalue(false)) 
            }
         }
        
         catch (error) {
          console.log(error);
          setIsLoading(false);
        }
     };

     if (isLoading) {
      fetchData();
    }

    
 },[Update,dispatch,decodedPart])


    return (
        <>
           
     
             <div className="contain" >
          
            {isSmScreen ? <Headermobile/> : <Header  logo={logo}  />} 
            {!isSmScreen && <Categoryheader  value={value}/>}
             <div className="content">
           
          {(()=>
          {
                   if(showup)
                   return(
                  <>
                     <Deals value={value3} loading={loading1}/>

                      <ExploreCategory value={value} loading={loading1}/>

                      {value2?.map((cur,index)=>
                      {
                          return (
                            <>
                             <div key={index}>
                               <Cleaning value={cur}  value2={cur} loading={loading1}/>
                               </div>
                            </>
                          ) 
                      })}
                     
                  </>
                    )
                    else  
                     return(
                      <>
                          <Deals value={value3} loading={loading1}/>
                         
                           <ExploreCategory value={value} loading={loading1}/> 
    
                           <Cleaning value={value2}  value2={value2} loading={loading1}/>  
                           
                      </>
                        )

          })()}

            
         
             </div>
            
             <Bottomnavigation/>
             <Footer/>
          
             </div>
        </>
    )
    
}

const mapDispatchToProps = {
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Home);


/* 
    <Cleaning value={value}  value2={value2} loading={loading1}/>
 <Deals value={value1} loading={loading1}/> 

 <Bottomnavigation/>
  localStorage.removeItem('timeslot');

  localStorage.removeItem('CategoryId');
  localStorage.removeItem('coupon_id');
  localStorage.removeItem('booking_no');
  localStorage.removeItem('service');
  localStorage.removeItem('Categoryname');
  localStorage.removeItem('Price');
  localStorage.removeItem('Money');
  localStorage.removeItem('totalPrice1');
  localStorage.removeItem('totalAmount');
  localStorage.removeItem('variantId');
  localStorage.removeItem('tempQuantities');





 





<Toppicks value={value} value2={value3} loading={loading1}/>

   const apiUrl24=`${Baseurl}/locations/?store_id=${store.business_id}`;

          const response1 = await fetch(apiUrl24);
          const locatedata = await response1.json();
          const  Categorydata = locatedata.data;

          console.log(Categorydata);
          dispatch(locationUpdate(Categorydata));
          const  Status=locatedata.status;
          
          
    
          console.log(Categorydata);
          */