
import { Bottomnav, CHECKOUT, COUPONS, Decreaseitem, FILTER_DATA, FORCE_DATA, Increaseitem, LOCATION, 
   Myaddress, Myallaccountdata, Mycheckout, Myhomeloading, Myloaddata, Mylocal, Myplace, Myprofile, Name, Openlogin, PHONE, 
   SEARCH_FILTER, SET_DATA, Showdata, TOTALPRICE, VARIANT, mysortdata, myvariantdata } from "./action";
   
import {COUNT1, COUNT2 } from './action';
import { UPLOAD_IMAGE, DELETE_IMAGE ,BusinessId, LogoItem} from './action';

const initialState = {
    itemAdded: false,
    data:null,
    image: null,
    cityname:[],
    phone:null,
    search:[],
    servicevalue:'',
    forceData:'',
    variantData:'',
    checkout:[],
    countno1:"",
    countno2:"",
    totalprice:"",
    code:"",
    bottomvalue:0,
    Name:"",
    Openlogin:'',
    Showdata:true,
    Increase:'',
    Decrease:'',
    logo:'',
    address:'',
    local:false,
    place:false,
    profile:false,
    checkoutdata:true,
    logincredentials:false,
    sort:"Recently added",
    variant:{},
    loading:true,
    homeload:true
  };
  
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_TO_CART":
        return {
          ...state,
          cartItems: state.cartItems + 1,
          itemAdded: true,
        };

        case 'RESET_ITEM_ADDED':
          return {
            ...state,
            itemAdded: false,
          };
     
      

        case SET_DATA:
          return {
            ...state,
            data: action.payload,
          };


      case UPLOAD_IMAGE:
        return {
          ...state,
          image: action.payload,
        };
      case DELETE_IMAGE:
        return {
          ...state,
          image: null,
        };

      case PHONE:
        return {
          ...state,
          phone:action.payload,
        }

        case LOCATION:
           return   {
               ...state,
               cityname:action.payload,

            };
            
          case SEARCH_FILTER:
            return {
              ...state,
              search:action.payload,
            }
          
          case FILTER_DATA:
            return {
              ...state,
              servicevalue:action.payload,
            }
            case FORCE_DATA:
            return{
              ...state,
              forceData:action.payload,
            }

            case VARIANT:
              return {
                ...state,
                variantData:action.payload,
              }

              case CHECKOUT:
                return {
                  ...state,
                  checkout:action.payload,
                }

                case COUNT1:
                  return {
                    ...state,
                    countno1:action.payload,
                  }

                  case COUNT2:
                    return {
                      ...state,
                      countno2:action.payload,
                    }
                

                    case TOTALPRICE:
                      return {
                        ...state,
                        totalprice:action.payload,

                      }

                      case COUPONS:
                        return{
                          ...state,
                          code:action.payload,
                        }
                 
                      case Bottomnav:
                        return{
                          ...state,
                          bottomvalue:action.payload
                        }  

                        case Name:
                          return {
                            ...state,
                            Name:action.payload
                          }
              
                          case Openlogin:
                            return {
                              ...state,
                              Openlogin:action.payload
                            }
                        case Showdata:
                          return {
                            ...state,
                            Showdata:action.payload
                          } 

                          case Increaseitem:
                            return {
                              ...state,
                              Increase:action.payload
                            }

                            case Decreaseitem:
                              return {
                                ...state,
                                Decrease:action.payload
                              }
                        
                         case LogoItem:
                          return {
                            ...state,
                            logo:action.payload
                          }

                          case Myaddress:
                            return {
                              ...state,
                              address:action.payload
                            }
                            case Mylocal:
                              return {
                                ...state,
                                local:action.payload
                              }

                              case Myplace:
                                return {
                                  ...state,
                                  place:action.payload
                                }
                                case Myprofile:
                                  return {
                                    ...state,
                                    profile:action.payload
                                  }
                                  case Mycheckout:
                                    return {
                                      ...state,
                                      checkoutdata:action.payload
                                    }
                                    case Myallaccountdata:
                                      return {
                                        ...state,
                                        logincredentials:action.payload
                                      } 
                              case mysortdata:
                                return {
                                  ...state,
                                  sort:action.payload
                                } 
                                  
                              case myvariantdata:
                                return {
                                  ...state,
                                  variant:action.payload
                                }  
                                case Myloaddata:
                                  return {
                                    ...state,
                                    loading:action.payload
                                  }  
                                case Myhomeloading:
                                  return {
                                    ...state,
                                    homeload:action.payload
                                  }

      default:
        return state;
    }
  };
  
  export default cartReducer;

  