import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Box, Grid} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

const Categoryheader = ({value}) => {

const Category=value?.slice(0,10);

const businessname=localStorage.getItem('businessname');

const navigate=useNavigate();


const Menu=()=>
{
    navigate(`/${businessname}/all-category`);

}

const Categoryname=(values, names)=>
{
    navigate(`/${businessname}/search`);
    localStorage.setItem("CategoryId" ,values);
    localStorage.setItem("Categoryname",names);
}



  return (
      <>
          <div className='category_header'>
          <Container maxWidth='lg'>
          <Box sx={{ width: '100%' }}>
           <div className='category_headerdata' >
           {Category &&  <div className="category_headername hamburcategory" onClick={Menu}>

                 <span>All</span>
             </div>
             }

            {Category?.map((cur, index)=>
                {
                    return (
                        <>
                        <div key={index}>
                       <span className="category_headername"  onClick={()=>Categoryname(cur._id,cur.bo_category_name)}>{cur.bo_category_name}</span>
                       </div>
                        </>
                    ) 
                })}
                    </div>
                     
                      </Box>
            </Container>
      
          </div>
      
      </>
  )
}

export default Categoryheader

/*                <span style={{display:"flex", alignItems:"center"}}><MenuIcon/></span>*/