import React  from 'react'
import Footer from '../Footer/Footer'
import Bottomnavigation from '../Home/BottomNavigation'
import Header from '../Header/header';
import Applied from './Applied';
import { createTheme, useMediaQuery } from "@mui/material";
import Headercoupon from './Headercoupon';
import BottomNavigation from '../Home/BottomNavigation';


const Applycoupon = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

   
    const handleLoading=()=>
    {
    
    }

  return (
   <>
         <div className="contain">
            {isSmScreen ? <Headercoupon/> :  <Header handleLoading={handleLoading} />}
           
            <div className="content" style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}}>
             
             <Applied/>
            
           
             </div>
             <Footer/>
             </div>
   </>
  )
}

export default Applycoupon


