import React, {useState, useEffect , useRef} from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import {createTheme,  useMediaQuery } from '@mui/material';
import {ReactComponent as Sharelogo} from "../assests/Share.svg";
import lottie from 'lottie-web';
import successbookingdata from "../assests/success_booking.json";
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    borderRadius:'6px',
  }));

const Deleteservice=()=>
{


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });


  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const businessId=localStorage.getItem('storeId');
  const businessname=localStorage.getItem('businessname');

    const customBooking=
    {

      backgroundColor:'#57339F',
      color:'var(--white)',
    fontSize:'16px',
    fontWeight:500,
    textTransform:'none',
    marginTop:'24px',
    letterSpacing: '0.8px',  
      '&:hover': {
        backgroundColor: '#57339F', 
      },
    }
    const locationname=JSON.parse(localStorage.getItem('location').toLocaleLowerCase());

    const navigate = useNavigate();

    const booking= localStorage.getItem('booking_no');
    const [isLoading, setIsLoading] = useState(true);
    const Baseurl=process.env.REACT_APP_BASE_URL;
    const access= JSON.parse(localStorage.getItem("access_token"));

    const [booked, setbooked]=useState('');


  const Booking=()=>
  {
       navigate(`/${businessname}/bookingId/${booking}`);
  } 

  
  useEffect(()=>
  {
      const apiUrl19=`${Baseurl}/booking-cancel/${booking}/?store_id=${businessId}`

      const fetchData= async()=>
      {
         try 
         {
             if(access!==null)
             {
               
               const response = await fetch(apiUrl19);

                const Update = await response.json();

                const bookingdetails=Update;
                console.log(bookingdetails);

                setbooked(bookingdetails);

             }
             setIsLoading(false);
         }catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
      if (isLoading) {
        fetchData();
      }
     
  }, [isLoading])


  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer?.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        animationData: successbookingdata,
        loop: true,
        autoplay: true, 
      });

    }
  }, [booked]);



  if(booked?.date)
  {

  const dateParts = booked?.date.split(' '); 


const inputDate = dateParts[0]; 
const inputTime = dateParts[1] + ' ' + dateParts[2]; 

const [day, month, year] = (inputDate?.split('/'));
  const dateObject = new Date(`${year}-${month}-${day}`);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };

  const formattedDate = dateObject.toLocaleString('en-IN', options);

     return (
        <>
              {booked!==undefined &&
            <section>
            
             
                <div className="experience-services">
               <div className='booking_success'><div style={{paddingInline:"10px"}}>
               <Container maxWidth='lg' className="p-16">
               <div className='sharedlogo'><Sharelogo style={{transform:'scale(0.9)', cursor:'pointer'}}/></div></Container></div>
                <div className='booked_successed'>
                <div style={{width:"100px",top:'30px',position:'relative'}} ref={animationContainer}></div>
               <h1 className="formattiming">Cancelled</h1> 
               <h1 className="booking_request">You have been successfully Cancelled <br></br>service.</h1>
                </div>
                </div>
               
                <Container maxWidth="lg">
               <div className='booking_succeed'>

             
               <div className="payment_typed">
                 <div className="paymentfull ">Payment Type</div>
                 <div className="accounttype">{booked.payment_type}</div>
               </div>

               <div  className="payment_typed">
                 <div  className="paymentfull">Amount</div>
                 <div className="accounttype">₹{booked.amount}</div>
               </div>

               <div  className="payment_typed">
                 <div  className="paymentfull">Booking Id</div>
                 <div className="accounttype">{booked.booking_number}</div>
               </div>

               <div  className="payment_typed">
                 <div  className="paymentfull">Booking Date</div>
                 <div className="accounttype">{formattedDate}</div>
               </div>

               <div  className="payment_typed">
               <div  className="paymentfull">Booking Time</div>
                 <div className="accounttype">{inputTime}</div>
               </div>
                  
               <div className="reschedule-paymentdata ">
            
            <Button style={customBooking} className='primary_button' onClick={Booking}>View Booking</Button>
              </div>
                   
              
              </div>
              </Container>
             </div>

            </section>
}
        </>
     )
  } 
}

export default Deleteservice;


/*
  position:isSmScreen?'fixed':'',
  bottom:isSmScreen?'16px':'',
  marginTop:isSmScreen?'none':'24px',

      
*/