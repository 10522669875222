import React from 'react';
import { Box, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import DoneIcon from '@mui/icons-material/Done';
import Serviceskeleton from './Additionalskeleton';
import Container from '@mui/material/Container';
import {createTheme,  useMediaQuery } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
  }));
const Servicenote = ({productdata, loading}) => {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
  return (
   <>
               <section className= {isSmScreen? "m-10": "section1 p-10"}>
            <Container maxWidth="lg" className="p-16" >

             {loading ?

             <>
                  <Serviceskeleton/>
             </>
            
               :
             <div>
              <div>
              <Box sx={{width:"100%"}}>
            <Grid container rowSpacing={2}  columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center">
            <Grid item  xs={12} sm={12} md={12}>
            <Item className="global_box_shadow" >
            <div className='item_additional'>
               <h3 className='title' style={{margin:'0px'}}>Product Details</h3>
                <div className='additional_details'>{productdata[0].bo_product_description}</div> 
               </div>
            </Item>
            </Grid>
            </Grid>
            </Box>
            </div>
            </div>
             }
           </Container>
            </section>

   </>
  )
}

export default Servicenote
/*

            line number 29      {Add.additional_details!=="" &&  <section className= {isSmScreen? "m-10": "section1 p-10"}>
    <div className='done_tick'>
                <div className='doneIcon'><DoneIcon/></div>
                  </div>

                                  <div className='additional_details'>{Add.service_note}</div> 
                */