import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import {createTheme, useMediaQuery } from "@mui/material";

const Returnpolicy = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/');
  const encodedPart = pathSegments[1]; 
  const decodedPart = decodeURIComponent(encodedPart);


  return (
       <>
       <section className= {isSmScreen? "slider-header service-margin": "section mt-20 explore-padding"}>
            <Container maxWidth='lg' className='container-padding'>
            <h1 className='head_title'>Return policy</h1><br></br>
            <span className='policy_title'>At <span style={{fontWeight:"700"}}>Parashar Creative Studio</span>, you can return items within <span style={{fontWeight:700}}>7 days</span> of purchase. Items must be unused, in original packaging, and in the same condition as received. Refunds will be processed within <span style={{fontWeight:700}}>5-7 business days</span> of receiving the return. Please note that shipping costs are non-refundable unless the item is defective or damaged upon arrival. For any issues or concerns, our customer service team is here to assist you in ensuring a smooth return process.</span> <br></br><br></br>
           

    
         </Container>
         </section>
       </>
  )
}

export default Returnpolicy