import React from 'react';
import { Box, Grid, } from "@mui/material";
import {  createTheme, useMediaQuery } from '@mui/material';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
   }));

const Dealskeleton = () => {

    
    const arrayLength = 1;
    const item = Array.from({ length: arrayLength }, (_, index) => `Item ${index + 1}`);

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });


     


  return (
        <>
         

             <div className="explore_container">
             <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} columnSpacing={{xs:2, sm:2, md:4}} display="flex" justifyContent="start" >

            <Grid item  xs={12} sm={12} md={12}>

            <Carousel  interval={3000} showThumbs={false} showStatus={false}  infiniteLoop={true} >
               
             {item.reverse().map((cur)=>
             {
                return (
                    <>
                    
              <div className="bath_deal">  <Skeleton className="bathroom" /></div> 
             
                    </>
                )
             })}
          
           
             </Carousel>
         
             </Grid>
             </Grid>
             </Box>
             </div>
   
        </>
  )
}

export default Dealskeleton