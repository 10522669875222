import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/header';
import {createTheme,  useMediaQuery } from '@mui/material';
import Wishlist from './Wishlist';
import Headerbookmark from './Headerbookmark';

const Bookmark = () => {


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


  
  return (
    <>
         <div className="contain">

                {isSmScreen ? <Headerbookmark /> : <Header/>}
                <div className="content"  style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}}>
                <Wishlist />
                
                </div>
                <Footer />
                </div>
    </>
  )
}



export default Bookmark;