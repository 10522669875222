import React, { useState, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchFilter } from "../Redux/action";
import { filterData } from "../Redux/action";


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
  }));




const SearchFilter=({searchResult,  handleCloseShow, handle})=>
{

  const location = useLocation();
  const navigate=useNavigate();
  const Baseurl=process.env.REACT_APP_BASE_URL;

  const businessId=localStorage.getItem('storeId');
  const businessname=localStorage.getItem('businessname');

 const searchdata=searchResult?.slice(0,7);

  const [categoryname, setcategoryname]=useState();
  const dispatch=useDispatch();

  useEffect(()=>
  {
    (async () => {
    
       let requestOptions = {
        method: 'GET',

         redirect: 'follow'
    };
    
     fetch(`${Baseurl}/consumer/customer-product-search-web/?business_owner_id=${businessId}&search`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data=JSON.parse(result);
        const  Categorydata = data.data;  
        dispatch(searchFilter(Categorydata));
    
      })
      .catch(error => console.log('error', error));
    
    
   
  })();
  },[]);


  const Showdata = (categoryname, productname,Id,dataId)=>
  {
         
    localStorage.setItem('DataId',dataId)
    localStorage.setItem('CategoryId', Id)
    localStorage.setItem('Categoryname', categoryname);


    const Categoryname=categoryname?.replace(/ /g, '-');
    const servicename=productname?.replace(/ /g, '-');

   
       localStorage.setItem('Show', true);
       localStorage.setItem('productname', productname);
       localStorage.removeItem('totalPrice1');
       localStorage.removeItem('totalAmount');
       localStorage.removeItem('tempQuantities');

    if(location.pathname.split('/').slice(0, 3).join('/')===`/${businessname}/product`)
    {
         window.location.reload();
    }
    else
    {
      navigate(`/${businessname}/product/${Categoryname}/${servicename}`);
    }
    
  
  } 


    return (
        <>
      
         <div className="search_show_item">
          
         <Item>
             {searchdata?.map (cur=>
             {
                
                return (
                  <>
                  <div className="search_show" onClick={()=>Showdata(cur.bo_product_category_name, cur.bo_product_name, cur.bo_product_category_id, cur._id)}>
                  <div className="explore_image_search"><LazyLoadImage  src={cur.bo_product_images.img_urls[0].length > 0 ? cur.bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                   alt={cur.bo_product_images.img_urls[0].length > 0 ? 'category' : 'No Image'} onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} /></div>
                   <div className="explore_name_search">{cur.bo_product_name}</div>
                   </div>
                  </>
                )
             })}
             </Item>
            
             </div>


        </>
    )

}

export default SearchFilter;

/*




*/




/*

  if(access!==null)

  {
  }
*/

/*            

   {filteredItems.map(cur=>
           {
                return (
                  <>
                        <div>{cur.service_name}</div>
                  </>
                )
           })}

 {filteredItems.map((items, index) => 
            {
               return (
                <>
                      <div key={index} className="search_suggestion">
                       
     

                      </div>
                </>
               )
            }
            )}




                       <div className="explore_name">{items.service_name}</div>
<div className="explore_image_recommend"><LazyLoadImage src={item.image_gallery[0].image_url} alt="category" loading="lazy" /></div>


  inside the showdata
  const servicename = localStorage.getItem('productname');
      

           
           const foundCategory = categoryname.find(category => category.category_id === value);
          
       
            
              if(servicename)
              {
                
                navigate(`/${businessname}/search`);
                localStorage.setItem("CategoryId" ,value);
                localStorage.setItem("Categoryname",foundCategory.category_name);

              }
                     
            
    if (value)
    {
           
         
           handleCloseShow();
           
           if(window.location.pathname!=="/search")
           {
                
           
                  navigate(`/${businessname}/search`)
                  localStorage.setItem("CategoryId" ,value);
                  localStorage.setItem("Categoryname",foundCategory.category_name);
                     dispatch(filterData(value))

                   
           }
           else
           {
                 handle(value)
                 localStorage.setItem("CategoryId" ,value);
                 localStorage.setItem("Categoryname",foundCategory.category_name);
              
           }
          
    }




*/