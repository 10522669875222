import React, {useState} from "react";
import HeaderbookingId from "./HeaderbookingId";
import Cancelleditem from "./Cancelleditem";
import {createTheme,  useMediaQuery } from '@mui/material';
import Header from "../Header/header";
import SearchFilter from "../Header/SearchFilter";
import { useSelector } from 'react-redux'
import HeaderorderId from "./HeaderoderId";

const Cancelled=()=>
{

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
    
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

      
 

  const  updateLocation=()=>
  {
     
  } 

  const Message=()=>
  {
    
  }



  const handleLoading=()=>
  {
  
  }
    
    return (
        <>
            <div className="contain">
            {isSmScreen ?  <HeaderbookingId/> : <Header   updateLocation={updateLocation} Message={Message} handleLoading={handleLoading}/>}
           
            <div className="content"  style={{background:"#f3f3f3", marginBottom:"0px"}}>         
            {isSmScreen ? "" : <HeaderorderId/>}         
            <Cancelleditem/>
             
             </div>
             </div>
        </>
    )
}

export default Cancelled;

