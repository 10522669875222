import React, {useState, useEffect} from "react";
import { Box, Grid, createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useSelector, connect, useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import {ReactComponent as Delete } from "../assests/Delete.svg";
import { resetItemAdded, Bottomnavigatevalue, addToCarts} from "../Redux/action";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Bookmarkskeleton from "./Bookmarkskeleton";



const Wishlist=()=>
{

  

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Items = styled(Paper)(({ theme }) => ({
    
    padding: '20px',
    background: '#FFF',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
    marginBottom:isSmScreen?'4px':'14px',
    
  
  }));

  const checkoutflex={

    display:'grid',
    gridTemplateColumns: isSmScreen ? '96px  auto' : '110px  auto',
    gridTemplateRows:'1fr',
    gridColumnGap:'18px'

  }

  const checkoutgrid=
  {
    display:'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows:'1fr',
    gridColumnGap:'18px',
    paddingBottom:'10px',
    marginBottom: '10px',
  }


  const deletebuttons=
  {
      color:'var(--primary)',
      textTransform:'none',
    }

    
    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

  
    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');

    const Baseurl = process.env.REACT_APP_BASE_URL;

    const Node_baseurl = process.env.REACT_APP_NODEJS_BASE_URL;
    
    const increased=useSelector(state=>state.forceData)


    const access= localStorage.getItem("accessToken");


   const [open,setopen] = useState(false);

 

   const [buttonClicked, setButtonClicked] = useState(false);


   const navigate= useNavigate();
  
   const [loading, setloading] = useState(true);
 
  
  const [showcart, setshowcart]=useState(false)
 

  const Totalcount=JSON.parse(localStorage.getItem('quantity'));



  const [showpayment, setshowpayment] = useState(localStorage.getItem('showbutton'));

  const couponValue= useSelector(state=>state.code);



 
 const [products, setProducts] = useState();


 let variantdelete=[];


 
  const [tempQuantities, setTempQuantities] = useState(Totalcount);


  const [isLoading, setIsLoading] = useState(true);
  
 const dispatch = useDispatch();
 dispatch(Bottomnavigatevalue(2));


   const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

      const [input, setinput]=useState(
        {
            payment:""
        }
      )

      if(!localStorage.getItem('checked'))
      {
            input.payment="";
      }

    const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };

  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   

   const Showbutton = localStorage.getItem('showbutton');
  

useEffect(()=>
{

  if(Showbutton==='true')
  {
      setshowpayment(true);
  }
  else
  {
      setshowpayment(false);
  }
    
},[Showbutton])


useEffect(()=>
{ 
  if(showpayment===true)
  {

     localStorage.setItem('quantity', JSON.stringify(tempQuantities));
  }
  
},[showpayment, tempQuantities])



const deleted=(cartId)=>
{
   setopen(true);
 localStorage.setItem('bookmarkId', cartId);
}

const cancel=()=>
{
   setopen(false);
}


const productId=localStorage.getItem('productId')
  
 const selectedArray = products?.filter((item) => item.product_id === productId);
 variantdelete.push(selectedArray);



const deleteditem=(newState)=>
{
 
  const bookmarkId = localStorage.getItem('bookmarkId');
  const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {};

   if(access!==null)
   {
    
     
     let requestOptions = {
       method: 'DELETE',
       redirect: 'follow'
     };
     
     fetch(`${Node_baseurl}/bookmark/${bookmarkId}/${businessId}`, requestOptions)
       .then(response => response.text())
       .then(result => {
         const data=JSON.parse(result);
         console.log(data);
       
           if (data.status === 'success') {

            const isBookmarked = !!bookmarks[productId];
    
            if (isBookmarked) {
    
              delete bookmarks[productId];
            } 
    
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks));

            localStorage.removeItem('cartId');  
           
            setopenSnackbar1({ ...newState, openSnackbar: true });
            setIsLoading(true);
           
           
          }  

          setopen(false);
          setButtonClicked(true);
  
       })
       .catch(error => console.log('error', error)); 
      }
  
 
}

  
 useEffect(()=>
{
  const access= localStorage.getItem('accessToken')
 
  const apiUrl8 =`${Node_baseurl}/bookmark/${businessId}`;

  const fetchData = async()=>
  {
     try 
     {
      if((buttonClicked===true || access!==null))
      { 
          

           setloading(true)
          const response2 = await fetch(apiUrl8);
           
            const checkout = await response2.json();
            const  Categorydata = checkout.data;
    
             if(Categorydata.length!==0)
             {
                setProducts(Categorydata);
                setButtonClicked(false);
                setloading(false)
                console.log(Categorydata)
             }
            else 
            {
                navigate(`/${businessname}/empty-bookmark`)
            }
            
        }
        
            setIsLoading(false);


     } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    fetchData();
  }
 
            
},[isLoading, businessId, increased, showcart])

 

const Bookmarked=(categoryname, productname,Id,dataId, price)=>
{
  localStorage.setItem('DataId',dataId)
  localStorage.setItem('CategoryId', Id)
  localStorage.setItem('Categoryname', categoryname);
  localStorage.setItem('Discountprice', price);

  const Categoryname=categoryname?.replace(/ /g, '-');
  const servicename=productname?.replace(/ /g, '-');

   navigate(`/${businessname}/product/${Categoryname}/${servicename}`);


     localStorage.setItem('Show', false);
     localStorage.setItem('productname', productname);
     localStorage.setItem('productId', dataId)
     localStorage.removeItem('totalPrice1');
     localStorage.removeItem('totalAmount');
     localStorage.removeItem('tempQuantities');
}




     return (
        <>
                   <section className= {isSmScreen? "m-10": "section1 p-10"}>
            
            <Container maxWidth='lg' className="p-16">
              <div>

             <div className="breadcrumb mobile-bodypadding ">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/bookmark`}  
        >
          Bookmark
        </StyleNavLink>
        
      </Breadcrumbs>
      </div>
            
            {loading ? 
             <> 
              <Bookmarkskeleton/>
             </>
             :

              <Box sx={{width:"100%", boxSizing:"border-box"}} className={isSmScreen ? {}:"mb_bookmark"}>
          <Grid container rowSpacing={1}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start">
           
             
           {products?.map((cur,index)=>
            {      
                return (
                  <>
                   <Grid item  xs={12} sm={isSmScreen? 12:6} md={6}>
       
                  <Items className="global_box_shadow" style={{cursor:"pointer"}} onClick={()=>Bookmarked(cur.bo_product_category_name, cur.bo_product_name, cur.bo_product_category_id, cur.bo_product_id, cur.bo_product_discounted_price)}>
                    <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >
                    {showpayment &&  <div className="image_lazyload">

                      <LazyLoadImage  src={cur.bo_product_images.img_urls.length>0 ? cur.bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                        alt={cur.bo_product_images.img_urls.length > 0 ? 'category' : 'No Image'}  className="checkoutimage roundimage"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} />
                    </div>
                }
                <div>
                    
                         <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                         {cur.bo_product_name}              
                         </h2>
          
          {showpayment &&   <div>
       
           
               <div className="priceIcon">
                  
               {cur.bo_product_discounted_price!==null &&  <div className="expected_price"><span>₹{cur.bo_product_discounted_price}</span></div>}
               
               {cur.bo_product_price!==null &&  <div className="real_price"><span><del>₹{cur.bo_product_price}</del></span></div>}
               <div className="discount_price">{parseInt(((cur.bo_product_price-cur.bo_product_discounted_price)*100)/cur.bo_product_price)}% OFF</div>
              
            </div>
        
                    <div className="checkoutdetails">
               <div className="deleteoutlined" onClick={(e)=>{e.stopPropagation();deleted(cur._id)}}><Delete/></div>
              </div>
              </div>
          }

                              
                 
              </div> 
              </div>  
              </Items>
           
    
           </Grid>
                          
                  </>
                )
            })}

         </Grid>
         </Box>
}
         </div>

         <Dialog
                      
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title">
                        {"Delete Service"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                        Are you sure you want to delete bookmark item ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus  style={deletebuttons} onClick={cancel}>
                          Cancel
                        </Button>
                        <Button style={deletebuttons} onClick={()=>deleteditem({ vertical: 'top', horizontal: 'center' })} autoFocus>
                          Yes, Delete
                        </Button>
                      </DialogActions>
                    </Dialog>

         
             <Stack spacing={2} sx={{ width: '100%' }}>

                  <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                   <Alert  severity="success" sx={{ width: '100%' }}>
                         Bookmark deleted successfully !
                         </Alert>
                    </Snackbar>

                  </Stack> 

         </Container>

         


         </section>
       
        </>
     )
}

const mapDispatchToProps = {
    addToCarts,
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Wishlist);


/*


               
 isSmScreen? navigate(`/${businessname}/connect`):setopenSnackbar2({...openSnackbar2, opentoast:true}); 
      */      