import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import {store, persistor} from "./Redux/store";
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
 
       <Provider store={store}>
        <PersistGate loading={null} persistor= {persistor}>
      <BrowserRouter>
      <App />
       </BrowserRouter>
       </PersistGate>
       </Provider>

 // </React.StrictMode>
);

reportWebVitals();

/*
const container = document.getElementById('root');
const root = hydrateRoot(container,  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>);



const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

if (rootElement.hasChildNodes()) {
  root.hydrateRoot(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

reportWebVitals();
    

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
      <PersistGate loading={null} persistor= {persistor}>
    <BrowserRouter>
    <App />
     </BrowserRouter>
     </PersistGate>
     </Provider>, rootElement);
} else {
 rootElement.render(<Provider store={store}>
      <PersistGate loading={null} persistor= {persistor}>
    <BrowserRouter>
    <App />
     </BrowserRouter>
     </PersistGate>
     </Provider>, rootElement);
}
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


//   
// </PersistGate>

