import React, {useEffect, useState} from "react";
import Footer from '../Footer/Footer'
import Bottomnavigation from '../Home/BottomNavigation'
import Header from '../Header/header';
import { useSelector } from 'react-redux'
import SearchFilter from '../Header/SearchFilter'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {createTheme, useMediaQuery } from "@mui/material";

import {ReactComponent as StoreLogo} from '../assests/Store.svg';
import Headerempty from "../Checkout/Headerempty";


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:'40px 20px',
  
  
  }));


const EmptyStore= () => {

 // const [loading, setloading]=useState(false)

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

   

  return (
      <>
     
       <div className="contain">
         
            
             <div className="content">


              
             <section className='section'>
              <div className="container">

    
              <Item className="global_box_shadow">
                <div className="empty_data">
                <div ><StoreLogo/></div>
                <span className="head_title">Look like the store is not yet </span>
                <span className="head_title">setup.</span>
                </div>
              </Item>

              </div>

              </section>
              
              </div>
              <Bottomnavigation/>
              <Footer/>
              </div>
             
            

       
      
      </>
  )
}

export default EmptyStore;

/*  <Header Filter={Filter}  updateLocation={updateLocation} Message={Message} handleLoading={handleLoading} />

*/