import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';

const Error = () => {
  return (
 
    <>
       <div className='thanking'>
        <div className='ErrorIcon'><ErrorIcon/></div>
        <div className='thank_title explore by_category'>Please Try Again !</div>
        <div className='payment_done hero_title'>Your Payment is failed</div>
       </div>
    </>

  )
}

export default Error