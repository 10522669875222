
import React from "react";
import { Box, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Viewskeleton from "./Viewsimilarskeleton";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {createTheme,  useMediaQuery } from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const Item = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
 
  }));
 

const Viewsimilar=({similar, data, loading})=>
{

   const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });
  
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const businessname=localStorage.getItem('businessname');
   
    const categoryname= localStorage.getItem('Categoryname');

    const responsive = {
      superdesktop: {
        breakpoint: { max: 3000, min: 1400 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 1400, min: 1200 },
        items: 4,
      },
     minidesktop: {
         breakpoint: { max: 1200, min: 768 },
         items: 3,
      },
  
      tablet: {
        breakpoint: { max: 768, min: 480 },
        items: 3,
        
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 2,
       
      }
    };

   
    const Similardata = similar?.flatMap(subarray => subarray)
    .filter(item => item.bo_product_category_name === categoryname && item._id!==data);

    function formatIndianNumber(number) {
      const parts = number.toString().split(".");
      let integerPart = parts[0];
      let formattedInteger = "";
    
      while (integerPart.length > 3 && number>=10000) {
        formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
        integerPart = integerPart.slice(0, -3);
      }
      formattedInteger = integerPart + formattedInteger;
    
      return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
    }
 

     return (
        <>

    

         <section className= {isSmScreen? "m-10": "section1 p-10"}>
            <Container maxWidth="lg" className="p-16" >

             {loading ?

             <>
                   <Viewskeleton/>
             </>
            
               :
            
              <div>
              {Similardata?.length!==0  &&
              <Box sx={{width:"100%"}}>
            <Grid container rowSpacing={2}  columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center">
            <Grid item  xs={12} sm={12} md={12}>

            <Item className="global_box_shadow item_additional" >
            <div  style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <h3 className="title" style={{margin:'0px'}}>You may like this</h3>
          <NavLink to={`/${businessname}/search`} className="view-all">View All</NavLink>
          </div>

          <Carousel responsive={responsive}>
          {Similardata?.filter(cur => cur.bo_product_status === 1)?.map((cur,index)=>
             {
               if (!cur) {
                  return null; 
                }
                const isLastItem = index === Similardata.length - 1;
                return(
                    <>
                 
                 <div key={index} className={`carousel-item ${!isLastItem ? 'with-gap' : ''}`}>
                       <Item className="global_box_shadow " style={{cursor:"pointer"}} >
                       <div className="explore_image_similar"> 
                       <LazyLoadImage  src={cur.bo_product_images.img_urls.length > 0 ? cur.bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'}
                   alt={cur.bo_product_images.img_urls.length > 0 ? 'category' : 'No Image'} onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} /> 
                       </div>
                       
                       <div className="bottom_container">

                 <span className=" secondary_title text-wrapping">{cur.bo_product_name}</span>
                   
                       <div className="priceIcon">
                  
                       {cur.bo_product_discounted_price!==null &&  <div className="expected_price"><span>₹{formatIndianNumber(cur.bo_product_discounted_price)}</span></div>}
                       
                       {cur.bo_product_price!==null &&  <div className="real_price"><span><del>₹{formatIndianNumber(cur.bo_product_price)}</del></span></div>}
                       <div className="discount_price">{parseInt(((cur.bo_product_price-cur.bo_product_discounted_price)*100)/cur.bo_product_price)}% OFF</div>

                    </div>

               
                      </div>

                      </Item>
                      </div>
             
             </>
                )
             })
          }
              </Carousel>             

            </Item>
            </Grid>
            </Grid>
            </Box>
}
            </div>
         
        }
             
           </Container>
            </section>

        </>
     )
}



export default Viewsimilar;

/*  
viewsimilarserviced css add 
  <section className= {isSmScreen? "m-10": "section1 p-10"} style={isSmScreen? {marginTop:'20px'}: {marginTop:'30px'}}>
               <Container maxWidth="lg" className="sectioncontainer" >
         
             <div>
             <div className="view_all mobile-bodypadding similar_services">
          <h1 className="head_title explore ">You may like this</h1>
          <NavLink to={`/${businessname}/`} className="view-all">View-All</NavLink>
          </div>

          {loading ?
          
          <>
              <Viewskeleton/>
          </>
          
          :
          
       
          <Box sx={{width:"100%", margin:'20px 0px'}}   >
          <Grid container  rowSpacing={2} columnSpacing={{xs:2, sm:3, md:3}}   display="flex" justifyContent="start">
          <Grid item  xs={12}  sm={12} md={12} >


        
           
         </Grid>
          </Grid>
          </Box>
          }
          </div>
        
       </Container>
          </section>






   {similar.length!==0  &&  line number 80

    line number 104
     

 const Items = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   padding:'20px',
   textAlign: 'center',
   
  }));

  const   customButton= {
     backgroundColor:'#57339F',
     padding:'0px 8px',
     textTransform:'none',
     fontSize:'16px',
     whiteSpace: 'nowrap',
     right:'16px',
     margin:"6px 0px 4px"
   };

    {show &&  <div className="add_data "  onClick={()=>services(cur.service_id,cur.service_name,cur.service_variant[0]?.variant_id, cur.service_variant[0]?.variant_sale_price)} ><button  className="addcheckout" >Add</button></div>} 
              
               {cur.show===false ?   <div>
              <table className="gocheckout"> 
              <tr>
              <td>
              <button onClick={()=>remove(cur.service_variant[0].variant_id)}  className="removeIcon"><RemoveIcon/></button></td>
                
              <td> <div className="datacount">{Totalcount}</div></td>
                   
              <td><button onClick={()=>add(cur.service_variant[0].variant_id)} className="removeIcon"><AddIcon/></button></td>
              </tr>
              </table>
                 </div>
                 :
                 <div className="add_data "  onClick={()=>services(cur.service_id,cur.service_name,cur.service_variant[0]?.variant_id, cur.service_variant[0]?.variant_sale_price, cur.show)} ><button  className="addcheckout" >Add</button></div>
     }
     
    

    <div className="priceIconsimilar">
<div className="real_pricevaraint"><del className="price"><span>₹{cur.service_variant[0].variant_price}</span></del></div>
                       <div className="discountvariant">{(cur.service_variant[0].variant_percentage).toFixed(1)}% </div>
                      </div>
       

*/