import React from 'react'
import { Box, Grid, createTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:'20px',
    marginBottom:'20px'
    
   }));

const Addressskeleton = () => {


    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });

     const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
   

 
  return (
     <>
           

           <Box sx={{width:'100%'}}>
            <Grid container display="flex" justifyContent="center">
            <Grid  item xs={12} sm={isSmScreen ? 12 : 9} md={9}>

        
           
           <div>
          <div className="personalinfo" style={{paddingBottom:'20px'}}><Skeleton width="22vh"/></div>

                  <Item className='global_box_shadow'>
         
                  <div style={{display:'grid', gridRowGap:'6px'}}>
        
        <div className='priceonline'><Skeleton width="20vh"/></div>
        <div className='priceonline'><Skeleton width="20vh"/></div>
        <div className='cash'><Skeleton width="22vh"/></div>

        <div className='cash'><Skeleton width="18vh"/></div>
       
        </div>

          <div style={{display:'flex', justifyContent:'flex-end', marginRight:'10px', gridColumnGap:'20px'}}>

              <Skeleton width="18vh" height="6vh"/>
              <Skeleton width="18vh" height="6vh"/>
           </div>

         </Item>
              
         

          <div style={{display:'flex', justifyContent:'flex-end', marginRight:'10px'}}>
          <Skeleton width="18vh" height="6vh"/>
           </div>
          </div>
          
        
       
                      </Grid>
            </Grid>
            </Box>

     </>
  )
}

export default Addressskeleton


