import React from "react";
import HeaderbookingId from "./HeaderbookingId";
import {createTheme,  useMediaQuery } from '@mui/material';
import Header from "../Header/header";
import Return from "./Return";
import HeaderorderId from "./HeaderoderId";


const Bookingcancel=()=>
{
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
    
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

      
  const  updateLocation=()=>
  {
     
  } 

  const Message=()=>
  {
    
  }


  const handleLoading=()=>
  {
  
  }
  
    return (
        <>
        
        <div className="contain">
          
        {isSmScreen ?    <HeaderbookingId/>: <Header   updateLocation={updateLocation} Message={Message} handleLoading={handleLoading}/>} 
           
            <div className="content"  style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}} >  
            {isSmScreen ? "" : <HeaderorderId/>}       
             <Return/>
             
             </div>
             </div>

        </>
    )
}

export default Bookingcancel;