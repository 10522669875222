import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {ReactComponent as Homedata} from "../assests/Homedata.svg"
import {ReactComponent as Order} from '../assests/Orders.svg';
import {ReactComponent as AvatarLogo} from "../assests/Avatar.svg";
import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router-dom";


import { Bottomnavigatevalue } from '../Redux/action';
import {connect, useDispatch, useSelector } from 'react-redux';


 const Bottomnavigation=({itemAdded})=> {

 const Bottomvalue = useSelector(state=>state.bottomvalue);



 const access= localStorage.getItem("accessToken");

 const businessname=localStorage.getItem('businessname');
  const [value, setValue] = useState(Bottomvalue);
  
  const servicename = localStorage.getItem('productname');

  const categoryname= localStorage.getItem('Categoryname');

  const sanitizedServiceName = servicename?.replace(/ /g, '-');

   const Categoryname=categoryname?.replace(/ /g, '-');

 const dispatch=useDispatch();

 const navigate=useNavigate()

const Home=()=>
{
  navigate(`/${businessname}/`);
}

const Booking=()=>
{
  if(access!==null)
  {
    navigate(`/${businessname}/order-history`);
  }

  else{
    navigate(`/${businessname}/connect`);
  }

}

const Account=()=>
{
  if(access!==null)
  {
     navigate(`/${businessname}/account`)
  }
  else
  {
    navigate(`/${businessname}/connect`);
  }
}

   useEffect(()=>
  {
     dispatch(Bottomnavigatevalue(value));

  },[value])



  return (
      <>
            <div className='bottomNavigation'>

            <Box sx={{ pb: 7 }}  display={{xs:"flex", sm:"flex"}}>
              
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 , zIndex:100}} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Home" onClick={Home} icon={<Homedata/>} />
          <BottomNavigationAction label="My Orders" onClick={Booking} icon={<Order/>} />
          <BottomNavigationAction label="Account" onClick={Account} icon={<AvatarLogo/>} />
        </BottomNavigation>
      </Paper>
     
    </Box>
    </div>
      </>
  
  );
}
const mapStateToProps = (state) => {
  return {
    itemAdded: state.itemAdded,
  };
};


export default  connect(mapStateToProps)(Bottomnavigation);




