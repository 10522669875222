import React, {useState} from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Seater from "../assests/Seater.png";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import {ReactComponent as Settinglogo} from "../assests/Setting.svg";
import {ReactComponent as Packagelogo} from "../assests/Package.svg";
import RemoveIcon from '@mui/icons-material/Remove';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarRatings from 'react-star-ratings';
import {createTheme,  useMediaQuery } from '@mui/material';


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    marginBottom:'20px',
    borderRadius:'6px',
    padding: theme.spacing(1),
  
  }));
 


  function getCurrentDateTime() {
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
  
    const currentDateTime = new Date().toLocaleString('en-US', options);
    const formattedDateTime = currentDateTime.replace(',', '');
    return formattedDateTime;
  }

const  CompletedItem=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const Currenttime= getCurrentDateTime();
    
    const [rating1, setRating1] = useState(0);
    const [starColor1, setStarColor1] = useState('lightgray');

    const handleRatingChange1 = (newRating) => {
     
        if (newRating === rating1) {
  
           const newColor = starColor1 === 'lightgray' ? 'var(--primary)' : 'lightgray';
           setStarColor1(newColor);

         } else {
         
           setRating1(newRating);
           setStarColor1('var(--primary)');
         
         }   
        
      };


     return (
        <>

            <section className="section">

                  <Box sx={{width:"100%"}}>
                  <Grid container rowSpacing={2}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >

                <Grid item xs={12} sm={isSmScreen ? 12 : 7} md={7} >
                       
                       <Item style={{cursor:"pointer"}}>
                       <div>
                            
                       <div className="explore_categorydatas  categoriespickdatas">
                       <div className="explore_image_categorydatas imgdata">
                       <LazyLoadImage src={Seater}  alt="category"  onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}}   />
                       </div>

                       

                       <div className="bookingId-cancelled">
                        <div className="booking-cancelled">
                        <div className="booking_title">Booking ID: 14587</div>
                        <div className="booked">Accepted</div> 
                        </div>
                        <h3 className="text-wrap secondary_title ">Home Cleaning</h3>
                        <div className='step-time'>{Currenttime}</div>
                        
                    </div>
                       </div>

                       <div className="solid_border"></div>
            
            <div className="tracking_service experience-services">
           
            <h1 className="explore_title ">Service Tracker</h1>
            <div className="keyboardup"><KeyboardArrowUpIcon/></div>

            </div>
              
              <div className="close-cancelled experience-servicedata">
                <div className="done-icondata" ><DoneIcon/></div>
                <div>
                    <div className="accounttype  service-cancelled">Your Services is completed</div>
                    <div className='step-time'>{Currenttime}</div>
                </div>
            </div>
         
                  </div>
                       </Item>

                       <Item>
               
                       <div className="experience-services">

                      <h3 className="schedule_title">Your Services Feedback.</h3>
                      <div className="mylocations">
                        <div className="customer-said feedback-review">your feedback for Service</div>
                        <div><KeyboardArrowRightIcon/></div>
                      </div>

                      <div className="mylocations">
                      <StarRatings  rating={rating1} starRatedColor={starColor1}    starEmptyColor="lightgray"   starHoverColor={starColor1}   changeRating={handleRatingChange1}   numberOfStars={5} starDimension="20px"  starSpacing="5px" />
                        <div className="star-service">4.0</div>
                      </div>
 
                      <div className="review_message">I liked the services</div>
                    
                      <div className="explore_image_categorydatas imgdata">
                       <LazyLoadImage src={Seater}  alt="category"   onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}}  />
                       
                       </div>
                       <div className="service-time">Ram kumar, Pune . 9 months ago</div>

                </div>
                       </Item>
                 
                       <Item>
                              
                      <div className="experience-services">
                       <div className="booking_detaildata">
            <h1 className="booking_service">Booking Details</h1>
             
             <div className="packagelogo">
             <div><Packagelogo/></div>
              <div className="booking_title">Order id: 1458795</div>
             </div>

             <div className="packagelogo">
             <div className="fmdgood"><FmdGoodOutlinedIcon/></div>
             <div>
              <div className="booking_title">Home</div>
              <div className="booking_address">415 1St floor <br></br>
                 HSR Layout, Block 5 Krishna street <br></br>
                Bengaluru, Karanataka-560087.</div>
              </div>
             </div>

             <div className="packagelogo">
             <div><Settinglogo/></div>
             <div>
              <div className="booking_title">Home cleaning</div>
              <div className="booking_address">QTY: 2</div>
              </div>
              </div>
              </div>
              </div>

                     </Item>
                     </Grid>

                     <Grid item xs={12}sm={isSmScreen ? 12 : 5} md={5} >
                     <Item >
                        <div className="item_bookingdata">
                 <div className="bill_details">Bill Details (2 services) </div> 
            
              <div className="totalcost">
               <div >Service Bill</div>
               <div className="totalprices"><CurrencyRupeeOutlinedIcon className="currency_rupees"/>4,400</div>
             

              </div>

              <div className="totalcost">
               <div>Service charges</div>
               <div className="totalprices"><CurrencyRupeeOutlinedIcon className="currency_rupees"/>900</div>
            
                
                  
              </div>

              <div className="totalcost">
               <div>Discount</div>
               
              <div className="total_discount"><RemoveIcon className="remove_discount"/><CurrencyRupeeOutlinedIcon className="currency_rupee"/>900</div>
                
                 
             
               
              </div>

              <div className="totalcost">
               <div>Gst</div>
               <div className="totalprices"><CurrencyRupeeOutlinedIcon className="currency_rupees"/>200</div>    
             
              </div>

           <div className="border"></div>              
    
              <div className="totalcost">
               <div className="total_money">Total</div>
               <div className="totalprices"><CurrencyRupeeOutlinedIcon className="currency_rupees"/>4,600</div>
             
              </div>
              </div>
            </Item>


                     
                       </Grid>
                       </Grid>
                       </Box>
                       </section>

        </>
     )
}

export default CompletedItem;

/*  
   <div className="booking_details">
              <div className="bookingid">
               <div className="booking_name">Home Cleaning</div>
               <div className="quantitynumber" >QTY: 2</div>
               <div className='step-time'>{Currenttime}</div>
              </div>

              <div className="booked">Accepted</div>
             </div>

<div className='step-time'>{Currenttime}</div> */