import React, {useState, useEffect } from "react";
import { Box, Grid, createTheme, useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { couponsCode } from "../Redux/action";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom";
import Nocoupon from "../assests/Nocoupon.png";
import Container from '@mui/material/Container';
import Couponskeleton from "./Couponskeleton";



const Applied=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 0,
        sm: 768,
      },
    },
  });

    const Baseurl = process.env.REACT_APP_BASE_URL;
   

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const Item = styled(Paper)(({ theme }) => ({
    
      padding: '20px',
      background: '#FFF',
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
      marginBottom:isSmScreen?'4px':'14px',
      
    
    }));
   

    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');

    const cart =localStorage.getItem('cart')
    const checkout =localStorage.getItem('checkout')
    const payment =localStorage.getItem('payment')

    const [coupons, setcoupons] = useState([]);

    const [loading, setloading]=useState(true);

    const  navigate=useNavigate();

     const dispatch = useDispatch();

     const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));
  
    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

 useEffect(()=>
{
    (async () => {
     
           setloading(true)
      
          let formdata = new FormData();
          formdata.append("business_owner_id", businessId);
          
           let requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };
          
          fetch(`${Baseurl}/consumer/customer-coupon/`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const coupondata=JSON.parse(result);
         
             const latestcoupons = coupondata.coupons;
              setcoupons(latestcoupons);
              setloading(false)
              console.log(latestcoupons)
           
           
            })
            .catch(error => console.log('error', error)); 
        
        
     })();

},[])
 
const apply=(value, couponType)=>
{

   navigate(-1)
   dispatch(couponsCode(value));
   localStorage.setItem('couponType', couponType)
   localStorage.setItem('coupon_id', JSON.stringify(value))
}



    return (
        <>
          
            <section className= {isSmScreen? "slider-header service-margin": "section explore-padding"}>
            <Container maxWidth='lg' className="container-padding">
              <div>

              <div className="breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>

      {(cart==='cart' || checkout==='checkout' || payment==='payment') &&  <StyledNavLink to={`/${businessname}/viewcart`}>
          Cart
        </StyledNavLink>
      }

      {(cart==='cart'&& (checkout==='checkout' || payment==='payment')) &&
        <StyledNavLink to={`/${businessname}/address`}>
          Checkout
        </StyledNavLink>
}
     {(cart==='cart' && checkout==='checkout' && payment==='payment') &&
        <StyledNavLink to={`/${businessname}/payment`}>
          Payment
        </StyledNavLink>
    }
        <StyleNavLink
          to={`/${businessname}/applycoupons`}  
        >
          Coupons
        </StyleNavLink>
      </Breadcrumbs>
      </div>
                  
      {loading ?
          <>
           <Couponskeleton/>
          </>
             
             :
              <Box sx={{width:"100%"}} className={isSmScreen ? {}:"mb_bookmark"}>
          <Grid container rowSpacing={1}   columnSpacing={{xs:2, sm:3, md:4}} display="flex"   >
         
          
           {coupons.length!==0 ? coupons?.map(cur=>
            {
         
                   return (
                    <>
                      <Grid item md={12} xs={12} sm={12}>
                       <Item className="global_box_shadow">
                         <div  className="coupon_data">
             <div className="coupon_applied" >
             
              <div className="coupon_image"><LazyLoadImage src={cur.bo_coupen_image_url}  alt="logo"   onError={(e)=> {e.target.src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image'}} /></div>
              <div className="coupon_applydata">
                <div className="coupon_title">{cur.bo_discount_title}</div>
                <div className="coupon_description">{cur.bo_discount_description}</div>
                <div className="apply_coupon">
                <div className="coupon_code">{cur?.bo_coupen_code}</div>
                <div ><button className="applydatacoupons" onClick={()=>apply(cur?.bo_discount_price, cur?.bo_coupan_type)}>APPLY</button></div>
                </div>
              </div>
             </div>
            
             </div>
             </Item></Grid>
                    </>
                   )
            }           
         )
              :
              <Grid item md={12} xs={12} sm={12}>
                   <Item className="global_box_shadow">
                <div className="empty_data">
                <div><LazyLoadImage  src={Nocoupon} alt="nocoupon" /></div>
                <span className="label">No coupon available</span>
                </div>
              </Item>
              </Grid>

    }


          
          </Grid>
          </Box>
}
          </div>
          </Container>
          </section>

  
        </>
    )
}

export default Applied;