import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";


const Headerorder=()=>
{

     const navigate = useNavigate();
    

  
  const businessname=localStorage.getItem('businessname');

  const Back=()=>
  {
       navigate(`/${businessname}/`);
  }

  const Homedata=()=>
  {
    navigate(`/${businessname}/`);
  }

    return (
        <>
         
          <div className="secondary-header mobile-header">

               
         
             <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            style={{color:"black"}}
         
          >
            <ArrowBackIcon    onClick={Back} />
          </IconButton>
        
         <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', width:'100%'}}>
         <div className="schedule-online">
         <h1 onClick={Homedata}>Home</h1>
        </div> 
        </div>


        </div>
         

        </>
    )

}



export default Headerorder