import React, {useState, useEffect} from "react";
import OtpInput from "otp-input-react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import Edit from "../assests/iconpencil.png"
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { connect, useSelector,useDispatch } from 'react-redux';
import { Handlemycheckout, Handlemylocal, UpdateName, resetItemAdded } from "../Redux/action";

const Verifycode=()=>
{

  const Loginitem=
  {
    backgroundColor:'var(--primary)',
    color:'var(--white)',
    fontWeight:500,
    textTransform:'none',
    width:'100%',
    letterSpacing: '0.8px',  
    '&:hover': {
      backgroundColor: '#57339F', 
    },
  }

    const [OTP, setOTP] = useState("");
    const [error, setError]=useState(false);

    const [profile, setprofile]=useState(false)

    const access=localStorage.getItem('accessToken')

    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');
    
    const Baseurl = process.env.REACT_APP_BASE_URL;
  
    const [show, setShow] = useState(true);

    const timerStarted = false;
 
    const dispatch = useDispatch()

    const verifyMobile= useSelector(state=>state.phone)
    const selectedName = useSelector(state=>state.Name);

    const [time, setTime] = useState(30);
    const navigate =useNavigate();

    useEffect(() => {
      const intervalId = setInterval(() => {
        setTime(prevTime => {
          if (prevTime === 0) {
            setShow(false);
            clearInterval(intervalId); 
            return  0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000); 

      return () => clearInterval(intervalId);
    }, []); 
  
    const minutes = Math.floor(time / 60); 
    const seconds = time % 60;

   const Editable=()=>
   {
        navigate(-1);
   }


    const Home=()=>
    {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}`);

      let formdata = new FormData();
     
      formdata.append("otp_code", OTP);
      formdata.append("business_owner_id", businessId);
      
        let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/verify-customer-otp/`, requestOptions)
        .then(response => response.text())
        .then(result => {
          
          const data= JSON.parse(result);
          
          if(data.status==="success")
          {
            console.log(data);
               setprofile(true)
               dispatch(Handlemycheckout(false))
          }

          else
          {
             setError(true);
             setOTP("");
          }
          
          })
        .catch(error => console.log('error', error)); 


    }

    const Resend=()=>
    {
      setOTP("");
      setTime(30) ;
      setShow(true);
      const intervalId = setInterval(() => {
        setTime(prevTime => {
          if (prevTime === 0) {
            setShow(false);
            clearInterval(intervalId); 
            return  0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000); 

      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}`);

     let formdata = new FormData();
     formdata.append("business_owner_id", businessId);

     let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${Baseurl}/consumer/customer-otp-send/`, requestOptions)
           .then(response => response.text())
          .then(result=>{
           const data= JSON.parse(result);
           console.log(data.otp);
           

     })
         .catch(error => console.log('error', error));
    
    }


    const Back=()=>
    {
           navigate(-1);
    }


    useEffect(()=>
    {
     
      const access= localStorage.getItem("accessToken");

   
      if(profile===true)
      {
      if(access!==null)
      {
  
      (async () => {
  
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
  
        let formdata = new FormData();
        formdata.append("business_owner_id", businessId);
      
        let requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
          redirect: 'follow'
     };
     
      fetch(`${Baseurl}/consumer/customer-profile/`, requestOptions)
       .then(response => response.text())
       .then(result => {
         const data=JSON.parse(result);
         const Updateprofile=data.data;
         dispatch(UpdateName(Updateprofile.c_name.split(' ')[0]));
         if(data.status==="success")
         {
            setError(false);
            navigate(-1);
            navigate(-1);
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);
      
           let requestOptions = {
            method: 'GET',
            headers: myHeaders
          };
          fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
          .then(response => response.text())
          .then(result => {
            const checkout=JSON.parse(result);
      
            const  Categorydata = checkout.cart_data;
            
           

            if(Categorydata.length===0&& checkout.status==="success")
            {
               console.log('checkout')
            
               const updatedCartItems =JSON.parse(localStorage.getItem('updatedCartItems'))
                if(updatedCartItems.length!==0)
                {
                      dispatch(Handlemylocal(true))
                }
                else
                {
                  resetItemAdded();
                }
             
            }
           
          })
          .catch(error => console.log('error', error)); 
            
         }
     
       })
       .catch(error => console.log('error', error));
     
   })();
      }
    }
  
    },[access,profile])

    return(
        <>
          

          <div className="section-content section_container">
               <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} display="flex" justifyContent="center">
          <Grid item xs={11} sm={6} md="none" >
         
          <div style={{position:"static" ,marginTop:"-22px"}}>
        <div>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 ,mb:2}}
            style={{color:"black"}}
          >
            <ArrowBackIcon  onClick={Back}/>
          </IconButton>
        
        </div>
      </div>
       
          <div>
          <div className="head_title" style={{paddingBottom:"20px"}}>OTP</div>
                     
                     <div className="secondary">
                     We have sent an verification code to your mobile number
                     </div>

                     <div className="number_login" style={{padding:"12px 0px"}}>
                     <span className="number">{verifyMobile}</span>
                     <span><img className="icon" src={Edit} alt="icon"  onClick={Editable}/></span>
                     </div>
                     </div>
                     <div className="otpread "><OtpInput  className="otpinput" value={OTP} onChange={setOTP} autoFocus OTPLength={5}  otpType="number"  disabled={false} secure  /></div>
  
                        <div className="timer">
                       {error && <div className="already_otp">Enter a  valid OTP.</div>}
                       
                         
                       <div className="timer_restart">
                      {show &&  <div className="resend_otp">0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>}
                        <button className="resend_otpcode" onClick={Resend} disabled={seconds>0} >Resend OTP</button>
                        </div> 

                        </div>
                    
                      <div className="enterotp">
                        
                      <Button style={Loginitem}  className="secondary_button submission" onClick={Home}>Submit</Button>
                    
                      </div>
                     
              
                      
                      </Grid>
                       
                       

                  </Grid>
                  </Box>
                
                 
                 </div> 



        
        </>
    )
}


const mapDispatchToProps = {
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Verifycode);

/* 
          var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZW1iZXJfaWQiOjEsInVzZXJfdG9rZW4iOiJsdm5kbWh5Z2tnemZvaHhiZGZwbGx4cWhja2pzaGkiLCJsb2dpbl93aXRoIjoibW9iaWxlIiwiY3JlYXRlZF9vbiI6IjIwMjMtMDQtMjQgMTI6MTI6NTQuMTIwOTIyIiwiZXhwaXJpbmdfb24iOiIyMDIzLTA1LTE0IDEyOjEyOjU0LjEyMDk0NiJ9.LFp7KfCrKfLv8inKNRyNPujsfbin6nhouSXf6-s9OIk");
            

            
               
           */