import React, { useEffect,  useState } from "react";
import { Box, Grid, Typography, createTheme, useMediaQuery } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ProfileDetailskeleton from "./Profileskeleton";
import Footer from "../Footer/Footer";
import Header from "./header";
import Bottomnavigation from "../Home/BottomNavigation";
import Modal from '@mui/material/Modal';
import Resizer from 'react-image-file-resizer';
import SearchFilter from "./SearchFilter";
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, deleteImage, UpdateName, mobileNumber, Bottomnavigatevalue, Handlemyprofile } from '../Redux/action';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Headerprofile from "./Headerprofile";
import { useNavigate } from "react-router-dom";


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    
   }));
 

const ProfileDetail=()=>
{


  const dispatch = useDispatch();
  const navigate = useNavigate();
  dispatch(Bottomnavigatevalue(3));


    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });

      const save_data={
        backgroundColor:'var(--primary)',
        color:'var(--white)',
        fontWeight:'500',
        textTransform: 'none',
        marginBlock:'10px',
        fontSize:'16px',
      }

      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

      const access= localStorage.getItem("accessToken");
      const businessId=localStorage.getItem('storeId');
      const businessname=localStorage.getItem('businessname');

      const Baseurl=process.env.REACT_APP_BASE_URL;

      const [fileInput,setfileInput] = useState();

      const [profile, setprofile]=useState(null);

      const [name, setname]=useState();
      const timerStarted = false;

      const [buttonClicked, setButtonClicked] = useState(false);
  

      const [show1, setShow1] = useState(true);
   
      const [open, setOpen] = useState(false);
      const [isLoading, setIsLoading] = useState(true);
 
      const [emailvalid, setemailvalid] = useState(false);

      const [fnamevalid,setfnamevalid] = useState(false);
   
     // const [name,]

      const [loadingprofile, setloadingprofile] = useState(true);
      const selectedImage = useSelector((state) => state.image);
      
      const Myprofile = useSelector(state=>state.profile);

      const  selectedLoading = useSelector(state=>state.Loading);
    

      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });


     
    const [openSnackbar1, setopenSnackbar1]=useState({

    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };

  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;


   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

  const [openSnackbar3, setopenSnackbar3]=useState({

    opentoast1: false,
    verticaltoast1: 'top',
    horizontaltoast1: 'center',
   });

  const { verticaltoast1, horizontaltoast1, opentoast1 } = openSnackbar3;

  const handleCloseToast1 = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar3({...openSnackbar3, opentoast1:false});

  };

       const [input, setinput]=useState(
        {
            c_name:"",
            c_email:"",
            c_gender:"",
            
        }
       )

  

  
      const [newState, setnewStatte]=useState();


   
      
    

      const [Update, setUpdate] =useState();
  
      const [UpdateMessage,setUpdateMessage]=useState()

 
     
       const updateLocation=(locationdata)=>
    {
      setUpdate(locationdata);
   
    }
  
  const Message=(success)=>
  {
      setUpdateMessage(success);
  
  }
    
   
   
      const onFileChange=async(e,newStated)=>
      {
         
           setfileInput(e.target.files[0]);
           setname(e.target.files[0].name);
           console.log(e.target.files[0].name);
          
           const file = e.target.files[0];

           Resizer.imageFileResizer(
            file,
            300, 
            300, 
            'JPEG', 
            70, 
            0, 
            (compressedFile) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                const compressedDataURL = reader.result;

                dispatch(uploadImage(compressedDataURL));

                console.log(selectedImage);
                
              };
               reader.readAsDataURL(compressedFile);
            },
            'blob' 
          );
             setnewStatte(newStated)
           setOpen(false);
      }

     useEffect(()=>
     {
      const apiUrl13 =`${Baseurl}/consumer/customer-profile-upload/`;

      if(name && fileInput)
      {  
      
      let formdata = new FormData();
      formdata.append("business_owner_id", businessId);
      formdata.append("profile_picture",fileInput,name);

      fetch(apiUrl13,
      {
        method:'POST',
        headers: {
          'Authorization' : `Bearer ${access}`
         },
         body: formdata,
      }).then(response => response.text())
      .then(result=> {

        const data= JSON.parse(result);
        if(data.status==='success')
        {
          setopenSnackbar3({ ...newState, opentoast1: true });
        }
      })
    }
     },[fileInput, name]) 

   
      const handleOpen=()=>setOpen(true);
     const handleChange=(e)=>
     {
         const name=e.target.name;
         const value=e.target.value;

         setinput({...input, [name]:value});
     } 

    

     const cancel=()=>
     {
        setOpen(false);
     }

    const remove=(newState)=>
    {
     
        dispatch(deleteImage());
        setOpen(false);
      
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}` );
        let formdata = new FormData();
     
        formdata.append("business_owner_id", businessId);
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
      
      fetch(`${Baseurl}/consumer/customer-profile-delete/`, requestOptions)
        .then(response => response.text())
        .then(result => {

            const Update=JSON.parse(result);
            
            console.log(result);
        
             if(Update.status==="success")
             {
              setopenSnackbar1({ ...newState, openSnackbar: true });
             }
           

          })
        .catch(error => console.log('error', error));

    }
  
   

     const edit1=()=>
     {
     
         setShow1(false);
        
     }


     const cancel1=()=>
     {
      setShow1(true);
     }
     const Save1 =(newState)=>
     {
          
           const newfname=input.c_name;
         
          if(newfname?.length===0)
          {
               setfnamevalid(true);        
          }

          else 
          {

            setfnamevalid(false);
           
          
           const newemail=input.c_email;

          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

          
          if (emailPattern.test(newemail)) {
            
            setemailvalid(false);

               
               

               (async () => {
 
     
                if(buttonClicked===true || access!==null)
                {
             
             
                if(access!==null)
                {
                 
          
                let myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${access}` );
                 
                 let formdata = new FormData();
                 formdata.append("name", input.c_name );
                 formdata.append("email", input.c_email );
                 formdata.append("gender", input.gender);
                 formdata.append("business_owner_id", businessId);
                  
                 let requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: formdata,
                  redirect: 'follow'
                };
                
                fetch(`${Baseurl}/consumer/customer-update/`, requestOptions)
                  .then(response => response.text())
                  .then(result => {
          
                    const Updatedata=JSON.parse(result);
                    
                  
                    if(Updatedata.status==="success")
                    {
                      setopenSnackbar2({ ...newState, opentoast: true });
                      setButtonClicked(true);
                      setloadingprofile(true);
                      setIsLoading(true);
                      if(!Myprofile)
                      {
                        setShow1(true);
                      }
                      
                   if (!timerStarted && Myprofile) {

                    const timer2 = setTimeout(() => {
                      dispatch(Handlemyprofile(false))
                      navigate(`/${businessname}/address`)
                      
                    }, 1200);
                    return () => clearTimeout(timer2);
                         
                }

                    }
              
                  })
                  .catch(error => console.log('error', error));
                } 
              }
           })();  
               
   
           } else {
             
             setemailvalid(true);
             
           }
           
          }

     }




 useEffect(()=>
 {
     const apiUrl12=`${Baseurl}/consumer/customer-profile/`;
    
     const fetchData = async ()=>
     {
         try 
         {

          if(buttonClicked===true || access!==null)
          {
            if(access!==null)
            {   
              setloadingprofile(true);

           
              let formdata = new FormData();
              formdata.append("business_owner_id", businessId);

            const response= await fetch(apiUrl12, 
              {
                method:'POST',
                headers: {
                  'Authorization' : `Bearer ${access}`
                 },
                 body: formdata,
              });
            const Update = await response.json();
            const Updateprofile=Update.data;

            setprofile(Updateprofile);
            setinput(Updateprofile);
           dispatch(UpdateName(Updateprofile.c_name.split(' ')[0]));
            console.log(Updateprofile);
            if(Updateprofile.c_profile_picture!==null)
            {
              dispatch(uploadImage(Updateprofile.c_profile_picture));
            }
          
            setButtonClicked(false)
            setloadingprofile(false);
            setIsLoading(false);
     
            }}

         } catch (error) {
          console.log(error);
          setIsLoading(false);
     }
     };

     if (isLoading) {
      fetchData();
    }

 },[buttonClicked, access,isLoading,name,fileInput,loadingprofile])

 
  
  const handleLoading=()=>
  {
  
  }

     return (
        <>

                <div className="contain">
              {isSmScreen ? <Headerprofile/> :      <Header   updateLocation={updateLocation} Message={Message} handleLoading={handleLoading} />}
                <div className="content">


             <section className= {isSmScreen ? "section mt-20" : "section"}>

             <Container maxWidth="lg">

             {loadingprofile ?
               
               <>
               <ProfileDetailskeleton/>
               </>
            
            :


             <div className="explore_container">
              <Box sx={{width:"100%"}}>


          <Grid container rowSpacing={2}  columnSpacing={{xs:2, sm:1, md:4}} display="flex" justifyContent="space-between">
          <Grid item xs={12} sm={isSmScreen ? 12 : 3} md={3}>
           <Item className="avatar_profile">
      


          {profile && <label onClick={handleOpen}><Avatar alt="avatar"  src={selectedImage} className="avatar_photo"/></label>}

        
           <div className="hello">Hello</div>
           </Item>
          
            
            <Modal open={open}>
                <div className="updateprofile">
     
          <div>
           <div className="change_profile">Change Profile Photo</div>
           <div><hr style={{color:"var(--stroke)", opacity:"0.5", width:"44vw"}}></hr></div>


           <input type="file" id="fileInput"  name="profile" className="update_photo" onChange={(e)=>onFileChange(e, { vertical: 'top', horizontal: 'center' })}/>
           <label   htmlFor='fileInput' className="upload_photo"  >Upload Photo</label>

           <div><hr style={{color:"var(--stroke)", opacity:"0.5",  width:"44vw"}}></hr></div>

           <div className="remove_photo" onClick={()=>remove({ vertical: 'top', horizontal: 'center' })}>Remove Current Photo</div>
           <div><hr style={{color:"var(--stroke)", opacity:"0.5",  width:"44vw"}}></hr></div>

           <div className="cancel" onClick={cancel}>Cancel</div>

         </div>
            

        </div>
 
            </Modal>  
         

          </Grid>

          <Grid item xs={12} sm={isSmScreen? 12:9} md={9}>
           <Item>
            <form>
             <div className="personalinfo">Personal Info</div>
             
              <Box component="form" sx={{ '& > :not(style)': { m: 1,},  }} noValidate  autoComplete="off" className="form-input" >
              <div className={show1 && "edit_data"}>
              {show1 && <label htmlFor="personal"  className="label">Name</label>}
              {show1 ? <div className="edit" onClick={edit1}>Edit</div> :<div className="edit" style={{display:"flex", justifyContent:"end"}} onClick={cancel1}>Cancel</div> }
              </div>
         
          {show1 ? 
              <div>
            {input &&(input.c_name==="" || input.c_name===undefined) ?  (<div className="details">Your Full Name</div>) :  (<div className="details">{input.c_name}</div> )}
              <div className="hrdata"><hr style={{color:"var(--stroke)", opacity:"0.5"}}></hr></div>
              </div>

                 :  
              
              <div>
             
              <TextField id="first_name" label="Name" value={input.c_name}   name="c_name"        onChange={handleChange} className="text_field" />
              {fnamevalid &&  <div><label className="validemail field_name">Name is required</label></div>}
              </div>
  
          }

             <div>
             
             {show1 &&    <label htmlFor="gender" value={input.c_gender} className="label">Your Gender</label> }
             <RadioGroup  name="gender"  onChange={handleChange} >
                <div className="gender">
               <FormControlLabel value="Male"  checked={input.c_gender === 'Male'}  disabled={show1} control={<Radio style={{ color: input.c_gender === "Male" ? 'var(--primary)' : '' }}  />} label=<Typography style={{fontSize:"14px"}}>Male</Typography>  />
               <FormControlLabel value="Female"   checked={input.c_gender === 'Female'} disabled={show1} control={<Radio style={{ color: input.c_gender === "Female" ? 'var(--primary)' : '' }}  />} label=<Typography style={{fontSize:"14px"}}>Female</Typography>  />
               </div>
              </RadioGroup>
              </div>
      
              <div className="edit_data">
             {show1 && <label htmlFor="email" className="label">Email Address</label>}
 
             </div>
  
              {show1 ? 
             <div>
            {input && (input.c_email==="" || input.c_email===undefined) ?   <div className="details">Your Email Address</div> :  <div className="details">{input.c_email}</div>  }
              <div className="hrdata"><hr style={{color:"var(--stroke)", opacity:"0.5"}}></hr></div>
              </div>
                  
                  :
                <div>
              <div><TextField id="email" type="email" label="Email Address" value={input.c_email}  name="c_email" onChange={handleChange} className="text_field"  /></div>
              {emailvalid &&  <div><label className="validemail">Enter a valid email</label></div>}
      
              </div>
              }

             
              {show1 &&
                <>
               <div className="edit_data">

               <label  className="label">Mobile Number</label>
               </div>
             <div>
              <div className="details">{input.c_mobile_no}</div>
              <div className="hrdata"><hr style={{color:"var(--stroke)", opacity:"0.5"}}></hr></div>
              </div>
              </>
                 
              }

                
              </Box>
              {!show1 &&
              <div className="save_profile">
          <Button style={save_data}  className="secondary_button" onClick={()=>Save1({ vertical: 'top', horizontal: 'center' })}>Save</Button></div>}
            </form>
                   
                  

           </Item>

          </Grid>

          </Grid>
          </Box>

                        <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                     <Alert  severity="success" sx={{ width: '100%' }}>
                           Profile picture  successfully deleted !
                           </Alert>
                      </Snackbar>

                    </Stack>   

                   <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                     <Alert  severity="success" sx={{ width: '100%' }} >
                         Profile updated successfully !
                           </Alert>
                      </Snackbar>

                    </Stack>

                     <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast1} autoHideDuration={2000}  key={verticaltoast1 + horizontaltoast1} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast1}>
                     <Alert  severity="success" sx={{ width: '100%' }} >
                         Profile picture updated successfully !
                           </Alert>
                      </Snackbar>

                    </Stack>


          </div>
             }
      
     
    
           </Container>
             </section>
            
             </div>
             <Footer/>
             
     </div>
     
        </>
     )
}


export default ProfileDetail; 


/*      

/*
  useEffect(()=>
  {

    (async () => {
    
    if(access!==null)
    {  

      if(name && fileInput)
      {  
  
       
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${access}` );

    let formdata = new FormData();
     formdata.append("profile_picture",fileInput,name);
  
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
    
     fetch(`${Baseurl}/upload-profile-picture/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data=JSON.parse(result);
         
        console.log(result);

      })
      .catch(error => console.log('error', error));
    
    }
  
}
})();

  },[name,fileInput])  


  useEffect(()=>
  {
      (async () => {
    if(buttonClicked===true || access!==null)
    {
      if(access!==null)
      {
          

      setloadingprofile(true);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}` );
    
      let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(`${Baseurl}/profile/`, requestOptions)
      .then(response => response.text())
      .then(result => {

          const Update=JSON.parse(result);
          const Updateprofile=Update.data;
          setprofile(Updateprofile);
          setinput(Updateprofile);
          
          console.log(Updateprofile);
          setButtonClicked(false)
          setloadingprofile(false);
               
        })
      .catch(error => console.log('error', error));

      }
    }
  })();
  },[buttonClicked, access])
  



              


  {preview && (
        <AvatarEditor
          width={150}
          height={150}
          border={50}
          color={[255, 255, 255, 0.6]} // Color of the crop area border
          scale={1.2} // Scale factor for zooming
          rotate={0} // Rotation angle
          borderRadius={75} // Border radius of the cropped image
          image={preview}
          onCrop={handleCrop}
          style={{ marginTop: '20px' }}
        />
      )}
     


export default ProfileDetail;
*/


