import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Container from '@mui/material/Container';

const  Footer=() => {
  
    
  const currentyear = new Date().getFullYear();

  

  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/');
  const encodedPart = pathSegments[1]; 
  const decodedPart = decodeURIComponent(encodedPart);
  const businessname=localStorage.getItem('businessname');
  

  
    return (
      <footer  className="footer_resource">
       <div className="footer_address">
       <Container maxWidth='lg' className="p-16">
        <div className="footer_right">
       <div >
          <span className="footer_title">©</span>
          <span className="footer_title">{currentyear}</span>
        {decodedPart===""?<span className="footer_title"> Store.</span> :<span className="footer_title"> Parashar Creative Studio | </span>}
          <span className="footer_title">All right reserved.</span>
          </div>

          <div className="footer_pagargraph">
         {decodedPart===""?  <span className="footer_title"><NavLink to="/" className="footer_link">Privacy Policy</NavLink></span> :   <span className="footer_title"><NavLink to={`/${businessname}/privacy-policy`} className="footer_link">Privacy Policy</NavLink></span>}
         {decodedPart===""? <span className="footer_title"><NavLink to="/" className="footer_link">Terms and Conditions</NavLink></span> :   <span className="footer_title"><NavLink to={`/${businessname}/terms-and-conditons`} className="footer_link">Terms and Conditions</NavLink></span>}
         {decodedPart===""? <span className="footer_title"><NavLink to="/" className="footer_link">Shipping Policy</NavLink></span> :   <span className="footer_title"><NavLink to={`/${businessname}/shipping`} className="footer_link">Shipping Policy</NavLink></span>}
         {decodedPart===""? <span className="footer_title"><NavLink to="/" className="footer_link">Refund Policy</NavLink></span> :   <span className="footer_title"><NavLink to={`/${businessname}/refund-and-cancellation-policy`} className="footer_link">Refund Policy</NavLink></span>}
         {decodedPart===""? <span className="footer_title"><NavLink to="/" className="footer_link">Return Policy</NavLink></span> :   <span className="footer_title"><NavLink to={`/${businessname}/return-policy`} className="footer_link">Return Policy</NavLink></span>}
            </div>
            </div>
            </Container>
          </div>
         
      </footer>
    );
    
  }
  
  export default Footer;

  /*
          
      <div className="footer_title"><NavLink to='/shipping-policy' className="footer_link">Shipping Policies</NavLink></div>


        <Box sx={{ bgcolor: '#f5f5f5', py: 5, width:"100%"}}>
   
        <Grid container spacing={2}  >
        <Grid item  sm={3.8} md={4}>
        <Paper style={{ height: '100%', display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"start", backgroundColor:"transparent", boxShadow:"none"}}>
          
          <div className="title resources">Services</div>
          <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Painting</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Appliance Repair</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Plumbering</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Home Cleaning</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Pest Control</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Netting</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Interior Design</div></NavLink>
               <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Electrical Service</div></NavLink>
    

          </Paper>
        </Grid>
        <Grid item xs={12} sm={3.8} md={4}>
        <Paper style={{ height: '100%', display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"start", backgroundColor:"transparent", boxShadow:"none"}}>
         

       
        <div className="title resources " >Resources</div>

        <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Home</div></NavLink>
                <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Contact us</div></NavLink>
                <NavLink to="/home" className="footer_title"><div className="footer_pagargraph">Blog</div></NavLink>

          </Paper>
        </Grid>
        <Grid item xs={12} sm={3.8} md={4}>
        <Paper style={{ height: '100%', display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"start", backgroundColor:"transparent", boxShadow:"none"}}>
         
        
        <div className="title resources">Address</div>

        <address className="footer_address">Nanorama Technologies Pvt Ltd
                     <br></br>   447/A, 9th Cross,
                       <br></br>  2nd Phase, JP Nagar,
                     <br></br>    Bangalore 560078, India</address>
                     <NavLink className="footer_title">
                      <div className="phone_icon">
                        <PhoneIcon/>
                        <div>+91 9876543210</div>
                        </div>
                     </NavLink>

                     <NavLink className="footer_title">
                      <div className="phone_icon">
                        <EmailIcon/>
                        <div>contact@lavendel.co.in</div>
                        </div>
                     </NavLink>
              
          </Paper>
        </Grid>
      </Grid>
            

         
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, paddingTop:"2%" }}>
              <IconButton aria-label="Facebook" sx={{ mr: 1 }}>
                <Facebook />
              </IconButton>
              <IconButton aria-label="Twitter" sx={{ mr: 1 }}>
                <Twitter />
              </IconButton>
              <IconButton aria-label="LinkedIn" sx={{ mr: 1 }}>
                <LinkedIn />
              </IconButton>
              <IconButton aria-label="Instagram">
                <Instagram />
              </IconButton>
            </Box>
            <Typography variant="body1" color="#2d2d2d" align="center">
              &copy; {new Date().getFullYear()} Example Company. All rights reserved.
            </Typography>
          
            
            </Box>    



               
               
          
            */