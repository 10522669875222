import React,{useState}  from "react";
import Orders from "./Orders";
import Headerbooking from "./Headerbooking";
import Bottomnavigation from "../Home/BottomNavigation";
import { Bottomnavigatevalue } from "../Redux/action";
import { useDispatch } from "react-redux";
import Footer from "../Footer/Footer";
import {createTheme,  useMediaQuery } from '@mui/material';
import Header from "../Header/header";
import Categoryheader from "../Header/Categoryheader";



const Booking=()=>

{

    const dispatch= useDispatch();

    dispatch(Bottomnavigatevalue(1));

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
    
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

      const Categorydata=JSON.parse(localStorage.getItem('Allcategorydata'))
 
    
    return (
        <>
             <div className="contain">
          {isSmScreen ? <Headerbooking/> : <Header />} 
          {!isSmScreen && <Categoryheader  value={Categorydata}/>}
                     
            <div className="content" style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}} >

             <Orders/>
         
             </div>
             <Footer/>
             </div>
         
        </>
    )
}

export default Booking;