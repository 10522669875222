import React, { useState, useEffect } from "react";
import { Box, Grid, createTheme, useMediaQuery } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { NavLink, useNavigate } from "react-router-dom";
import StarRatings from 'react-star-ratings';
import Ratingskeleton from "./Ratingskeleton";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import AppsIcon from '@mui/icons-material/Apps';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from '@mui/material/Container';
import { LazyLoadImage } from "react-lazy-load-image-component";


const Ratings=({reviewed,datarating, loading})=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    zIndex:-999,
    marginBottom:isSmScreen? "0px":"40px",
    
  }));

  const Items = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    zIndex:-999,
    padding:'20px',
    marginBottom:isSmScreen? "0px":"40px",
    
  }));


    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);
    const [progress3, setProgress3] = useState(0);
    const [progress4, setProgress4] = useState(0);
    const [progress5, setProgress5] = useState(0);
    const [review, setReview] = useState('');
    const [show, setShow] = useState(false);
    

    const navigate = useNavigate();

    const subsetOfData = datarating?.slice(0, 3);
    const businessname=localStorage.getItem('businessname');

    const [selectedImage, setSelectedImage] = useState(null);
  
    const [selectedReview, setSelectedReview] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [open, setOpen] = useState(false);
  
 console.log(datarating)
   

    useEffect(() => {

    
          

        const fetchProgressFromAPI = async () => {
          try {
  
            const fetchedProgress = reviewed?.total_count; 
        
            setProgress1((reviewed?.separated.rate_5/fetchedProgress)*100);

            setProgress2((reviewed?.separated.rate_4/fetchedProgress)*100);
            setProgress3((reviewed?.separated.rate_3/fetchedProgress)*100);
            setProgress4((reviewed?.separated.rate_2/fetchedProgress)*100);
            setProgress5((reviewed?.separated.rate_1/fetchedProgress)*100);

            

            setShow(true);


          } catch (error) {
            console.error('Error fetching progress:', error);
            setShow(false);
          }
        };
    
        fetchProgressFromAPI();
      
      }, [progress1, progress2, progress3, progress4, progress5,reviewed]);

      const customStyles = {
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'green', 
        },
      };
      const customStyles2 = {
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'orange', 
        },
      };

      const customStyles3 = {
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'red', 
        },
      };
 

      const reviewall=()=>
      {
            
        navigate(`/${businessname}/review`)

      }

      const picture=(Id, index)=>
      {
          setOpen(true);
          setSelectedImage(Id);
          setSelectedImageIndex(index)

          const review = subsetOfData.find((cur) => {
            const reviewImagesString = cur?.c_review_images.replace(/'/g, '"');
            const reviewImages = JSON.parse(reviewImagesString);
            return reviewImages.some((img,index) => cur.pk===Id);
          });
          
      
          const reviewImagesString = review?.c_review_images.replace(/'/g, '"');
          const reviewImages = JSON.parse(reviewImagesString);
          
        setSelectedReview(reviewImages);
      }
      const handle=()=>
      {
        setOpen(false);
        setSelectedImage(null);
      }
     

   return (
    <>   
         
         
             {loading ?  
            <>
         <section className= {isSmScreen? "slider-header service-margin": "section1 explore-padding"}>
            <Container maxWidth="lg" className="p-16" >
            <Items className="global_box_shadow">
            <h1 className=" head_title explore">Ratings & Reviews</h1>
            <Ratingskeleton/>
            </Items>
            </Container>
            </section>
            </>
            
            :
            
         <section className= {isSmScreen? "slider-header service-margin": "section1 explore-padding"}>
            <Container maxWidth="lg" className="p-16" >
             <Item className="global_box_shadow">
           
             <div className="item_additional">
             <div className="item_review">
          <h1 className="head_title explore">Ratings & Reviews</h1>
         
        {!isSmScreen &&   <NavLink  className="view-all" to={`/${businessname}/review`} onClick={reviewall}>Show more ({reviewed?.total_count})</NavLink>}
              </div>
           
          <Box sx={{width:"100%"}}   >
          <Grid container rowSpacing={2}  columnSpacing={{xs:2, sm:3, md:4}} display="flex" alignItems="center" padding="10px 0px">
       
         <Grid item className="rating_review_datas" >
         <div >
           <div className="rating_average_value">{reviewed?.average}</div>
           <div className="star_value_data">
           <StarRatings rating={reviewed?.average}   starRatedColor="rgb(2, 74, 2)"  numberOfStars={5}  name='rating'  starDimension="15px"   starSpacing="4px"   />
           </div>
           </div>

         </Grid>



           <Grid item xs={5}  sm={isSmScreen ? 6 :'none'} md="none" className="reveiwing_data">

           <div className="rating_review_data">
           <div className="rating_average_value">{reviewed?.average}</div>
           <div className="star_value_data">
           <StarRatings rating={reviewed?.average}   starRatedColor="rgb(2, 74, 2)"  numberOfStars={5}  name='rating'  starDimension="15px"   starSpacing="4px"   />
           </div>
           </div>

           </Grid>
          

          </Grid>
        </Box>
         
          <Box sx={{width:"100%"}}>
         
          <span className="customer-said">Customer Reviews</span>
          <Grid container>

          <Grid item xs={12} sm={12} md={12} >
        
         <div className="review_data">

          {subsetOfData && subsetOfData?.map((cur, Index)=>
          {
            const reviewImagesString = cur?.c_review_images?.replace(/'/g, '"');
            const reviewImages = reviewImagesString&& JSON.parse(reviewImagesString);

            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const date = new Date(cur.updated_on);
            const formattedDate = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
               
              return (
                <>

                    <div className="overall_review">
                    
                    <div className="overall_caption">
                    <div className="review_message">{cur.c_review_message}</div>
                    <div className="overall_starrating">
                    <div>{cur.c_rating}</div>
                    <StarIcon  className="starvalues"/>
                 </div>
                    
                    </div>
                                 
                  <div  className="image_data">
                  {reviewImages && reviewImages.length > 0 ? (
                    reviewImages.map((image, index) => (
                      <div onClick={() => picture(cur._id, index)} key={index}>
                        <img
                          className="image_picture"
                          src={image}
                          alt="category"
                          onError={(e) => {
                            e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png";
                            e.target.alt = 'No Image';
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      <img
                        className="image_picture"
                        src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                        alt="No Image"
                      />
                    </div>
                  )}
                     </div>
                     
                     <div className="reviewed_on">Reviewd on {formattedDate}</div>
                    </div>
                </>
              )

          })}
          { selectedImage && <Modal open={Boolean(selectedImage)}>
                   
                   
                   <div className="gallery_imagedata">
                      
                      <div className="view_image_gallery">
                      <div>
  
                         <h3 className="secondary_title">Review Image</h3>  
                      </div>
                     
                      <div className="right_images">
                     <div className="closeicon" ><CloseIcon style={{transform:"scale(1.2)"}} onClick={handle}/></div> 
                      </div>
                      </div>

                                <div className="review_imageshow">
                                <Carousel interval={3000} showThumbs={false} showStatus={false} infiniteLoop={true}>
                                
                                {selectedReview.map((image, indeximage) => (
                                           
                                    <div key={indeximage} className="image_datashow">
                                      <img
                                        className="image_datas"
                                        src={image}
                                        alt={`Review Image ${indeximage}`}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  ))}
                                 
                                  </Carousel>

                                 </div>
                                
                                 </div>
                            
                   

                              
                          
                 
                   </Modal>
                   
                 
                   }
                   
                 
          </div>
          
          {isSmScreen &&   <NavLink  className="view-all" to={`/${businessname}/review`} onClick={reviewall}>View All Reviews({reviewed?.total_count})</NavLink>}
          </Grid>
              
              </Grid>
              </Box>

              </div>
             
          </Item>
          </Container>
        
          </section>
                  }
             
           
    </>
   )

}

export default Ratings;

/* <Grid item  xs={7}  sm={isSmScreen ? 6 : 6} md={6}>

        <div className="show_rating"> 
         <div className="starrating">5
          <div> <StarIcon className="starvalue"/></div>
        </div>
        <LinearProgress variant="determinate" value={progress1}  sx={customStyles} color="inherit" />
      {show &&  <div className="show_rated">{reviewed?.separated.rate_5}</div> }
        </div> 

        <div className="show_rating">
        <div className="starrating">4
          <div> <StarIcon  className="starvalue"/></div>
        </div>
          <LinearProgress variant="determinate" value={progress2}  sx={customStyles} color="inherit"/>
          {show &&     <div className="show_rated">{reviewed?.separated.rate_4}</div>}
        </div>

          <div className="show_rating">
          <div className="starrating">3
          <div> <StarIcon  className="starvalue"/></div>
        </div>
          <LinearProgress variant="determinate" value={progress3} sx={customStyles} color="inherit" />
          {show &&     <div className="show_rated">{reviewed?.separated.rate_3}</div>}
          </div>

          <div className="show_rating">
          <div className="starrating">2
          <div> <StarIcon  className="starvalue"/></div>
        </div>
          <LinearProgress variant="determinate" value={progress4} sx={customStyles2} color="inherit" />
          {show &&   <div className="show_rated">{reviewed?.separated.rate_2}</div>}
          </div>

          <div className="show_rating">
          <div className="starrating">1
          <div> <StarIcon  className="starvalue"/></div>
        </div>
        <LinearProgress variant="determinate" value={progress5} sx={customStyles3} color="inherit" /> 
        {show &&   <div className="show_rated">{reviewed?.separated.rate_1}</div>}

          </div>
          </Grid>
    */

