import React from "react";
import Footer from '../Footer/Footer'
import Header from '../Header/header';
import {createTheme,  useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate, NavLink } from "react-router-dom";
import {ReactComponent as BookingLogo} from '../assests/Booking.svg';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Headerbookmarkempty from "./Headerbookmarkempty";


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:'40px 20px',
  
  
  }));

const Emptybookmark = () => {

    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
          },
        },
      });
  
      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


const Booked=
{
    backgroundColor:'var(--primary)',
    fontSize:isSmScreen?'14px':'16px',
    color:'var(--white)',
    border:'2px solid var(--primary)',
    outline:'none',
    textTransform: 'none',
}

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: 'inherit',
    fontSize:'12px',
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const StyleNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    ariaCurrent:"page",
    fontSize:'12px',
    color:"var(--primary)",
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  
  

    const businessname=localStorage.getItem('businessname');
    const navigate=useNavigate();
   

const Booking=()=>
{
    navigate(`/${businessname}/all-category`);
}




  return (
      <>
       
       <div className="contain">
           {isSmScreen ? <Headerbookmarkempty/> :   <Header  />}
            
             <div className="content">

             <section className='section'>
              <div className="container">

              <div className="breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/bookmark`}  
        >
          Bookmark
        </StyleNavLink>
        
      </Breadcrumbs>
      </div>

              <Item className="global_box_shadow">
                <div className="empty_data">
                <div ><BookingLogo/></div>
                <span className="label">Your Bookmark is empty !</span>
                <span className="logging_title">Add item to it now</span>
                 <span><Button  style={Booked} className='primary_button' onClick={Booking}>Add Item</Button></span>
                </div>
              </Item>

              </div>

              </section>
            
              </div>
              <Footer/>
             
              </div>
       
       
      
      </>
  )
}

export default Emptybookmark