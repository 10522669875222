
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Orderskeleton=()=>
{

    const Item = styled(Paper)(({ theme }) => ({
    
        ...theme.typography.body2,
        borderRadius:'6px',
        padding:'20px',
       marginBottom:'20px'
      
      }));
      const arrayLength = 3;



      const item = Array.from({ length: arrayLength }, (_, index) => `Item ${index + 1}`);
      
    return (
        <>
             {item.map(cur=>{
                return(
                    <>

              <Item className="global_box_shadow">
                  <div className="bookingid">
            
               <div className="booking_detailsdata">
               
               <div className="booking_titledata"><Skeleton width="20vh"/></div>
                <div className="booking_name"><Skeleton width="20vh"/></div>
               </div>
 
            
                <div className="booking_name"><Skeleton width="14vh"/></div>

         
                <div><Skeleton width="14vh"/></div>
                     
                  
                  <div className="booking_detailsdata">
               
                 <div className="booking_time"><Skeleton width="12vh"/></div>

                          
               <div className="bookingdata">                           
          
                <div className="booking_time"><Skeleton width="22vh"/></div>
                        
                </div>

               </div>
                
               </div>

               </Item>   
              
                    </>
                )
             })}
              
                 
        </>
    )
}
export default Orderskeleton 