import React, {useState} from "react";
import Footer from '../Footer/Footer'
import Bottomnavigation from '../Home/BottomNavigation'
import Header from '../Header/header';
import {createTheme,  useMediaQuery } from '@mui/material';
import Paymentcomplete from "./Paymentcomplete";
import Headerpayment from "./Headerpayment";


const Payment=()=>
{

 
  const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const handleLoading=()=>
  {
  
  }

   return (
    <>
         {isSmScreen ?  <div className="contain">
               <Headerpayment/>
              <div className="content" style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}}>
                
              <Paymentcomplete/>

              </div>
              </div> :
               <div className="contain">
             <Header handleLoading={handleLoading} />
             <div className="content" style={{backgroundColor:"#f3f3f3"}}>
               <Paymentcomplete/>
               
              </div>
              <Footer/>
              </div>
             

             }
    </>
   )
}

export default Payment;

/*       
     <Bottomnavigation/>




{isSmScreen ?"" : <Location open={open}/>}
          {isSmScreen ?"" : <Payment open={open}/>}
          */