import React, { useState, useEffect } from "react";
import "../Custom.css";

import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Successfully from "./Successfully";
import Button from '@mui/material/Button';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import {Handlemylocal, mobileNumber,UpdateName } from "../Redux/action";
import { useDispatch } from 'react-redux';


const SignUp=()=>
{
   
       
  const Loginitem=
  {
    backgroundColor:'var(--primary)',
    color:'var(--white)',
    fontWeight:500,
    width:'100%',
    textTransform:'none',
    letterSpacing: '0.8px',  
    '&:hover': {
      backgroundColor: '#57339F', 
    },
  }

   const Baseurl = process.env.REACT_APP_BASE_URL;

   const [isloading, setIsLoading]=useState(false);
   

    const [user, setuser]=useState('');
    const [phone, setphone]= useState("primary_section")
    const [invalid, setInvalid] = useState(false);
    const [google, setgoogle]=useState('');
    const businessname=localStorage.getItem('businessname');

    const access=localStorage.getItem('accessToken')
    const dispatch=useDispatch();
 
    const navigate=useNavigate();

    const clientId="651863082976-om5d8r132rksclhu4uhcs90vdqepaqte.apps.googleusercontent.com";
    const businessId=localStorage.getItem('storeId');

   const handleinput=(e)=>
   {
      

      
    let inputValue = e.target.value;
       
    if (!inputValue.startsWith('+91 ')) {
      setuser('+91 ' + inputValue);
    } else {
      setuser(inputValue);
    }
      
   }

   const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && user === '+91 ') {
      setuser('');
    }
  };

   const BackLogin=()=>
   {
       navigate(`/${businessname}/`);
   }

   const submit = ()=>
   {
      
       const user1 = /^[+ 0-9]+$/g.test(user);

       const user2 = user.replace(/^0+/, '');
  
      if(user.length!==14 ||  user1===false)
    {
      setInvalid(true);

      setphone("secondary_section");
    }
    else{
      setInvalid(false);
      setphone("primary_section")

  
      if(user)
      {
          
         let formdata = new FormData();
         formdata.append("mobile_number", user2.slice(user2.length - 10));
         formdata.append("business_owner_id", businessId);
         formdata.append("fcm_token", "abc");


        
             let requestOptions = {
             method: 'POST',
             body: formdata,
             redirect: 'follow',
               
        };
        
           fetch(`${Baseurl}/consumer/customer-connect/`, requestOptions)
          .then(response => response.text())
          .then(result=>{
              const data= JSON.parse(result);
              if(data.status==='success')
              { 
                
                
                localStorage.setItem('accessToken', data.access_token)
                
                dispatch(mobileNumber(user2.slice(user2.length - 10)));
                dispatch(Handlemylocal(false))
               
                let myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${data.access_token}`);
          
               let formdata = new FormData();
               formdata.append("business_owner_id", businessId);
          
               let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              };
          
              fetch(`${Baseurl}/consumer/customer-otp-send/`, requestOptions)
              .then(response => response.text())
              .then(result => {
                const data=JSON.parse(result);
          
                console.log(data.otp)
                if(data.status==='success')
                {
                  navigate(`/${businessname}/verify-code`) 
                }
               
              })
              .catch(error => console.log('error', error)); 
                

              }
              else{
                navigate(`/${businessname}/connect`);
               
              } 
              
           })
            .catch(error => console.log('error', error));
           
            
        }
    
        
   }
         
   }

   useEffect(()=>
   {
 
    
      if(isloading===true)
     { 
 
 
      let formdata = new FormData();
      formdata.append("social_id", google.sub);
      formdata.append("email", google.email);
      formdata.append("first_name", google.given_name);
      formdata.append("profile_pic", google.picture);
      formdata.append("store_id", businessId);
 
      let requestOptions = {
       method: 'POST',
       body: formdata,
       redirect: 'follow'
     };
 
     fetch(`${Baseurl}/google-login/`, requestOptions)
     .then(response => response.text())
     .then(result => {
       const data=JSON.parse(result);
 
       console.log(data)
       if(data.status==='success')
       {
        
         localStorage.setItem("access_token", JSON.stringify(data.access_token));
         dispatch(mobileNumber(null));
         navigate(-1);
         
       }
      
       if(data.status==='success')
       {
 
         const access= JSON.parse(localStorage.getItem("access_token"));
   
       if(access!==null)
       {   
         fetch(`${Baseurl}/profile/?store_id=${businessId}`,  {
           headers: {
             'Authorization' : `Bearer ${access}`
            }
         })
         .then(response => response.text())
         .then(result => {
           const Update=JSON.parse(result);
           console.log(Update);
           if(Update.status==="success")
           {
             const Updateprofile=Update.data;
             dispatch(UpdateName(Updateprofile?.first_name));
           }
         
           });
     
 
         }
       }
     })
     .catch(error => console.log('error', error)); 
     }  
 
   },[isloading])
        



    return (
        <>
        
                
          <div className="section-content section_container">
            <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} display="flex" justifyContent="center" alignItems='center'  >
          <Grid item xs={11} sm={6} md={4} >
           <div>
          <div>
          <div>
        
          <div style={{position:"static" ,marginTop:"-22px"  }}>
        <div >
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 ,mb:2}}
            style={{color:"black"}}
          >
            <ArrowBackIcon  onClick={BackLogin}/>
          </IconButton>
        
        </div>
        </div>


          <div className="head_title" style={{paddingBottom:"20px"}}>Login/Sign up</div>

                     
                     <div className="secondary">
                     Login to your account and explore
                     </div>
                     </div>
                     
                     
                          <div style={{padding:"12px 0px"}}>
                                   
           {!invalid &&  <TextField className="login_data" id="outlined-required"   label="Phone Number"  type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1" },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }}  value={user} name="user"  minLength={10} maxLength={10}  onKeyDown={handleKeyDown}  />}
           {invalid &&   <TextField
          error
          id="outlined-error"
          label="Error"
          className="login_data"   type="text"   sx={{    '& input::placeholder': {      color: 'black', fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1" },   }}  inputProps={{ inputMode: 'numeric',     pattern: '[0-9]*',      onChange: handleinput,     }}  value={user} name="user"  onKeyDown={handleKeyDown}
          
        />}
                        <div>{invalid  && <label className="login_exist">Enter a valid mobile number.</label>}</div>
                       </div>
                                        
                   
                

                 <div className="logging_title" style={{padding:"12px 0px"}}>By logging in, I agree to <NavLink to ="/terms-and-conditons">terms</NavLink> and  <NavLink to="/privacy-policy">privacy policy</NavLink>.</div>

                 <div><Button style={Loginitem} className="secondary_button submission" onClick={submit}>Send OTP</Button></div> 
                      </div>
                      </div>

                     
                      </Grid>
                      

                  </Grid>
          
                  </Box>
                
                 
                 </div> 

     
               
            
        </>
    )
}

export default SignUp;

/*    
  
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}`);
  
       let formdata = new FormData();
       formdata.append("business_owner_id", businessId);
  
       let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
         
         fetch(`${Baseurl}/consumer/customer-otp-send/`, requestOptions)
               .then(response => response.text())
              .then(result=>{
               const data= JSON.parse(result);
               console.log(data.otp);
               if(data.status==="success")
               {
                navigate(`/${businessname}/verify-code`) 
                dispatch(mobileNumber(user2.slice(user2.length - 10)));
               }

            

         })
             .catch(error => console.log('error', error));



     <div className="google">
                    <GoogleOAuthProvider
                    clientId={clientId} >

                     
                   <GoogleLogin 
                     onSuccess={credentialResponse => {
                      let decoded = jwt_decode(credentialResponse.credential);
                    console.log(decoded);
                    setgoogle(decoded);
                    setIsLoading(true);
            
                    
                    }}
               
                     onError={() => {
                  console.log('Login Failed');
                     }}
                     
                    
                    />
                
                 </GoogleOAuthProvider>
                 </div>

     <div className={phone}>Phone Number</div>

      const [open, setOpen] =useState(false);

   

    const handleOpen=()=> setOpen(true);

    const handleClose=()=>setOpen(false);

      const timerStarted=true;

                  <div><Modal><Successfully/></Modal></div>

        if(data.status==='success')
               { 
                    setOpen(true); 
                    
                    if (!timerStarted) {
 
                      const timer2 = setTimeout(() => {
                          
                         setOpen(false);
                         localStorage.setItem("otp",  JSON.stringify(data.otp));
                       
                      }, 3000);
                      return () => clearTimeout(timer2);
                      
                    }
               }




       <NavLink to="/signIn" className="already_register" ><div><span className="primary_register">Already register? </span><span className="member">Sign in</span></div></NavLink> 
            
           <div className=" secondary_title  horizontal"><span><hr className="hr"></hr></span>Or sign up with <span><hr  className="hr1"></hr></span></div>

        <div className="online_authentication">
                      <Grid container  display="flex" justifyContent="space-between"  columnGap={{md:2 , sm:2}} rowSpacing={0.1}>

                      <Grid item xs={11} sm={5.4} md={5.4}  display="flex" justifyContent="start" >
                      <button className="signupgoogle">
                      <img src={SignGoogle} className="google_auth" alt="signupgoogle"/>
                     
                            </button> 
                     </Grid>
                     <Grid item xs={11} sm={5.4} md={5.4}  display="flex" justifyContent={{xs:"start", sm:"end", md:"end"}} >
                            <button className="signupfacebook">
                            <img src={SignFacebook} className="google_auth" alt="signupfacebook"/>
                          </button> 
                                </Grid> 

                             </Grid>  
                         </div>

               <div className="secondary_title verify">I have read and agree to  <span className="terms">Terms and Condition</span> and <span className="terms">Privacy Policy</span> of OnRoad</div>
                  

    */