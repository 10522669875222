import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {NavLink, useNavigate} from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import {ReactComponent as ShoppingBagOutlinedIcon} from '../assests/Bag.svg';
import {ReactComponent as Bookmark} from "../assests/Bookmark.svg";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Handlemycheckout } from "../Redux/action";

const Headermobile=({itemAdded})=>
{

     const navigate = useNavigate();
     const dispatch=useDispatch();

     const Back=()=>
     {
          navigate(-1);
     }

  const businessname=localStorage.getItem('businessname');
  const access= localStorage.getItem("accessToken");
  const Logoitem=useSelector(state=>state.logo);
     
  const Shopping = ()=>
  {
    
       if(itemAdded===true)
       {
          dispatch(Handlemycheckout(true))
          navigate(`/${businessname}/viewcart`);
       }
  
       else
       {
         navigate(`/${businessname}/empty-cart`);
       }
     
  
  }
  
      const Bookmarkdata=()=>
      {
         if(access===null)
         {
           navigate(`/${businessname}/connect`);
         }
         else
         {
          navigate(`/${businessname}/bookmark`);
         }
   
      }

        
 const SearchIcon=()=>
 {
     
     navigate(`/${businessname}/all-category`)
 }

    return (
        <>
         
          <div className="secondary-header mobile-header">

      
        
         <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', width:'100%'}}>
         <NavLink to={`/${businessname}/`} className='logoImage'>{(Logoitem!==null && Logoitem!==undefined) ? <LazyLoadImage  className='logo' src={Logoitem} alt="logo" /> : <LazyLoadImage className='logo' src='https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png' alt="logo" />}</NavLink> 
        

         <div style={{display:'flex',alignItems:'center', gridColumnGap:'10px'}}>
  
         <div className='bookmark' onClick={SearchIcon}>
         <div className='handleLogins'><SearchOutlinedIcon /></div>
        </div>

         <div className='bookmark' onClick={Shopping}>
         <div className='handleLogins shoppingBag' style={{width:'18px', height:'20px',position:'relative'}}><ShoppingBagOutlinedIcon/></div>
           {itemAdded && <div className="red-dot"></div>} 
              </div>

         <div className='bookmark' onClick={Bookmarkdata}>
         <div className='handleLogins'><Bookmark/></div>
        </div>
      
        </div>  
        </div>


        </div>
         

        </>
    )

}

const mapStateToProps = (state) => {
  return {
    itemAdded: state.itemAdded,
  };
};

export default connect(mapStateToProps)(Headermobile);