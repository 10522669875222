import React,{useEffect,useState} from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {createTheme,  useMediaQuery } from '@mui/material';
import { Handlemycheckout, addToCarts,resetItemAdded,variantdataId,} from "../Redux/action";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack'


const Item = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  padding:'20px', 
  zIndex:'4',
}));

const Addtocart = ({productdata,  selectedVariant, setSelectedVariant, addToCarts,resetItemAdded, variant, loadingnumber,setloadingnumber }) => {

  const  custombuyButton= {
    backgroundColor:'var(--white)',
    color:'#4D1BDB',
    flex:"1",
    fontSize:'16px',
    textTransform:'none',
    whiteSpace: 'nowrap',
    fontWeight:600,
    '&:hover': {
      backgroundColor: '#4D1BDB', 
    },

};
const  customaddButton= {
  backgroundColor:'#4D1BDB',
  color:'var(--white)',
  fontSize:'16px',
  flex:'1',
  textTransform:'none',
  whiteSpace: 'nowrap',
  fontWeight:600,
  '&:hover': {
    backgroundColor: '#4D1BDB', 
  },

};

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
 
  
  const Baseurl = process.env.REACT_APP_BASE_URL;

 
  const [tempQuantities, setTempQuantities] = useState(
    JSON.parse(localStorage.getItem('tempQuantities')) || {}
  );



  
  const  Totalcount = localStorage.getItem('quantity');
  const businessId=localStorage.getItem('storeId');
  const businessname=localStorage.getItem('businessname');
  const productId=localStorage.getItem('productId')


  const dispatch=useDispatch()
 
  const Show=localStorage.getItem('Show');

  const navigate=useNavigate();

  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  
useEffect(()=>
{
  setloadingnumber(false)
  console.log(loadingnumber)
},[loadingnumber])


  
  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, opentoast } = openSnackbar2;

   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };

   const handleAddToCart=(newState)=>
   {
      const storedAccessToken = localStorage.getItem("accessToken");

      const updatedCartItems = JSON.parse(localStorage.getItem('updatedCartItems'))
 
     if(storedAccessToken!==null)
     {
       localStorage.setItem('Show', true);
 
       let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
       myHeaders.append("Authorization", `Bearer ${storedAccessToken}` );   
       
       let raw = JSON.stringify({
         "business_owner_id": businessId,
         "cart": updatedCartItems
       });
   
       let requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
       };
       
       fetch(`${Baseurl}/consumer/customer-cart/`, requestOptions)
         .then(response => response.text())
         .then(result => {
           const data=JSON.parse(result);
          console.log(data);
 
           if(data.status==="success")
           {
             
               addToCarts();
              localStorage.setItem('showbutton', true)
              localStorage.setItem('quantity', (tempQuantities[selectedVariant]||1));
              localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);
              setopenSnackbar2({ ...newState, opentoast: true });
           }
 
         })
         .catch(error => console.log('error', error)); 
     
    }
 
    else
    {

      localStorage.setItem('Show', true);
      addToCarts();
      localStorage.setItem('showbutton', true)
      localStorage.setItem('quantity', (tempQuantities[selectedVariant]||1));
      localStorage.setItem('Totalcounts', parseInt(tempQuantities[selectedVariant]||0)+1);
      setopenSnackbar2({ ...newState, opentoast: true });

      const productdataWithQuantity = productdata.map(item => ({ ...item, quantity: 1 }));

      
      const existingproductDataString = localStorage.getItem('productdata');

      const existingproductDataArray = existingproductDataString ? JSON.parse(existingproductDataString) : [];

      console.log("existingproductDataString",existingproductDataString)

      if(existingproductDataArray.length===0)
      {
        console.log("existingproductDataString",existingproductDataString)
        localStorage.setItem('productdata', JSON.stringify(productdataWithQuantity));
      }
      else {
        {existingproductDataArray && existingproductDataArray.map(cur=>
          {
              if(cur._id.$oid!==productId)
              {
                existingproductDataArray.push(...productdataWithQuantity);
        
                const updatedDataString = JSON.stringify(existingproductDataArray);
              
                localStorage.setItem('productdata', updatedDataString);
              }
            
          })}
      }
  
    }
   }
 

    const handleGoToCart=()=>
    {
      dispatch(Handlemycheckout(true))
      setTimeout(()=>
        {
          navigate(`/${businessname}/viewcart`);
        },1000)
          
    }
    const BuyNow =()=>
    {
      
      const updatedCartItems = JSON.parse(localStorage.getItem('updatedCartItems'))
   
     const access= localStorage.getItem("accessToken");
       if(access!==null)
       {
        
         navigate(`/${businessname}/address`, { state: { updatedCartItems } });
        
       }
       else 
       {
          navigate(`/${businessname}/connect`)
       }
   
    }


  return (
   <>
            

             {isSmScreen &&
      <Item className='global_box_shadow'>

     
        <div>

        <div className="addtocheckout">

       
           <Button style= {custombuyButton} className='secondary_button'  onClick={()=>BuyNow({ vertical: 'top', horizontal: 'center' })}>Buy Now</Button> 
         

        {Show==="true" ?   <Button style={customaddButton} className='secondary_button'  onClick={handleGoToCart}>Go to Cart</Button>  
       :
         <Button style={customaddButton} className='secondary_button' onClick={()=>handleAddToCart({ vertical: 'top', horizontal: 'center' })}>Add to Cart</Button>  
    }
         
           </div>

          </div>
           
    </Item>
     }

       <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}>
                     <Alert  severity="success" sx={{ width: '100%' }}>
                        Cart added successfully !
                           </Alert>
                      </Snackbar>

                    </Stack>

   </>
  )
}
const mapDispatchToProps = {
  addToCarts,
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Addtocart)
