import React from "react";
import { Box, Button, Grid ,createTheme, useMediaQuery } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-loading-skeleton/dist/skeleton.css'
import Cardskeleton from "./Cardskeleton";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useDispatch } from "react-redux";
import { filterData } from "../Redux/action";
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  textAlign: 'center',
  borderRadius:'12px',
}));

const Cleaning=({value, value2, loading})=>
{
   // console.log(value2) 

   const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });
  
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const Deleteitem=
   {
     backgroundColor:'var(--white)',
     flex:1,
     fontSize:isSmScreen?'14px':'16px',
     color:'var(--primary)',
     border:'2px solid var(--primary)',
     outline:'none',
    
   }

   const dispatch= useDispatch();
   const navigate= useNavigate();

 
  const valueItem= value2?.slice(0,4);
  const businessname=localStorage.getItem('businessname');


  const View=(CategoryId, Categoryname)=>
  {

      localStorage.setItem("CategoryId",CategoryId)
      
      localStorage.setItem("Categoryname",Categoryname)
  }

 const service=(categoryname, productname,Id,dataId, price)=>
 {

      localStorage.setItem('DataId',dataId)
      localStorage.setItem('CategoryId', Id)
      localStorage.setItem('Categoryname', categoryname);
      localStorage.setItem('Discountprice', price);

     // dispatch(filterData(Id.$oid));
      const Categoryname=categoryname?.replace(/ /g, '-');
      const servicename=productname?.replace(/ /g, '-');

       navigate(`/${businessname}/product/${Categoryname}/${servicename}`);
    
      //   localStorage.setItem('discountPrice', discountprice);
      //   localStorage.setItem('Price', saleprice);
         localStorage.setItem('Show', false);
         localStorage.setItem('productname', productname);
         localStorage.setItem('productId', dataId)
         localStorage.removeItem('totalPrice1');
         localStorage.removeItem('totalAmount');
         localStorage.removeItem('tempQuantities');
         
 }

 function formatIndianNumber(number) {
  const parts = number.toString().split(".");
  let integerPart = parts[0];
  let formattedInteger = "";

  while (integerPart.length > 3 && number>=10000) {
    formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
    integerPart = integerPart.slice(0, -3);
  }
  formattedInteger = integerPart + formattedInteger;

  return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
}
 
    return (
        <> 
       
       <section className="section explore-padding ">
      
   
       <Container maxWidth="lg" className="container-padding" >
     
             <div className="view_all">
               <div>
                 
           {value2 &&   <h1 className="head_title explore">{value[0]?.bo_product_category_name}</h1>}

            </div>
         {value2 && <NavLink to={`/${businessname}/search`} className="view-all" onClick={()=>View(value[0]?.bo_product_category_id, value[0]?.bo_product_category_name)}>View All</NavLink>}
          </div>
              
         {loading ? 

          <>

             <Cardskeleton /> 
        </>
          
           :

            <div className="explore_container m-10">
           
              <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2} columnSpacing={{xs:2, sm:2, md:4}} display="flex" justifyContent="start" >
           

          {valueItem
              ?.filter(cur => cur.bo_product_status === 1) 
              .map((cur, index) => (
                <Grid item xs={6} sm={isSmScreen ? 4 : 3} md={3} key={index}>
                <div className="categoriesdata">
                <Item className="global_box_shadow transform_data" style={{ cursor: "pointer" }} onClick={() => service(cur.bo_product_category_name, cur.bo_product_name, cur.bo_product_category_id, cur._id, cur.bo_product_discounted_price)}>
                  <div className="explore_image_recommend">
                    <LazyLoadImage src={cur.bo_product_images.img_urls !== null ? cur.bo_product_images.img_urls[0] : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} alt={cur.bo_product_images.img_urls !== null ? 'category' : 'No Image'} onError={(e) => { e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"; e.target.alt = 'No Image' }} />
                  </div>
                  <div>
                    <div className="bottom_container">
                      <span className="secondary_title text-wrapping">{cur.bo_product_name}</span>
                      <div className="priceIcon">
                        {cur.bo_product_discounted_price !== null && <div className="expected_price"><span>₹{formatIndianNumber(cur.bo_product_discounted_price)}</span></div>}
                        {cur.bo_product_price !== null && <div className="real_price"><span><del>₹{formatIndianNumber(cur.bo_product_price)}</del></span></div>}
                        <div className="discount_price">{parseInt(((cur.bo_product_price - cur.bo_product_discounted_price) * 100) / cur.bo_product_price)}% OFF</div>
                      </div>
                      {(parseInt(cur.bo_product_quantity)<=5 && parseInt(cur.bo_product_quantity)!==0) &&
                        
                      <div className="sold selectoffer" style={{display:"flex",justifyContent:"start", position:"relative",top:"-4px"}} >Only few left</div>
                      }
                       {parseInt(cur.bo_product_quantity)<=0 &&
                        
                        <div className="sold selectoffer" style={{display:"flex",justifyContent:"start", position:"relative",top:"-4px"}}  >Out of Stock</div>
                        }
                    </div>
                  </div>
                </Item>
                </div>
                </Grid>
              ))
            }

            </Grid>             
             </Box>
           </div>
          
         }
         </Container>
         </section>
     
        </>
    )
}

export default Cleaning;

/* 

ye hide hoga 
 <div className="buttons">
                     <Button style={Deleteitem} className="primary_button">Add</Button>

                     </div>
                      



                     <div className="starIcon">
                     <div className="pickrating"><StarIcon className="rupee" /><span className="currency1">{cur.rating}</span></div>
                     {cur.variant[0]?.variant_staff!==null &&    <div className="pickrating"><AccountCircleOutlinedIcon className="rupee" /><span className="currency1">{cur.variant[0]?.variant_staff} staff</span></div>}
                  
                    {cur.variant[0]?.variant_duration!==null &&      <div className="pickrating"><AccessTimeOutlinedIcon className="rupee" /> <span className="currency1">{cur.variant[0]?.variant_duration}</span></div>}
                    </div>
     <div className="view_detailsIcon">
                     <button className="view_details">View Details</button>
                     <div><ExpandCircleDownIcon  className="expandCircle" /></div>
                    </div>
      

  <div className="discount">{(cur.variant[0].variant_percentage).toFixed(1)} %</div>
                    <div className="real_price"><del className="price"><CurrencyRupeeOutlinedIcon  className="rupee"/> <span className="currency">{cur.variant[0].variant_price}</span></del></div>




  
    
                 

  <div >{cur.service_name}</div>


     {item.map((cur)=>
             {
                return(
                    <>
                 
                     
                      
                      
                         <div className="explore_name">{cur.name}</div>
                        
        












    <div><hr className="starhr"></hr></div>


  <div className="section_title">
          <Container maxWidth="xl">
        
          <div className="view_all">
          <div className="recommend">Recommended</div>
          <NavLink className="nav-bar">View All</NavLink>
          </div>
              <Box sx={{width:"100%"}}>
              <Grid container spacing={2} columnGap={{sm:2,md:0,xm:0}} display="flex" justifyContent="center">
               {item.map((cur)=>
               {
                return(
                    <>
                    <Grid item xs={5.6} sm={5.6} md={3.6}>
                    <div className="cleaning">
                    <NavLink to={cur.service} className="cleaning_service">
                  
                   
                    <div className="service-image"><img className="service" src={cur.image} alt="logo"/></div>
             
                    <div className="cleaning_service">

                    <div className="service_name">{cur.name}</div>
                    
                    <div className="servicestarIcon">
                    <div className="rating"><StarIcon style={{transform:'scale(0.5)'}}/>{cur.rating}</div>
                    <div className="rating"><AccountCircleOutlinedIcon  style={{transform:'scale(0.5)'}}/>{cur.staff} staff</div>
                   
                    </div>
                 
                    <div className="ratingtime"><AccessTimeOutlinedIcon style={{transform:'scale(0.5)'}}/> {cur.time} mins</div>

                    <div><hr className="recommendedhr"></hr></div>
                   
                    <div className="servicepriceIcon">
                    <div className="discount">{((cur.price-cur.expected_price)/(cur.price)*100).toFixed(2)  } %</div>
                    <div className="real_price"><del className="price"><CurrencyRupeeOutlinedIcon  style={{transform:'scale(0.4)', color:"var(---stroke)"}}/> <span className="currency">{cur.price}</span></del></div>
                    <div className="expected_price"><CurrencyRupeeOutlinedIcon  style={{transform:'scale(0.5)'}}/><span className="currency">{cur.expected_price}</span></div>
                    
                    </div>

                    </div>
                     
               
                    </NavLink>
                    </div>
                    </Grid>
                 
                </>
                )
               })}
               </Grid>
               </Box>
             
            </Container>
            </div>

                
   

                    */