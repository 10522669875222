import React,{useEffect,useState} from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Box, Grid,createTheme, useMediaQuery } from "@mui/material";


const Cartskeleton = () => {


    
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const Items = styled(Paper)(({ theme }) => ({
    
        ...theme.typography.body2,
        padding: '20px',
        background: '#FFF',
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
        marginTop:'1px'
      
      }));
      
  const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:"20px",
    margin:isSmScreen?"2px 0px 10px":"12px 0px 20px",
    background: '#FFF',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
   
  }));

      const checkoutflex={

        display:'grid',
        gridTemplateColumns: isSmScreen ? '96px  auto' : '110px  auto',
        gridTemplateRows:'1fr',
        gridColumnGap:'18px'
    
      }
    
      const checkoutgrid=
      {
        display:'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows:'1fr',
        gridColumnGap:'18px',
        paddingBottom:'10px',
        marginBottom: '10px',
      }

      const [showpayment, setshowpayment] = useState(localStorage.getItem('showbutton'));

      const Showbutton = localStorage.getItem('showbutton');

      useEffect(()=>
   {

  if(Showbutton==='true')
  {
   
      setshowpayment(true);
  }
  else
  {
 
      setshowpayment(false);
  }
    
},[Showbutton])

  return (
    <>
     
     <Box sx={{width:"100%", boxSizing:"border-box"}}>
          <Grid container rowSpacing={2}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start">
           

          <Grid item  xs={12} sm={isSmScreen? 12:8} md={8}>
       
          <Items className="global_box_shadow mobile-bodymargin">
          
          
                    
                    <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >
                    {showpayment &&  <div className="image_lazyload">

                      <Skeleton height="100px" className="checkoutimage roundimage" />
                    </div>
                }
                <div>
                    
                   
                         <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                         <Skeleton/>            
                         </h2>

                    
          
          {showpayment &&   <div>
       
            
               <div className="priceIcon">
                  
                 <div className="expected_price"><span><Skeleton width="8vh"/></span></div>
               
                <div className="real_price"><span><del><Skeleton width="8vh"/></del></span></div>
               <div><Skeleton width="6vh"/></div>
              
            </div>
            
                     
           
                    <div className="checkoutdetails">
              
              <Skeleton width="3vh"/>
              <Skeleton width="3vh"/>
              <Skeleton width="3vh"/>
              <Skeleton width="3vh"/>
    
              </div>
              </div>
          }
                 
              </div> 
              </div>  
                
    

          </Items>
           
    
        </Grid>

        <Grid item xs={12} sm={isSmScreen? 12:4} md={4} style={{ paddingTop: isSmScreen ? "8px" : "6px"}}  >

       <div>
         

         <Item className="global_box_shadow">
         <div className="coupon">
         <div className="offerIcon">
         <div className="localoffericon"><Skeleton width="2vh"/></div>
          <div className="apply"><Skeleton width="8vh"/></div>
          </div>
          
          <div className="keyboard"><Skeleton width="2vh"/></div>
          </div>
         </Item>
        
         </div>

        

          <Item className={ showpayment ?"item_booking global_box_shadow " : "item_booking global_box_shadow mobile-bodymargin"}>
           <div className="bill_details"><Skeleton width="12vh"/></div>
          
            <div className="totalcost">
            <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}><Skeleton width="8vh"/></div>
             <div className="totalprices"><Skeleton width="8vh"/></div>                     
               

            </div>

            <div className="totalcost">
            <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}><Skeleton width="8vh"/></div>
           
            <div className="totalprices"><Skeleton width="8vh"/></div>  
                
            </div>

            <div className="totalcost">
             <div className="total_discount"><Skeleton width="8vh"/></div>
             
            <div className="total_discount"><Skeleton width="8vh"/></div>
              
        
            </div>


         <div className="border"></div>              
  
            <div className="totalcost">
             
             <div className="total_money"><Skeleton width="8vh"/></div>
        
             <div className="totalprices"><Skeleton width="8vh"/></div> 
            
           
            </div>

             
                   <div className="booking_button">

                     <Skeleton width="12vh" height="6vh"/>
                     <Skeleton width="12vh" height="6vh"/>

                   </div>
                     

          </Item>
       
         </Grid> 

  
         </Grid>
         </Box>
    </>
  )
}

export default Cartskeleton;