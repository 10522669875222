import React, {useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import {Box, Grid, createTheme,  useMediaQuery } from '@mui/material';
import {ReactComponent as Sharelogo} from "../assests/Share.svg";
import lottie from 'lottie-web';
import successbookingdata from "../assests/success_booking.json";
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import Similardata from '../Product/Similardata';
import { resetItemAdded} from "../Redux/action";
import {connect} from 'react-redux';
import Headerorder from './Headeroder';

const Paymentpaid=({resetItemAdded})=>
{


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const Item1 = styled(Paper)(({ theme }) => ({
      
    ...theme.typography.body2,
    padding:"20px",
    borderRadius:'6px',
    marginBottom:isSmScreen?"10px":"20px",
    boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',
  
  }));

      const customReschedule=
      {
          backgroundColor:'#ffffff',
      color: '#4D1BDB',
    fontSize:'16px',
    fontWeight: 500,
    border:'1px solid #4D1BDB',
    letterSpacing: '0.8px',  
    textTransform:'none',
    marginTop:'24px',
      '&:hover': {
        backgroundColor: '#ffffff', 
      },

      }

      const customBooking=
      {

      backgroundColor:'#4D1BDB',
      color:'var(--white)',
    fontSize:'16px',
    fontWeight: 500,
    textTransform:'none',
    marginTop:isSmScreen?'6px':'24px',
    letterSpacing: '0.8px',  
      '&:hover': {
        backgroundColor: '#4D1BDB', 
      },
      }

    
  
        const [isLoading, setIsLoading] = useState(true);
        const [similar,setSimilar] = useState([]);
      
        const datas=localStorage.getItem('DataId')
        const businessId=localStorage.getItem('storeId');
        const Baseurl = process.env.REACT_APP_BASE_URL;
        const [loading, setloading]=useState(true)
        
        const navigate =useNavigate()
       
        useEffect(()=>
        {
          

          const apiUrl7=`${Baseurl}/consumer/customer-homepage/`;
      
         const fetchData = async()=>
         {
           try 
           {
              
               setloading(true);
              
            
               let formdata = new FormData();
               formdata.append("business_owner_id", businessId);
               
                let requestOptions = {
                 method: 'POST',
                 body: formdata,
                 redirect: 'follow'
               };
               
               const response5 = await fetch(apiUrl7,requestOptions);
               
               const productdata = await response5.json();
               const similar = productdata.product_list;
               console.log(similar)
               setSimilar(similar)
      
               setloading(false);
      
               setIsLoading(false)

               resetItemAdded();

               localStorage.removeItem('currentAddress')
               localStorage.removeItem('checkedpayment')
               localStorage.removeItem('showbuttonpayment')
               localStorage.removeItem('showbuttons')
               localStorage.removeItem('checkedaddress')
               localStorage.removeItem('productImage')
                localStorage.removeItem('cart')
                localStorage.removeItem('checkout')
                localStorage.removeItem('payment')
           }
            catch (error) {
            console.log(error);
            setIsLoading(false);
          }
         }

        
       
      
         if (isLoading) {
          fetchData();
        }
        },[isLoading])

        const Home=()=>
        {
            navigate(`/${businessname}/`);
        }
    
      const Booking=()=>
      {
           navigate(`/${businessname}/order-history`);
      }  

   

    const OrderId= localStorage.getItem('OrderId');


    const couponValue= useSelector(state=>state.code);
   
    const couponType = localStorage.getItem('couponType')

    const totalprice1=localStorage.getItem('Discountprice')

    const Amount = couponValue!=""? couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100):0
 
    const TotalAmount=totalprice1-Amount;

    const paymentType=localStorage.getItem('paymentType')

    const currentDate = new Date();

    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

    const businessname=localStorage.getItem('businessname');
   

   


  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        animationData: successbookingdata,
        loop: true,
        autoplay: true, 
      });
    }
  }, []);

  function formatIndianNumber(number) {
    const parts = number.toString().split(".");
    let integerPart = parts[0];
    let formattedInteger = "";
  
    while (integerPart.length > 3 && number>=10000) {
      formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
      integerPart = integerPart.slice(0, -3);
    }
    formattedInteger = integerPart + formattedInteger;
  
    return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
  }


     return (
        <>
            
            <section>
            
             
                <div className="experience-services">
               <div className='booking_success'>
               {isSmScreen && <div><Headerorder/></div>}
                <div className='booked_successed'>
                 
                <div style={{width:"100px",top:'30px',position:'relative'}} ref={animationContainer}></div>
               <h1 className="formattiming">Order placed successfully</h1>
               <div className="title" style={{color:"white"}}>Confirmation will be sent to your email.</div>
                </div>
                </div>
               
                <section className= {isSmScreen? "slider-header service-margin": "explore-padding"}>
           <Container maxWidth='lg' className="p-16">

                <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={2}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center" marginBlock={isSmScreen ?{} :"20px"} >
         
          <Grid item  xs={12} sm={isSmScreen? 12:7.8} md={7.8}>
          
          <Similardata similar={similar} data={datas} loading={loading}/>
          
          </Grid>

          <Grid item  xs={12} sm={isSmScreen? 12:4.2} md={4.2}>

          <Item1 className=" global_box_shadow">
               <div className='item_booking'>
               <div className="bill_details">Billing Details </div>
             
               <div className="payment_typed">
                 <div className="paymentfull ">Payment Type</div>
                 <div className="accounttype" style={{fontSize:'12px'}}>{paymentType}</div>
               </div>

               <div  className="payment_typed">
                 <div  className="paymentfull">Amount</div>
                 <div className="accounttype" style={{fontSize:'12px'}}>₹{formatIndianNumber(TotalAmount)}</div>
               </div>

               <div  className="payment_typed">
                 <div  className="paymentfull">Order Id</div>
                 <div className="accounttype" style={{fontSize:'12px'}}>{OrderId}</div>
               </div>

               <div  className="payment_typed">
                 <div  className="paymentfull">Date</div>
                 <div className="accounttype" style={{fontSize:'12px'}}>{formattedDate}</div>
               </div>
    
              </div>

              <div className="reschedule-paymentdata">
             <Button style={customReschedule} className='secondary_button cancelhover' onClick={Home} >Continue Shopping</Button>

            <Button style={customBooking} className='secondary_button' onClick={Booking}>Track Order</Button>
              </div>
              </Item1>

                   
          
              </Grid>

              </Grid>
              </Box>
              </Container>
              </section>
             </div>

            </section>
           
        </>
     )
     
}

const mapDispatchToProps = {
  resetItemAdded,
};

export default connect(null, mapDispatchToProps)(Paymentpaid);


/*
  <div style={{paddingInline:"10px"}}>
               <Container maxWidth='lg' className="p-16">
               <div className='sharedlogo'><Sharelogo style={{transform:'scale(0.9)', cursor:'pointer'}}/></div></Container></div>


  position:isSmScreen?'fixed':'',
  bottom:isSmScreen?'16px':'',
  marginTop:isSmScreen?'none':'24px',

      
*/