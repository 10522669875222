import React, {useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import {createTheme,  useMediaQuery } from '@mui/material';

const Thanks = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });


  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const customReschedule=
  {
      backgroundColor:'#ffffff',
  color: '#1A1E24',
 fontSize:'16px',
 fontWeight: 500,
border:'1px solid #1A1E24',
letterSpacing: '0.8px',  
textTransform:'none',
marginTop:'24px',
  '&:hover': {
    backgroundColor: '#ffffff', 
  },

  }

  const customBooking=
  {

  backgroundColor:'#57339F',
  color:'var(--white)',
fontSize:'16px',
fontWeight: 500,
textTransform:'none',
marginTop:isSmScreen?'6px':'24px',
letterSpacing: '0.8px',  
  '&:hover': {
    backgroundColor: '#57339F', 
  },
  }

    const navigate = useNavigate();

     const booking= localStorage.getItem('booking_no');
     const [isLoading, setIsLoading] = useState(true);
     const Baseurl=process.env.REACT_APP_BASE_URL;
     const access= JSON.parse(localStorage.getItem("access_token"));
     const businessId=localStorage.getItem('storeId');
     const businessname=localStorage.getItem('businessname');

  const Booking=()=>
  {
       navigate(`/${businessname}/bookingId/${booking}`);
  }  

  const ReSchedule=()=>
  {
      navigate(`/${businessname}/schedule`);
      localStorage.setItem('screen',2)
  }


  useEffect(()=>
  {
      const apiUrl12=`${Baseurl}/booking-success/${booking}/?store_id=${businessId}`

      const fetchData= async()=>
      {
         try 
         {
             if(access!==null)
             {
               
               const response = await fetch(apiUrl12,
                {
                  headers: {
                    'Authorization' : `Bearer ${access}`
                   }
                }
                );

                const Update = await response.json();

             }
             setIsLoading(false);
         }catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      };
      if (isLoading) {
        fetchData();
      }
     
  }, [isLoading])


 



  return (
 
    <>
        <section className="section">
       <div className='thanking'>
        <div className='checkCircleIcon'><CheckCircleIcon/></div>
        <div className='thank_title explore by_category'>Thank You !</div>
        <div className='payment_done '>Your Payment is done</div>
      
       
       <div className="reschedule-paymentdata">
            <Button style={customReschedule} className='secondary_button' onClick={ReSchedule} >Reschedule</Button>

          <Button style={customBooking}  className='secondary_button'  onClick={Booking}>View Booking</Button>
              </div>
              </div>
              </section>
             
    </>

  )
}

export default Thanks