import React from "react";
import Header from "../Header/header.js";
import Footer from "../Footer/Footer";
import {createTheme,  useMediaQuery } from '@mui/material';
import Headermobile from "../Header/headermobile.js";
import Container from '@mui/material/Container';
import Returnpolicy from "./Returnpolicy.js";

const Return = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
   
    const handleLoading=()=>
    {

    }

    const updateLocation=()=>
    {

    }


    return (
       <>
        
        <div className="contain">
        {isSmScreen ? <Headermobile updateLocation={updateLocation} handleLoading={handleLoading} /> : <Header   updateLocation={updateLocation} handleLoading={handleLoading}  />} 
           <div className="content">
           <section className= {isSmScreen? "slider-header service-margin": "explore-padding"} style={{backgroundColor:"#f3f3f3"}} >
            <Container maxWidth='lg' className='container-padding'>
           <h1 className='privacy_title' style={{margin:"0px"}} >Return policy</h1>
           </Container>
           </section>
            <Returnpolicy/>
         
             

            </div>
            <Footer/> 
            
          </div>

       </>
    )
}

export default Return;