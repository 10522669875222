import React,{useState,useEffect} from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Seater from "../assests/Seater.png";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import {ReactComponent as Settinglogo} from "../assests/Setting.svg";
import {ReactComponent as Packagelogo} from "../assests/Package.svg";
import RemoveIcon from '@mui/icons-material/Remove';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import TrackingSlider from "./Trackingslider";
import { NavLink} from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Container from '@mui/material/Container';


 



const Cancelleditem=()=>
{

  

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });
  
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const Item = styled(Paper)(({ theme }) => ({
    
      ...theme.typography.body2,
      padding:'20px',
      marginBottom: isSmScreen?"10px":"20px",
      borderRadius:'6px'
    
    }));
    
    const booking_number= localStorage.getItem('booking_no');

    const [isLoading, setIsLoading] = useState(true);
    const Baseurl=process.env.REACT_APP_BASE_URL;
  const [bookingdetails, setbookingdetails]=useState();
  const [service, setservice]=useState([]);
  const [billing, setbilling] = useState();
  const businessId=localStorage.getItem('storeId');
  const businessname=localStorage.getItem('businessname');

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };

 const formattedDateTime = new Date(bookingdetails?.booking_date).toLocaleString('en-IN', options);





    useEffect(()=>
  {
   
    
      const apiUrl15=`${Baseurl}/booking-tracking/${booking_number}/?store_id=${businessId}`

      const fetchData = async()=>
      {
          try 
          {
                 const response1 = await fetch(apiUrl15);
                  const Update = await response1.json(); 
                   console.log(Update);
                  
                   setbookingdetails(Update);
                   setservice(Update.booking_details.service_name);
                   setbilling(Update.booking_details.billing_details);
                   console.log(Update.booking_details.service_name)
                  setIsLoading(false)
          }
          catch (error) {
            console.log(error);
            setIsLoading(false);
          }
      };

      if (isLoading) {
        fetchData();
      }
      

  },[isLoading, booking_number])


     return (
        <>
    {bookingdetails!==undefined &&
                         <section className= {isSmScreen? "slider-header service-margin ": "section1 explore-padding mt-20"}>
                         <Container maxWidth='lg' className="container-padding">

                  <Box sx={{width:"100%"}}>
         
                  <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >

                  <Grid item xs={12} sm={isSmScreen ? 12 : 7} md={7} >

                    
                       <Item style={{cursor:"pointer"}} className="global_box_shadow">
                            
                       <div >
                      
                       <div >
                

                       <div className="bookingId-cancelled">
                        <div className="booking-cancelled">
                        <div className="booking_title">Booking ID: {booking_number}</div>
                        <div className={bookingdetails.booking_status_caption==='Finished' ? 'finished': 'cancelled'}>{bookingdetails.booking_status_caption}</div>
                        </div>
                         {service.map(cur=>
                  {
                     return (
                      <>
                            <div className="booking_name">{cur.service_name}</div>
                            <div className="quantitynumber" >QTY: {cur.quantity}</div>
                      </>
                     )
                  })}
                     
                        <div className='step-time'>{formattedDateTime}</div>
                        
                    </div>
                       </div>

                       <div className="solid_border"></div>
            
            <div className="tracking_service  service-tracker">
           
            <h1 className="explore_title ">Service Tracker</h1>
            <div className="keyboardup"><KeyboardArrowUpIcon/></div>

            </div>
              
                         
            <div>
             <TrackingSlider  bookingdetails={bookingdetails} />
             
             </div>
         
         </div>

                       </Item>

                  {bookingdetails.booking_status_caption==='Finished' &&     
                            <Item className="global_box_shadow">
                             <div>
         
              <div className="customer-said experiencedata">How was your Experience ?</div>
          {bookingdetails.review.length===0 ? 
              <div className="mylocations">
                <NavLink className="customer-said customer-review" to ={`/${businessname}/customer-review`} >
                Write a Review our for Service
                </NavLink>
                <NavLink>
                 <KeyboardArrowRightIcon/>
                </NavLink>
              </div>
              :
              <div>
                <div><span className="booking_name">Overall service:</span> {bookingdetails.review[0]?.review_round}</div>
                <div><span className="booking_name">Review message: </span>{bookingdetails.review[0]?.review_message}</div>
                <div className="image-uploader">
                {bookingdetails.review[0].gallery.map(cur=>
                  {
                    return <div className="image_lazyloads"><LazyLoadImage  src={cur.image_url}  alt="category" className="checkoutimage" /></div>
                  })}
               </div>
               </div>
          }
              </div>

                       </Item>
            }

        {bookingdetails.booking_status_caption==='Cancel' &&
                    <Item className="global_box_shadow">
                              
                              <div className="booking_detaildata">
                   <h2 className="booking_service" style={{margin:"0px"}}>Booking Details</h2>
                    
                    <div className="packagelogo">
                    <div><Packagelogo/></div>
                     <div className="booking_title">Order id: {booking_number}</div>
                    </div>
       
                    <div className="packagelogo">
                    {(()=>
                                         {
                                                 if(bookingdetails.booking_details.booking_address.address_details[0].address_type==='home')
                                                 {
                                                    return <div className="fmdgood"><HomeOutlinedIcon/></div>
                                                 }
       
                                                 else if(bookingdetails.booking_details.booking_address.address_details[0].address_type==='office')
                                                 {
                                                    return <div className="fmdgood"><ApartmentOutlinedIcon/></div>
                                                 }
       
                                                 else 
                                                 {
                                                     return <div className="fmdgood"><FmdGoodOutlinedIcon/></div>
                                                 }
                                         })()}
                   
                    <div>
                     <div className="booking_title">{bookingdetails.booking_details.booking_address.address_details[0].address_type}</div>
                     <div className="booking_address"> {bookingdetails.booking_details.booking_address.address_details[0].address}.</div>
                     </div>
                    </div>
       
                    <div className="packagelogo">
                    <div><Settinglogo/></div>
                    <div>
                    {service.map(cur=>
                         {
                            return (
                             <>
                                  <div className="booking_name">{cur.service_name}</div>
                                  <div className="quantitynumbers" >QTY: {cur.quantity}</div>
                             </>
                            )
                         })}
                     </div>
                    </div>
                   
                     </div>
       
                            </Item>
                   
                        }         

                     

                     </Grid>


                     <Grid item xs={12}sm={isSmScreen ? 12 : 5} md={5} >

          {bookingdetails.booking_status_caption==='Finished' &&         <Item className="global_box_shadow">
                              
                              <div className="booking_detaildata">
                   <div className="booking_service">Booking Details</div>
                    
                    <div className="packagelogo">
                    <div><Packagelogo/></div>
                     <div className="booking_title">Order id: {booking_number}</div>
                    </div>
       
                    <div className="packagelogo">
                    {(()=>
                                         {
                                                 if(bookingdetails.booking_details.booking_address.address_details[0].address_type==='home')
                                                 {
                                                    return <div className="fmdgood"><HomeOutlinedIcon/></div>
                                                 }
       
                                                 else if(bookingdetails.booking_details.booking_address.address_details[0].address_type==='office')
                                                 {
                                                    return <div className="fmdgood"><ApartmentOutlinedIcon/></div>
                                                 }
       
                                                 else 
                                                 {
                                                     return <div className="fmdgood"><FmdGoodOutlinedIcon/></div>
                                                 }
                                         })()}
                   
                    <div>
                     <div className="booking_title">{bookingdetails.booking_details.booking_address.address_details[0].address_type}</div>
                     <div className="booking_address"> {bookingdetails.booking_details.booking_address.address_details[0].address}.</div>
                     </div>
                    </div>
       
                    <div className="packagelogo">
                    <div><Settinglogo/></div>
                    <div>
                    {service.map(cur=>
                         {
                            return (
                             <>
                                  <div className="booking_name">{cur.service_name}</div>
                                  <div className="quantitynumbers" >QTY: {cur.quantity}</div>
                             </>
                            )
                         })}
                     </div>
                    </div>
                   
                     </div>
       
                            </Item>
                   
                        }
                     <Item className="global_box_shadow">
                     <div className="item_booking">
              {service.length>1?  <div className="bill_details">Bill Details ({service.length} services) </div> :  <div className="bill_details">Bill Details ({service.length} service) </div>}
            
              <div className="totalcost">
               <div >Service Bill</div>
               <div className="totalprices">₹{parseInt(billing.service_bill)}</div>
             

              </div>

              <div className="totalcost">
               <div>Service charges</div>
               <div className="totalprices">₹{parseInt(billing.service_charges)}</div>
            
                
                  
              </div>

              <div className="totalcost">
               <div>Discount</div>
               
              <div className="total_discount"><RemoveIcon className="remove_discount"/>₹{parseInt(billing.discount)}</div>
                
                 
             
               
              </div>

              <div className="totalcost">
               <div>Gst</div>
               <div className="totalprices">₹{parseInt(billing.gst)}</div>    
             
              </div>

           <div className="border"></div>              
    
              <div className="totalcost">
               <div className="total_money">Total</div>
               <div className="totalprices">₹{parseInt(billing.total)}</div>
             
              </div>
              </div>
 

            </Item>


                    
                       </Grid>
                       </Grid>
                       </Box>
                       </Container>
                       </section>
}
        </>
     )
}

export default Cancelleditem;

/*  

 <div className="close-cancelled  experience-servicedata">
                <div className="close-icondata" ><CloseIcon/></div>
                <div>
                    <div className="accounttype  service-cancelled">This Services Cancelled</div>
                    <div className='step-time'>{bookingdetails.booking_date}</div>
                </div>
            </div>


   <div className="booking_details">
              <div className="bookingid">
               <div className="booking_name">Home Cleaning</div>
               <div className="quantitynumber" >QTY: 2</div>
               <div className='step-time'>{Currenttime}</div>
              </div>

              <div className="booked">Accepted</div>
             </div>

<div className='step-time'>{Currenttime}</div> */