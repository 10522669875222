import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import {createTheme, useMediaQuery } from "@mui/material";

const Cancellation = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/');
  const encodedPart = pathSegments[1]; 
  const decodedPart = decodeURIComponent(encodedPart);


  return (
       <>
       <section className= {isSmScreen? "slider-header service-margin": "section mt-20 explore-padding"}>
            <Container maxWidth='lg' className='container-padding'>
            <h1 className='head_title'>Refund and Cancellation policy</h1><br></br>
            <span className='policy_title'>This cancellation policy outlines about how you can cancel or seek a refund for a product / service that you have purchased through the Platform. Under this policy:</span> <br></br><br></br>
           <ol className='policy_title'>
             <li>Cancellations will only be considered if the request is made <span style={{fontWeight:"700"}}>7 days</span> of placing the order. However, cancellation request may not be entertained if the orders have been communicated to such sellers / merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an even, you may choose to reject the product at the doorstep.</li>
             <li>After <span style={{fontWeight:"700"}}>2 days</span> does not accept cancellation requests for perishable items like flowers, eatables, etc. However, the refund / replacement can be made if the user establishes that quality of the product delivered is not good.</li>
              <li>In case of receipt of damaged or defective items, please report to our customer service team. The request would be entertained once the seller/ merchant listed on the Platform, has checked and determined the same at its own end. This should be reported within <span style={{fontWeight:"700"}}>7 days</span> of receipt of products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within <span style={{fontWeight:"700"}}>7 days</span> of receiving the product. The customer service team after looking into your complaint will take an appropriate decision.</li>
             <li>In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.</li>
             <li>In case of any refunds approved by <span style={{fontWeight:"700"}}>7 days</span>, it will take <span style={{fontWeight:"700"}}>7 days</span> for the refund to he processed to you.</li>
           </ol><br></br>

    
         </Container>
         </section>
       </>
  )
}

export default Cancellation