import React,{useState, useEffect} from "react";
import Deleteservice from "./Deleteservice";
import Header from "../Header/header.js";
import Bottomnavigation from "../Home/BottomNavigation";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import {createTheme,  useMediaQuery } from '@mui/material';
import  SearchFilter from "../Header/SearchFilter.js";
import { useNavigate } from "react-router-dom";

const Confirmdelete=()=>
{


      const locationname=JSON.parse(localStorage.getItem('location').toLocaleLowerCase());
      const businessname=localStorage.getItem('businessname');

      const navigate = useNavigate();

     const theme = createTheme({
          breakpoints: {
            values: {
              xs: 0,
              sm: 768,
            },
          },
        });
      
    
      
        const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  
 
    
     const handleLoading=()=>
     {

     }

     const updateLocation=()=>
     {

     }
     const handle=()=>
 {
 
 }


  
     return (
        <>
          <div className="contain">
               {isSmScreen? "" : <Header   updateLocation={updateLocation} handleLoading={handleLoading} /> }
            <div className="content">
              <Deleteservice/> 
           
           
             </div>
             {isSmScreen?"":  <Footer/>  }
           </div>
         
        </>
     )
}

export default Confirmdelete;