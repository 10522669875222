
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, TextareaAutosize, createTheme, useMediaQuery, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { useNavigate, NavLink , useLocation} from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Handlemyaddress, Handlemyplace, Handlemyprofile, UpdateName, couponsCode } from "../Redux/action";
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Timingskeleton from './Timingskeleton';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';




const Timing = () => {



  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const save_data={
    backgroundColor:'var(--primary)',
    color:'var(--white)',
    fontWeight:'500',
    textTransform: 'none',
    marginBlock:'10px',
    fontSize:'16px',
  }

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const totalquantity = localStorage.getItem('totalquantity')
  
    const Item1 = styled(Paper)(({ theme }) => ({
      
      ...theme.typography.body2,
      padding:"20px",
      borderRadius:'6px',
      marginBottom:isSmScreen?"10px":"20px",
      boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',
    
    }));

  const businessId=localStorage.getItem('storeId');
  
    const Baseurl=process.env.REACT_APP_BASE_URL;
    const access=localStorage.getItem("accessToken");

    const { state } = useLocation();
    const { updatedCartItems, arr } = state || {};

    const updatedCartItem = JSON.parse(localStorage.getItem('updatedCartItems'))
    const arrs = JSON.parse(localStorage.getItem('arr'))

    const [currentAddress, setCurrentAddress] = useState();
  
    const Showbutton = JSON.parse(localStorage.getItem('showbuttons'));

    const [showbutton, setshowbutton]= useState(Showbutton || '');

    const [open, setopen] = useState(false)

    const [input, setinput]=useState(
      {
          c_name:"",
          c_email:"",
          gender:"",
          
      }
     )
     const [emailvalid, setemailvalid] = useState(false);

     const [fnamevalid,setfnamevalid] = useState(false);

    useEffect(()=>
    {
        if(Showbutton===null)
        {
          setshowbutton(true)
        }
    },[showbutton])

  

    const [loading, setloading]=useState(true)

   const couponType = localStorage.getItem('couponType')
   const selectedName = useSelector(state=>state.Name);

   


    const  customButton= {
      backgroundColor:'#4D1BDB',
      color:'var(--white)',
      width:'100%',
    fontSize:'16px',
    fontWeight: 500,
    textTransform:'none',
    opacity:showbutton ? '0.6':"1",
    letterSpacing: '0.8px',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        backgroundColor: '#4D1BDB', 
      },
    
    };
    
    const  customButtonprocessed= {
      backgroundColor:'#4D1BDB',
      color:'var(--white)',
    fontSize:'16px',
    fontWeight: 500,
    textTransform:'none',
    width:'100%',
    letterSpacing: '0.8px',
    opacity: showbutton ? '0.6':'1',
      '&:hover': {
        backgroundColor: '#4D1BDB', 
      },
    
    };
    
      
    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const dispatch=useDispatch();



    const businessname=localStorage.getItem('businessname');
  
    const checkedaddress =localStorage.getItem('checkedaddress')
    const [user, setuser]=useState(checkedaddress|| "");
   
    const [address, setaddress]=useState();
    const [customer, setcustomer]=useState();

  

    const [showprofile, setshowprofile]=useState(false)
   
    const couponValue= useSelector(state=>state.code);

   
   const [isLoading,setIsLoading] = useState(true)

    const totalprice1=localStorage.getItem('Discountprice')

    const [lastClickedField, setLastClickedField] = useState(null);

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [openSnackbar1, setopenSnackbar1]=useState({

      openSnackbar: false,
      vertical: 'top',
      horizontal: 'center',
     });
  
  
     const { vertical, horizontal, openSnackbar } = openSnackbar1;
  
     const handleClose = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setopenSnackbar1({...openSnackbar1, openSnackbar:false});
  
    };

  

    const navigate= useNavigate();

    const [map, setmap]=useState(true);

    const currentDate = new Date();

    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

  
    useEffect(()=>
    {
      
      const apiUrl8=`${Baseurl}/consumer/customer-address/`;
  

      const fetchData = async()=>
      {
          try 
          {
           
             setloading(true)
           const response2=await fetch(apiUrl8,
            {
              headers: {
                'Authorization' : `Bearer ${access}`
               }
            });

            const Update=await response2.json();
            console.log(Update)
            const address=Update.address_list;
            const profile=Update.customer_data;

            setaddress(address);
            setcustomer(profile);

            setinput(profile);
            if(Update.status==='success')
            {
                setloading(false)
            }

            setIsLoading(false);

          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
      }
      if (isLoading) {
        fetchData();
      }
  
  
    },[isLoading,access])
    
   
    const coupon=()=>
    {
        
        navigate(`/${businessname}/applycoupons`);
    }
 
    function formatIndianNumber(number) {
      const parts = number.toString().split(".");
      let integerPart = parts[0];
      let formattedInteger = "";
    
      while (integerPart.length > 3 && number>=10000) {
        formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
        integerPart = integerPart.slice(0, -3);
      }
      formattedInteger = integerPart + formattedInteger;
    
      return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
    }
  


const removecoupon=()=>
{
  
  dispatch(couponsCode(''));
  localStorage.removeItem('coupon_id')
}

 const handleChange=(e)=>
  {
     setshowbutton(false)
     setuser(e.target.value)

     localStorage.setItem('showbuttons', JSON.stringify(false));
     localStorage.setItem('checkedaddress', e.target.value)

     const selectedArray = address?.filter((item) => item._id=== e.target.value);

     console.log(selectedArray)
     setCurrentAddress(selectedArray)
     localStorage.setItem('currentAddress',JSON.stringify(selectedArray))

  }

  const Profilecompelete=()=>
  {
     navigate(`/${businessname}/profile`);
  }
    
    const handleClose3=()=>
    {

      if(selectedName===null || selectedName==="")
      {
         dispatch(Handlemyprofile(true))
        {isSmScreen ?setshowprofile(true)  : setopen(true)}
      }

      else 
      {

      const result = 
      (arr === undefined && updatedCartItems === undefined) 
        ? (updatedCartItem === undefined || updatedCartItem===null ? arrs : updatedCartItem)
       : (updatedCartItems === undefined || updatedCartItems===null ? arr : updatedCartItems);

  
       
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${access}`);

      
      let raw = JSON.stringify({
       
        "cart": result,
        "business_owner_id": businessId,
        "order_info": {
          "product_bill_amount": parseInt(totalprice1),
          "coupon_id": "",
          "discount": couponValue!==""?parseInt(couponValue):0,
          "total_amount": couponValue!==""?(couponType ==='Flat' ? parseInt(totalprice1-parseInt(couponValue)) :parseInt(totalprice1-(parseInt(totalprice1*couponValue)/100))):parseInt(totalprice1),
          "delivery_charge": 0,
        },
      });
      
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Baseurl}/consumer/customer-checkout/`, requestOptions)
      .then(response => response.text())
      .then(result=>{
        const data= JSON.parse(result);
        console.log(data.status);

        if(data.status==='success')
        {
             localStorage.setItem('OrderId',data.order_number)
             localStorage.setItem('data',JSON.stringify(data))
             navigate(`/${businessname}/payment`, {state: {data, currentAddress, customer}})
        }
          
  })
      .catch(error => console.log('error', error));

}  

    }

     

  const Newaddress=()=>
  {
      dispatch(Handlemyplace(true))
      dispatch(Handlemyaddress(false))
      navigate(`/${businessname}/my-address`);
  }
  const handleChanged=(e)=>
  {
      const name=e.target.name;
      const value=e.target.value;

      setinput({...input, [name]:value});
  } 

  const cancel1=()=>
  {
     setopen(false)
  }

  const Save1 =(newState)=>
  {
       
        const newfname=input.c_name;
      
       if(newfname?.length===0)
       {
            setfnamevalid(true);        
       }

       else 
       {

         setfnamevalid(false);
        
        const newemail=input.c_email;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

       
       if (emailPattern.test(newemail)) {
         
         setemailvalid(false);

            (async () => {

  
             
          
             if(access!==null)
             {
              
       
             let myHeaders = new Headers();
             myHeaders.append("Authorization", `Bearer ${access}` );
              
              let formdata = new FormData();
              formdata.append("name", input.c_name );
              formdata.append("email", input.c_email );
              formdata.append("gender", input.gender);
              formdata.append("business_owner_id", businessId);
               
              let requestOptions = {
               method: 'POST',
               headers: myHeaders,
               body: formdata,
               redirect: 'follow'
             };
             
             fetch(`${Baseurl}/consumer/customer-update/`, requestOptions)
               .then(response => response.text())
               .then(result => {
       
                 const Updatedata=JSON.parse(result);
                 
                 if(Updatedata.status==="success")
                 {
                  dispatch(UpdateName(input.c_name.split(' ')[0]));
                   setopenSnackbar1({ ...newState, openSnackbar: true });
                    setopen(false)
                    setinput({c_name:"",c_email:"",gender:""})
                   setIsLoading(true);
                 }
           
               })
               .catch(error => console.log('error', error));
             } 
        })();  
            

        } else {
          
          setemailvalid(true);
          
        }
        
       }

  }
 

  const handleFocus = (fieldName) => {
    setLastClickedField(fieldName);
  };

  
    return (
        <>
             
             <section className= {isSmScreen? "slider-header service-margin": "explore-padding"}>
           <Container maxWidth='lg' className="p-16">
             <div>
            
      { map &&     <div className="breadcrumb mobile-bodypadding">
           <Breadcrumbs aria-label="breadcrumb">
           <StyledNavLink to={`/${businessname}/`}>
             Home
           </StyledNavLink>
           <StyledNavLink to={`/${businessname}/viewcart`}>
             Cart
           </StyledNavLink>
           <StyleNavLink to={`/${businessname}/address`}>
             Checkout
           </StyleNavLink>
         </Breadcrumbs>
           </div>
}

            {loading  ?
            <>
             <Timingskeleton/>
            </>
              :
            

             <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >
          <Grid item  xs={12} sm={isSmScreen? 12:7} md={7}>

          
         {((isSmScreen && showprofile))&&<Item1 className="background" style={{cursor:'pointer'}}>
         <div className='booking_name'>Please complete your profile <span onClick={Profilecompelete}>click here</span></div>
          </Item1>}
     
       {address  && address.length!==0 && <div>
         {address.map(cur=>
          {
             return (
              <>
                 <Item1 className="global_box_shadow" style={{cursor:'pointer'}}>
          <div style={{display:'flex'}}>
          <RadioGroup name="paymentMethod" value={user} onChange={handleChange}>
              <FormControlLabel value={cur._id} checked={user === cur._id} control={<Radio />} label={<Typography></Typography>} />
                  </RadioGroup>
            
                  <div>
        
      {customer && customer[0].c_name ? <div className='price_online' style={{fontWeight:400}}>{customer[0].c_name}</div>: <div className='price_online'>Guest user</div>}
        <div className='price_online' style={{fontWeight:400}}>Mobile: +91-{customer[0].c_mobile_no}</div>
        <div className='cash' style={{fontSize:'12px'}}>{cur.ca_address1} {cur.ca_address2}</div>

        <div className='cash' style={{fontSize:'12px'}}>{cur.ca_city}, {cur.ca_state} - {cur.ca_pin_code}</div>
       
        </div>

             </div>
             </Item1>
              </>
             )
          })}
          </div>
          
     }
              <Item1 className="global_box_shadow" style={{cursor:'pointer'}} onClick={Newaddress}>
                <div className='addIconaddress' >
                  <div style={{display:'flex', alignItems:'center'}}><AddIcon/></div>
                  <div  style={{display:'flex', alignItems:'center'}}>Add address</div>
                </div>

              </Item1>

            </Grid>

            { map && 
          <Grid item xs={12} sm={isSmScreen? 12:5} md={5}>

           {couponValue==="" ?

           <Item1 className="global_box_shadow">
           <div className="coupon" onClick={coupon}>
           <div className="offerIcon">
           <div className="localoffericon"><LocalOfferIcon/></div>
            <div className="apply">Apply coupon</div>
            </div>
            
            <div className="keyboard"><KeyboardArrowRightIcon/></div>
            </div>
           </Item1>
              :
           <Item1 className="global_box_shadow">
           <div className="coupon">
           <div className="offerIcons">
           <div className="localoffericon"><LocalOfferIcon/></div>
           <div>
           <div className="apply">1 Coupon Applied</div>
           {(()=>
                 {
                  const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);

                  return  <div className="total_discount additional_save"> You saved additional ₹{formatIndianNumber(parseInt(totalservicecharge1))}</div>
                 })()}
        
           </div>
         
            </div>
            
            <button className="remove-coupon" onClick={removecoupon} >Remove coupon</button>
            </div>
           </Item1>
           }
          

            <Item1 className=" global_box_shadow">
        
          
              <div className='item_booking'>
            <div className="bill_details">Billing Details </div>
            
              <div className="totalcost">
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Item Price {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>
               {(()=>
                 {

                  const totalservicecharge1= (totalprice1)
                  return <div className="totalprices">₹{formatIndianNumber(totalservicecharge1)}</div>                     
                 })()}

              </div>
  {/*
              <div className="totalcost">
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Delivery fee</div>
             
               {(()=>
                 {

                  const totalservicecharge1= (totalprice1/10)             

                  return <div className="total_discount">FREE</div>
                     
                 })()}
                  
              </div>
              */}

              <div className="totalcost">
               <div className="total_discount">Discount</div>
               {couponValue==="" ?  
              <div className="total_discount"><RemoveIcon className="remove_discount"/>₹0</div>
                :
                  <div>

                   {(()=>
                 {

                  const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);
             

                  return <div className="total_discount"> <RemoveIcon className="remove_discount"/>₹{formatIndianNumber(totalservicecharge1)}</div>
                   

                 })()}
                 </div>
               }
              </div>

            
           <div className="border"></div>              
    
              <div className="totalcost">
               
             <div className="total_money"> Grand Total {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>
          
             
               {(()=>
                 {
                    if(couponValue==="")
                    {
                                         
                        const totalservicecharge= (parseInt(totalprice1));
      
                        return <div className="totalprices">₹{formatIndianNumber(totalservicecharge)}</div>
                    }

                    else
                    {
                     

                
                      const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);
                      const totalservicecharge= (parseInt(totalprice1) - totalservicecharge1) ;
    
                      return <div className="totalprices">₹{formatIndianNumber(totalservicecharge)}</div>
                    }
             
                     

                 })()}
             
              </div>
              </div>
                
              <Grid item xs='none'  sm={isSmScreen ? 0: 12} md={12}  display= {{ xs: 'none', md: 'flex' }} style={{ display: isSmScreen ? 'none' : 'flex',  paddingTop:'20px'}}>
                     
                     {}    <Button style={customButtonprocessed} className='secondary_button' onClick={()=>handleClose3({ vertical: 'top', horizontal: 'center' })} disabled={showbutton} >Continue</Button>
                      </Grid>
     
                 <Grid item xs={12}  sm={isSmScreen ? 0: 12}  display= {{ xs: 'flex',  md: 'none' }} style={{ display: isSmScreen ? 'flex' : 'none', paddingTop:'20px' }} justifyContent={'center'}>
     
                 <Button style={customButton} className='secondary_button' onClick={()=>handleClose3({ vertical: 'top', horizontal: 'center' })} disabled={showbutton} >Continue</Button>
    
               
                  </Grid>

            </Item1>
         
                                
            </Grid>
      }
            </Grid>
            </Box>
           
    }   
                           
                           <Modal open={open}> 
                           <Item1 className="completeprofile">
                            <form>
             <div className="personalinfo">Personal Info</div>
             
              <Box component="form" sx={{ '& > :not(style)': { m: 1,},  }} noValidate  autoComplete="off" className="form-input" >
          
             
              <div className="edit" style={{display:"flex", justifyContent:'end'}} onClick={cancel1}><CloseIcon/></div>
           
              <div>
             
              <TextField id="first_name" label="Name" value={input.c_name}   
              name="c_name"   onChange={handleChanged} 
              className="text_field"  autoFocus={lastClickedField === 'name'}
              onFocus={() => handleFocus('name')} />
              {fnamevalid &&  <div><label className="validemail field_name">Name is required</label></div>}
              </div>
  

             <div>
             

             <RadioGroup  name="gender"  onChange={handleChanged} >
                <div className="gender">
               <FormControlLabel value="Male"  checked={input.gender === 'Male'}   control={<Radio style={{ color: input.gender === "Male" ? 'var(--primary)' : '' }}  />} label=<Typography style={{fontSize:"14px"}}>Male</Typography>  />
               <FormControlLabel value="Female"   checked={input.gender === 'Female'}  control={<Radio style={{ color: input.gender === "Female" ? 'var(--primary)' : '' }}  />} label=<Typography style={{fontSize:"14px"}}>Female</Typography>  />
               </div>
              </RadioGroup>
              </div>
      
              
  
                <div>
              <div><TextField id="email" type="email" 
              label="Email Address" value={input.c_email}  
              name="c_email" onChange={handleChanged} className="text_field"
              autoFocus={lastClickedField === 'email'}
              onFocus={() => handleFocus('email')} /></div>
              {emailvalid &&  <div><label className="validemail">Enter a valid email</label></div>}
      
              </div>
                 
              </Box>
         
              <div className="save_profile">
          <Button style={save_data}  className="primary_button" onClick={()=>Save1({ vertical: 'top', horizontal: 'center' })}>Save</Button></div>
            </form>
            </Item1> 
                   </Modal>
                  
                   <Stack spacing={2} sx={{ width: '100%' }}>
                              
                              <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
                            <Alert  severity="success" sx={{ width: '100%' }}>
                            Profile updated successfully !
                            </Alert>
                            </Snackbar>

                            </Stack> 
           
   </div>
   </Container>

             </section>

        </>
      
    
  )

}


export default Timing;

/* 

    
    

            const response3=await fetch(apiUrl17,
              {
                headers: {
                  'Authorization' : `Bearer ${access}`
                 }
              });
  
              const Updatedata=await response3.json();;
              const checkoutdatas=Updatedata.data;
             
              setitem(checkoutdatas);

         
              const response4 = await fetch(apiUrl19);
              const Updated = await response4.json(); 
              // console.log(Update);
               setbookingdetails(Update);
               setservice(Updated.booking_details.service_name);
               setbilling(Updated.booking_details.billing_details);
           //    console.log(Updated.booking_details.service_name)

             

   <Modal open={open3}>
              <div className='locationopened'>
              <div className="locationheader">
                      
                        <div className="locationIcons">
                        <div className='mylocation'><MyLocationIcon /></div>
                    <span className="head_title explore locationname ">Location</span>
                    </div>
                    
                    </div>


                        <div className="locationbody">
    
                    <div className="secondary_title explore">Address</div>
                      
                   {!invalid && <TextField id="house"  label="Enter House / Flat / Block no"   style={{width:"100%"}} value={user.house}   name="house"     onChange={handleChange}   sx={{'& input::placeholder': {  fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1",   },  }} />}
                    {invalid &&   <TextField
          error
          id="outlined-error"
          label="Error"
          className="login_data"   type="text"  style={{width:"100%"}} value={user.house}   name="house"        onChange={handleChange}   sx={{'& input::placeholder': {  fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1"     },  }} 
          
        />}
                   <div>{invalid  && <label className="login_exist">Enter a valid house address.</label>}</div>

                  {!invalid1 && <TextField id="apartment"  label="Enter Landmark / Road / Area"    style={{width:"100%"}}    value={user.apartment}   name="apartment"    onChange={handleChange}  sx={{'& input::placeholder': {  fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1"     },  }}  />}
                   
                  {invalid1 &&   <TextField
          error
          id="outlined-error"
          label="Error"
          className="login_data"   type="text"  style={{width:"100%"}}    value={user.apartment}   name="apartment"    onChange={handleChange}  sx={{'& input::placeholder': {  fontSize:'14px', fontWeight:'500', fontFamily:'Montserrat', opacity:"1"     },  }} 
          
        />}
              <div>{invalid1  && <label className="login_exist">Enter a valid road area.</label>}</div>

                    <h1 className="direction">Direction to Reach</h1>

                  <TextareaAutosize minRows={4}className="textareaauto" placeholder="e.g near to the shop" ></TextareaAutosize>
                       
             </div>   
                 

                    <div className="loginfooter custom_button">
                    <Button style={ customButtonview}  className='secondary_button' onClick={Cancelconfirmlocation} >Back</Button>
                      <Button style={Confirmbuttonaddress} className='primary_button' onClick={Confirmpayment}disabled={Confirmbutton} >Confirm Location</Button>
                        </div>
                     
                  </div>
                         


              </Modal>



line number 746
     {}    <Button style={customButtonprocessed} className='primary_button' onClick={handleClose3}  disabled={showbutton}>Continue</Button>


  line number 612

 <Item1 className='global_box_shadow'>
             <div className="payment_online">
             
            <RadioGroup  name="payment"  onChange={handleChange} >
               <div className="payment_online">
              <FormControlLabel value="online"  checked=''   control={<Radio/>} label={<Typography>
                <div >
                 
                <div className="priceonline"></div>
             
                
               
      
                </div>
                 </Typography> 
                    
                }  />
           
              </div>
             </RadioGroup>
             </div>    

             </Item1>


  <RadioGroup  name="payment"  onChange={handleChange} >
               <div className="payment_online">
              <FormControlLabel value="online"  checked=''   control={<Radio  style={{ color: input.payment === 'online' ? 'var(--primary)' : '' }}  />} label={<Typography>
                <div >
                 
                <div className="priceonline">Pay Online</div>
             
                
                <div>
                       <span className="Pay-online" >Get instant <span className="totalsave">₹{parseInt(totalAmount*0.02)}</span> on paying online </span>
                </div>
      
                </div>
                 </Typography> 
                    
                }  />
           
              </div>
             </RadioGroup>

  <div className='current-addressdata'>{currentAddress}</div>


  <div className='dashed_border saved_border'></div>
        
        let requestOption = {
         method: 'GET',
         headers: myHeaders,
         redirect: 'follow'
       };

       fetch(`${Baseurl}/address/`, requestOption)
       .then(response => response.text())
       .then(result=>{
           const data= JSON.parse(result);
           const Address=data.address_list;
           setsaveAddress(Address);
           console.log(Address);
       })
       .catch(error => console.log('error', error));
   


       

                {currentAddress!==''  &&  
                
                  
                <div style={showcurrent} className="mylocations" onClick={Currentlocation} >


                
                <div  className="locationIcon">
                 <div><Homologo  /></div>
                 <div className='saved_currentlocation' >
                 <div className='additional_title' >Home</div>
                 <div className='current-address'>{currentAddress}</div>
                 </div>
                  </div> 
                        
          
                       
                 
                      </div>
          
                    
                }


      
                    </div>
                       
                 

                  

            

        

  
    
                    


            */