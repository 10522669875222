import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Header from "../Header/header";
import { createTheme, useMediaQuery } from '@mui/material';
import StarRatings from 'react-star-ratings';
import SearchFilter from "../Header/SearchFilter";
import { Message } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import Bottomnavigation from "../Home/BottomNavigation";
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from "react-router-dom";
import HeaderRating from "./HeaderRating";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Ratingskeleton from "./Ratingskeleton";

const Item = styled(Paper)(({ theme }) => ({
    
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom:'20px',
}));
const Review=()=>
{
    

  const theme = createTheme({
    breakpoints: {
      values: {
        md: 0,
        sm: 768,
      },
    },
  });

  const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: 'inherit',
    fontSize:'12px',
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const StyleNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    ariaCurrent:"page",
    fontSize:'12px',
    color:"var(--primary)",
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

    const [item, setItem] = useState('');

    const access= localStorage.getItem("accessToken");
    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');
    const Baseurl=process.env.REACT_APP_BASE_URL;
    const categoryname=localStorage.getItem('Categoryname');
    const servicename =localStorage.getItem('productname');
    const Categoryname=categoryname?.replace(/ /g, '-');
    const sanitizedServiceName = servicename?.replace(/ /g, '-');

    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);
    const [progress3, setProgress3] = useState(0);
    const [progress4, setProgress4] = useState(0);
    const [progress5, setProgress5] = useState(0);
    const [review, setReview] = useState('');
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);

    const [loading, setloading]=useState(false)

    const datas=localStorage.getItem('DataId')
    const [Update, setUpdate] =useState();

    const [selectedImage, setSelectedImage] = useState(null);
  
    const [selectedReview, setSelectedReview] = useState([]);
    const [open, setOpen] = useState(false);
    
   


     const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

     const updateLocation=(locationdata)=>
{
    setUpdate(locationdata);
    console.log(locationdata);
}



    useEffect(()=>
    {
      
      setloading(true)
  
       fetch(`${Baseurl}/consumer/customer-get-reviews-web/${datas}/?business_owner_id=${businessId}`)
        .then(response => response.text())
        .then(result => {
          const data=JSON.parse(result);
          const  Categorydata = data.review_counter;
         
          const Reviewdata = data.reviews

          setItem( Categorydata );
          setData(Reviewdata);
          setloading(false)
    
        })
        .catch(error => console.log('error', error));
      
      
    
    },[])

    useEffect(() => {

    

        const fetchProgressFromAPI = async () => {
          try {
  
            const fetchedProgress = item.total_count; 
        
            setProgress1((item.separated.rate_5/fetchedProgress)*100);

            setProgress2((item.separated.rate_4/fetchedProgress)*100);
            setProgress3((item.separated.rate_3/fetchedProgress)*100);
            setProgress4((item.separated.rate_2/fetchedProgress)*100);
            setProgress5((item.separated.rate_1/fetchedProgress)*100);

            setShow(true);

          } catch (error) {
            console.error('Error fetching progress:', error);
            setShow(false);
          }
        };
    
        fetchProgressFromAPI();
      
      }, [progress1, progress2, progress3, progress4, progress5,item]);

      const customStyles = {
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'green', // Replace with your desired color for the unfilled bar
        },
      };
      const customStyles2 = {
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'orange', // Replace with your desired color for the unfilled bar
        },
      };

      const customStyles3 = {
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'red', // Replace with your desired color for the unfilled bar
        },
      };

 
   const handleLoading=()=>
{

}

const picture=(Id, index)=>
{
    setOpen(true);
    setSelectedImage(Id);
  

    const review = data.find((cur) => {
      const reviewImagesString = cur?.c_review_images.replace(/'/g, '"');
      const reviewImages = reviewImagesString && JSON.parse(reviewImagesString);
      return reviewImages.some((img,index) => cur._id===Id);
    });
    

    const reviewImagesString = review?.c_review_images.replace(/'/g, '"');
    const reviewImages = reviewImagesString && JSON.parse(reviewImagesString);
    
  setSelectedReview(reviewImages);
}
const handle=()=>
{
  setOpen(false);
  setSelectedImage(null);
}

   return (
    <>   

            <div className="contain">
              {isSmScreen ? <HeaderRating/> : <Header  updateLocation={updateLocation} handleLoading={handleLoading}  />}
              
              <div className="content" style={{backgroundColor:"#f3f3f3"}}>

             
            <section className= {isSmScreen? "slider-header service-margin": "section1 explore-padding"}>
            <Container maxWidth="lg" className="container-padding">

               <div className="breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyledNavLink
        
        to={`/${businessname}/search`}
      >
         {categoryname}

      </StyledNavLink>
      <StyledNavLink
   
        to={`/${businessname}/product/${Categoryname}/${sanitizedServiceName}`}
        
      >
         {servicename}
      </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/review`}  
        >
          Ratings & Reviews
        </StyleNavLink>
        
      </Breadcrumbs>
      </div>

          {loading ?
               <>
                 <Item className="global_box_shadow">
                 <Ratingskeleton/>
                 </Item>
              </>
              :
         

             <Item className="global_box_shadow">
             <div className="item_additional">
          <h1 className=" head_title explore">Ratings & Reviews</h1>
             
          <Box sx={{width:"100%"}}   >
          <Grid container rowSpacing={2}  columnSpacing={{xs:2, sm:3, md:4}} display="flex" alignItems="center" padding="10px 0px">
       
         <Grid item className="rating_review_datas" >
         <div >
           <div className="rating_average_value">{item?.average}</div>
           <div className="star_value_data">
           <StarRatings rating={item?.average}   starRatedColor="rgb(2, 74, 2)"  numberOfStars={5}  name='rating'  starDimension="15px"   starSpacing="4px"   />
           </div>
           </div>

         </Grid>


           
          


           <Grid item xs={5}  sm={isSmScreen ? 6 :'none'} md="none" className="reveiwing_data">

           <div className="rating_review_data">
           <div className="rating_average_value">{item?.average}</div>
           <div className="star_value_data">
           <StarRatings rating={item?.average}   starRatedColor="rgb(2, 74, 2)"  numberOfStars={5}  name='rating'  starDimension="15px"   starSpacing="4px"   />
           </div>
           </div>

           </Grid>
          

        
          </Grid>
        </Box>
         
          <Box sx={{width:"100%"}}>
         
          <div className="customer-said">Customer Reviews</div>
          <Grid container>

          <Grid item xs={12} sm={12} md={12} >
        
         <div className="review_details">

          {data?.map(cur=>
          {
            const reviewImagesString = cur?.c_review_images?.replace(/'/g, '"');
            const reviewImages = reviewImagesString && JSON.parse(reviewImagesString);
            
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const date = new Date(cur.updated_on);
            const formattedDate = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;

              return (
                <>

                 
                    <div className="overall_review">
                    <div className="overall_caption">
                    <div className="review_message">{cur?.c_review_message}</div>
                    <div className="overall_starrating">
                    <div>{cur.c_rating}</div>
                    <StarIcon  className="starvalues"/>
                 </div>
                    
                    </div>
                 
                    <div className="image_profile_data">
                    {reviewImages && reviewImages.length > 0 ? (
                        reviewImages.map((image, index) => (
                          <div onClick={() => picture(cur._id, index)} key={index}>
                            <img
                              className="image_picture"
                              src={image}
                              alt="category"
                              onError={(e) => {
                                e.target.src = "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png";
                                e.target.alt = 'No Image';
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div>
                          <img
                            className="image_picture"
                            src="https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                            alt="No Image"
                          />
                        </div>
                      )}

                     </div>
                      
                     <div className="reviewed_on">Reviewd on {formattedDate}</div>
                    </div>
                </>
              )

          })}

            {selectedImage && <Modal open={Boolean(selectedImage)}>
                   
                   
                   <div className="gallery_imagedata">
                      
                      <div className="view_image_gallery">
                      <div>
  
                         <h3 className="secondary_title">Review Image</h3>  
                      </div>
                     
                      <div className="right_images">
                     <div className="closeicon" ><CloseIcon style={{transform:"scale(1.2)"}} onClick={handle}/></div> 
                      </div>
                      </div>

                                <div>
                                <Carousel interval={3000} showThumbs={false} showStatus={false} infiniteLoop={true}>
                                
                                {selectedReview.map((image, indeximage) => (
                                           
                                    <div key={indeximage} className="image_datashow">
                                      <img
                                        className="image_datas"
                                        src={image}
                                        alt={`Review Image ${indeximage}`}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  ))}
                                 
                                  </Carousel>

                                 </div>
                                
                                 </div>
                          
                   </Modal>
                   
                 
                   }

          </div>
          </Grid>
              
           


              </Grid>
              </Box>
</div>
          </Item>
}
          </Container>
          </section>
         
          </div>
          <Footer/>
        
          </div>

    </>
   )

}

export default Review;

/*
  <Grid item  xs={7}  sm={isSmScreen ? 6 : 6} md={6}>

        <div className="show_rating"> 
         <div className="starrating">5
          <div> <StarIcon className="starvalue"/></div>
        </div>
        <LinearProgress variant="determinate" value={progress1}  sx={customStyles} color="inherit" />
      {show &&  <div className="show_rated">{item?.separated.rate_5}</div> }
        </div> 

        <div className="show_rating">
        <div className="starrating">4
          <div> <StarIcon  className="starvalue"/></div>
        </div>
          <LinearProgress variant="determinate" value={progress2}  sx={customStyles} color="inherit"/>
          {show &&     <div className="show_rated">{item?.separated.rate_4}</div>}
        </div>

          <div className="show_rating">
          <div className="starrating">3
          <div> <StarIcon  className="starvalue"/></div>
        </div>
          <LinearProgress variant="determinate" value={progress3} sx={customStyles} color="inherit" />
          {show &&     <div className="show_rated">{item?.separated.rate_3}</div>}
          </div>

          <div className="show_rating">
          <div className="starrating">2
          <div> <StarIcon  className="starvalue"/></div>
        </div>
          <LinearProgress variant="determinate" value={progress4} sx={customStyles2} color="inherit" />
          {show &&   <div className="show_rated">{item?.separated.rate_2}</div>}
          </div>

          <div className="show_rating">
          <div className="starrating">1
          <div> <StarIcon  className="starvalue"/></div>
        </div>
        <LinearProgress variant="determinate" value={progress5} sx={customStyles3} color="inherit" /> 
        {show &&   <div className="show_rated">{item?.separated.rate_1}</div>}

          </div>
          </Grid>
    


        <div className="show_rated">{item.separated.rate_1}</div>

                     */