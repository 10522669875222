
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Box, Grid, TextareaAutosize, createTheme, useMediaQuery, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { couponsCode } from "../Redux/action";
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useRazorpay from "react-razorpay";
import Paymentcompleteskeleton from './Paymentcompleteskeleton';






const Paymentcomplete = () => {



  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  
    const Item1 = styled(Paper)(({ theme }) => ({
      
      ...theme.typography.body2,
      padding:"20px",
      borderRadius:'6px',
      marginBottom:isSmScreen?"10px":"20px",
      boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',
    
    }));

  const businessId=localStorage.getItem('storeId');
  
    const Baseurl=process.env.REACT_APP_BASE_URL;
    const access= localStorage.getItem("accessToken");

    const currentlocation=JSON.parse(localStorage.getItem('currentAddress'))

    const datas=JSON.parse(localStorage.getItem('data'))

    const { state } = useLocation();
    const { data, currentAddress, customer } = state || {};

  
    const Showbutton = JSON.parse(localStorage.getItem('showbuttonpayment'));

    const [showbutton, setshowbutton]= useState(Showbutton || '');

    useEffect(()=>
    {
        if(Showbutton===null)
        {
          setshowbutton(true)
        }
    },[showbutton])

    const [loading, setloading]=useState(true)

    const totalprice1=localStorage.getItem('Discountprice')

    const  customButton= {
      backgroundColor:'#4D1BDB',
      color:'var(--white)',
      width:'100%',
    fontSize:'16px',
    fontWeight: 500,
    textTransform:'none',
    opacity:showbutton ? '0.6':"1",
    letterSpacing: '0.8px',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        backgroundColor: '#4D1BDB', 
      },
    
    };
    
    const  customButtonprocessed= {
      backgroundColor:'#4D1BDB',
      color:'var(--white)',
    fontSize:'16px',
    fontWeight: 500,
    textTransform:'none',
    width:'100%',
    letterSpacing: '0.8px',
    opacity: showbutton ? '0.6':'1',
      '&:hover': {
        backgroundColor: '#4D1BDB', 
      },
    
    };
    
      
      const  customButtonsdataopacity= {
        backgroundColor:'#57339F',
        color:'var(--white)',
      fontSize:'16px',
      textTransform:'none',
      fontWeight: 500,
      width:isSmScreen?'100%':'none',
      opacity:'0.6',
        '&:hover': {
          backgroundColor: '#57339F', 
          
        },
      
      };
    
    

    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const dispatch=useDispatch();

 

    const businessname=localStorage.getItem('businessname');
  
    const couponType = localStorage.getItem('couponType')

    const [address, setaddress]=useState('');
   
    const couponValue= useSelector(state=>state.code);

    const totalquantity = localStorage.getItem('totalquantity')

    const [invalid, setInvalid] = useState(false);
    const [invalid1, setInvalid1] = useState(false);
   
  
   const Screen = JSON.parse(localStorage.getItem('screen'));
 
  

    const navigate= useNavigate();


    const checkedpayment =localStorage.getItem('checkedpayment')
    const [user, setuser]=useState(checkedpayment|| "");


    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading]=useState(false)


    const [map, setmap]=useState(true);

    const currentDate = new Date();

    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

    const [Confirmbutton, setconfirmbutton]=useState(true);

    const [Confirmbuttonaddress, setconfirmbuttonaddress]=useState(customButtonsdataopacity);

    const [saveAddress, setsaveAddress]=useState([]);
   
      
    const [Razorpay] = useRazorpay();

    const OrderId= localStorage.getItem('OrderId')
  
    const updatedCartItem = JSON.parse(localStorage.getItem('updatedCartItems'))
    const arrs = JSON.parse(localStorage.getItem('arr'))

    console.log(arrs)
    console.log(updatedCartItem)

    useEffect(()=>
    {
          
      
    },[isLoading])

   
  
    const coupon=()=>
    {
        localStorage.setItem('payment','payment')  
        navigate(`/${businessname}/applycoupons`);
    }
    useEffect(()=>
{
   
   
},[couponValue])


const removecoupon=()=>
{
  
  dispatch(couponsCode(''));
  localStorage.removeItem('coupon_id')
}


    const handleChange=(e)=>
    {
          
          setshowbutton(false)
          setuser(e.target.value)
          localStorage.setItem('showbuttonpayment', JSON.stringify(false))
          localStorage.setItem('checkedpayment', e.target.value)
    }

  
    const handlePaymentRazorpay = async (params) => {
  
    
      const Amount = parseInt(totalprice1);
     
       const options = {
         key :`${(data.payment_options.find((option) =>
          option.payment_via === 'Razorpay'
        )?.secret_key || 'rzp_test_kDAJKbFqaSqr6I')}`,
         amount:Amount*100,
         currency: "INR",
         name: "Parashar Creative Studio",
         description: "Test Transaction",
         image: "https://example.com/your_logo",
   
         handler: function (response) {
           const razorpay= response.razorpay_payment_id;
           console.log(razorpay);
  
           if(razorpay!=="")
           {
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);
  
            let formdata = new FormData();
            formdata.append("transaction_id", razorpay);
            formdata.append("order_number", OrderId);
            
            let requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
            };
  
            
        fetch(`${Baseurl}/consumer/customer-payment-status-update/`, requestOptions)
        .then(response => response.text())
        .then(result=>{
          const data= JSON.parse(result);
          console.log(data.status);
  
          if(data.status==='success')
          {
            
            localStorage.removeItem('data')
            localStorage.removeItem('arr')
            localStorage.setItem('paymentType', user)
            navigate(`/${businessname}/bookingsuccessfull`)
            window.history.pushState({ fromPaymentPage: true }, '',  window.location.href);
          }
            
      })
        .catch(error => console.log('error', error));
            
           }              
           
         },
         prefill: {
          name: customer.c_name,
          email: customer.c_email,
          contact:customer.c_mobile_no,
        },
        notes: {
          address:`${currentAddress[0].ca_address1} + ${currentAddress[0].ca_address2}`,
        },
        theme: {
          color: "#000000",
        },
       };
     
       const rzp1 = new Razorpay(options);
     
       rzp1.on("payment.failed", function (response) {
         alert(response.error.code);
         alert(response.error.description);
         alert(response.error.source);
         alert(response.error.step);
         alert(response.error.reason);
         alert(response.error.metadata.order_id);
         alert(response.error.metadata.payment_id);
       });
     
       rzp1.open();
     };
 
 

    const Placeorder=()=>
    {

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${access}`);
  
        
        let raw = JSON.stringify({
         
          "cart": (updatedCartItem===undefined ||updatedCartItem===null)?arrs:updatedCartItem,
          "business_owner_id": businessId,
          "order_info": {
            "product_bill_amount": parseInt(totalprice1),
            "coupon_id": "",
            "discount":couponValue!==""?parseInt(couponValue):0,
            "total_amount": couponValue!==""?(couponType ==='Flat' ? parseInt(totalprice1-parseInt(couponValue)) :parseInt(totalprice1-(parseInt(totalprice1*couponValue)/100))):parseInt(totalprice1),
            "delivery_charge": 0,
          },

          "order_number": OrderId,
          "city":((currentAddress!==undefined) ?currentAddress[0].ca_city:currentlocation[0].ca_city),
          "state":((currentAddress!==undefined) ?currentAddress[0].ca_state : currentlocation[0].ca_state),
          "zipcode":((currentAddress!==undefined) ?currentAddress[0].ca_pin_code :currentlocation[0].ca_pin_code) ,
          "address_details": [
            {
              "address": ((currentAddress!==undefined) ?`${currentAddress[0].ca_address1} ${currentAddress[0].ca_address2}`: `${currentlocation[0].ca_address1} ${currentlocation[0].ca_address2}`)
            }
          ],
          "date": formattedDate,
          "payment_type": user
         
        });
        
        let requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
  
        fetch(`${Baseurl}/consumer/customer-checkout/`, requestOptions)
        .then(response => response.text())
        .then(result=>{
          const data= JSON.parse(result);
          console.log(data.status);
  
          if(data.status==='success')
          {
          
            if(user==='COD')
            {
    
              let myHeaders = new Headers();
              myHeaders.append("Authorization", `Bearer ${access}`);
    
              let formdata = new FormData();
              formdata.append("transaction_id", "");
              formdata.append("order_number", data.order_id);
              
              let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              };
    
              
          fetch(`${Baseurl}/consumer/customer-payment-status-update/`, requestOptions)
          .then(response => response.text())
          .then(result=>{
            const data= JSON.parse(result);
            console.log(data.status);
    
            if(data.status==='success')
            {
              localStorage.removeItem('arr')
               localStorage.setItem('paymentType', user)
               localStorage.removeItem('data')
              navigate(`/${businessname}/bookingsuccessfull`)
              window.history.pushState({ fromPaymentPage: true }, '',  window.location.href);
            }
              
      })
          .catch(error => console.log('error', error));
    
             
            }
           else if(user==='Paytm')
           {
            localStorage.removeItem('data')
             navigate(`/${businessname}/bookingsuccessfull`)
             window.history.pushState({ fromPaymentPage: true }, '',  window.location.href);
           }
    
           else 
           {
        
               handlePaymentRazorpay()
           }
            
          }
            
    })
        .catch(error => console.log('error', error));
      
    }

     
    function formatIndianNumber(number) {
      const parts = number.toString().split(".");
      let integerPart = parts[0];
      let formattedInteger = "";
    
      while (integerPart.length > 3 && number>=10000) {
        formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
        integerPart = integerPart.slice(0, -3);
      }
      formattedInteger = integerPart + formattedInteger;
    
      return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
    }
  
    console.log("datas",data)
  
    return (
        <>
             
             <section className= {isSmScreen? "slider-header service-margin": "explore-padding"}>
           <Container maxWidth='lg' className="p-16">
             <div>
            
      { map &&     <div className="breadcrumb mobile-bodypadding">
           <Breadcrumbs aria-label="breadcrumb">
           <StyledNavLink to={`/${businessname}/`}>
             Home
           </StyledNavLink>

           <StyledNavLink to={`/${businessname}/viewcart`}>
             Cart
           </StyledNavLink>

           <StyledNavLink to={`/${businessname}/address`}>
             Checkout
           </StyledNavLink>

           <StyleNavLink to={`/${businessname}/payment`}>
             Payment
           </StyleNavLink>
         </Breadcrumbs>
           </div>
}
        
          

             <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >
          <Grid item  xs={12} sm={isSmScreen? 12:7} md={7}>

            
       
             <Item1 className='global_box_shadow'>

           {datas?.payment_options.some((option) =>
              option.payment_via === 'COD' &&
              option.status === 1
       )         &&   <div className="payment_onlines " style={{alignItems:"start"}} >
        
        
        <div>
           <RadioGroup name="paymentMethod" value={user} onChange={handleChange}>
              <FormControlLabel value="COD" checked={user === 'COD'} control={<Radio />} label={<Typography></Typography>} />
                  </RadioGroup>
                  </div>
             <div>
              <div className="priceonline" style={{fontWeight:400}}>COD</div>
              <div className='cash'>Pay in cash or pay in person at the time of delivery</div>
                </div>
           
            </div>
          
        }

       {datas?.payment_options.some((option) =>
              option.payment_via === 'Paytm' &&
              option.status === 1
       )         && 
          <div className="payment_onlines apply_border_top" style={{alignItems:"start"}}>

          <div style={{marginRight:'-16px'}}>
          <RadioGroup name="paymentMethod" value={user} onChange={handleChange}>
              <FormControlLabel value="Paytm" checked={user === 'Paytm'} control={<Radio />} label={<Typography></Typography>} />
                  </RadioGroup>
                  </div>

          <div>
            <div className="priceonline" style={{fontWeight:400}}>Paytm</div>
            <div className='cash'>Seamless Transactions through Paytm Payment Gateway</div>
              </div>
         
            </div>

    }          

          {datas?.payment_options.some((option) =>
              option.payment_via === 'Razorpay' &&
              option.status === 1
          )         && 
                <div className='payment_onlines apply_border_top'>
                     <div>
                  <RadioGroup name="paymentMethod" value={user} onChange={handleChange}>
                        <FormControlLabel value="Razorpay" checked={user === 'Razorpay'} control={<Radio />} label={<Typography></Typography>} />
                </RadioGroup>
                </div>
                <div>
                <div className="priceonline" style={{fontWeight:400}}>Razorpay</div>
                <div className='cash'>Boost Payments with Razorpay Payment Gateway</div>
            </div>
         
      </div>
   } 
       </Item1>


            </Grid>

            { map && 
          <Grid item xs={12} sm={isSmScreen? 12:5} md={5}>

           {couponValue==="" ?

           <Item1 className="global_box_shadow">
           <div className="coupon" onClick={coupon}>
           <div className="offerIcon">
           <div className="localoffericon"><LocalOfferIcon/></div>
            <div className="apply">Apply coupon</div>
            </div>
            
            <div className="keyboard"><KeyboardArrowRightIcon/></div>
            </div>
           </Item1>
              :
           <Item1 className="global_box_shadow">
           <div className="coupon">
           <div className="offerIcons">
           <div className="localoffericon"><LocalOfferIcon/></div>
           <div>
           <div className="apply">1 Coupon Applied</div>
           {(()=>
                 {
                  const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);

                  return  <div className="total_discount additional_save"> You saved additional ₹{formatIndianNumber(parseInt(totalservicecharge1))}</div>
                 })()}
        
           </div>
         
            </div>
            
            <button className="remove-coupon" onClick={removecoupon} >Remove coupon</button>
            </div>
           </Item1>
           }
          

            <Item1 className=" global_box_shadow">
        
          
              <div className='item_booking'>
            <div className="bill_details">Billing Details </div>
            
              <div className="totalcost">
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Item Price {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>
               {(()=>
                 {

                  const totalservicecharge1= (totalprice1)
                  return <div className="totalprices">₹{formatIndianNumber(totalservicecharge1)}</div>                     
                 })()}

              </div>
{/*
              <div className="totalcost">
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Delivery fee</div>
             
               {(()=>
                 {

                  const totalservicecharge1= (totalprice1/10)             

                  return <div className="total_discount">FREE</div>
                     
                 })()}
                  
              </div>
              */}

              <div className="totalcost">
               <div className="total_discount">Discount</div>
               {couponValue==="" ?  
              <div className="total_discount"><RemoveIcon className="remove_discount"/>₹0</div>
                :
                  <div>

                   {(()=>
                 {

                  const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);
             

                  return <div className="total_discount"> <RemoveIcon className="remove_discount"/>₹{formatIndianNumber(totalservicecharge1)}</div>
                   

                 })()}
                 </div>
               }
              </div>

            
           <div className="border"></div>              
    
              <div className="totalcost">
               
              <div className="total_money"> Grand Total {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>
          
             
               {(()=>
                 {
                    if(couponValue==="")
                    {
                                         
                        const totalservicecharge= (parseInt(totalprice1));
      
                        return <div className="totalprices">₹{formatIndianNumber(totalservicecharge)}</div>
                    }

                    else
                    {
                     

                      const totalservicecharge1= couponType ==='Flat' ? parseInt(couponValue) :parseInt((totalprice1*couponValue)/100);
                      const totalservicecharge= (parseInt(totalprice1)  - totalservicecharge1) ;
    
                      return <div className="totalprices">₹{formatIndianNumber(totalservicecharge)}</div>
                    }
             
                     

                 })()}
             
              </div>
              </div>
                
              <Grid item xs='none'  sm={isSmScreen ? 0: 12} md={12}  display= {{ xs: 'none', md: 'flex' }} style={{ display: isSmScreen ? 'none' : 'flex',  paddingTop:'20px'}}>
                     
                     {}    <Button style={customButtonprocessed} className='secondary_button' onClick={Placeorder}>Place Order</Button>
                      </Grid>
     
                 <Grid item xs={12}  sm={isSmScreen ? 0: 12}  display= {{ xs: 'flex',  md: 'none' }} style={{ display: isSmScreen ? 'flex' : 'none', paddingTop:'20px' }} justifyContent={'center'}>
     
                 <Button style={customButton} className='secondary_button' onClick={Placeorder}>Place Order</Button>
    
               
                  </Grid>

            </Item1>
         
                            
         
            
         
            </Grid>
}
            </Grid>
            </Box>

           
   </div>
   </Container>

             </section>

        </>
      
    
  )

}


export default Paymentcomplete;

/* 

 

  key: "rzp_test_kDAJKbFqaSqr6I", 
  line number 612

 <Item1 className='global_box_shadow'>
             <div className="payment_online">
             
            <RadioGroup  name="payment"  onChange={handleChange} >
               <div className="payment_online">
              <FormControlLabel value="online"  checked=''   control={<Radio/>} label={<Typography>
                <div >
                 
                <div className="priceonline"></div>
             
                
               
      
                </div>
                 </Typography> 
                    
                }  />
           
              </div>
             </RadioGroup>
             </div>    

             </Item1>

*/