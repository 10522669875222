import React,{useState, useEffect} from "react";
import Header from "../Header/header.js";
import Bottomnavigation from "../Home/BottomNavigation";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import {createTheme,  useMediaQuery } from '@mui/material';
import  SearchFilter from "../Header/SearchFilter.js"
import Thanks from "./Thanku";
import { useNavigate } from "react-router-dom";


const Thanking=()=>
{


    

     const businessname=localStorage.getItem('businessname');

        const navigate = useNavigate();

     const theme = createTheme({
          breakpoints: {
            values: {
              xs: 0,
              sm: 768,
            },
          },
        });
      
    
      
        const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


 
    
     const handleLoading=()=>
     {

     }

     const updateLocation=()=>
     {

     }


 /*
useEffect(() => {
  const handleBackButton = (e) => {
    if (e.state && e.state.fromPaymentPage) {

       navigate(`/${businessname}/`);
    }
    else
    {
      navigate(`/${businessname}/`);
    }
  };

  window.addEventListener('popstate', handleBackButton);
  return () => {
    window.removeEventListener('popstate', handleBackButton);
  };
}, []);

*/



     return (
        <>
         
         <div className="contain">
               {isSmScreen? "" : <Header   updateLocation={updateLocation} handleLoading={handleLoading} /> }
            <div className="content">
             <Thanks/>  
           
           

             </div>
             {isSmScreen?"":  <Footer/>  }
           </div>

        </>
     )
}

export default Thanking;