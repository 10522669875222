import React,{useState, useEffect} from "react";
import { Box, Grid, createTheme, useMediaQuery, TextareaAutosize } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import StarRatings from 'react-star-ratings';
import Button from '@mui/material/Button';
import {ReactComponent as Addlogo} from "../assests/Photo.svg";
import { useNavigate,NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const Item = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   borderRadius:'6px',
   marginBottom:"20px",
   padding:'20px',

 }));

 const Items = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   padding:'20px',
   marginBottom:"20px",
   borderRadius:'6px',


 }));
 
 function getCurrentDateTime() {
   const options = {
     day: 'numeric',
     month: 'short',
     year: 'numeric',
     hour: 'numeric',
     minute: '2-digit',
     hour12: true,
   };
 
   const currentDateTime = new Date().toLocaleString('en-US', options);
   const formattedDateTime = currentDateTime.replace(',', '');
   return formattedDateTime;
 }


const Customerreview=()=>
{
     const [showbutton, setshowbutton]=useState(true);

    const Currenttime= getCurrentDateTime();

    const  customButtons= {
      backgroundColor:'#57339F',
      color: '#ffffff',
      width:'100%',
    fontSize:'16px',
    border:'2px solid var(--primary)',
    opacity:'0.7',
    letterSpacing: '0.8px',  
      '&:hover': {
          backgroundColor:'#57339F',
      },
    
    }; 
    
    const custom=
    {
      backgroundColor:'#57339F',
      color: '#ffffff',
      width:'100%',
      textTransform:'none',
    fontSize:'16px',
    border:'2px solid var(--primary)',
    outline:'none',
    opacity:showbutton?'0.7':'1', 
      '&:hover': {
          backgroundColor:'#57339F',
      },

    }

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });

    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));
  
    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const booking_number= localStorage.getItem('booking_no');
    const reviewId= localStorage.getItem('reviewId');
    const businessname=localStorage.getItem('businessname');

    const productId= localStorage.getItem('productId')
    const [isLoading, setIsLoading] = useState(true);
    const Baseurl=process.env.REACT_APP_BASE_URL;
    const [service, setservice]=useState([]);

   

    const [text, settext]=useState('')
  
    const access= localStorage.getItem("accessToken");
    const businessId=localStorage.getItem('storeId');

    const [starColor1, setStarColor1] = useState('lightgray');


   const [customstyle, setcustomstyle]= useState(customButtons);

    const [rating1, setRating1] = useState(0);
   
    const [showimage, setshowimage]=useState(false)

    const [update, setupdate]=useState(false)

    const navigate=useNavigate();

    const [file, setfile]=useState([]);
    const [name, setname]=useState([]);

    const [review, setreview]=useState()
   

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
  
    
  
     const [openSnackbar1, setopenSnackbar1]=useState({
  
      openSnackbar: false,
      vertical: 'top',
      horizontal: 'center',
     });
  
  
     const { vertical, horizontal, openSnackbar } = openSnackbar1;
  
     const handleClose = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
        navigate(-1);
      setopenSnackbar1({...openSnackbar1, openSnackbar:false});
  
    };

    const [openSnackbar2, setopenSnackbar2]=useState({

      opentoast: false,
      verticaltoast: 'top',
      horizontaltoast: 'center',
     });
  
  
     const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;
  
     const handleCloseToast = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setopenSnackbar2({...openSnackbar2, opentoast:false});
  
    };

    const [openSnackbar3, setopenSnackbar3]=useState({

      opentoast1: false,
      verticaltoast1: 'top',
      horizontaltoast1: 'center',
     });
  
  
     const { verticaltoast1, horizontaltoast1, opentoast1 } = openSnackbar3;
  
     const handleCloseToast1 = (reason) => {
      if (reason === 'clickaway') {
        return;
        
      }
      navigate(-1);
      setopenSnackbar3({...openSnackbar3, opentoast1:false});
  
    };
  
     

    const handleRatingChange1 = (newRating) => {
     
      if (newRating === rating1) {

         const newColor = starColor1 === 'lightgray' ? 'var(--primary)' : 'lightgray';
         setStarColor1(newColor);

       } else {
       
         setRating1(newRating);
         setStarColor1('var(--primary)');
       
       }   
      
    };

  

    const handleFileChange = (e,newState) => {

       /*
     if(showimage)
     {

      let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/customer-delete-review-image-web/${reviewId}/?business_owner_id=${businessId}&image="hkljljhhoioijo"`, requestOptions)
        .then(response => response.text())
        .then(result => {

          const data =JSON.parse(result)

          if(data.status==='Success')
          {
            const files = e.target.files;

              const selectedFiles = [...file, ...files].slice(0, 5);
              setfile(selectedFiles);
              setname(selectedFiles.name);
              setshowimage(false)
     

      if (file.length + files.length > 5) {

        setopenSnackbar2({ ...newState, opentoast: true });
      }
 
      const fileInput = e.target.files;

        }
      })
        .catch(error => console.log('error', error));
    }
    else 
    { */
        const files = e.target.files;

              const selectedFiles = [...file, ...files].slice(0, 5);
              setfile(selectedFiles);
              setname(selectedFiles.name);
              setshowimage(false)
     

      if (file.length + files.length > 5) {

        setopenSnackbar2({ ...newState, opentoast: true });
      }
 
      const fileInput = e.target.files;

   // }


    };

    const handleChange=(e)=>
    {
       
        const value = e.target.value;
        settext(value);
    }

   

    useEffect(()=>
    {
     
      const apiUrl25=`${Baseurl}/consumer/customer-get-single-review/${reviewId}/?business_owner_id=${businessId}`;

      const fetchData = async()=>
      {
          try 
          {
            
                 const response2= await fetch(apiUrl25)
                 
                 const Review=await response2.json();
                
                 if(Review.status==='Success')
                 {
                  console.log(Review.review)
                  setreview(Review.review)
                  setupdate(true)
                 }
                
                 
                  setIsLoading(false)
          }
          catch (error) {
            console.log(error);
            setIsLoading(false);
          }
      };
  
      if (isLoading) {
        fetchData();
      }
        
    },[isLoading])
       
  useEffect(()=>
  {
    if(review)
    {
        if(parseInt(review[0].c_rating)>0)
        {
          setStarColor1('var(--primary)');
        }
     
        if(review[0].c_review_images && review[0].c_review_images.length!==0)
        {
          setfile(review[0].c_review_images)
          setshowimage(true)
        }

        setRating1(parseInt(review[0].c_rating))
        settext(review[0].c_review_message)
      
      
    }
  },[review])

  

  const Submit=(newState)=>
  {
   
    if(update)
    {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}` ); 

      let formdata = new FormData();
      formdata.append("co_order_number", booking_number);
      formdata.append("bo_product_id", productId);
      formdata.append("c_rating", rating1);
    
      formdata.append("c_review_message", text);
      formdata.append("business_owner_id", businessId);

      if (!update) {
        {file?.map(cur=>
          {
              formdata.append("review_images", cur, cur.name);
          })}
      } else {
        formdata.append("review_images", "");
      }
     
      let requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
          
  fetch(`${Baseurl}/consumer/customer-update-single-review/${reviewId}/`, requestOptions)
  .then(response => response.text())
  .then(result=>{
      const data= JSON.parse(result);
      
      if(data.status==='Success')
      {
       
        setopenSnackbar3({ ...newState, opentoast1: true })
        setIsLoading(false);
        localStorage.removeItem('productId')
       
      }

  })
  .catch(error => console.log('error', error));
    }

    else 
    {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}` ); 

        let formdata = new FormData();
        formdata.append("co_order_number", booking_number);
      formdata.append("bo_product_id", productId);
        formdata.append("c_rating", rating1);
      
        formdata.append("c_review_message", text);
        formdata.append("business_owner_id", businessId);

        {file?.map(cur=>
          {
              formdata.append("review_images", cur, cur.name);
          })}
      
        
        
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
   
   
            
    fetch(`${Baseurl}/consumer/customer-create-single-review/`, requestOptions)
    .then(response => response.text())
    .then(result=>{
        const data= JSON.parse(result);
        
        if(data.status==='Success')
        {
         
          setopenSnackbar1({ ...newState, openSnackbar: true })
          setIsLoading(false);
          localStorage.removeItem('productId')
         
        }

    })
    .catch(error => console.log('error', error));
  } 

   }  


   const handleRemoveImage = (index) => {
    const updatedImages = file.filter((_, i) => i !== index);
    setfile(updatedImages);
  };


  useEffect(() => {
    if (starColor1 === 'var(--primary)'  && file.length!==0 && text!=='') {
      setshowbutton(false);
    } else {
      setshowbutton(true);
    }
  }, [starColor1,file,text]);


     return (
        <>

        
        
                 <section className= {isSmScreen? "slider-header service-margin": "section1 explore-padding mt-20"}>
                <Container maxWidth='lg' className="p-16">
            
                <div className="breadcrumb mobile-bodypadding">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyledNavLink to={`/${businessname}/order-history`} >
          My Order
        </StyledNavLink>
        <StyledNavLink
              to={`/${businessname}/bookingId/${booking_number}`}  
            >
              {booking_number}
            </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/customer-review`}  
        >
          Review
        </StyleNavLink>
        </Breadcrumbs>
        </div>
            
                       <Box sx={{width:"100%"}}>
                       <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >

                        <Grid item xs={12} sm={isSmScreen ? 12 : 7} md={7} >

                       <Items  className="global_box_shadow">
                    
                            <div className="bookingId-cancelled">
                           
                             <div className="booking_title">Order ID: {booking_number}</div>
                
                             <div className="ratings-data">
                           <div className="customer-said customer-review">Rating</div>
                           <StarRatings  rating={rating1} starRatedColor={starColor1}    starEmptyColor="lightgray"   starHoverColor={starColor1}   changeRating={handleRatingChange1}   numberOfStars={5} starDimension="20px"  starSpacing="5px" />
                           </div>
                            
                             
                         </div>
                            
                       </Items>
                     
                       <Items  className="global_box_shadow">
                       
                       <div>
                       <div className="ratings-data">
                        <div className="customer-said customer-review">Add a Written Reviews</div>
                         <div>
                           <div className="reviews-idea" >Write your Reviews</div>
                           <TextareaAutosize minRows={5} className="textareaautosize" value={text} placeholder="write why you liked our service ?"  onChange={handleChange} ></TextareaAutosize>
                         </div>
                        </div>
                        </div>

                       </Items>
                       </Grid>

                    <Grid item xs={12}sm={isSmScreen ? 12 : 5} md={5} >
                       <Items className="global_box_shadow">
                       <div>
                       <div className="ratings-data">
                        <div className="customer-said customer-review">Add a Photo or video</div>
                         <div>
                           <div className="reviews-idea" >Add photo of the services</div>
                            <div className="add-photo">

                        
                              {file.length===0 ? <label htmlFor='fileinput'><Addlogo/></label> :
                              <div className="image-uploader">
                              {file?.map((image, index)=>{

                                  return (
                                    <>
                                      <div key={index} className="image_container" >
                                     {showimage ?<label htmlFor='fileinput'><div className="image_lazyloads"><LazyLoadImage  src={image}  alt="category" className="checkoutimage" /></div></label>   : <label htmlFor='fileinput'><div className="image_lazyloads"><LazyLoadImage  src={URL.createObjectURL(image)}  alt="category" className="checkoutimage" /></div></label>}
                                       <div className="delete-image" onClick={() => handleRemoveImage(index)}><HighlightOffTwoToneIcon  style={{color:"black", transform:'scale(0.7)'}}/></div>
                                       </div>
                                    </>
                                  )
                              })}
                              </div>
                            }
                        
                        
                            <input type="file"  id="fileinput" style={{display:"none"}} multiple onChange={(e)=>handleFileChange(e, { vertical: 'top', horizontal: 'center' })}/>
                          

                            </div>

                         </div>
                        </div>
                        </div>
                     {isSmScreen ? "" :  <div className="addressdatebutton experience-service"><Button style={custom} className="primary_button" onClick={()=>Submit({ vertical: 'top', horizontal: 'center' })} disabled={showbutton} >Submit</Button> </div>}  

                       </Items>

                       
                        {isSmScreen ?  <Items> <Button style={custom} className="primary_button" onClick={()=>Submit({ vertical: 'top', horizontal: 'center' })} disabled={showbutton} >Submit</Button>  </Items> : ""}  

                       
                         
                           </Grid>
                     </Grid>
                     </Box>
                     <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                         Review published successfully!
                           </Alert>
                      </Snackbar>

                    </Stack>

                     <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast1} autoHideDuration={2000}  key={verticaltoast1 + horizontaltoast1}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast1}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                          Review update successfully!
                           </Alert>
                      </Snackbar>

                    </Stack>

                       <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}  >
                     <Alert  severity="error" sx={{ width: '100%' }}>
                         Maximum 5 images upload
                           </Alert>
                      </Snackbar>

                    </Stack>
                   
                  </Container>
          </section>
         
        </>
                          
     )

}

export default Customerreview;

/* 




   {(name===''|| name===undefined)? <Addlogo/> : <div className="image_lazyload"><LazyLoadImage  src={Image}  alt="category" className="checkoutimage" /></div>} 


 formdata.append("booking_number", booking_number);
   formdata.append("service_id", service[0].service_id);
    formdata.append("overall_services", rating1);
    formdata.append("staff_behaviour", rating2);
    formdata.append("services_on_time", rating3);
    formdata.append("services_by_staffs", rating4);
    formdata.append("review_message", text);
    formdata.append("images", file, name);



 <div className="mylocations">

                          <div><CloseIcon className="close-icon"/></div>
                           </div>

 

<div className="mylocations">
                         <div className="customer-said customer-review"> Services on time</div>
                        <div><CloseIcon className="close-icon" /></div> 
                         </div>


                       */