import React from "react";
import Approvepayment from "./Approvepayment";
import Headerpaymentgetway from "./Headerpaymentgetway";

const Paymentgatway=()=>
{

   

     return (
        <>
            <div className="contain">
              <Headerpaymentgetway />
           
            <div className="content">

           
            
               <Approvepayment/>
         
        
             
             </div>
             </div>

        </>
     )
}

export default Paymentgatway;