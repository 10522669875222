import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import {createTheme, useMediaQuery } from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Aboutus = () => {

    const theme = createTheme({
        breakpoints: {
          values: {
            md: 0,
            sm: 768,
          },
        },
      });

      const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
      const Baseurl=process.env.REACT_APP_BASE_URL;

  const location = useLocation();
  const { pathname } = location;
  const pathSegments = pathname.split('/');
  const encodedPart = pathSegments[1]; 
  const decodedPart = decodeURIComponent(encodedPart);

  const [about, setabout]=useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>
  {
    const apiUrl25=`${Baseurl}/consumer/customer-store-info/`;

     
    var formdata = new FormData();
    formdata.append("store_name", decodedPart);
    
    let requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const fetchData=async()=>
    {
        try 
        {
            const response2 = await fetch(apiUrl25, requestOptions);
            const business = await response2.json();
             const store=business.store_info;

             if(business.status==='success')
             {
                setabout(store)
             }
        }
        catch (error) {
            console.log(error);
            setIsLoading(false);
          }
    };
    if (isLoading) {
        fetchData();
      }
  },[isLoading])


  return (
       <>
       {about.length!==0 &&
                  <section className= {isSmScreen? "slider-header service-margin": "section mt-20 explore-padding"}>
            <Container maxWidth='lg' className='container-padding' style={{marginBottom:"60px"}}>
          
            <h1 className='head_title'>Shipping Policy</h1><br></br>
            <span className='policy_title'>The orders for the user are shipped through registered domestic courier companies and/or speed post only. Order are shipped within <span style={{fontWeight:"700"}}>2 </span> to <span style={{fontWeight:"700"}}>5 days</span> from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. </span><br></br><br></br><br></br>
            <span className='policy_title'>Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. </span><br></br><br></br><br></br>
            <span className='policy_title'>Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable. For any issues in utilizing our services you may contact our helpdesk on within <span style={{fontWeight:"700"}}>2 days</span>.</span><br></br><br></br>
            <h2 className='address_title' style={{margin:"0px", fontSize:'16px'}}>Store information</h2>
             <div className='policy_title'>Store name: {about[0]?.bo_name}</div><br></br>
            <h2 className='address_title' style={{margin:"0px"}}>Our Address</h2>
            <div className='policy_title'>{about[0]?.store_address}</div>
         </Container>
         </section>
}
       </>
  )
}

export default Aboutus