import React,{useEffect,useState} from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Box, Grid,createTheme, useMediaQuery } from "@mui/material";


const Bookmarkskeleton = () => {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const Items = styled(Paper)(({ theme }) => ({
    
        ...theme.typography.body2,
        padding: '20px',
        background: '#FFF',
        boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
        marginBottom:isSmScreen?'4px':'14px',
      
      }));
      


      const checkoutflex={

        display:'grid',
        gridTemplateColumns: isSmScreen ? '96px  auto' : '110px  auto',
        gridTemplateRows:'1fr',
        gridColumnGap:'18px'
    
      }
    
      const checkoutgrid=
      {
        display:'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows:'1fr',
        gridColumnGap:'18px',
        paddingBottom:'10px',
        marginBottom: '10px',
      }

      const [showpayment, setshowpayment] = useState(localStorage.getItem('showbutton'));

      const Showbutton = localStorage.getItem('showbutton');

      useEffect(()=>
   {

  if(Showbutton==='true')
  {
   
      setshowpayment(true);
  }
  else
  {
 
      setshowpayment(false);
  }
    
},[Showbutton])

const arrayLength = 4;



const item = Array.from({ length: arrayLength }, (_, index) => `Item ${index + 1}`);

  return (
    <>
     
     <Box sx={{width:"100%", boxSizing:"border-box"}}>
          <Grid container rowSpacing={1}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="start">
           
         {item.map(cur=> {
            return (
                <>
                  <Grid item  xs={12} sm={isSmScreen?12:6} md={6}>
       
       <Items className="global_box_shadow mobile-bodymargin">
       
       
                 
                 <div style={showpayment? checkoutflex : checkoutgrid} className="check_out_data" >
                 {showpayment &&  <div className="image_lazyload">

                   <Skeleton height="100px" className="checkoutimage roundimage" />
                 </div>
             }
             <div>
                 
                
                      <h2 className="label_titles text-wrapping" style={{ marginTop: "0px" }}>
                      <Skeleton width={"24vh"}/>            
                      </h2>

                 
       
       {showpayment &&   <div>
    
         
            <div className="priceIcon">
               
              <div className="expected_price"><span><Skeleton width="8vh"/></span></div>
            
             <div className="real_price"><span><del><Skeleton width="8vh"/></del></span></div>
            <div><Skeleton width="6vh"/></div>
           
         </div>
         
                  
        
                 <div className="checkoutdetails">
           
           <Skeleton width="3vh"/>
 
           </div>

           </div>
       }
       
              
           </div> 
           </div>  
             
        

       </Items>
        
 
     </Grid>
                </>
            )
         })}

         

         </Grid>
         </Box>
    </>
  )
}

export default Bookmarkskeleton;