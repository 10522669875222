import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";

const Headerpaymentgetway=()=>
{

     const navigate = useNavigate();

     const Back=()=>
     {

     }

    return (
        <>
            <div className="header">
                <div className="section schedule-online">
             <div>     
            <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            style={{color:"black"}}
          >
            <ArrowBackIcon  onClick={Back}/>
          </IconButton>
          </div> 
          <div><h1 className="schedule_title ">Payment Gateway</h1></div>
          </div>
            
            </div>

        </>
    )

}

export default Headerpaymentgetway;