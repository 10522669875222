import React, {useState} from "react";
import Footer from '../Footer/Footer'
import Bottomnavigation from '../Home/BottomNavigation'
import Header from '../Header/header';
import { useSelector } from 'react-redux'
import SearchFilter from '../Header/SearchFilter'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import {ReactComponent as Error} from '../assests/PageError.svg'


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:'40px 20px',
  
  
  }));

const Page404= () => {

    
    
   
   
  
  
   
  
  
  


  return (
      <>
       
      
       <div className="contain">

            
             <div className="content">
 

              
             <section className='section'>
              <div className="container">
             
    
              <Item className="global_box_shadow">
                <div className="empty_data">
                <div className="pageError"><Error/></div>
                <div>
                  <h1 className="head_title" style={{textAlign:"center", fontFamily: 'var(--fontfamily)'}}>404 - Page Not Found</h1>
                  <p className="label">The page you are looking for does not exist.</p>
                </div>
                </div>
              </Item>

              </div>

              </section>
        
              </div>
              <Bottomnavigation/>
              <Footer/>
              </div>
             
            
       
       
      
      </>
  )
}

export default Page404

/*             <Header Filter={Filter}  updateLocation={updateLocation} Message={Message} handleLoading={handleLoading} /> */