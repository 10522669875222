import React,{useState, useEffect} from "react";
import Paymentpaid from "./Paymentpaid";
import Header from "../Header/header.js";
import Footer from "../Footer/Footer";
import {createTheme,  useMediaQuery } from '@mui/material';
import { useNavigate } from "react-router-dom";




const BookingSuccessfull=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

 

    const businessname=localStorage.getItem('businessname');
  
        
        const navigate =useNavigate()
       
    
      useEffect(() => {
        const handleBackButton = (e) => {
          if (e.state && e.state.fromPaymentPage) {
      
             navigate(`/${businessname}/`);
          }
          else
          {
            navigate(`/${businessname}/`);
          }
        };
      
        window.addEventListener('popstate', handleBackButton);
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, []);



     return (
        <>
         
         <div className="contain">
               {isSmScreen? "" : <Header/> }
            <div className="content" style={{backgroundColor:"#f3f3f3", marginBottom:"0px"}} >
             <Paymentpaid/>  
         
             </div>
             {isSmScreen?"":  <Footer/>  }
           </div>

        </>
     )
}

export default BookingSuccessfull