import React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import {ReactComponent as Phonecall} from "../assests/Phonecall.svg";
import {createTheme,  useMediaQuery } from '@mui/material';




const TrackingSlider = ({bookingdetails}) => {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding:'8px 0px',
    marginTop:"8px",
    width: isSmScreen ? '244px' :'300px',

  }));

  const [activeStep, setActiveStep] = useState(1);
 
  const [activeStep2, setActiveStep2] = useState(0);
  const [activeStep3, setActiveStep3] = useState(0);
  const [activeStep4, setActiveStep4] = useState(0);
  const [activeStep5, setActiveStep5] = useState(0);
  const [activeStep6, setActiveStep6] = useState(0);
  const [activeStep7, setActiveStep7] = useState(0);
  const [activeStep8, setActiveStep8] = useState(0);
 
  const [confirm, setConfirm]=useState(false);
  const [shipped, setShipped]=useState(false);
  const [delevired, setDelevired]=useState(true);
  const [cancel, setCancel]=useState(false);
  const [rejected, setRejected]=useState(false);
  const [returnInitiated, setReturnInitiated]=useState(false);
  const [returnCompleted, setReturnCompleted]=useState(false);

  const [trackingStep, settrackingStep] = useState(bookingdetails[0].order_status);



   const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric'};
  
    const dateParts = dateString?.split('T');
   const inputDate = dateParts[0];
  
    const [hours, minutes] =dateParts&& dateParts[1]?.split(":").slice(0, 2);
    const formattedTime = `${hours % 12 || 12}:${minutes} ${hours < 12 ? 'AM' : 'PM'}`;
  
    const [year, month, day] = inputDate?.split('-');
    const dateObject = new Date(`${year}-${month}-${day}`);
    const formattedDate = dateObject.toLocaleString('en-IN', options);
  
    return `${formattedDate}, ${formattedTime}`;
  };

    const formattedDateTime1 = trackingStep?.step1.date!=="" && formatDate(trackingStep?.step1.date);
    const formattedDateTime2 = trackingStep?.step2.date!=="" && formatDate(trackingStep?.step2.date);
    const formattedDateTime3 = trackingStep?.step3.date!=="" && formatDate(trackingStep?.step3.date);
    const formattedDateTime4 = trackingStep?.step4.date!=="" && formatDate(trackingStep?.step4.date);
    const formattedDateTime5 = trackingStep?.step5.date!=="" && formatDate(trackingStep?.step5.date);
    const formattedDateTime6 = trackingStep?.step6.date!=="" && formatDate(trackingStep?.step6.date);
    const formattedDateTime7 = trackingStep?.step7.date!=="" && formatDate(trackingStep?.step7.date);
    const formattedDateTime8 = trackingStep?.step8.date!=="" && formatDate(trackingStep?.step8.date);
  

useEffect(()=>
{
  if(trackingStep.step2.date!=="")
  {
          setActiveStep(2);
          setActiveStep2(2)
          setConfirm(true)
  }
 if(trackingStep.step3.date!=="")
  {
     setActiveStep(3);
     setActiveStep3(3)
     setShipped(true)
  }
  if(trackingStep.step4.date!=="")
  {
      setActiveStep(4);
      setActiveStep4(4)
      setDelevired(true)
  }
      
  if(trackingStep.step5.date!=="")
  {
          setActiveStep(5);
          setDelevired(false)
          setCancel(true)
          setActiveStep5(5)
  }
 if(trackingStep.step6.date!=="")
  {
      setActiveStep(6);
      setDelevired(false)
      setRejected(true)
      setActiveStep6(6)
  }
  if(trackingStep.step7.date!=="")
  {
      if(trackingStep.step4.date!=="")
      {
        setDelevired(true)
      }
      else
      {
        setDelevired(false)
      }
      setActiveStep(7);
      setReturnInitiated(true)
      setCancel(false)
      setActiveStep7(7)
  }

  if(trackingStep.step8.date!=="")
  {
    if(trackingStep.step4.date!=="")
    {
       setDelevired(true)
    }
    else
    {
      setDelevired(false)
    }
    
     setActiveStep(8);
     setReturnCompleted(true)
     setActiveStep8(8);
     setCancel(false)
  }


},[activeStep])


 
  
  return (
       <>
       
       <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
       
          <Step key={trackingStep.step1.caption}>
           <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step1.status) === 1 ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38)!important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step1.status) === 1 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>

              {trackingStep.step1.caption}
              <div className='step-time'>{formattedDateTime1}</div>
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>

           
       {confirm &&   <Step key={trackingStep.step2.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step2.status) === activeStep2 ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step2.status) === activeStep2 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step2.caption}
              {formattedDateTime2 &&  <div className='step-time'>{formattedDateTime2}</div>} 
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
}
          
        {shipped &&  <Step key={trackingStep.step3.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step3.status) === activeStep3  ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step3.status) === activeStep3 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step3.caption}
               {formattedDateTime3 &&  <div className='step-time'>{formattedDateTime3}</div>} 
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
}
          
      {delevired &&   <Step key={trackingStep.step4.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step4.status) === activeStep4 ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step4.status) === activeStep4 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step4.caption}
              {formattedDateTime4 &&  <div className='step-time'>{formattedDateTime4}</div>}
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
  }
      {cancel &&    <Step key={trackingStep.step5.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step5.status) === activeStep5  ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step5.status) === activeStep5 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step5.caption}
              {formattedDateTime5 &&  <div className='step-time'>{formattedDateTime5}</div>}
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
}
        {rejected &&  <Step key={trackingStep.step6.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step6.status) === activeStep6  ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step6.status) === activeStep6 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step6.caption}
              {formattedDateTime6 &&  <div className='step-time'>{formattedDateTime6}</div>} 
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
}
       {returnInitiated &&   <Step key={trackingStep.step7.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step7.status) === activeStep7  ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step7.status) === activeStep7 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step7.caption}
              {formattedDateTime7 &&  <div className='step-time'>{formattedDateTime7}</div>} 
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
}
       {returnCompleted &&   <Step key={trackingStep.step8.caption}>
          <StepLabel sx={(theme) => ({
             '& .MuiStepIcon-root': {
               color: parseInt(trackingStep.step8.status) === activeStep8  ? '#26a541 !important' : 'rgba(0, 0, 0, 0.38) !important',
               height:'15px !important'
              },
              '& .MuiStepLabel-label':
              {
                fontSize:'14px !important',
                 fontFamily:'var(--fontfamily) !important',
                 fontWeight:parseInt(trackingStep.step8.status) === activeStep8 ? '600 !important' : '400 !important',
                 lineHeight:'20px !important'
              }
           })}>
              {trackingStep.step8.caption}
              {formattedDateTime8 &&  <div className='step-time'>{formattedDateTime8}</div>} 
            </StepLabel>


            <StepContent>
             
              <Box sx={{ mb: 2 }}>
                <div>
                 
                </div>
              </Box>
            </StepContent>
          </Step>
}   
      </Stepper>
    </Box>

    </>
  );
};

export default TrackingSlider;




