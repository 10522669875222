import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import {ReactComponent as ShoppingBagOutlinedIcon} from '../assests/Bag.svg';
import {ReactComponent as Bookmark} from "../assests/Bookmark.svg";
import { connect ,useDispatch} from "react-redux";
import { Handlemycheckout } from "../Redux/action";

const Headercheckout=({itemAdded})=>
{

     const navigate = useNavigate();

     const dispatch=useDispatch()

     const Back=()=>
     {
          navigate(-1);
     }

  const servicename =localStorage.getItem('productname');
  const businessname=localStorage.getItem('businessname');
  const access= localStorage.getItem("accessToken");
     
  const Shopping = ()=>
  {
    
       if(itemAdded===true)
       {
          dispatch(Handlemycheckout(true))
          navigate(`/${businessname}/viewcart`);
       }
  
       else
       {
         navigate(`/${businessname}/empty-cart`);
       }
     
  
  }
  
      const Bookmarkdata=()=>
      {
         if(access===null)
         {
           navigate(`/${businessname}/connect`);
         }
         else
         {
          navigate(`/${businessname}/bookmark`);
         }
   
      }

    return (
        <>
         
          <div className="secondary-header mobile-header">

               
         
             <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            style={{color:"black"}}
         
          >
            <ArrowBackIcon    onClick={Back} />
          </IconButton>
        
         <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', width:'100%'}}>
         <div className="schedule-online">
         <h1>{servicename}</h1>
        </div>

         <div style={{display:'flex',alignItems:'center', gridColumnGap:'10px'}}>

         <div className='bookmark' onClick={Shopping}>

         <div className='handleLogins shoppingBag' style={{width:'18px', height:'20px',position:'relative'}}><ShoppingBagOutlinedIcon/></div>
           {itemAdded && <div className="red-dot"></div>} 
              </div>

         <div className='bookmark' onClick={Bookmarkdata}>
         <div className='handleLogins'><Bookmark/></div>
        </div>
      
        </div>  
        </div>


        </div>
         

        </>
    )

}

const mapStateToProps = (state) => {
  return {
    itemAdded: state.itemAdded,
  };
};

export default connect(mapStateToProps)(Headercheckout);


/*    <div className="schedule-onlines"> */