import React from "react";
import { Box, Grid,createTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Button from '@mui/material/Button';

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });



const Timingskeleton=()=>
{

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  
    const Item1 = styled(Paper)(({ theme }) => ({
      
      ...theme.typography.body2,
      padding:"20px",
      borderRadius:'6px',
      marginBottom:isSmScreen?"10px":"20px",
      boxShadow: '0px 1px 12px 0px rgba(48, 47, 47, 0.08)',
    
    }));

    const  customButton= {
        backgroundColor:'#ebebeb',
        width:'100%',
   
      };
      
      const  customButtonprocessed= {
     backgroundColor:'#ebebeb',
      width:'100%',
      
      
      };

     return (
        <>
         <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >
          <Grid item  xs={12} sm={isSmScreen? 12:7} md={7}>
      

                 <Item1 className="global_box_shadow" style={{cursor:'pointer'}}>
          <div style={{display:'flex',gridColumnGap:'8px'}}>
          <div ><Skeleton width={"4vh"}/></div>
            
                  <div style={{display:'grid', gridRowGap:'6px'}}>
        
        <div className='priceonline'><Skeleton width={"24vh"}/></div>
        <div className='priceonline'><Skeleton width={"22vh"}/></div>
        <div style={{display:'flex', gridColumnGap:'8px'}}>
        <div><Skeleton width={"12vh"}/> </div>
        <div> <Skeleton width={"12vh"}/></div>
        </div>
        <div style={{display:'flex', gridColumnGap:'8px'}}>
        <div><Skeleton width={"12vh"}/> </div>
        <div><Skeleton width={"12vh"}/> </div>
        <div> <Skeleton width={"12vh"}/></div>
        </div>
        </div>

             </div>
             </Item1>
           


              <Item1 className="global_box_shadow" style={{cursor:'pointer'}}>
                <div className='addIconaddress' >
                  <div style={{display:'flex', alignItems:'center'}}><Skeleton width={"6vh"}/></div>
                  <div  style={{display:'flex', alignItems:'center'}}><Skeleton width={"20vh"}/></div>
                </div>

              </Item1>

            </Grid>

           
          <Grid item xs={12} sm={isSmScreen? 12:5} md={5}>

        

           <Item1 className="global_box_shadow">
           <div className="coupon">
           <div className="offerIcon">
           <div className="localoffericon"><Skeleton width={"2vh"}/></div>
            <div className="apply"><Skeleton width={"10vh"}/></div>
            </div>
            
            <div className="keyboard"><Skeleton width={"2vh"}/></div>
            </div>
           </Item1>
            
          

            <Item1 className=" global_box_shadow">
        
          
              <div className='item_booking'>
            <div className="bill_details"><Skeleton width={"14vh"}/></div>
            
              <div className="totalcost">
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}><Skeleton width={"10vh"}/></div>
               {(()=>
                 {

                  return <div className="totalprices"><Skeleton width={"10vh"}/></div>                     
                 })()}

              </div>

              <div className="totalcost">
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}><Skeleton width={"10vh"}/></div>
             
               {(()=>
                 {
                  return <div className="totalprices"><Skeleton width={"10vh"}/></div>
                     
                 })()}
                  
              </div>

              <div className="totalcost">
               <div className="total_discount"><Skeleton width={"10vh"}/></div>
               
                  <div>

                   {(()=>
                 {

                   return <div className="total_discount"><Skeleton width={"10vh"}/></div>
                   
                 })()}
                 </div>
               
              </div>

            
           <div className="border"></div>              
    
              <div className="totalcost">
               
               <div className="total_money"><Skeleton width={"10vh"}/></div>
          
             <div className="totalprices"><Skeleton width={"10vh"}/></div>
                    
             
                    
             
              </div>
              </div>
                
              <Grid item xs={false}  sm={isSmScreen ? 0: 12} md={12}  display= {{ xs: 'none', md: 'flex' }} style={{ display: isSmScreen ? 'none' : 'flex',  paddingTop:'10px'}}>
                     
              <Button style={customButtonprocessed}><Skeleton height={"4vh"} /></Button>  

                      </Grid>
     
                 <Grid item xs={12}  sm={isSmScreen ? 0: 12}  display= {{ xs: 'flex',  md: 'none' }} style={{ display: isSmScreen ? 'flex' : 'none', paddingTop:'10px' }} justifyContent={'center'}>
     
                 <Button style={customButton}><Skeleton height={"4vh"} /></Button>  
    
               
                  </Grid>

            </Item1>
         
                            
         
            
         
            </Grid>

            </Grid>
            </Box>
           
        </>
     )
}

export default Timingskeleton