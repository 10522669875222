
import  React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Modal from '@mui/material/Modal';
import Auth from '../Authentication/Login';
import {NavLink, useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Verification from '../Authentication/Verification';
import Successfully from "../Authentication/Successfully";
import Logged from "../Authentication/Logged";
import {ReactComponent as SignoutLogo} from "../assests/Signout.svg"
import { connect, useSelector, useDispatch } from "react-redux";
import { Handlemyaddress, Handlemycheckout, UpdateName, locationUpdate } from '../Redux/action';
import {ReactComponent as ShoppingBagOutlinedIcon} from '../assests/Bag.svg';
import {ReactComponent as AvatarLogo} from "../assests/Avatar.svg";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {ReactComponent as MyaddressLogo} from "../assests/Myaddress.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Container from '@mui/material/Container';
import {createTheme,  useMediaQuery } from '@mui/material';
import SearchFilter from './SearchFilter';

import {ReactComponent as Bookmark} from "../assests/Bookmark.svg";

import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';



 const Header=({handleLoading,  itemAdded, logo, handle} )=> {


  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const [totalvalue, setTotalvalue] = useState();
    const [open, setOpen]=useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const verifyMobile= useSelector(state=>state.phone);

    const Logoitem=useSelector(state=>state.logo);

   
     
    const Baseurl=process.env.REACT_APP_BASE_URL;

    const access= localStorage.getItem("accessToken");

    const settings = [
      { icon: <AvatarLogo />, text: 'My Account' },
      { icon: <MyaddressLogo />, text: 'My Addresses' },
      { icon: <ShoppingBagOutlinedIcon />, text: 'My Orders' },
      { icon: <SignoutLogo />, text: 'Logout' }
    ];

    const selectedImage = useSelector((state) => state.image);
    const selectedName = useSelector(state=>state.Name);
 
    const businessname=localStorage.getItem('businessname');
    const businessId=localStorage.getItem('storeId');
   
 
  const dispatch = useDispatch();
  const navigate=useNavigate();
    
  const Showdata=useSelector(state=>state.Showdata)

  const logincredentials=useSelector(state=>state.logincredentials)


 
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const items=useSelector(state=>state.search);
  const [searchResult, setsearchResults] = useState(items);
  const [show, setShow]=useState(false);
  const handleCloseShow=()=>setShow(false);

useEffect(()=>
{
  if(logincredentials)
  {
     handleOpen()
  }

},[logincredentials])

const Filter = (e)=>
{
        
          const length = e.target.value.length; 
          if(length!==0)
          {
             setShow(true);
             const searchTerm = e.target.value.toLowerCase();
             const foundItems = items.filter(
               (item) =>
                 item.bo_product_name &&
                 item.bo_product_name.toLowerCase().includes(searchTerm)
             );
         
            setsearchResults(foundItems);   
          }
          else{
              setShow(false);
          }
}


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

 
 
 
 const Close=(values)=>
 {
    
    switch(values)
    {
       case 0 : {
            //  navigate(`/${businessname}/profile`);
              setAnchorElNav(null);
      }
       break;

        case 1: {
          
          dispatch(Handlemyaddress(true));
          navigate(`/${businessname}/my-address`);
          
       } break;

       case 2: {
        
            navigate(`/${businessname}/order-history`);
         
       } break;

       case 3: {
        localStorage.removeItem("accessToken");
        setAnchorElNav(null);
        dispatch(UpdateName(""));
      //  navigate(`/${businessname}/`)
       }
       break;
    }

 } 
 const Hide=(values)=>
 {
    
    switch(values)
    {
       case 0 : {
              navigate(`/${businessname}/profile`);
              setAnchorElNav(null);
      }
       break;

       case 1: {
       
            dispatch(Handlemyaddress(true));
            navigate(`/${businessname}/my-address`);
       }
        break;

        case 2: {
       
         navigate(`/${businessname}/order-history`);
      }
       break;
     
       case 3: {
        localStorage.removeItem("accessToken");
        navigate(`/${businessname}/`)
        setAnchorElNav(null);
        dispatch(UpdateName(""));
       }
       break;
    }

 }



  
const handleInput=(e)=>
{
     
    const value=e.target.value;
    setTotalvalue(value);
    Filter(e);
}


  const Search=()=>
  {  
     //navigate(`/${businessname}/all-category`);  
  } 



const Shopping = ()=>
{
  
     if(itemAdded===true)
     {
        dispatch(Handlemycheckout(true))
        navigate(`/${businessname}/viewcart`);
     }

     else
     {
       navigate(`/${businessname}/empty-cart`);
     }
   

}

    const Bookmarkdata=()=>
    {
       if(access===null)
       {
          handleOpen()
       }
       else
       {
        navigate(`/${businessname}/bookmark`);
       }
 
    }

const location = useLocation();
const { pathname } = location;
const pathSegments = pathname.split('/');
const encodedPart = pathSegments[1]; 
const decodedPart = decodeURIComponent(encodedPart);

  return (

    <>
        

 {decodedPart!==""  &&   
  <div className="header">
      <Container maxWidth='lg' className="p-16">
    <div className='header_show'>
    <div className='place'>
   <NavLink to={`/${businessname}/`} className='logoImage'>{(Logoitem!==null && Logoitem!==undefined && Logoitem!=="") ? <LazyLoadImage  className='logo' src={Logoitem} alt="logo" /> : <LazyLoadImage className='logo' src='https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png' alt="logoimage" />}</NavLink> 
     
   
        </div>

      
     <div className='input_search_value'>
     <input className="input_search" type="text"  value={totalvalue}  onChange={handleInput} placeholder='Search for products...'/>
     <div className='searchingfilter'><SearchOutlinedIcon/></div>
     <div className="search_valueshow">{show &&  <SearchFilter searchResult={searchResult}
              handleCloseShow={handleCloseShow} handle={handle}  />}</div>

     </div>
           
     <div className="search_item">
   
     <div className='search_valueitem'>

          {access===null ?
       <Box sx={{display: { xs: 'none',sm:"flex", md: 'flex' }}}>
        <div style={{display:"flex", alignItems:"center", gridColumnGap:'20px'}}>

        <div className='bookmark' onClick={Shopping}>
          
        <div className='handleLogins shoppingBag' style={{width:'18px', height:'20px',position:'relative'}}><ShoppingBagOutlinedIcon/></div>
                <div  className='handleLogins'>Cart</div>
                 
                {itemAdded && <div className="red-dot"></div>}
                  </div>

         <div className='bookmark' onClick={Bookmarkdata}>
         <div className='handleLogins'><Bookmark/></div>
        <div className='handleLogins'>Bookmark</div>
        </div>

        <div className='bookmark' onClick={handleOpen}>
       <div  style={{fontWeight:500}} className='handleLogin'> <AvatarLogo/></div>
       
      
      <div className='handleLogins'>
        Account
      </div>
      
       </div>
       </div>


             </Box>
                :


            <Box sx={{display: { xs: 'none',sm:'flex', md: 'flex' }, gridColumnGap:'20px'}} >


               <div className='bookmark' onClick={Shopping}>
                 
               <div className='handleLogins shoppingBag' style={{width:'18px', height:'20px',position:'relative'}}><ShoppingBagOutlinedIcon/></div>
                <div  className='handleLogins'>Cart</div>
                 
                {itemAdded && <div className="red-dot"></div>}
                  </div>

                   
                     <div className='bookmark' onClick={Bookmarkdata}>
                     <div className='handleLogins'><Bookmark/></div>
                     <div className='handleLogins'>Bookmark</div>
                     </div>

                <div style={{display:"flex", alignItems:"center"}}> 
                {(selectedName===null || selectedName==="") ? 

                (
                <div className='bookmark' onClick={handleOpenNavMenu}>

                  <div className='handleLogins'> <AvatarLogo/></div>
                  
                <div  className='handleLogins' style={{display:'flex', gridColumnGap:'2px'}} > Hi, Guest <KeyboardArrowDownOutlinedIcon  style={{width:'12px', height:'12px'}}/></div> 
                 </div>
                 )
                :
                (
                <div className='bookmark' onClick={handleOpenNavMenu}>  
                 <div className='handleLogins'> <AvatarLogo/></div>
                 <div className='handleLogins' style={{display:'flex', gridColumnGap:'2px'}}> Hi, {selectedName} <KeyboardArrowDownOutlinedIcon  style={{width:'12px', height:'12px'}} /> </div>  
                 </div>
                )

                 }

            
                 </div>
                  <Menu
                        id="menu-appbar"
                  anchorEl={anchorElNav}
                   anchorOrigin={{
                     vertical: 'bottom',
                   horizontal: 'left',
                  }}
            keepMounted
          transformOrigin={{
           vertical: 'top',
          horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
            sx={{
           display: { xs: 'none',sm:'block', md: 'block' },zIndex:9999
               }}
            >
            {settings.map((setting, index) => (
           <MenuItem key={setting} onClick={()=> Hide(index)}>
          <Typography className='profile_setting'>
            <div className='handleLogins' style={{width:'16px', height:'18px',position:'relative'}}>{setting.icon}</div>
          <div style={{fontSize:'14px', fontFamily:'var(--fontfamily)', fontWeight:'500'}}>{setting.text}</div>
          </Typography>
         </MenuItem>
        ))}
           </Menu>
         </Box>

    }

           <div><Modal open={open}  ><Auth handle={handleClose}/></Modal></div>

         
        </div>

          </div>
 
          </div>

          </Container>
           </div>
  }

           </>

  );
}


const mapStateToProps = (state) => {
  return {
    itemAdded: state.itemAdded,
  };
};

export default connect(mapStateToProps)(Header);

/*
  <div className='handleLogins'><KeyboardArrowDownOutlinedIcon/></div>
                 </div>
 className='opennavmenu'
    <div className='guest shopping' onClick={Shopping}>
 <div className='shoppingBag'><ShoppingBagOutlinedIcon/>Cart</div>
  <section className= {isSmScreen? "m-10": "section"}>
           <Container maxWidth='lg' className="p-16">
               </Container>
          </section>

             {(()=>
             {
                    if(access!==null && verifyMobile===null)
                    {
                      return (
                        <>
                        
                        <Box sx={{display: { xs: 'none',sm:"flex", md: 'flex' }}}>
               <div style={{display:"flex", alignItems:"center", gridColumnGap:'20px'}}>
           </div>
            <div><Modal    open={open}  ><Auth handle={handleClose}  handleVerify={handleverifyOpen} 
            handleOpen={handleSuccessOpen}  handleSuccessfullyClose = {handleSuceessClose}    /></Modal></div>

              <div><Modal open = {successOpen}><Successfully/></Modal></div>
           <div><Modal    open={verifyOpen}  ><Verification handleVerifyClose={handleverifyclose}
             handleLoggedInOpen = {handleLoggedOpen} handleLoggedInClose = {handleLoggedClose} /></Modal></div>
             <div><Modal open ={loggedOpen}><Logged/></Modal></div>
             </Box>
                        </>
                      )
                    }

               


             })()}








const searchParams= new URLSearchParams(window.location.search);
      searchParams.forEach((_, key) => searchParams.delete(key));
      searchParams.set("city", selectCity)
      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

<Avatar alt="Remy Sharp" src={selectedImage} style={{marginLeft:"6px"}} />

<Avatar alt="Remy Sharp" src={selectedImage} style={{marginLeft:"6px"}} />


 <div className='guestheader shopping' onClick={Shopping}>
                  <div><ShoppingBagOutlinedIcon/></div>
                  {itemAdded && <div className="red-dot"></div>}
                  </div>

line number 418 mein lagega

  
        <select className="city" value={selectCity} onChange={handleLocationChange}  >
        
        
       {locationdatas && locationdatas.map(city=>(
      <option key={city.location_id} value={city.location_name}>{city.location_name}</option>
       ))}
        </select>



        
const [data, setData] = useState([]);

 

  const fetchData = () => {
    fetch(`${Baseurl}/locations/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
          
        }
        console.log(response)
        return response.json();
      })
      .then(json => setData(json))
      .catch(error => console.error(error));
  };

  useEffect(()=>
  {
      fetchData();
  })
*/