import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import {createTheme, useMediaQuery } from "@mui/material";

const HeaderorderId=()=>
{

     const navigate = useNavigate();
 
     const businessname=localStorage.getItem('businessname');

     const Back=()=>
     {
          navigate(`/${businessname}/order-history`);
     }


  const booking_number= localStorage.getItem('booking_no');
     
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });



    return (
        <>
         
      
          <div className="secondary-header mobile-header">
          <Container maxWidth='lg' className="p-16">
          <div className="schedule-online" style={{left:'-12px',position:'relative'}}>
         
             <IconButton
            size="large"
            aria-label="menu"
            style={{color:"black"}}
          >
            <ArrowBackIcon onClick={Back}/>
          </IconButton>
        
         <h1>Order ID: {booking_number}</h1>
        </div>

        </Container>
        </div>
     
     
        </>
    )

}


export default HeaderorderId;