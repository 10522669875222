import React, { useState, useEffect } from "react";
import {ReactComponent as BookingLogo} from '../assests/Booking.svg';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate, NavLink } from "react-router-dom";
import { createTheme, useMediaQuery, Box, Grid, Pagination } from '@mui/material';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Orderskeleton from "./Orderskeleton";


 

  const Items = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
     borderRadius:"6px 6px 0px 0px",
     padding:'20px',
    
  
  }));

const Orders=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 360,
        sm: 0,
      },
    },
  });

  const themes = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });



  const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: 'inherit',
    fontSize:'12px',
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const StyleNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    ariaCurrent:"page",
    fontSize:'12px',
    color:"var(--primary)",
    fontWeight:'500',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const isSmScreen = useMediaQuery(themes.breakpoints.down('sm'));

 const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    borderRadius:'6px',
    padding:'20px',
   cursor:'pointer',
   marginBottom:isSmScreen?'4px':'14px',
  
  }));

  const Baseurl=process.env.REACT_APP_BASE_URL;

  const access= localStorage.getItem("accessToken");

  const isxsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [upbooking, setupbooking] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;



  const [totalcount, settotalcount]=useState()

   const customButtons={
    backgroundColor: '#4D1BDB', 
    color:'var(--white)',
    textAlign: 'center',
    textTransform:'none',
    fontSize:isxsScreen?'14px':'16px',
    letterSpacing: '0.8px',  
    outline:'1.5px solid var(--primary)',
    '&:hover': {
      backgroundColor: '#4D1BDB', 
    },
   }
  
  
      const businessname=localStorage.getItem('businessname');

      localStorage.removeItem('productId')

      const [upcoming, setupcoming]=useState(true);

      const booking_number= localStorage.getItem('booking_no');

      const [showup, setshowup]=useState(false);

      const [loading, setloading] = useState(true);

      const navigate=useNavigate();

  const Accepted=(bookingNo)=>
  {
    console.log(bookingNo);
      navigate(`/${businessname}/bookingId/${bookingNo}`);
      localStorage.setItem('booking_no',bookingNo);
  }

 

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(()=>
  {
   
    
    if(access!==null)
    {

      setloading(true)
     let myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${access}`);

    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };


    
    fetch(`${Baseurl}/consumer/customer-order-history-pagination/?page=${currentPage}&page_size=10`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data=JSON.parse(result);
 
        const upcoming=data.order_history;

        setupbooking(upcoming);
        settotalcount(data.total_count)
        setshowup(true);
        console.log(data);
        setloading(false)
        
      })
      .catch(error => console.log('error', error)); 
    }
  },[currentPage])

  
  const options = { day: 'numeric', month: 'short', year: 'numeric' };

  const Explore=()=>
  {
      navigate(`/${businessname}/all-category`)
  }

  
  function formatIndianNumber(number) {
    const parts = number.toString().split(".");
    let integerPart = parts[0];
    let formattedInteger = "";
  
    while (integerPart.length > 3 && number>=10000) {
      formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
      integerPart = integerPart.slice(0, -3);
    }
    formattedInteger = integerPart + formattedInteger;
  
    return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
  }


    return (
        <>

             
       <Box sx={{width:"100%"}}>
          <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:2, md:2}} display="flex" justifyContent="start" >
          <Grid item  xs={12}  sm={12} md={12}  >

          <section className= {isSmScreen? "m-10": "section1 p-10"}>
            
            <Container maxWidth='lg' className="p-16">

        
       <div className="breadcrumb mobile-bodypadding">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/order-history`}  
        >
          My Order
        </StyleNavLink>
        
      </Breadcrumbs>
      </div>
          
          {loading ? 
          <>
           <Orderskeleton/>
            </>
         
          :
           <>
          {(()=>
          {
                  if(showup)
                  {
                    return (
                    <>
                     {upcoming && (upbooking.length===0  ?
           <div>
            
           <div className="bookinglogo"><BookingLogo/></div>
           <h1 className="hero_title bookingyet">No Booking yet!</h1>
           <h2 className="explore_titlewide" >Explore wide range of service near by <br></br> you.</h2>

          <div className="bookingbutton"><Button style={customButtons} onClick={Explore} className="primary_button">Add Item</Button></div> 
           </div>
           :
             <div className={isSmScreen ? {}:"mb"}>
             
             {upbooking.map(cur=>
                  {
                   
                    return (
                      <>

              <Item className="global_box_shadow" onClick={()=>Accepted(cur.order_number)}>
                  <div className="bookingid">
            

               <div className="booking_detailsdata">
               
               <div className="booking_titledata" style={{fontWeight:400}}><span style={{fontWeight:500}}>Order Id:</span> {cur.order_number}</div>
               { (()=>
                  {
                           if(cur.status_caption==='Confirm' || cur.status_caption==='Shipped' || cur.status_caption==='Delivered')
                           {
                             return  <div className="booked">{cur.status_caption}</div>
                           }
                           else if (cur.status_caption==='Canceled' || cur.status_caption==='Rejected' || cur.status_caption==='Return initiated')
                           {
                                return <div className="accepted">{cur.status_caption}</div>
                           }
                           else
                           {
                             return <div className="placed">{cur.status_caption}</div>
                           }
                  })()}

               </div>
 
               {
                  cur.product_details.length!==0 && cur.product_details.map(cur=>
                    {
                       return (
                        <>
                                 {cur.varient_info?.length!==0 ? 
                                <div className="booking_name" style={{width:'70%',fontWeight:400}}>
                                        <span style={{fontWeight:500}}>Product Name:</span> {cur.product_name} 
                                       
                                        </div>
                              :
                              <div className="booking_name" style={{width:'70%',fontWeight:400}} ><span style={{fontWeight:500}}>Product Name:</span> {cur.product_name}</div>

                       } 
                             
                        </>
                       )
                     
                         
                    })
                  }
         
              
           <div className="booking_name" style={{fontWeight:400}}><span style={{fontWeight:500}}>Price:</span> ₹{formatIndianNumber(cur.total_amount)}</div>
                  
                  <div className="booking_detailsdata">
               
                  {(() => {
                     const itemCount = cur.product_details.length;
                        return (
                          <div>
                             <div className="booking_time">{itemCount} {itemCount === 1 ? 'item' : 'items'}</div>

                          </div>
                        );
                      })()}
               
            
               <div className="bookingdata">  
                                         

                {(()=>
                {
                  
                  const dateParts = cur.schedule_date.split('T');
                  const inputDate = dateParts[0];
                  
                  const [hours, minutes] = dateParts[1].split(":").slice(0, 2);
                  const formattedTime = `${hours % 12 || 12}:${minutes} ${hours < 12 ? 'AM' : 'PM'}`;
                  
                    return  <div className="booking_time">{inputDate+', '+formattedTime}</div>
                        
                })()}

                </div>

               </div>
                

               </div>

               </Item>   
                           
                      </>
                    )}) }
                  </div>
           )}
                 
               <Pagination
                    count={Math.ceil(totalcount / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    sx={{'& .MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: 'var(--primary)',
                      color: 'var(--white)',
                    }, marginBottom:"60px", display:"flex", justifyContent:'flex-end'}}/>
                    </>
                    ) }
          
          })()}
          </>
        }
         
              </Container>
           </section>

          </Grid>
          </Grid>
      
          </Box>

             
        </>
    )
   
  
}

export default Orders;
/*  
[{cur.varient_info[0] && cur.varient_info[0].bo_product_variant_value}{cur.varient_info.length>1 && " + " }{cur.varient_info.length>1 && cur.varient_info[1].bo_product_variant_value}]        
                  */
