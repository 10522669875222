import React, {useState, useEffect} from "react";
import Header from "../Header/header";
import  SearchFilter from "../Header/SearchFilter.js"
import Footer from "../Footer/Footer";
import Searchresult from "./Searchresult";
import { useSelector,useDispatch } from "react-redux";
import Bottomnavigation from "./BottomNavigation";
import Headersearch from "./Headersearch";
import { createTheme, useMediaQuery } from '@mui/material';
import Categoryheader from "../Header/Categoryheader";
import { Handlemyload } from "../Redux/action.js";

const Searchshow=()=>
{

  const themes = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(themes.breakpoints.down('sm'));

   const item=useSelector(state=>state.search);

    const [searchResult, setsearchResults] = useState(item);
    const [show, setShow]=useState(false);

    const [loadingdata, setloadingdata]=useState(true);
    const [value, setValue] = useState([]);
   
    const  CityID=JSON.parse(localStorage.getItem("CityId"));
   
    const [isLoading, setIsLoading] = useState(true);
    
   const dispatch=useDispatch()

    const [value2, setValue2] = useState([]);

    const  filter  = localStorage.getItem("CategoryId")

    const [CategoryId, setcategoryId] = useState(filter);

    const [Update, setUpdate] =useState();
    const [filterdata, setFilterdata] = useState(filter);
 
    const businessId=localStorage.getItem('storeId');

    const servicevalue=useSelector(state=>state.servicevalue)

   const handleCloseShow=()=>setShow(false);

    const Baseurl = process.env.REACT_APP_BASE_URL;

  
    const updateLocation=(locationdata)=>
    {
        setUpdate(locationdata);
        console.log(locationdata);
    }

    const businessname=localStorage.getItem('businessname');
    const Categorydata=JSON.parse(localStorage.getItem('Allcategorydata'))


const selectedfilter=(filterItem)=>
{
  
 if(filterdata>1)
 {
   setcategoryId(filterdata)
  console.log(filterdata);
   setFilterdata(0);
 }
 else
 {
   console.log(filterdata);
   setcategoryId(filterItem);
 }
 

}

  const handleCategory=(loading)=>
  {
       setIsLoading(loading);
       
  }

  const handleCategory1=(loading)=>
  {
     setIsLoading(loading);
  }

  const handleCategory2=(loading)=>
  {
       setIsLoading(loading);
  }
  


  const handle=(categoryid)=>
  {
    setcategoryId(categoryid)
  }


/*

 useEffect(()=>
 {
   
   setcategoryId(filter)
 
  
 },[filter])

*/

useEffect(() => {


    const fetchData = async () => {
      try {
      
        setIsLoading(true);
       
      
        const apiUrl11 = `${Baseurl}/consumer/product-search/`;

        let formdata = new FormData();
        
        formdata.append("business_owner_id", businessId);
        formdata.append("category_id", filter);

         let requestOptions = {
         method: 'POST',
         body: formdata,
        redirect: 'follow'
        };

         let response,data
         dispatch(Handlemyload(true))
          setloadingdata(true);
          
          response = await fetch(apiUrl11, requestOptions);
          data = await response.json();

          console.log(data)

          const categoryService = data.results;
          const categoryData = data.sub_category;


          const updatedItems =categoryData.map(item => ({ ...item, selected: false }));
          setValue(updatedItems);

    

          //  const category= categoryService.filter(category => category.category_id === CategoryId);

              setValue2(categoryService); 
            
          setloadingdata(false);
        
     
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      
        setIsLoading(false);
      }
    };
 
    fetchData();
  }, [CityID,Update, CategoryId,filter]);
  
 
 
  const handleLoading=()=>
{
  
}


    return (
        <>
             <div className="contain">
                {isSmScreen ?<Headersearch/>  :<><Header  handleLoading={handleLoading} style={{position:"fixed",top:0 }} /></>}
                {!isSmScreen && <Categoryheader  value={Categorydata}/>}
              <div className="content">
             <Searchresult value = {value} setValue={setValue} value2={value2} loading={loadingdata}  /> 
              
             </div>
             <Footer/>
             
             </div>
        </>
    )
    
}
export default Searchshow;

/*

selectedfilter = {selectedfilter}  handleCategory={handleCategory}  handleCategory1={handleCategory1}  handleCategory2={handleCategory2} 

              <Searchresult value = {value} setValue={setValue} value2={value2}  selectedfilter = {selectedfilter} loading={loadingdata} handleCategory={handleCategory}  handleCategory1={handleCategory1}  handleCategory2={handleCategory2}  />


<div className="contain">
                {isSmScreen ?<Headersearch/>  :<><Header  updateLocation={updateLocation}   handleLoading={handleLoading} /> <Categoryheader value={value} /></>}
              <div className="content" style={{backgroundColor:"#f3f3f3"}}>
              <Searchresult value = {value} setValue={setValue} value2={value2}  selectedfilter = {selectedfilter} loading={loadingdata} handleCategory={handleCategory}  handleCategory1={handleCategory1}  handleCategory2={handleCategory2}  />
              
             </div>
              <Footer/>
             </div>


}
        if(((CategoryId===0 || CategoryId===null) &&  filterdata<1) && ((filterdata <1) || Update!==""))
       {

           console.log(CategoryId)

        setloadingdata(true);
          response = await fetch(apiUrl11);
          data = await response.json();

          const categoryDataService = data.services;
          const categoryData = data.category;

          const updatedItem = categoryData.map(item => ({ ...item, selected: false }));
          setValue(updatedItem);

          setValue2(categoryDataService);
          setloadingdata(false);
        }
        */

